<template>
  <div
    class="container"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="gotoCoding"
    :style="hover ? hoverStyle : unHoverStyle"
  >
    <div class="left-side">
      <!-- <TopZero /> -->
      <div class="front">
        <div class="block1">
          <div class="top-left-zero-container">
            <TopZero />
          </div>
          <div class="bottom-l-shape-container">
            <LShaped />
          </div>
        </div>
        <div class="blockTwo">
          <div class="top-one-container">
            <TopOne />
          </div>
          <div class="bottom-middle-container">
            <div class="bottom-zero-container">
              <BottomLeftZero />
            </div>
            <div class="bottom-plus-container">
              <Plus
                :strokeColor="myColors.onBackground"
                :strokePosition="bottomPlusPosition"
              />
            </div>
          </div>
        </div>
        <div class="block3">
          <div class="top-zero-container">
            <TopRightZero />
          </div>
          <div class="bottom-one-container">
            <BottomOne />
          </div>
        </div>
      </div>
      <div class="back">
        <div class="plus-row">
          <div class="right-plus">
            <Plus :strokeColor="myColors.main" />
          </div>
          <div class="left-plus">
            <Plus :strokeColor="myColors.onBackground" />
          </div>
        </div>
        <RadioBox />
      </div>
    </div>
    <div class="right-side">
      <div class="right-side-back">
        <div
          class="top-background-block"
          :style="{ 'background-color': myColors.foregroundAccentActive }"
        ></div>
      </div>
      <div class="right-side-front">
        <div class="l-container">
          <LShaped />
        </div>
        <div class="right-bottom-container">
          <div class="title-container">
            <Header :sectionName="codingTitle"></Header>
          </div>
          <div class="right-content-container">
            <div class="text-container">
              <TextSection
                :bodyText="codingBody"
                :layoutConfiguration="textLayout"
                :bottomBoxHeight="bottomHeight"
              />
            </div>
            <div class="bottom-graphics-container">
              <div class="bottom-graphics-left">
                <div></div>
                <div>
                  <BottomRightZero />
                </div>
              </div>
              <div class="bottom-graphics-right">
                <div></div>
                <div>
                  <div>
                    <div>
                      <div
                        :style="{
                          'background-color': myColors.foregroundAccentActive,
                        }"
                      ></div>
                      <div
                        :style="{
                          'background-color': myColors.foregroundAccentActive,
                        }"
                      ></div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";
import TopOne from "@/components/svg/TopOne.vue";
import BottomLeftZero from "@/components/svg/BottomLeftZero.vue";
import TopRightZero from "@/components/svg/TopRightZero.vue";
import RadioBox from "@/components/svg/RadioBox.vue";
import LShaped from "@/components/svg/LShaped.vue";
import BottomOne from "@/components/svg/BottomOne.vue";
import TopZero from "@/components/svg/TopZero.vue";
import BottomRightZero from "@/components/svg/BottomRightZero.vue";
import Plus from "@/components/svg/Plus.vue";
import Header from "@/components/appBody/section/desktop/Header.vue";
import TextSection from "@/components/appBody/section/desktop/TextSection.vue";
import { data } from "@/core/data/index";

export default {
  store: store,
  components: {
    TopOne,
    Header,
    Plus,
    TopRightZero,
    TextSection,
    BottomRightZero,
    TopZero,
    LShaped,
    BottomOne,
    BottomLeftZero,
    RadioBox,
  },
  data() {
    return {
      firstPlusPosition: {
        right: "1.625rem",
        top: "0.8rem",
      },
      secondPlusPosition: {
        left: "24rem",
        top: "0.8rem",
      },
      bottomPlusPosition: {
        left: "9.3rem",
      },
      codingTitle: "software engineering",
      textLayout: "14.5rem auto",
      textHeight: "15.8rem",
      bottomHeight: "1.375rem",
      hover: false,
    };
  },

  computed: {
    isDark: function() {
      return this.$store.getters.isDark;
    },
    myColors: function() {
      return colors(this.isDark, this.$store);
    },
    codingBody: function() {
      return data.written.skills.engineering.description;
    },
    hoverStyle: function() {
      return {
        background: this.isDark
          ? "rgb(255 243 204 / 5%)"
          : "rgba(250, 113, 63, 0.12)",
      };
    },
    unHoverStyle: function() {
      return { background: "" };
    },
  },
  methods: {
    gotoCoding() {
      this.$router.push("/software_engineering");
    },
  },
};
</script>

<style scoped>
.container {
  width: 65rem;
  height: 55rem;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}
.right-side {
  width: 30rem;
  height: 100%;
}
.left-side {
  width: 31rem;
  height: 100%;
}
.back {
  width: 100%;
  height: 38.75rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.plus-row {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.right-plus {
  width: 3rem;
  height: 100%;
}
.left-plus {
  width: 3rem;
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}
.front {
  height: 55rem;
  width: 31rem;
  position: absolute;
  z-index: 10;
  display: grid;
  grid-template-columns: 10.5rem auto 8rem;
}

.block1 {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.block1 > * {
  width: 100%;
}
.blockTwo {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.blockTwo > * {
  width: 100%;
}
.block3 {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.block3 > * {
  width: 100%;
}
.front > * {
  height: 100%;
}
.top-zero-container {
  height: 24rem;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.bottom-one-container {
  height: 23rem;
}
.top-one-container {
  height: 11.625rem;
  display: flex;
  flex-flow: row nowrap;
}
.bottom-middle-container {
  height: 18rem;
  display: grid;
  grid-template-rows: 16.8rem auto;
}
.bottom-middle-container > * {
  width: 100%;
}
.bottom-zero-container {
  display: flex;
}
.bottom-plus-container {
  display: flex;
  flex-flow: row nowrap;
}
.top-left-zero-container {
  height: 31rem;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.bottom-l-shape-container {
  height: 9rem;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
.bottom-l-shape-container > * {
  transform: rotate(180deg);
  position: relative;
  left: -6.8rem;
}
.right-side-back {
  width: 30rem;
  height: 19rem;
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}
.right-side-front {
  height: 52.57rem;
  width: 30rem;
  z-index: 10;
  position: relative;
  top: 2.5rem;
  display: grid;
  grid-template-rows: 8.6rem auto;
}
.top-background-block {
  width: 9rem;
  height: 16rem;
  position: relative;
  left: 2.5rem;
}
.l-container {
  display: flex;
  flex-flow: column;
}
.l-container > * {
  transform: rotate(270deg);
}
.right-bottom-container {
  display: grid;
  grid-template-columns: 3.8rem auto;
}

.title-container {
}
.right-content-container {
  display: grid;
  grid-template-rows: 15.8rem auto;
}
.text-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.text-container > * {
  float: left;
}
.bottom-graphics-container {
  display: grid;
  grid-template-columns: 8rem auto;
}
.bottom-graphics-right {
  display: grid;
  grid-template-rows: auto 10.6rem;
}
.bottom-graphics-right > div:nth-child(1) {
}
.bottom-graphics-right > div:nth-child(2) {
  display: grid;
  grid-template-columns: 11rem auto;
}
.bottom-graphics-right > div:nth-child(2) > div:nth-child(1) {
}
.bottom-graphics-right > div:nth-child(2) > div:nth-child(1) > div {
  height: 3.25rem;
  width: 4rem;
  float: right;
}
.bottom-graphics-right > div:nth-child(2) > div:nth-child(1) > div {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
}
.bottom-graphics-right
  > div:nth-child(2)
  > div:nth-child(1)
  > div
  > div:nth-child(1) {
  grid-column: 1 / 1;
  height: 1rem;
}
.bottom-graphics-right
  > div:nth-child(2)
  > div:nth-child(1)
  > div
  > div:nth-child(2) {
  grid-column: 3 / 3;
}
.bottom-graphics-right > div:nth-child(2) > div:nth-child(2) {
}
.bottom-graphics-left {
  display: grid;
  grid-template-rows: auto 17.5rem;
}
.bottom-graphics-left > div:nth-child(1) {
}
.bottom-graphics-left > div:nth-child(2) {
}
</style>
