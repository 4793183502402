<template>
  <svg
    width="282"
    height="142"
    viewBox="0 0 282 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4401 37.718C29.6963 37.718 38.0105 29.4038 38.0105 19.1476C38.0105 8.89142 29.6963 0.577148 19.4401 0.577148C9.1839 0.577148 0.869629 8.89142 0.869629 19.1476C0.869629 29.4038 9.1839 37.718 19.4401 37.718Z"
      :fill="myColors.illustration.bottomRightNode.circle"
    />
    <path
      d="M107.054 55.9709L107.848 60.8913L28.0109 27.2423L26.4237 26.6074C25.9475 26.29 25.4713 25.6551 25.1539 25.0202C24.519 23.2742 25.4713 21.2109 27.2173 20.576C28.0109 20.2585 28.6458 20.2585 29.4394 20.576L30.0743 20.8934L109.118 54.0663L107.054 55.9709Z"
      :fill="myColors.illustration.bottomRightNode.circle"
    />
    <path
      d="M259.903 117.396L257.205 123.745L169.749 86.9216L157.369 81.6838L156.576 81.3664C155.941 81.0489 155.306 80.414 154.988 79.4617C154.353 77.7158 155.306 75.6524 157.052 75.0175C157.845 74.7001 158.48 74.7001 159.274 75.0175L159.909 75.1762L174.194 81.2076L259.903 117.396Z"
      :fill="myColors.illustration.bottomRightNode.circle"
    />
    <path
      d="M262.443 141.204C272.7 141.204 281.014 132.89 281.014 122.634C281.014 112.378 272.7 104.063 262.443 104.063C252.187 104.063 243.873 112.378 243.873 122.634C243.873 132.89 252.187 141.204 262.443 141.204Z"
      :fill="myColors.illustration.bottomRightNode.circle"
    />
    <path
      d="M149.433 107.079L112.769 94.3815L107.69 60.7325L107.055 55.9708L143.719 68.6686L144.83 76.4459L149.433 107.079Z"
      :fill="myColors.illustration.bottomRightNode.circle"
      stroke="#FA713F"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M157.369 81.6839L169.75 86.9217L149.433 107.079L144.83 76.446L143.719 68.6687L143.878 68.51L168.639 43.9081L174.194 81.2077L159.909 75.1763L159.274 75.0176C158.639 74.8588 157.845 74.8588 157.052 75.0176C155.306 75.6524 154.354 77.7158 154.988 79.4618C155.306 80.2554 155.782 80.8903 156.576 81.3664L157.369 81.6839Z"
      fill="#FFFDF7"
      :stroke="myColors.illustration.bottomRightNode.boxOutline"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M168.639 43.9081L143.878 68.5099L143.719 68.6687L107.055 55.9709L109.118 53.9075L131.974 31.2103L168.639 43.9081Z"
      fill="#FFFDF7"
      :stroke="myColors.illustration.bottomRightNode.boxOutline"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { store } from "@/store/store";
import { codingColors } from "@/core/colors.js";
export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style>
</style>