export const colors = function (isDark, store) {
  console.log(isDark);
  return isDark
    ? {
      main: store.getters.darkPrimary,
      background: store.getters.backgroundDark,
      onBackground: store.getters.onBackgroundDark,
      foregroundAccent: store.getters.foregroundAccentDark,
      foregroundAccentActive: store.getters.foregroundAccentDark,
      foreground: store.getters.foregroundDark,
      mosaic: {
        warm: store.getters.darkAccentFaint,
        accent: store.getters.darkAccentFainter
      },
    }
    : {
      main: store.getters.lightPrimary,
      background: store.getters.backgroundLight,
      onBackground: store.getters.onBackgroundLight,
      foregroundAccent: store.getters.foregroundAccent,
      foreground: store.getters.foregroundLight,
      foregroundAccentActive: store.getters.foregroundAccentActive,
      mosaic: {
        warm: store.getters.foregroundFaint,
        accent: store.getters.accentFaint
      },
    };
};

export const codingColors = function (isDark, store) {
  return isDark
    ? {
      backgroundBox: {
        background: store.getters.darkPrimary,
        line: store.getters.backgroundLight,
        dots: store.getters.darkPrimary,
        rimFill: store.getters.backgroundLight,
      },
      leftMostZero: {
        background: store.getters.foregroundAccent,
        line: store.getters.darkPrimary,
        depthOutline: store.getters.backgroundLight,
        depthFill: store.getters.backgroundDark,
      },
      rightMostBottomZero: {
        depthBg: store.getters.backgroundDark,
        depthLn: store.getters.darkPrimary,
      },
      rightMostTopZero: {
        outline: store.getters.darkPrimary,
        depthOutline: store.getters.backgroundLight,
      },
      firstTopMostOne: {
        depthHighlight: store.getters.backgroundLight,
        outline: store.getters.darkPrimary,
        depthOutline: store.getters.backgroundLight,
      },
      bottomOne: {
        depthFill: store.getters.darkPrimary,
        depthOutline: store.getters.backgroundLight,
      },
      light: {
        highlight: store.getters.backgroundLight,
      },
      dPointerOutline: store.getters.darkPrimary,
      dPointerDepthFill: store.getters.darkPrimary,
      dPointerDepthOutline: store.getters.onBackgroundDark,
      dSlideContainerDepthOutline: store.getters.onBackgroundDark,
      dSliderContainerPrimary: store.getters.darkPrimary,
      dSlideHandle: {
        depth: store.getters.darkPrimary,
        depthOutline: store.getters.onBackgroundDark,
        outline: store.getters.darkPrimary,
      },
      dCursor: {
        depth: store.getters.darkPrimary,
        depthLines: store.getters.darkPrimary,
        depthOutline: store.getters.onBackgroundDark,
        foreOutline: store.getters.darkPrimary,
      },
      dLShape: store.getters.darkPrimary,
      engineering: {
        topOne: {
          depth: store.getters.darkPrimary,
          depthOutline: store.getters.onBackgroundDark,
          topdepthOutline: store.getters.onBackgroundDark,
          mainOutline: store.getters.darkPrimary,
        },
        topRightZero: {
          depth: store.getters.darkPrimary,
          mainOutline: store.getters.onBackgroundDark,
        },
        topZero: {
          outline: store.getters.darkPrimary,
        },
        bottomOne: {
          outline: store.getters.darkPrimary,
          depthDark: store.getters.darkPrimary,
          depthDarkOutline: store.getters.onBackgroundDark,
        },
        bottomMiddleZero: {
          depth: store.getters.backgroundDark,
          depthOutline: store.getters.darkPrimary,
          outline: store.getters.foregroundAccent,
        },
        topLeftZero: {
          depth: store.getters.backgroundDark,
          depthOutline: store.getters.onBackgroundDark,
        },
        radioBox: {
          depth: store.getters.darkPrimary,
          depthOutline: store.getters.onBackgroundDark,
          depthLines: store.getters.onBackgroundDark,
          meshBackground: store.getters.backgroundDark,
          indicatorDepth: store.getters.darkPrimary,
          indicatorOutline: store.getters.lightPrimary,
          rimOutline: store.getters.darkPrimary,
        },
        bottomRightZero: {
          depth: store.getters.darkPrimary,
          depthOutline: store.getters.onBackgroundDark,
          outline: store.getters.onBackgroundDark,
        },
      },
      illustration: {
        topRightHandle: {
          circleAndBar: store.getters.darkPrimary,
          nodeBar: store.getters.darkPrimary,
          boxOutline: store.getters.darkPrimary,
        },
        pencil: {
          tip: store.getters.darkPrimary,
          bodyOutlinesAndLines: store.getters.darkPrimary,
          eraserTipOutlineAndLines: store.getters.onBackgroundDark,
          eraser: store.getters.darkPrimary,
          eraserRimLinesAndOutlines: store.getters.onBackgroundDark,
          eraserHolderLines: store.getters.darkPrimary,
          eraserRimFill: store.getters.darkPrimary,
        },
        scroll: {
          pageFill: store.getters.onBackgroundDark,
          pageOutline: store.getters.darkPrimary,
          dot: store.getters.darkPrimary,
          backsideLines: store.getters.onBackgroundDark,
        },
        bottomRightNode: {
          boxDepth: store.getters.darkPrimary,
          boxOutline: store.getters.darkPrimary,
          circle: store.getters.darkPrimary,
          bar: store.getters.darkPrimary,
        },
      },
    }
    : {
      backgroundBox: {
        background: store.getters.backgroundLight,
        line: store.getters.foregroundLight,
        dots: store.getters.lightPrimary,
        rimFill: store.getters.backgroundLight,
      },
      leftMostZero: {
        background: store.getters.foregroundAccent,
        line: store.getters.lightPrimary,
        depthOutline: store.getters.lightPrimary,
        depthFill: store.getters.foregroundAccent,
      },
      rightMostBottomZero: {
        depthBg: store.getters.lightPrimary,
        depthLn: store.getters.darkPrimary,
      },
      rightMostTopZero: {
        outline: store.getters.foregroundLight,
        depthOutline: store.getters.lightPrimary,
      },
      firstTopMostOne: {
        depthHighlight: store.getters.backgroundLight,
        outline: store.getters.foregroundLight,
        depthOutline: store.getters.lightPrimary,
      },
      bottomOne: {
        depthFill: store.getters.backgroundLight,
        depthOutline: store.getters.lightPrimary,
      },
      light: {
        highlight: store.getters.backgroundLight,
      },
      dPointerOutline: store.getters.onBackgroundLight,
      dPointerDepthFill: store.getters.lightPrimary,
      dPointerDepthOutline: store.getters.lightPrimary,
      dSlideContainerDepthOutline: store.getters.onBackgroundLight,
      dSliderContainerPrimary: store.getters.lightPrimary,
      dSlideHandle: {
        depth: store.getters.lightPrimary,
        depthOutline: store.getters.lightPrimary,
        outline: store.getters.lightPrimary,
      },
      dCursor: {
        depth: store.getters.lightPrimary,
        depthLines: store.getters.lightPrimary,
        depthOutline: store.getters.onBackgroundLight,
        foreOutline: store.getters.lightPrimary,
      },
      dLShape: store.getters.lightPrimary,
      engineering: {
        topOne: {
          depth: store.getters.lightPrimary,
          depthOutline: store.getters.onBackgroundDark,
          topdepthOutline: store.getters.onBackgroundLight,
          mainOutline: store.getters.lightPrimary,
        },
        topRightZero: {
          depth: store.getters.lightPrimary,
          mainOutline: store.getters.lightPrimary,
        },
        bottomOne: {
          outline: store.getters.lightPrimary,
          depthDark: store.getters.foregroundAccent,
          depthDarkOutline: store.getters.lightPrimary,
        },
        bottomMiddleZero: {
          depth: store.getters.lightPrimary,
          depthOutline: store.getters.lightPrimary,
          outline: store.getters.onBackgroundLight,
        },
        topLeftZero: {
          depth: store.getters.foregroundAccent,
          depthOutline: store.getters.lightPrimary,
        },
        radioBox: {
          depth: store.getters.backgroundLight,
          depthOutline: store.getters.onBackgroundLight,
          depthLines: store.getters.onBackgroundLight,
          meshBackground: store.getters.backgroundLight,
          indicatorDepth: store.getters.lightPrimary,
          indicatorOutline: store.getters.lightPrimary,
          rimOutline: store.getters.lightPrimary,
        },
        bottomRightZero: {
          depth: store.getters.lightPrimary,
          depthOutline: store.getters.lightPrimary,
          outline: store.getters.onBackgroundLight,
        },
        topRightHandle: {
          circleAndBar: store.getters.lightPrimary,
          nodeBar: store.getters.lightPrimary,
        },
      },
      illustration: {
        topRightHandle: {
          circleAndBar: store.getters.lightPrimary,
          nodeBar: store.getters.lightPrimary,
          boxOutline: store.getters.lightPrimary,
        },
        pencil: {
          tip: store.getters.lightPrimary,
          bodyOutlinesAndLines: store.getters.foregroundLight,
          eraserTipOutlineAndLines: store.getters.lightPrimary,
          eraser: store.getters.foregroundAccent,
          eraserRimLinesAndOutlines: store.getters.lightPrimary,
          eraserHolderLines: store.getters.lightPrimary,
          eraserRimFill: store.getters.foregroundAccent,
        },
        scroll: {
          pageFill: store.getters.onBackgroundDark,
          pageOutline: store.getters.onBackgroundLight,
          dot: store.getters.lightPrimary,
          backsideLines: store.getters.onBackgroundLight,
        },
        bottomRightNode: {
          boxDepth: store.getters.lightPrimary,
          boxOutline: store.getters.lightPrimary,
          circle: store.getters.lightPrimary,
          bar: store.getters.lightPrimary,
        },
      },
    };
};
