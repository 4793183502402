<template>
  <div id="app" :style="{ 'background-color': myColors.background }">
    <AppBar />
    <router-view />
  </div>
</template>

<script>
import { store } from "./store/store";
import AppBar from "@/components/app_bar/AppBar.vue";
import { colors } from "@/core/colors.js";

export default {
  store: store,
  name: "App",
  components: {
    AppBar,
  },
  data() {
    return {
      bg: this.$store.getters.backgroundLight,
      isDark: this.$store.getters.isDark,
    };
  },
  watch: {
    activeBg: function () {
      if (this.isDark) {
        this.bg = this.$store.getters.backgroundDark;
      } else {
        this.bg = this.$store.getters.backgroundLight;
      }
    },
  },

  computed: {
    isDarkMode: function () {
      return this.$store.getters.isDark;
    },
    adaptedHeight: function () {
      if (this.$route.name == "Sentinel Digital") {
        return { "margin-bottom": "200%", height: "200%" };
      } else {
        return { "margin-bottom": "", height: "100%" };
      }
    },
    myColors: function () {
      return colors(this.isDarkMode, this.$store);
    },
    bgColor: function () {
      if (this.isDarkMode) {
        return "hsl(0, 0%, 25.9%)";
      } else {
        return "hsl(0, 0%, 92.2%)";
      }
    },
    backgroundColor: function () {
      if (this.isDarkMode) {
        return this.$store.getters.backgroundDark;
      } else {
        return this.$store.getters.backgroundLight;
      }
    },
  },
};
</script>

<style lang="css" scoped>
:root {
  --primary-dark: #dd7f5c;
  --priamry-light: #fa713f;
  --background-light: #fbfafa;
  --background-dark: #424242;
  --on-background-light: #5e5e5e;
  --on-background-dark: #fff;
  --foreground-dark: #ebebeb;
  --foreground-light: #000;
  --foreground-accent-dark: #535350;
  --foreground-accent-active: #fff3cc;
  --dark-accent-faint: rgba(255, 246, 217, 0.09);
}

.dark-bg {
  background: var(--background-dark);
}

.light-bg {
  background: var(--background-light);
}

html,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  overflow-x: hidden;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  scroll-behavior: smooth;
}

#app {
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  block-size: 100%;
  padding-block-end: 10rem;
  inline-size: 100vw;
  box-sizing: border-box;
  overflow-x: hidden;
}

@media screen and (max-width: 830px) {
  #app {
    block-size: 100vh;
    overflow-x: hidden;
  }
}

@media screen and (min-height: 960px) {
  #app {
    block-size: 100%;
    overflow-x: hidden;
  }
}

@media screen and (max-height: 960px) {
  #app {
    block-size: 100%;
    overflow-x: hidden;
  }
}

textarea,
select,
input,
button {
  outline: none;
}

.cta {
  height: 44px;
  background-color: #fffdf7;
  border: 1px solid;
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 3rem;
  transition: all 190 ease-out;
}

.cta:hover {
  cursor: pointer;
  background-color: #fff3cc;
}

.content-container {
  top: 26rem;
  position: relative;
  width: 51.687rem;
  margin: 0 auto;
}

.skill-illustration {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.work-item-header {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.625rem;
  letter-spacing: 0.6rem;
  text-transform: uppercase;
  text-align: left;
  margin: 0 0 2rem 0;
}

.work-item-header-mobile {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.625rem;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-align: left;
  margin: 0 0 2rem 0;
}

.work-item-header-alt {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.625rem;
  letter-spacing: 0;
  text-align: left;
  margin: 0 0 2rem 0;
}

.work-item-subhead {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: left;
}

@media (max-width: 480px) {
  .three-col-img {
    flex-flow: column !important;
  }
}
</style>
