<template>
  <svg
    fill="none"
    height="195"
    viewBox="0 0 175 195"
    width="175"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="myColors.dPointerDepthFill"
      :stroke="myColors.dPointerDepthOutline"
      d="M151.107 66.4389C150.786 63.556 149.665 60.8333 148.062 58.591C145.979 55.548 142.775 53.3057 138.769 52.1846L21.9652 20.9533C14.2744 18.8712 7.06431 22.3947 3.37914 28.3207C2.25756 30.2426 1.45644 32.3247 1.13599 34.5669V18.5509V15.9883V15.8282C1.93711 5.89821 11.7108 -1.62936 22.1255 1.09337L138.93 32.3247C145.819 34.0864 150.145 39.6921 151.107 45.7782V51.2236V66.4389Z"
    />
    <path
      :fill="myColors.dPointerDepthFill"
      :stroke="myColors.dPointerDepthOutline"
      d="M174.497 160.133C174.177 157.41 173.376 154.687 172.094 152.285C171.293 150.683 170.171 149.402 168.889 147.961L134.921 114.007C129.153 108.241 128.833 99.1117 133.8 92.8654C134.12 93.3459 134.601 93.8264 135.082 94.3069L169.049 128.261C172.094 131.304 174.016 135.148 174.657 139.152V145.238V160.133H174.497Z"
    />
    <path
      :stroke="myColors.dPointerOutline"
      d="M168.729 176.149L156.872 188.001C149.181 195.688 136.523 195.688 128.833 188.001L94.865 154.047C88.6162 147.8 78.6822 147.8 72.4335 154.047L60.8973 165.578C51.7644 174.707 36.2226 170.543 32.8579 158.051L1.45377 41.2937C1.29355 40.653 1.13332 40.0124 0.973098 39.3718C0.652648 37.7702 0.812873 36.1686 0.973098 34.567C1.29355 32.3247 2.09467 30.0824 3.21625 28.3207C6.90142 22.3947 14.1116 18.8712 21.8024 20.9533L138.606 52.1846C142.612 53.3057 145.817 55.548 147.899 58.591C149.502 60.9934 150.623 63.7161 150.944 66.4389V70.2827C150.463 73.8063 149.021 77.3298 146.137 80.0525L134.601 91.5841C134.28 91.9044 133.8 92.3849 133.479 92.8653C128.512 99.1116 128.833 108.241 134.601 114.007L168.568 147.961C169.85 149.242 170.972 150.683 171.773 152.285C173.215 154.687 174.016 157.41 174.176 160.133V163.977C174.016 168.461 172.093 172.785 168.729 176.149Z"
      fill="#FFFDF7"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
import { store } from "@/store/store";
import { codingColors } from "@/core/colors.js";

export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style scoped></style>
