<template>
  <svg width="91" height="209" viewBox="0 0 91 209" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M78.5443 26.9148C75.8353 24.3864 72.7652 22.3998 69.5144 20.955C64.8189 18.7879 59.7621 17.5237 54.1636 17.5237C48.0233 17.5237 42.0635 19.1491 37.0068 21.858C32.853 24.0252 29.2411 27.0954 26.3515 30.5267C24.7261 32.3327 23.4619 34.4999 22.3784 36.4865C19.6694 41.5432 18.2246 47.3223 18.2246 53.4627V172.296C18.2246 177.895 19.4888 183.132 21.656 187.647C23.1007 190.898 25.0873 193.787 27.4351 196.316L13.3485 182.229L13.1679 182.048C12.9873 181.868 12.8067 181.687 12.6261 181.507L10.2783 179.159C4.49917 172.838 0.887207 164.35 0.887207 154.959V36.1253C0.887207 27.4566 3.95737 19.5103 9.19471 13.1893C15.6962 5.24304 25.8097 0.186279 36.8262 0.186279C46.5785 0.186279 55.4278 3.97885 61.9293 10.2998L78.5443 26.9148Z"
      :fill="myColors.engineering.bottomMiddleZero.depth"
      :stroke="myColors.engineering.bottomMiddleZero.depthOutline"
    />
    <path
      d="M68.7915 53.4624V172.296C68.7915 180.242 62.29 186.744 54.3437 186.744H53.9825C46.0362 186.744 39.5347 180.242 39.5347 172.296V169.406C46.2168 168.142 51.4541 162.182 51.4541 155.139V39.1951C52.3571 39.0145 53.2601 39.0145 54.1631 39.0145C62.29 39.0145 68.7915 45.3355 68.7915 53.4624Z"
      :fill="myColors.engineering.bottomMiddleZero.depth"
      :stroke="myColors.engineering.bottomMiddleZero.depthOutline"
    />
    <path
      d="M81.9752 30.5268C81.614 30.1657 81.2528 29.6239 80.8916 29.2627C80.1692 28.3597 79.2662 27.6373 78.5438 26.9149C75.8348 24.3865 72.7647 22.3999 69.5139 20.9552C64.8184 18.788 59.7616 17.5238 54.1631 17.5238C48.0228 17.5238 42.063 19.1492 37.0063 21.8581C32.8525 24.0253 29.2406 27.0955 26.351 30.5268C24.7256 32.3328 23.4615 34.5 22.3779 36.4866C19.6689 41.5433 18.2241 47.3224 18.2241 53.4628V172.296C18.2241 177.895 19.4883 183.132 21.6555 187.647C23.1003 190.898 25.0868 193.787 27.4346 196.316L27.6152 196.496C27.7958 196.677 27.9764 196.857 28.157 197.038L28.5182 197.399C29.2406 198.122 30.1436 199.025 31.0466 199.747C37.3675 204.984 45.3138 208.235 54.1631 208.235C63.0124 208.235 71.1393 204.984 77.2796 199.747C85.0453 193.065 90.1021 183.313 90.1021 172.296V53.4628C90.1021 44.7941 87.0319 36.6672 81.9752 30.5268ZM68.7915 172.296C68.7915 180.243 62.29 186.744 54.3437 186.744H53.9825C46.0362 186.744 39.5347 180.243 39.5347 172.296V53.4628C39.5347 46.4195 44.5914 40.4597 51.4541 39.1956C52.3571 39.015 53.2601 39.0149 54.1631 39.0149C62.1094 39.0149 68.6109 45.5165 68.6109 53.4628V172.296H68.7915Z"
      fill="#FFFDF7"
      :stroke="myColors.engineering.bottomMiddleZero.outline"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
import { store } from "@/store/store.js";
import { codingColors } from "@/core/colors.js";
export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style scoped>
svg {
  position: relative;
  left: 1rem;
}
</style>