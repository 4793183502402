<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.984883 13.5256L7.91309 1.52564L14.8413 13.5256H0.984883Z"
      stroke="#FA713F"
      :fill="triangleFill"
    />
  </svg>
</template>

<script>
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";
export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.isDark, this.$store);
    },
    triangleFill: function () {
      return this.userHasHovered == true ? this.myColors.main : "";
    },
  },
  props: {
    userHasHovered: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>