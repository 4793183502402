<template>
  <div id="app-bar-header">
    <Logo />
    <p id="header-title" :style="{ color: myColors.foreground }">{{ myName }}</p>
  </div>
</template>

<script>
import Logo from "../svg/Logo.vue";
import { store } from "@/store/store";
import { colors } from "@/core/colors.js";
import { data } from "@/core/data/index.ts";
import Vue from "vue";

export default Vue.extend({
  name: "AppBarHeader",
  store: store,
  components: { Logo },
  computed: {
    shouldHide: function () {
      return this.$store.getters.shouldShowHeader;
    },
    myName: function () {
      return data.written.highLevel.name;
    },
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.isDark, this.$store);
    },
    // headerPosition: function () {
    //   return !this.$store.getters.isDesktop && !this.shouldHide
    //     ? "0 auto"
    //     : "initial";
    // },
  },
  props: {
    textColor: String,
  },
});
</script>

<style>
#app-bar-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
#header-title {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  text-transform: uppercase;
}
</style>
