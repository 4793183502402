<template>
  <svg width="91" height="210" viewBox="0 0 91 210" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M78.8734 27.7486C76.1644 25.2203 73.0943 23.2337 69.8435 21.7889C65.148 19.6217 60.0912 18.3575 54.4927 18.3575C48.3524 18.3575 42.3926 19.9829 37.3359 22.6919C33.3627 24.8591 29.7508 27.9292 26.6806 31.3606C25.0552 33.1666 23.791 35.3337 22.7074 37.3203C19.9985 42.3771 18.5537 48.1562 18.5537 54.2965V173.13C18.5537 178.728 19.8179 183.966 21.9851 188.481C23.4298 191.732 25.4164 194.621 27.7642 197.149L13.6776 183.063L13.4969 182.882C13.3163 182.702 13.1358 182.521 12.9552 182.34L10.6074 179.993C4.82826 173.672 1.21631 165.184 1.21631 155.793V36.9591C1.21631 28.2904 4.28646 20.3441 9.52379 14.0232C16.0253 6.07688 26.1388 1.02014 37.1553 1.02014C46.9076 1.02014 55.7568 4.81271 62.2584 11.1336L78.8734 27.7486Z"
      :fill="myColors.engineering.bottomRightZero.depth"
      :stroke="myColors.engineering.bottomRightZero.depthOutline"
      stroke-miterlimit="10"
    />
    <path
      d="M69.1207 54.2961V173.13C69.1207 181.076 62.6192 187.577 54.6728 187.577H54.4923C46.546 187.577 40.0444 181.076 40.0444 173.13V170.24C46.7266 168.976 51.9639 163.016 51.9639 155.973V40.0289C52.8669 39.8483 53.7699 39.8483 54.6728 39.8483C62.6192 39.8483 69.1207 46.3498 69.1207 54.2961Z"
      :fill="myColors.engineering.bottomRightZero.depth"
      :stroke="myColors.engineering.bottomRightZero.depthOutline"
    />
    <path
      d="M82.1241 31.3606C81.7629 30.9994 81.4017 30.4576 81.0405 30.0964C80.3181 29.1934 79.4151 28.471 78.6927 27.7486C75.9837 25.2203 72.9136 23.2337 69.6628 21.7889C64.9673 19.6217 59.9105 18.3575 54.312 18.3575C48.1717 18.3575 42.212 19.9829 37.1552 22.6919C33.1821 24.8591 29.5701 27.9292 26.5 31.3606C24.8746 33.1666 23.6104 35.3337 22.5268 37.3203C19.8178 42.3771 18.373 48.1562 18.373 54.2965V173.13C18.373 178.728 19.6372 183.966 21.8044 188.481C23.2492 191.731 25.2357 194.621 27.5835 197.149L27.7641 197.33C27.9447 197.511 28.1253 197.691 28.3059 197.872L28.6671 198.233C29.3895 198.955 30.2925 199.858 31.1955 200.581C37.5164 205.818 45.4627 209.069 54.312 209.069C63.1613 209.069 71.2882 205.818 77.4285 200.581C85.1942 193.899 90.251 184.146 90.251 173.13V54.2965C90.4316 45.6278 87.3614 37.5009 82.1241 31.3606ZM69.121 173.13C69.121 181.076 62.6195 187.578 54.6732 187.578H54.4926C46.5463 187.578 40.0448 181.076 40.0448 173.13V54.2965C40.0448 47.2532 45.1015 41.2935 51.9642 40.0293C52.8672 39.8487 53.7702 39.8487 54.6732 39.8487C62.6195 39.8487 69.121 46.3502 69.121 54.2965V173.13Z"
      fill="#FFFDF7"
      :stroke="myColors.engineering.bottomRightZero.outline"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
import { store } from "@/store/store.js";
import { codingColors } from "@/core/colors.js";
export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style>
</style>