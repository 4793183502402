<template>
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.88544 0.162567L5.49739 1.46006C5.26145 2.24893 5.13453 3.08545 5.13453 3.95249C5.13453 8.74844 9.02241 12.6363 13.8184 12.6363C15.5511 12.6363 17.164 12.1293 18.5184 11.2557L19.6565 10.5217L19.2685 11.8192C18.0741 15.8125 14.3724 18.724 9.98944 18.724C4.64121 18.724 0.305618 14.3884 0.305618 9.04021C0.305618 5.62295 2.07604 2.61951 4.74735 0.896602L5.88544 0.162567ZM4.23773 2.53413C2.43879 4.12564 1.30562 6.4507 1.30562 9.04021C1.30562 13.8361 5.1935 17.724 9.98944 17.724C13.4513 17.724 16.441 15.698 17.8357 12.7662C16.6116 13.325 15.2509 13.6363 13.8184 13.6363C8.47013 13.6363 4.13453 9.30072 4.13453 3.95249C4.13453 3.47088 4.16973 2.99726 4.23773 2.53413Z"
      :fill="myColors.onBackground"
    />
  </svg>
</template>

<script>
import { store } from "@/store/store";
import { colors } from "@/core/colors.js";
export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.isDark, this.$store);
    },
  },
};
</script>

<style>
</style>