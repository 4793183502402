<template>
  <div class="section">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="css">
.section {
  /* margin-bottom: 4rem; */
}
</style>