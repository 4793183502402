<template>
  <svg
    class="type-icon"
    fill="none"
    height="270"
    viewBox="0 0 92 270"
    width="92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="myColors.dCursor.depth"
      :stroke="myColors.dCursor.depthOutline"
      d="M19.8294 250.361V269.42L0.922852 250.521V231.462L16.9453 247.478L19.8294 250.361Z"
    />
    <path
      :fill="myColors.dCursor.depth"
      :stroke="myColors.dCursor.depthOutline"
      d="M45.9461 64.895V205.836L45.7858 205.676V224.415C45.7858 229.7 44.1836 234.665 41.4598 238.669C36.8132 245.716 28.802 250.361 19.8294 250.361L16.9453 247.478L0.922852 231.462C15.1829 231.462 26.8793 219.77 26.8793 205.516V45.8359C26.8793 43.7538 26.5588 41.6717 26.0782 39.5897C37.2939 42.4725 45.7859 52.5627 45.9461 64.895Z"
    />
    <path
      :fill="myColors.dCursor.depth"
      :stroke="myColors.dCursor.depthOutline"
      d="M19.8294 19.8898V38.7887L0.922852 19.8898V0.990845L19.8294 19.8898Z"
    />
    <path
      :stroke="myColors.dCursor.depthOutline"
      d="M48.6699 30.3002C40.8189 23.7337 30.7247 19.7296 19.8294 19.7296L0.922852 0.830688C11.9784 0.830688 22.0726 4.83471 29.9236 11.4013C31.8463 13.0029 33.6088 14.7647 35.211 16.6866L48.6699 30.3002Z"
      fill="white"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
    <path
      :stroke="myColors.dCursor.depthOutline"
      d="M90.649 19.8898C76.389 19.8898 63.571 26.6165 55.3995 37.1871L54.1177 35.9058C52.8359 34.4644 51.5541 33.0229 50.112 31.7416C49.6314 31.2611 49.1507 30.9408 48.67 30.4603L36.4929 18.2882C44.6644 7.87775 57.4824 1.15101 71.7424 0.990845H71.9027L90.649 19.8898Z"
      fill="white"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
    <path
      :fill="myColors.dCursor.depth"
      :stroke="myColors.dCursor.depthOutline"
      d="M90.4883 250.361C83.118 250.361 76.5488 247.158 71.742 242.193C68.6978 238.989 66.6148 235.146 65.4933 230.661C65.0126 228.739 64.6921 226.657 64.6921 224.575L71.5818 231.462L71.742 231.622L90.4883 250.361Z"
    />
    <path
      :stroke="myColors.dCursor.depthLines"
      d="M67.7366 26.2962L48.8301 7.23712"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
    <path
      :stroke="myColors.dCursor.depthLines"
      d="M64.0515 28.6986L45.145 9.79968"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
    <path
      :stroke="myColors.dCursor.depthLines"
      d="M60.6868 31.4214L41.7803 12.5225"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
    <path
      :stroke="myColors.dCursor.foreOutline"
      d="M64.8527 64.8949V224.735C64.8527 226.817 65.1731 228.899 65.6538 230.821C66.7754 235.145 69.0185 239.15 71.9026 242.353C76.5491 247.318 83.2785 250.521 90.6489 250.521L90.8091 250.681V269.42C76.3889 269.42 63.5709 262.693 55.2392 252.123C50.2722 258.529 43.5427 263.494 35.8519 266.537C30.885 268.459 25.4373 269.42 19.8294 269.42V250.361C28.9623 250.361 36.8133 245.716 41.4598 238.669C44.1836 234.505 45.7859 229.7 45.7859 224.415V205.676L45.9461 205.836V64.8949L45.7859 64.7348C45.7859 52.5626 37.4542 42.4725 26.0782 39.5896C24.1555 39.1091 22.0726 38.7888 19.8294 38.7888L19.6692 38.6286V19.7297C30.7247 19.7297 40.8189 23.7337 48.5097 30.3003L49.9517 31.7417L53.9574 35.7457C54.2778 36.2262 54.7585 36.7067 55.0789 37.1872C55.0789 37.1872 55.0789 37.027 55.2392 37.027C63.4106 26.6166 76.2286 19.8898 90.4887 19.7297L90.6489 19.8898V38.7888C81.5161 38.7888 73.5048 43.4334 68.8583 50.6406C66.2947 54.8048 64.8527 59.6096 64.8527 64.8949Z"
      fill="white"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
import { store } from "@/store/store";
import { codingColors } from "@/core/colors.js";

export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style scoped></style>
