<template>
  <svg id="cc_icon-sun" width="22" height="22" viewBox="0 0 22 22" x="0" y="0">
    <path
      :style="{fill: iconColor}"
      d="M10.776 3.667v-2.75h0.917v2.75h-0.917zM3.991 3.343l2.047 2.047-0.648 0.648-2.046-2.047 0.648-0.648zM16.18 5.513l2-2 0.648 0.648-2 2-0.648-0.648zM11 5.042c-3.291 0-5.958 2.668-5.958 5.958s2.668 5.958 5.958 5.958c3.291 0 5.958-2.668 5.958-5.958s-2.668-5.958-5.958-5.958zM4.125 11c0-3.797 3.078-6.875 6.875-6.875s6.875 3.078 6.875 6.875c0 3.797-3.078 6.875-6.875 6.875s-6.875-3.078-6.875-6.875zM0.917 10.542h2.814v0.917h-2.814v-0.917zM18.269 10.542h2.814v0.917h-2.814v-0.917zM16.611 15.963l2.047 2.046-0.648 0.648-2.046-2.046 0.648-0.648zM3.513 18.18l2-2 0.648 0.648-2 2-0.648-0.648zM10.776 21.083v-2.75h0.917v2.75h-0.917z"
    />
  </svg>
</template>

<script>
import { store } from "../../store/store";
export default {
  store: store,
  name: "Sun",
  computed: {
    isDark: function() {
      return this.$store.getters.isDark;
    },
    iconColor: function() {
      if (this.isDark) {
        return this.$store.getters.foregroundDark;
      } else {
        return this.$store.getters.foregroundLight;
      }
    }
  }
};
</script>

<style>
</style>