<template>
  <svg
    width="192"
    height="258"
    viewBox="0 0 192 258"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="192"
      height="258"
    >
      <rect
        width="191.335"
        height="256.567"
        transform="matrix(-1 0 0 1 191.826 0.896729)"
        fill="#35B6FF"
      />
    </mask>
    <g>
      <path
        d="M22.0671 22.5817L159.142 236.736L164.89 233.057L27.8153 18.9024L22.0671 22.5817Z"
        :fill="myColors.illustration.bottomRightNode.bar"
      />
      <path
        d="M105.72 142.141L82.626 102.142L59.9458 115.236L83.0396 155.236L105.72 142.141Z"
        :fill="myColors.illustration.bottomRightNode.boxDepth"
        :stroke="myColors.illustration.bottomRightNode.boxOutline"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M125.345 130.854L102.251 90.8547L82.1824 102.441L105.276 142.441L125.345 130.854Z"
        fill="#FFFDF7"
        :stroke="myColors.illustration.bottomRightNode.boxOutline"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M162.675 256.359C170.719 258.514 178.987 253.74 181.142 245.696C183.298 237.653 178.524 229.384 170.48 227.229C162.436 225.074 154.168 229.847 152.013 237.891C149.858 245.935 154.631 254.203 162.675 256.359Z"
        :fill="myColors.illustration.bottomRightNode.circle"
      />
      <path
        d="M15.0559 30.3166C23.0998 32.472 31.368 27.6984 33.5233 19.6544C35.6787 11.6105 30.9051 3.3424 22.8612 1.18704C14.8173 -0.968313 6.54912 3.8053 4.39377 11.8492C2.23841 19.8931 7.01202 28.1612 15.0559 30.3166Z"
        :fill="myColors.illustration.bottomRightNode.circle"
      />
    </g>
  </svg>
</template>

<script>
import { store } from "@/store/store";
import { codingColors } from "@/core/colors.js";
export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style>
</style>