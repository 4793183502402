<template>
  <svg width="266" height="61" viewBox="0 0 266 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="266"
      height="61"
    >
      <rect x="0.481934" y="0.130127" width="265.4" height="60.7963" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M19.763 36.6522C26.8052 34.2178 30.5405 26.5355 28.1061 19.4934C25.6717 12.4512 17.9894 8.71585 10.9473 11.1502C3.90509 13.5846 0.169755 21.2669 2.60415 28.3091C5.03854 35.3512 12.7208 39.0866 19.763 36.6522Z"
        :fill="myColors.illustration.topRightHandle.circleAndBar"
      />
      <path
        d="M98.1301 28.7064L100.13 32.5492L22.8242 27.5328L21.2666 27.3995C20.7129 27.255 20.2573 26.9087 19.7498 26.4124C18.6312 25.1197 18.909 23.0084 20.2535 22.0398C20.8017 21.6824 21.3499 21.3249 22.0537 21.4175L22.8556 21.3082L99.308 26.2839L98.1301 28.7064Z"
        :fill="myColors.illustration.topRightHandle.circleAndBar"
      />
      <path
        d="M245.031 35.8632L244.648 42.0415L160.108 36.5028L148.103 35.7825L147.249 35.7417C146.546 35.6491 145.888 35.2047 145.381 34.7083C144.262 33.4157 144.592 31.4544 145.884 30.3358C146.433 29.9783 147.033 29.7709 147.685 29.7135L148.186 29.7079L162.101 30.6079L245.031 35.8632Z"
        :fill="myColors.illustration.topRightHandle.circleAndBar"
      />
      <path
        d="M256.706 52.1477C263.748 49.7133 267.483 42.031 265.049 34.9888C262.615 27.9467 254.932 24.2113 247.89 26.6457C240.848 29.0801 237.113 36.7624 239.547 43.8046C241.981 50.8467 249.664 54.5821 256.706 52.1477Z"
        :fill="myColors.illustration.topRightHandle.circleAndBar"
      />
      <path
        d="M148.866 59.3658L114.132 59.4495L100.131 32.5493L98.1304 28.7064L132.715 28.6747L136.046 34.9122L148.866 59.3658Z"
        fill="#FFFDF7"
        :stroke="myColors.illustration.topRightHandle.boxOutline"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M148.051 35.6322L160.056 36.3525L148.814 59.2155L135.995 34.7618L132.663 28.5243L132.761 28.3225L146.456 0.412725L161.997 30.3076L148.082 29.4076L147.581 29.4132C146.929 29.4706 146.329 29.678 145.78 30.0354C144.488 31.1541 144.308 33.0635 145.277 34.408C145.836 35.0544 146.442 35.3488 147.145 35.4414L148.051 35.6322Z"
        fill="#FFFDF7"
        :stroke="myColors.illustration.topRightHandle.boxOutline"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M146.508 0.563332L132.812 28.4731L132.714 28.6749L98.13 28.7067L99.1579 26.3361L111.923 0.595098L146.508 0.563332Z"
        fill="#FFFDF7"
        :stroke="myColors.illustration.topRightHandle.boxOutline"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<script>
import { store } from "@/store/store.js";
import { codingColors } from "@/core/colors.js";
export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style>
</style>