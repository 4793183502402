import { StyleGroup, ITextStyle, FormattedStyle } from "./types";
import { mobileHorizontalHeader, verticalDesktopHeader } from "./headers";

export const textStyles: StyleGroup = {
  headers: {
    mobileHeader: {
      horizontalHeader: mobileHorizontalHeader,
    },
    desktopHeader: {
      verticalSmallHeader: verticalDesktopHeader,
    },
  },
};
