import { ITextStyle, TextStyle } from "./types";

export const mobileHorizontalHeader: TextStyle = new TextStyle(
  600,
  1.75,
  2.25,
  0.25,
  "uppercase",
  "left"
);
export const verticalDesktopHeader: TextStyle = new TextStyle(
  600,
  1.75,
  1.25,
  0.25,
  "uppercase",
  "left"
);
