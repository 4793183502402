import { Written } from "./types";
import { highLevel } from "./high_level";
import { skills } from "./skills";
import { projects, uxProjects } from "./projects";

export const written: Written = {
  highLevel: highLevel,
  skills: skills,
  projects: projects,
  uxProjects: uxProjects,
};
