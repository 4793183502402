<template>
  <div>
    <svg
      class="skill-illustration"
      width="280"
      height="342"
      viewBox="0 0 280 342"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M90.8638 186.835H90.7626" :stroke="myColors.foreground" stroke-miterlimit="10" />
      <path
        d="M200.56 262.471L200.459 262.37"
        :stroke="myColors.foreground"
        stroke-miterlimit="10"
      />
      <path
        d="M273.39 118.011C272.889 117.41 272.388 116.909 271.787 116.409C268.181 113.103 263.372 110.999 258.163 110.799C257.862 110.799 257.562 110.799 257.261 110.799H43.1773C39.3705 110.799 35.8642 111.8 32.7587 113.503C30.154 114.906 27.7497 116.909 25.9465 119.314C24.8445 120.716 23.9429 122.219 23.2416 123.822C22.2398 126.026 21.6387 128.53 21.4384 131.135C21.4384 131.636 21.3382 132.137 21.3382 132.738V221.797C21.3382 222.098 21.3382 222.499 21.3382 222.799C21.5386 228.209 23.8427 233.118 27.349 236.824L28.0502 237.526C31.9572 241.232 37.2667 243.536 43.0772 243.536H257.161C269.182 243.536 279 233.819 279 221.697V132.638C279 127.028 276.896 121.918 273.39 118.011ZM268.181 219.994C268.181 226.907 262.571 232.416 255.758 232.416H44.68C40.3723 232.416 36.5655 230.213 34.3615 226.907C33.0592 224.903 32.2578 222.599 32.2578 219.994V134.241C32.2578 133.74 32.2578 133.239 32.3579 132.838C32.5583 130.935 33.2596 129.131 34.2614 127.529C35.9644 124.924 38.4689 123.02 41.4743 122.219C42.4761 121.918 43.5781 121.818 44.68 121.818H255.658C258.363 121.818 260.867 122.72 262.971 124.122C266.077 126.427 268.181 130.033 268.181 134.241V219.994Z"
        :fill="codingIllustrationColors.backgroundBox.rimFill"
        :stroke="codingIllustrationColors.backgroundBox.line"
        stroke-miterlimit="10"
      />
      <path
        d="M268.181 134.241V219.994C268.181 226.907 262.571 232.417 255.758 232.417H44.6801C40.3724 232.417 36.5656 230.213 34.3616 226.907C33.0593 224.903 32.2578 222.599 32.2578 219.994V134.241C32.2578 133.74 32.2578 133.239 32.358 132.838C32.5584 130.935 33.2596 129.131 34.2614 127.529C35.9645 124.924 38.469 123.02 41.4744 122.219C42.4762 121.918 43.5781 121.818 44.6801 121.818H255.658C258.363 121.818 260.868 122.72 262.971 124.122C266.077 126.427 268.181 130.133 268.181 134.241Z"
        :fill="myColors.background"
        :stroke="myColors.foreground"
        stroke-miterlimit="10"
      />
      <path
        d="M268.181 134.241V219.994C268.181 226.907 262.571 232.417 255.758 232.417H44.6801C40.3724 232.417 36.5656 230.213 34.3616 226.907C33.0593 224.903 32.2578 222.599 32.2578 219.994V134.241C32.2578 127.328 37.8679 121.818 44.6801 121.818H255.658C258.363 121.818 260.868 122.72 262.971 124.122C266.077 126.427 268.181 130.133 268.181 134.241Z"
        :fill="myColors.background"
        :stroke="myColors.foreground"
        stroke-miterlimit="10"
      />
      <path
        d="M268.181 134.241V219.994C268.181 226.907 262.571 232.417 255.758 232.417H44.6801C40.3723 232.417 36.5655 230.213 34.3616 226.907C34.5619 226.907 34.6621 226.907 34.8625 226.907H255.257C259.565 226.907 263.071 223.4 263.071 219.093V124.122C266.077 126.427 268.181 130.133 268.181 134.241Z"
        :fill="myColors.main"
      />
      <path
        d="M79.6427 165.196C79.6427 165.797 79.0416 166.298 78.4406 166.298C77.8395 166.298 77.3386 165.697 77.4388 165.096C77.4388 164.495 77.9397 164.094 78.5407 164.094C79.1418 164.094 79.6427 164.595 79.6427 165.196Z"
        :fill="myColors.main"
      />
      <path
        d="M256.359 160.688C256.359 161.389 255.859 161.79 255.257 161.79C254.656 161.79 254.156 161.289 254.156 160.688C254.156 160.087 254.656 159.486 255.257 159.486C255.859 159.486 256.359 159.986 256.359 160.688Z"
        :fill="myColors.main"
      />
      <path
        d="M198.055 166.298C197.454 166.298 196.953 165.797 196.953 165.196C196.953 164.595 197.354 164.094 197.955 164.094C198.556 164.094 199.157 164.495 199.157 165.096C199.157 165.697 198.656 166.298 198.055 166.298Z"
        :fill="myColors.main"
      />
      <path
        d="M134.641 160.688C134.641 160.087 135.142 159.486 135.743 159.486C136.344 159.486 136.845 159.986 136.845 160.688C136.845 161.289 136.445 161.79 135.844 161.79C135.242 161.79 134.641 161.289 134.641 160.688Z"
        :fill="myColors.main"
      />
      <path
        d="M135.743 177.518C136.344 177.518 136.845 178.019 136.845 178.62C136.845 179.221 136.445 179.722 135.844 179.722C135.142 179.722 134.641 179.321 134.641 178.72C134.641 178.219 135.142 177.518 135.743 177.518Z"
        :fill="myColors.main"
      />
      <path
        d="M255.257 179.822C254.556 179.822 254.055 179.321 254.055 178.72C254.055 178.119 254.656 177.518 255.257 177.618C255.859 177.618 256.259 178.119 256.359 178.72C256.359 179.321 255.959 179.822 255.257 179.822Z"
        :fill="myColors.main"
      />
      <path
        d="M79.6427 183.128C79.6427 183.729 79.0416 184.33 78.5407 184.33C77.9397 184.33 77.4388 183.929 77.4388 183.328C77.3386 182.627 77.8395 182.126 78.4406 182.126C79.0416 182.026 79.6427 182.527 79.6427 183.128Z"
        :fill="myColors.main"
      />
      <path
        d="M197.955 184.33C197.354 184.33 196.853 183.829 196.853 183.128C196.853 182.527 197.254 182.026 197.855 182.026C198.456 182.026 199.057 182.527 199.057 183.128C199.157 183.729 198.556 184.33 197.955 184.33Z"
        :fill="myColors.main"
      />
      <path
        d="M197.955 148.266C197.354 148.266 196.853 147.765 196.853 147.063C196.853 146.462 197.254 145.961 197.855 145.961C198.456 145.961 199.057 146.462 199.057 147.063C199.157 147.665 198.556 148.266 197.955 148.266Z"
        :fill="myColors.main"
      />
      <path
        d="M136.845 124.623C136.845 125.325 136.445 125.725 135.743 125.725C135.142 125.725 134.641 125.224 134.641 124.723C134.641 124.122 135.142 123.521 135.743 123.521C136.344 123.421 136.845 123.922 136.845 124.623Z"
        :fill="myColors.main"
      />
      <path
        d="M79.6428 147.164C79.6428 147.765 79.0417 148.266 78.4406 148.266C77.8395 148.266 77.3386 147.765 77.3386 147.064C77.3386 146.462 77.8395 146.062 78.4406 146.062C79.1419 146.062 79.6428 146.563 79.6428 147.164Z"
        :fill="myColors.main"
      />
      <path
        d="M256.359 142.656C256.359 143.357 255.859 143.758 255.257 143.758C254.656 143.758 254.156 143.257 254.156 142.656C254.156 142.054 254.656 141.453 255.257 141.453C255.859 141.453 256.359 141.954 256.359 142.656Z"
        :fill="myColors.main"
      />
      <path
        d="M135.743 197.855C135.142 197.855 134.641 197.253 134.641 196.652C134.641 196.151 135.242 195.55 135.743 195.55C136.344 195.55 136.845 196.051 136.845 196.753C136.845 197.454 136.445 197.855 135.743 197.855Z"
        :fill="myColors.main"
      />
      <path
        d="M136.845 142.656C136.845 143.357 136.344 143.758 135.643 143.758C135.042 143.758 134.541 143.257 134.541 142.656C134.541 142.054 135.142 141.453 135.643 141.453C136.344 141.453 136.845 142.054 136.845 142.656Z"
        :fill="myColors.main"
      />
      <path
        d="M256.359 196.752C256.359 197.454 255.959 197.854 255.257 197.854C254.656 197.854 254.156 197.354 254.156 196.853C254.156 196.252 254.656 195.65 255.257 195.65C255.859 195.55 256.359 196.051 256.359 196.752Z"
        :fill="myColors.main"
      />
      <path
        d="M78.4406 200.159C79.0416 200.159 79.6427 200.659 79.6427 201.261C79.6427 201.862 79.0416 202.363 78.4406 202.363C77.8395 202.363 77.3386 201.761 77.4388 201.16C77.4388 200.559 77.8395 200.159 78.4406 200.159Z"
        :fill="myColors.main"
      />
      <path
        d="M199.157 201.261C199.157 201.862 198.556 202.363 197.955 202.363C197.354 202.363 196.853 201.862 196.853 201.16C196.853 200.559 197.354 200.159 197.955 200.159C198.556 200.159 199.157 200.659 199.157 201.261Z"
        :fill="myColors.main"
      />
      <path
        d="M135.743 215.887C135.142 215.887 134.641 215.386 134.641 214.685C134.641 214.184 135.242 213.583 135.743 213.583C136.344 213.583 136.845 214.084 136.845 214.785C136.845 215.486 136.445 215.887 135.743 215.887Z"
        :fill="myColors.main"
      />
      <path
        d="M255.257 213.583C255.859 213.583 256.359 214.184 256.359 214.785C256.359 215.486 255.859 215.887 255.157 215.787C254.556 215.787 254.156 215.286 254.156 214.685C254.156 214.184 254.757 213.583 255.257 213.583Z"
        :fill="myColors.main"
      />
      <path
        d="M78.4406 218.191C79.0416 218.191 79.6427 218.692 79.6427 219.293C79.6427 219.894 79.0416 220.395 78.4406 220.395C77.8395 220.395 77.3386 219.794 77.4388 219.193C77.4388 218.592 77.8395 218.191 78.4406 218.191Z"
        :fill="myColors.main"
      />
      <path
        d="M198.055 130.233C197.454 130.233 196.953 129.732 196.953 129.131C196.953 128.53 197.354 128.029 197.955 128.029C198.556 128.029 199.157 128.43 199.157 129.031C199.157 129.632 198.656 130.233 198.055 130.233Z"
        :fill="myColors.main"
      />
      <path
        d="M256.359 124.623C256.359 125.324 255.859 125.725 255.257 125.725C254.656 125.725 254.156 125.224 254.156 124.623C254.156 124.022 254.656 123.421 255.257 123.421C255.859 123.421 256.359 123.922 256.359 124.623Z"
        :fill="myColors.main"
      />
      <path
        d="M79.6427 129.031C79.6427 129.632 79.1418 130.233 78.5407 130.233C77.9397 130.233 77.4388 129.833 77.4388 129.232C77.3386 128.53 77.8395 128.029 78.4406 128.029C79.0416 127.929 79.6427 128.43 79.6427 129.031Z"
        :fill="myColors.main"
      />
      <path
        d="M197.955 218.191C198.556 218.191 199.157 218.692 199.157 219.293C199.157 219.894 198.656 220.495 198.055 220.495C197.454 220.495 196.953 220.094 196.953 219.493C196.853 218.692 197.254 218.191 197.955 218.191Z"
        :fill="myColors.main"
      />
      <path
        d="M39.7713 165.096C39.7713 165.797 39.3706 166.298 38.6693 166.298C38.0683 166.298 37.4672 165.697 37.4672 165.196C37.4672 164.595 37.9681 164.094 38.5692 164.094C39.2704 163.994 39.7713 164.495 39.7713 165.096Z"
        :fill="myColors.main"
      />
      <path
        d="M151.972 218.592C152.573 218.592 153.175 219.093 153.175 219.694C153.175 220.295 152.573 220.796 151.972 220.796C151.371 220.796 150.971 220.395 150.87 219.794C150.87 219.193 151.371 218.692 151.972 218.592Z"
        :fill="myColors.main"
      />
      <path
        d="M67.1203 165.196C67.1203 165.897 66.6194 166.298 65.9182 166.298C65.3171 166.298 64.8162 165.597 64.8162 165.096C64.9164 164.495 65.4173 164.094 66.0184 164.094C66.7196 164.094 67.2205 164.495 67.1203 165.196Z"
        :fill="myColors.main"
      />
      <path
        d="M92.2654 218.592C92.8665 218.592 93.3674 219.093 93.3674 219.794C93.3674 220.395 92.8665 220.796 92.2654 220.896C91.6643 220.896 91.0632 220.395 91.0632 219.794C91.1634 219.193 91.6643 218.592 92.2654 218.592Z"
        :fill="myColors.main"
      />
      <path
        d="M161.99 126.226C161.289 126.226 160.888 125.725 160.888 125.124C160.888 124.623 161.489 124.022 161.99 124.022C162.591 124.022 163.092 124.523 163.092 125.124C163.092 125.825 162.692 126.226 161.99 126.226Z"
        :fill="myColors.main"
      />
      <path
        d="M181.826 126.226C181.225 126.226 180.724 125.825 180.724 125.224C180.724 124.623 181.225 124.022 181.826 124.022C182.427 124.022 182.928 124.623 182.928 125.224C183.028 125.725 182.527 126.226 181.826 126.226Z"
        :fill="myColors.main"
      />
      <path
        d="M258.764 219.193C258.764 219.894 258.263 220.395 257.662 220.395C257.061 220.395 256.46 219.794 256.56 219.193C256.56 218.692 257.061 218.191 257.662 218.091C258.363 218.091 258.764 218.592 258.764 219.193Z"
        :fill="myColors.main"
      />
      <path
        d="M244.238 219.293C244.238 218.592 244.638 218.191 245.34 218.191C245.941 218.191 246.442 218.692 246.442 219.293C246.442 219.894 245.941 220.495 245.34 220.495C244.739 220.395 244.238 219.894 244.238 219.293Z"
        :fill="myColors.main"
      />
      <path
        d="M181.926 162.291C181.225 162.291 180.824 161.89 180.824 161.189C180.824 160.688 181.325 160.087 181.826 159.987C182.427 159.987 183.028 160.487 183.028 161.089C183.028 161.79 182.627 162.191 181.926 162.291Z"
        :fill="myColors.main"
      />
      <path
        d="M216.789 219.193C216.789 218.492 217.289 218.091 217.991 218.191C218.492 218.191 218.993 218.792 218.993 219.293C218.993 219.894 218.391 220.395 217.79 220.395C217.189 220.395 216.789 219.894 216.789 219.193Z"
        :fill="myColors.main"
      />
      <path
        d="M161.99 162.291C161.289 162.291 160.788 161.79 160.888 161.189C160.888 160.588 161.489 159.987 162.09 160.087C162.591 160.087 163.092 160.588 163.192 161.189C163.092 161.79 162.692 162.191 161.99 162.291Z"
        :fill="myColors.main"
      />
      <path
        d="M38.6693 130.233C38.0683 130.233 37.4672 129.732 37.4672 129.131C37.4672 128.53 37.9681 128.029 38.5692 128.029C39.1702 128.029 39.6711 128.43 39.6711 129.031C39.7713 129.632 39.3706 130.233 38.6693 130.233Z"
        :fill="myColors.main"
      />
      <path
        d="M62.3117 126.226C61.7106 126.226 61.3099 125.825 61.3099 125.124C61.3099 124.523 61.911 123.922 62.4119 123.922C63.0129 123.922 63.5138 124.523 63.5138 125.124C63.5138 125.825 63.0129 126.226 62.3117 126.226Z"
        :fill="myColors.main"
      />
      <path
        d="M97.3745 165.096C97.3745 164.394 97.8754 163.994 98.4765 163.994C98.9774 163.994 99.5785 164.595 99.5785 165.096C99.5785 165.697 98.9774 166.298 98.3763 166.198C97.7753 166.298 97.2744 165.797 97.3745 165.096Z"
        :fill="myColors.main"
      />
      <path
        d="M67.1203 129.131C67.1203 129.833 66.6194 130.233 65.9182 130.233C65.3171 130.233 64.8162 129.532 64.8162 129.031C64.9164 128.43 65.4173 128.029 66.0184 128.029C66.7196 128.029 67.2205 128.43 67.1203 129.131Z"
        :fill="myColors.main"
      />
      <path
        d="M42.4762 126.226C41.7749 126.226 41.274 125.725 41.3742 125.124C41.3742 124.523 41.9753 123.922 42.5763 124.022C43.0772 124.022 43.5781 124.623 43.6783 125.124C43.5781 125.725 43.1774 126.226 42.4762 126.226Z"
        :fill="myColors.main"
      />
      <path
        d="M62.3117 162.291C61.7106 162.291 61.3099 161.89 61.2097 161.289C61.2097 160.688 61.8108 160.087 62.3117 160.087C62.9128 160.087 63.4137 160.688 63.4137 161.289C63.5138 161.79 63.0129 162.291 62.3117 162.291Z"
        :fill="myColors.main"
      />
      <path
        d="M97.3746 129.131C97.3746 128.43 97.7753 128.029 98.4766 128.029C99.0776 128.029 99.6787 128.63 99.5785 129.131C99.5785 129.632 99.0776 130.233 98.4766 130.233C97.8755 130.233 97.3746 129.833 97.3746 129.131Z"
        :fill="myColors.main"
      />
      <path
        d="M42.4761 162.291C41.7748 162.291 41.3741 161.79 41.3741 161.189C41.3741 160.688 41.9752 160.087 42.4761 160.087C43.0772 160.087 43.5781 160.588 43.5781 161.289C43.5781 161.89 43.1774 162.291 42.4761 162.291Z"
        :fill="myColors.main"
      />
      <path
        d="M186.635 219.193C186.635 219.894 186.234 220.395 185.533 220.395C184.931 220.395 184.33 219.894 184.33 219.293C184.33 218.792 184.831 218.191 185.332 218.191C186.134 218.091 186.635 218.492 186.635 219.193Z"
        :fill="myColors.main"
      />
      <path
        d="M124.724 129.031C124.724 128.33 125.224 127.929 125.825 127.929C126.427 127.929 126.927 128.43 126.927 129.031C126.927 129.632 126.326 130.233 125.725 130.233C125.224 130.233 124.724 129.732 124.724 129.031Z"
        :fill="myColors.main"
      />
      <path
        d="M139.25 129.031C139.25 129.732 138.849 130.233 138.148 130.233C137.547 130.233 136.945 129.632 136.945 129.031C136.945 128.53 137.446 127.929 138.047 127.929C138.849 127.929 139.25 128.33 139.25 129.031Z"
        :fill="myColors.main"
      />
      <path
        d="M248.946 160.688C248.946 161.289 248.445 161.79 247.744 161.79C247.143 161.79 246.642 161.189 246.742 160.588C246.842 159.987 247.343 159.586 247.844 159.586C248.345 159.486 248.946 160.087 248.946 160.688Z"
        :fill="myColors.main"
      />
      <path
        d="M159.285 129.131C159.285 129.833 158.785 130.233 158.083 130.233C157.582 130.233 156.981 129.632 156.981 129.131C156.981 128.53 157.482 128.029 158.083 128.029C158.785 128.029 159.285 128.43 159.285 129.131Z"
        :fill="myColors.main"
      />
      <path
        d="M158.183 218.191C158.885 218.191 159.285 218.692 159.285 219.393C159.285 219.994 158.785 220.495 158.183 220.495C157.582 220.495 156.981 219.894 156.981 219.393C156.981 218.692 157.582 218.091 158.183 218.191Z"
        :fill="myColors.main"
      />
      <path
        d="M125.825 164.094C126.427 164.094 127.028 164.695 126.927 165.296C126.827 165.897 126.226 166.398 125.625 166.398C125.024 166.298 124.623 165.897 124.623 165.296C124.724 164.495 125.224 163.994 125.825 164.094Z"
        :fill="myColors.main"
      />
      <path
        d="M185.633 128.029C186.334 128.029 186.735 128.53 186.634 129.232C186.534 129.833 186.134 130.233 185.533 130.233C184.931 130.233 184.33 129.632 184.431 129.031C184.431 128.43 185.032 127.929 185.633 128.029Z"
        :fill="myColors.main"
      />
      <path
        d="M248.946 124.623C248.946 125.224 248.445 125.725 247.744 125.725C247.043 125.725 246.642 125.224 246.642 124.523C246.642 123.922 247.143 123.421 247.744 123.421C248.345 123.421 248.946 124.022 248.946 124.623Z"
        :fill="myColors.main"
      />
      <path
        d="M227.909 159.486C228.51 159.486 229.01 160.087 229.01 160.688C229.01 161.289 228.51 161.69 227.909 161.69C227.207 161.69 226.706 161.189 226.706 160.588C226.706 160.087 227.307 159.486 227.909 159.486Z"
        :fill="myColors.main"
      />
      <path
        d="M216.789 129.031C216.789 128.33 217.289 127.929 217.991 127.929C218.492 127.929 219.093 128.53 219.093 129.031C219.093 129.632 218.492 130.233 217.891 130.133C217.289 130.233 216.789 129.732 216.789 129.031Z"
        :fill="myColors.main"
      />
      <path
        d="M139.25 165.096C139.25 165.797 138.849 166.298 138.248 166.298C137.647 166.298 137.046 165.697 137.046 165.196C137.046 164.695 137.547 164.094 138.148 164.094C138.849 163.994 139.25 164.395 139.25 165.096Z"
        :fill="myColors.main"
      />
      <path
        d="M139.25 219.293C139.25 219.994 138.749 220.395 138.047 220.395C137.547 220.395 137.046 219.794 136.945 219.293C136.945 218.692 137.547 218.191 138.148 218.191C138.949 218.191 139.35 218.592 139.25 219.293Z"
        :fill="myColors.main"
      />
      <path
        d="M244.238 129.031C244.238 128.33 244.739 127.929 245.34 127.929C245.941 127.929 246.442 128.43 246.442 129.031C246.442 129.632 245.841 130.233 245.34 130.233C244.739 130.233 244.238 129.732 244.238 129.031Z"
        :fill="myColors.main"
      />
      <path
        d="M258.764 129.031C258.764 129.732 258.263 130.233 257.662 130.233C257.061 130.233 256.46 129.632 256.56 129.031C256.56 128.53 257.061 128.029 257.662 127.929C258.363 127.929 258.764 128.43 258.764 129.031Z"
        :fill="myColors.main"
      />
      <path
        d="M124.724 219.293C124.724 218.592 125.124 218.191 125.825 218.191C126.427 218.191 126.927 218.692 126.927 219.193C126.927 219.794 126.427 220.395 125.825 220.395C125.224 220.395 124.724 219.894 124.724 219.293Z"
        :fill="myColors.main"
      />
      <path
        d="M159.285 165.196C159.285 165.897 158.785 166.298 158.083 166.298C157.582 166.298 156.981 165.697 156.981 165.196C156.981 164.595 157.482 164.094 158.083 164.094C158.785 164.094 159.285 164.495 159.285 165.196Z"
        :fill="myColors.main"
      />
      <path
        d="M92.2654 130.734C91.6643 130.734 91.0632 130.233 91.1634 129.632C91.1634 129.031 91.7645 128.53 92.3656 128.53C92.9666 128.53 93.3674 129.031 93.3674 129.632C93.3674 130.233 92.9666 130.734 92.2654 130.734Z"
        :fill="myColors.main"
      />
      <path
        d="M98.4766 218.191C99.0776 218.191 99.5785 218.792 99.5785 219.393C99.5785 219.994 98.8773 220.495 98.3764 220.495C97.7753 220.395 97.3746 219.994 97.3746 219.393C97.3746 218.592 97.7753 218.091 98.4766 218.191Z"
        :fill="myColors.main"
      />
      <path
        d="M186.635 165.096C186.635 165.797 186.234 166.298 185.533 166.298C184.931 166.298 184.33 165.697 184.33 165.196C184.33 164.595 184.831 164.094 185.533 164.094C186.234 164.094 186.635 164.495 186.635 165.096Z"
        :fill="myColors.main"
      />
      <path
        d="M151.972 128.43C152.573 128.43 153.175 128.931 153.175 129.532C153.175 130.133 152.573 130.634 151.972 130.634C151.371 130.634 150.971 130.233 150.87 129.632C150.87 129.031 151.371 128.53 151.972 128.43Z"
        :fill="myColors.main"
      />
      <path
        d="M161.99 225.404C161.389 225.404 160.888 224.803 160.888 224.202C160.888 223.601 161.389 223.1 162.091 223.1C162.692 223.1 163.092 223.501 163.092 224.202C163.092 224.903 162.591 225.404 161.99 225.404Z"
        :fill="myColors.main"
      />
      <path
        d="M129.432 160.688C129.432 161.289 128.931 161.79 128.23 161.79C127.629 161.79 127.228 161.389 127.128 160.688C127.128 160.087 127.629 159.486 128.23 159.486C128.831 159.486 129.432 160.087 129.432 160.688Z"
        :fill="myColors.main"
      />
      <path
        d="M216.789 165.196C216.789 164.495 217.189 164.094 217.891 164.094C218.391 164.094 218.993 164.595 218.993 165.196C218.993 165.797 218.492 166.398 217.891 166.398C217.289 166.298 216.789 165.797 216.789 165.196Z"
        :fill="myColors.main"
      />
      <path
        d="M67.1203 219.193C67.1203 219.894 66.7196 220.395 66.0184 220.395C65.4173 220.395 64.8162 219.794 64.8162 219.293C64.8162 218.692 65.3171 218.191 66.0184 218.191C66.7196 218.191 67.1203 218.592 67.1203 219.193Z"
        :fill="myColors.main"
      />
      <path
        d="M108.394 161.79C107.693 161.79 107.192 161.289 107.192 160.688C107.192 160.087 107.793 159.486 108.394 159.586C108.995 159.586 109.396 160.087 109.496 160.688C109.496 161.289 108.995 161.79 108.394 161.79Z"
        :fill="myColors.main"
      />
      <path
        d="M36.1648 132.537C36.8661 132.537 37.2668 133.038 37.2668 133.639C37.2668 134.14 36.7659 134.741 36.1648 134.741C35.5638 134.741 34.9627 134.24 34.9627 133.639C35.0629 132.938 35.4636 132.537 36.1648 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M244.238 165.096C244.238 164.395 244.739 163.994 245.44 164.094C246.041 164.194 246.542 164.695 246.442 165.196C246.442 165.797 245.841 166.298 245.24 166.298C244.638 166.298 244.238 165.797 244.238 165.096Z"
        :fill="myColors.main"
      />
      <path
        d="M38.5692 218.191C39.2704 218.191 39.6711 218.592 39.6711 219.293C39.6711 219.894 39.2704 220.395 38.6693 220.395C38.0683 220.395 37.4672 219.894 37.4672 219.293C37.4672 218.692 37.9681 218.191 38.5692 218.191Z"
        :fill="myColors.main"
      />
      <path
        d="M181.826 216.388C181.225 216.388 180.724 215.987 180.724 215.386C180.724 214.785 181.225 214.184 181.826 214.184C182.427 214.184 182.928 214.785 182.928 215.386C183.028 215.887 182.527 216.388 181.826 216.388Z"
        :fill="myColors.main"
      />
      <path
        d="M161.99 216.388C161.289 216.388 160.788 215.887 160.888 215.286C160.888 214.685 161.489 214.084 162.09 214.184C162.591 214.184 163.092 214.785 163.092 215.286C163.092 215.887 162.692 216.388 161.99 216.388Z"
        :fill="myColors.main"
      />
      <path
        d="M62.4119 216.388C61.8108 216.388 61.3099 215.987 61.3099 215.386C61.3099 214.785 61.8108 214.184 62.4119 214.184C63.0129 214.184 63.614 214.785 63.614 215.386C63.5138 215.887 63.0129 216.388 62.4119 216.388Z"
        :fill="myColors.main"
      />
      <path
        d="M42.3759 216.388C41.6747 216.388 41.2739 215.887 41.2739 215.186C41.2739 214.685 41.875 214.084 42.3759 214.084C42.977 214.084 43.4779 214.685 43.4779 215.286C43.5781 215.987 43.1774 216.388 42.3759 216.388Z"
        :fill="myColors.main"
      />
      <path
        d="M108.294 132.537C108.995 132.537 109.396 132.938 109.396 133.639C109.396 134.24 108.795 134.741 108.194 134.741C107.693 134.741 107.092 134.24 107.092 133.639C107.192 133.038 107.693 132.537 108.294 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M227.909 125.725C227.207 125.725 226.706 125.224 226.706 124.623C226.706 124.022 227.307 123.421 227.909 123.521C228.51 123.521 228.91 124.022 229.01 124.623C229.01 125.224 228.51 125.725 227.909 125.725Z"
        :fill="myColors.main"
      />
      <path
        d="M128.33 132.537C129.031 132.537 129.432 133.038 129.432 133.639C129.432 134.14 128.831 134.741 128.33 134.741C127.729 134.741 127.228 134.24 127.228 133.639C127.128 132.938 127.629 132.537 128.33 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M258.764 165.196C258.764 165.897 258.263 166.398 257.662 166.298C257.061 166.298 256.46 165.697 256.56 165.096C256.56 164.595 257.161 164.094 257.662 163.994C258.363 163.994 258.764 164.495 258.764 165.196Z"
        :fill="myColors.main"
      />
      <path
        d="M247.744 213.583C248.345 213.583 248.946 214.184 248.946 214.685C248.946 215.286 248.445 215.787 247.744 215.787C247.143 215.787 246.742 215.386 246.642 214.785C246.642 214.184 247.143 213.583 247.744 213.583Z"
        :fill="myColors.main"
      />
      <path
        d="M155.679 132.537C156.38 132.537 156.781 132.938 156.781 133.639C156.781 134.24 156.28 134.741 155.679 134.741C155.078 134.741 154.477 134.24 154.477 133.639C154.577 132.938 154.978 132.537 155.679 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M227.909 215.887C227.207 215.887 226.706 215.386 226.706 214.785C226.706 214.184 227.307 213.583 227.909 213.683C228.51 213.683 228.91 214.184 229.01 214.785C229.01 215.386 228.51 215.887 227.909 215.887Z"
        :fill="myColors.main"
      />
      <path
        d="M93.3674 165.597C93.3674 166.298 92.9666 166.699 92.2654 166.699C91.6643 166.699 91.1634 166.198 91.1634 165.697C91.0632 165.096 91.6643 164.495 92.2654 164.495C92.8665 164.495 93.3674 164.996 93.3674 165.597Z"
        :fill="myColors.main"
      />
      <path
        d="M150.971 165.697C150.971 164.996 151.371 164.495 152.073 164.495C152.674 164.495 153.275 165.096 153.275 165.697C153.275 166.298 152.674 166.799 152.073 166.799C151.371 166.799 150.971 166.398 150.971 165.697Z"
        :fill="myColors.main"
      />
      <path
        d="M37.367 223.801C37.367 224.402 36.766 224.903 36.1649 224.903C35.5638 224.903 35.0629 224.302 35.1631 223.701C35.1631 223.1 35.664 222.699 36.2651 222.699C36.8661 222.699 37.367 223.2 37.367 223.801Z"
        :fill="myColors.main"
      />
      <path
        d="M129.432 214.785C129.432 215.386 128.931 215.887 128.23 215.787C127.629 215.787 127.228 215.286 127.228 214.685C127.228 214.084 127.729 213.483 128.33 213.483C128.831 213.583 129.432 214.184 129.432 214.785Z"
        :fill="myColors.main"
      />
      <path
        d="M109.496 214.785C109.496 215.486 108.995 215.887 108.294 215.887C107.693 215.887 107.192 215.386 107.192 214.785C107.192 214.184 107.793 213.583 108.294 213.583C108.995 213.583 109.496 214.184 109.496 214.785Z"
        :fill="myColors.main"
      />
      <path
        d="M227.909 132.537C228.61 132.537 229.011 133.038 229.011 133.639C229.011 134.24 228.51 134.641 227.909 134.741C227.307 134.741 226.706 134.14 226.807 133.639C226.706 132.938 227.207 132.537 227.909 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M37.367 169.604C37.367 170.205 36.7659 170.806 36.265 170.806C35.6639 170.806 35.163 170.205 35.163 169.604C35.163 169.003 35.5638 168.602 36.265 168.602C36.8661 168.602 37.2668 169.003 37.367 169.604Z"
        :fill="myColors.main"
      />
      <path
        d="M247.744 132.537C248.445 132.537 248.846 133.038 248.846 133.639C248.846 134.14 248.345 134.741 247.744 134.741C247.143 134.741 246.642 134.24 246.542 133.639C246.642 132.938 247.043 132.537 247.744 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M108.294 168.602C108.995 168.602 109.396 169.003 109.396 169.704C109.396 170.305 108.795 170.806 108.194 170.806C107.693 170.806 107.092 170.305 107.092 169.704C107.192 169.103 107.693 168.602 108.294 168.602Z"
        :fill="myColors.main"
      />
      <path
        d="M42.5763 135.242C41.9752 135.242 41.3741 134.741 41.3741 134.14C41.3741 133.539 41.875 133.038 42.4761 133.038C43.0772 133.038 43.5781 133.439 43.5781 134.04C43.5781 134.641 43.1774 135.242 42.5763 135.242Z"
        :fill="myColors.main"
      />
      <path
        d="M129.432 169.704C129.432 170.305 128.831 170.906 128.33 170.806C127.729 170.806 127.228 170.205 127.228 169.604C127.228 169.003 127.629 168.602 128.33 168.602C128.931 168.602 129.432 169.003 129.432 169.704Z"
        :fill="myColors.main"
      />
      <path
        d="M156.781 169.704C156.781 170.305 156.18 170.906 155.679 170.806C155.078 170.806 154.577 170.205 154.577 169.604C154.577 169.003 154.978 168.602 155.679 168.602C156.38 168.602 156.781 169.003 156.781 169.704Z"
        :fill="myColors.main"
      />
      <path
        d="M227.909 168.602C228.51 168.602 228.91 169.003 228.91 169.704C228.91 170.305 228.409 170.906 227.808 170.806C227.207 170.806 226.706 170.205 226.706 169.604C226.706 169.003 227.307 168.602 227.909 168.602Z"
        :fill="myColors.main"
      />
      <path
        d="M247.744 168.602C248.445 168.602 248.946 169.003 248.946 169.704C248.946 170.305 248.345 170.906 247.844 170.806C247.243 170.806 246.742 170.305 246.742 169.704C246.642 169.103 247.043 168.602 247.744 168.602Z"
        :fill="myColors.main"
      />
      <path
        d="M43.5781 170.205C43.5781 170.806 43.0772 171.407 42.4761 171.407C41.875 171.407 41.3741 170.806 41.3741 170.205C41.3741 169.604 41.875 169.203 42.4761 169.203C43.1774 169.103 43.5781 169.504 43.5781 170.205Z"
        :fill="myColors.main"
      />
      <path
        d="M163.092 134.04C163.092 134.641 162.591 135.242 161.99 135.242C161.389 135.242 160.788 134.641 160.788 134.14C160.788 133.539 161.189 133.038 161.79 133.038C162.591 132.938 163.092 133.339 163.092 134.04Z"
        :fill="myColors.main"
      />
      <path
        d="M163.092 170.105C163.092 170.706 162.591 171.307 161.99 171.307C161.389 171.307 160.788 170.706 160.788 170.205C160.788 169.604 161.189 169.103 161.79 169.103C162.591 169.003 163.092 169.404 163.092 170.105Z"
        :fill="myColors.main"
      />
      <path
        d="M108.394 177.518C108.995 177.518 109.496 178.119 109.496 178.72C109.496 179.321 108.995 179.722 108.394 179.722C107.693 179.722 107.192 179.221 107.192 178.62C107.192 178.119 107.793 177.518 108.394 177.518Z"
        :fill="myColors.main"
      />
      <path
        d="M129.432 178.72C129.432 179.321 128.931 179.822 128.23 179.822C127.629 179.822 127.228 179.321 127.228 178.72C127.228 178.119 127.729 177.518 128.33 177.518C128.831 177.518 129.432 178.119 129.432 178.72Z"
        :fill="myColors.main"
      />
      <path
        d="M161.99 205.167C162.692 205.167 163.092 205.668 162.992 206.37C162.992 206.971 162.391 207.472 161.89 207.472C161.289 207.472 160.788 206.871 160.788 206.269C160.888 205.568 161.389 205.167 161.99 205.167Z"
        :fill="myColors.main"
      />
      <path
        d="M43.5781 224.302C43.5781 224.903 43.0772 225.504 42.4761 225.504C41.875 225.504 41.3741 224.903 41.3741 224.302C41.3741 223.701 41.875 223.3 42.4761 223.3C43.1774 223.2 43.5781 223.601 43.5781 224.302Z"
        :fill="myColors.main"
      />
      <path
        d="M42.4762 205.167C43.1774 205.167 43.5781 205.568 43.5781 206.269C43.5781 206.871 43.0772 207.371 42.5763 207.371C41.9753 207.371 41.3742 206.871 41.3742 206.269C41.274 205.668 41.7749 205.167 42.4762 205.167Z"
        :fill="myColors.main"
      />
      <path
        d="M227.808 179.822C227.107 179.822 226.706 179.321 226.706 178.62C226.706 178.019 227.408 177.518 227.909 177.518C228.51 177.618 228.91 178.119 228.91 178.62C228.91 179.321 228.51 179.822 227.808 179.822Z"
        :fill="myColors.main"
      />
      <path
        d="M248.946 178.72C248.946 179.321 248.445 179.822 247.744 179.822C247.143 179.822 246.742 179.421 246.642 178.82C246.642 178.219 247.143 177.618 247.744 177.618C248.345 177.518 248.946 178.119 248.946 178.72Z"
        :fill="myColors.main"
      />
      <path
        d="M247.744 204.667C248.445 204.667 248.946 205.168 248.846 205.769C248.846 206.27 248.345 206.871 247.744 206.871C247.143 206.871 246.542 206.37 246.542 205.769C246.642 205.067 247.043 204.667 247.744 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M227.909 204.667C228.61 204.667 229.011 205.168 229.011 205.869C229.011 206.47 228.51 206.871 227.909 206.971C227.307 206.971 226.706 206.37 226.807 205.869C226.706 205.067 227.207 204.667 227.909 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M155.679 204.667C156.38 204.667 156.881 205.067 156.881 205.769C156.881 206.37 156.28 206.971 155.679 206.871C155.078 206.871 154.577 206.37 154.577 205.769C154.577 205.168 154.978 204.667 155.679 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M42.4762 180.323C41.7749 180.323 41.274 179.822 41.3742 179.221C41.3742 178.62 41.9753 178.019 42.5763 178.119C43.0772 178.119 43.5781 178.62 43.6783 179.221C43.5781 179.822 43.1774 180.223 42.4762 180.323Z"
        :fill="myColors.main"
      />
      <path
        d="M128.23 204.667C128.931 204.667 129.432 205.067 129.432 205.769C129.432 206.37 128.831 206.971 128.33 206.871C127.729 206.871 127.228 206.37 127.228 205.769C127.128 205.168 127.529 204.667 128.23 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M108.394 204.667C109.096 204.667 109.496 205.168 109.496 205.869C109.496 206.47 108.895 206.871 108.394 206.971C107.793 206.971 107.292 206.37 107.292 205.769C107.192 205.067 107.693 204.667 108.394 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M36.1649 204.667C36.8661 204.667 37.367 205.067 37.367 205.769C37.367 206.37 36.7659 206.971 36.265 206.871C35.664 206.871 35.1631 206.37 35.1631 205.769C35.0629 205.168 35.4636 204.667 36.1649 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M62.4119 180.323C61.7106 180.323 61.3099 179.822 61.3099 179.221C61.3099 178.72 61.911 178.119 62.4119 178.119C63.0129 178.119 63.614 178.72 63.614 179.221C63.5138 179.822 63.0129 180.323 62.4119 180.323Z"
        :fill="myColors.main"
      />
      <path
        d="M161.99 180.323C161.289 180.323 160.788 179.822 160.888 179.221C160.888 178.62 161.489 178.019 162.09 178.119C162.591 178.119 163.092 178.62 163.192 179.221C163.092 179.822 162.692 180.223 161.99 180.323Z"
        :fill="myColors.main"
      />
      <path
        d="M150.971 201.761C150.971 201.06 151.371 200.559 152.073 200.559C152.674 200.559 153.275 201.16 153.275 201.761C153.275 202.363 152.674 202.863 152.073 202.863C151.371 202.863 150.971 202.463 150.971 201.761Z"
        :fill="myColors.main"
      />
      <path
        d="M181.926 180.323C181.225 180.323 180.824 179.922 180.824 179.221C180.824 178.72 181.325 178.119 181.926 178.119C182.527 178.119 183.128 178.62 183.128 179.221C183.028 179.822 182.527 180.323 181.926 180.323Z"
        :fill="myColors.main"
      />
      <path
        d="M38.6693 184.33C38.0683 184.33 37.4672 183.729 37.5674 183.128C37.5674 182.527 38.1684 182.026 38.7695 182.026C39.3706 182.026 39.7713 182.527 39.7713 183.128C39.7713 183.829 39.2704 184.33 38.6693 184.33Z"
        :fill="myColors.main"
      />
      <path
        d="M92.2654 200.559C92.8665 200.559 93.3674 201.06 93.3674 201.661C93.3674 202.262 92.9666 202.763 92.3656 202.763C91.7645 202.763 91.1634 202.262 91.1634 201.661C91.0632 201.261 91.6643 200.66 92.2654 200.559Z"
        :fill="myColors.main"
      />
      <path
        d="M258.764 201.261C258.764 201.962 258.263 202.363 257.662 202.363C257.161 202.363 256.56 201.862 256.56 201.261C256.56 200.66 257.061 200.159 257.662 200.058C258.363 200.058 258.764 200.559 258.764 201.261Z"
        :fill="myColors.main"
      />
      <path
        d="M245.34 200.159C245.941 200.159 246.542 200.659 246.542 201.261C246.542 201.862 245.941 202.363 245.34 202.363C244.739 202.363 244.338 201.862 244.338 201.261C244.238 200.559 244.638 200.159 245.34 200.159Z"
        :fill="myColors.main"
      />
      <path
        d="M161.99 153.275C161.389 153.275 160.788 152.774 160.788 152.173C160.788 151.572 161.289 151.071 161.89 151.071C162.491 151.071 162.992 151.472 162.992 152.073C163.092 152.674 162.591 153.275 161.99 153.275Z"
        :fill="myColors.main"
      />
      <path
        d="M216.789 201.261C216.789 200.559 217.189 200.159 217.891 200.159C218.492 200.159 219.093 200.76 218.993 201.261C218.993 201.761 218.492 202.363 217.891 202.363C217.289 202.363 216.789 201.962 216.789 201.261Z"
        :fill="myColors.main"
      />
      <path
        d="M66.1185 182.126C66.8198 182.126 67.2205 182.627 67.2205 183.328C67.2205 183.93 66.7196 184.43 66.1185 184.43C65.5175 184.43 64.9164 183.829 64.9164 183.328C64.9164 182.627 65.4173 182.126 66.1185 182.126Z"
        :fill="myColors.main"
      />
      <path
        d="M108.294 222.699C108.995 222.699 109.396 223.1 109.396 223.801C109.396 224.402 108.795 224.903 108.194 224.903C107.693 224.903 107.092 224.402 107.092 223.801C107.192 223.2 107.693 222.699 108.294 222.699Z"
        :fill="myColors.main"
      />
      <path
        d="M186.635 201.261C186.635 201.962 186.134 202.363 185.432 202.363C184.831 202.363 184.33 201.661 184.33 201.16C184.431 200.559 184.931 200.159 185.533 200.159C186.234 200.159 186.635 200.559 186.635 201.261Z"
        :fill="myColors.main"
      />
      <path
        d="M109.496 124.623C109.496 125.324 108.995 125.725 108.294 125.725C107.693 125.725 107.192 125.224 107.192 124.623C107.192 124.022 107.793 123.421 108.294 123.421C108.995 123.421 109.496 124.022 109.496 124.623Z"
        :fill="myColors.main"
      />
      <path
        d="M158.183 202.363C157.582 202.363 156.981 201.862 156.981 201.261C156.981 200.659 157.482 200.159 158.083 200.159C158.684 200.159 159.185 200.559 159.185 201.16C159.285 201.761 158.785 202.363 158.183 202.363Z"
        :fill="myColors.main"
      />
      <path
        d="M139.25 201.16C139.25 201.862 138.849 202.363 138.148 202.363C137.547 202.363 136.945 201.762 136.945 201.261C136.945 200.76 137.446 200.159 138.047 200.159C138.849 200.058 139.25 200.459 139.25 201.16Z"
        :fill="myColors.main"
      />
      <path
        d="M124.724 201.16C124.724 200.459 125.224 200.058 125.926 200.159C126.527 200.159 127.028 200.76 127.028 201.261C127.028 201.862 126.427 202.463 125.825 202.363C125.124 202.363 124.724 201.862 124.724 201.16Z"
        :fill="myColors.main"
      />
      <path
        d="M98.3764 182.126C98.9775 182.126 99.5785 182.627 99.5785 183.228C99.5785 183.829 98.9775 184.33 98.3764 184.33C97.7753 184.33 97.3746 183.829 97.3746 183.228C97.2744 182.527 97.6751 182.126 98.3764 182.126Z"
        :fill="myColors.main"
      />
      <path
        d="M43.5781 152.173C43.5781 152.774 43.0772 153.375 42.4761 153.375C41.875 153.375 41.3741 152.774 41.3741 152.173C41.3741 151.572 41.875 151.171 42.4761 151.171C43.1774 151.071 43.5781 151.471 43.5781 152.173Z"
        :fill="myColors.main"
      />
      <path
        d="M97.3746 201.261C97.3746 200.559 97.7753 200.159 98.4766 200.159C99.0776 200.159 99.6787 200.76 99.5785 201.361C99.5785 201.862 98.9775 202.463 98.4766 202.463C97.7753 202.363 97.3746 201.862 97.3746 201.261Z"
        :fill="myColors.main"
      />
      <path
        d="M247.744 150.57C248.445 150.57 248.946 151.071 248.846 151.672C248.846 152.173 248.345 152.774 247.744 152.774C247.143 152.774 246.542 152.273 246.542 151.672C246.642 150.97 247.043 150.57 247.744 150.57Z"
        :fill="myColors.main"
      />
      <path
        d="M128.23 222.699C128.931 222.699 129.432 223.1 129.432 223.801C129.432 224.402 128.831 225.003 128.23 224.903C127.629 224.903 127.228 224.402 127.128 223.801C127.128 223.2 127.529 222.699 128.23 222.699Z"
        :fill="myColors.main"
      />
      <path
        d="M67.1203 201.261C67.1203 201.962 66.6194 202.463 66.0184 202.363C65.4173 202.363 64.8162 201.762 64.9164 201.161C64.9164 200.559 65.4173 200.159 66.0184 200.159C66.7196 200.059 67.1203 200.559 67.1203 201.261Z"
        :fill="myColors.main"
      />
      <path
        d="M227.808 150.57C228.51 150.57 228.91 150.97 228.91 151.672C228.91 152.273 228.409 152.874 227.808 152.774C227.307 152.774 226.706 152.273 226.706 151.672C226.706 151.071 227.107 150.57 227.808 150.57Z"
        :fill="myColors.main"
      />
      <path
        d="M125.825 182.126C126.427 182.126 127.028 182.627 127.028 183.228C127.028 183.829 126.427 184.33 125.825 184.33C125.224 184.33 124.824 183.829 124.824 183.228C124.724 182.527 125.124 182.126 125.825 182.126Z"
        :fill="myColors.main"
      />
      <path
        d="M139.25 183.228C139.25 183.93 138.749 184.33 138.148 184.33C137.547 184.33 137.046 183.729 137.046 183.128C137.046 182.627 137.647 182.126 138.148 182.126C138.849 182.026 139.35 182.527 139.25 183.228Z"
        :fill="myColors.main"
      />
      <path
        d="M39.7713 201.261C39.7713 201.962 39.2704 202.363 38.6693 202.363C38.0683 202.363 37.5674 201.762 37.5674 201.161C37.5674 200.559 38.0683 200.159 38.6693 200.159C39.2704 200.059 39.7713 200.559 39.7713 201.261Z"
        :fill="myColors.main"
      />
      <path
        d="M158.184 182.126C158.885 182.126 159.286 182.627 159.286 183.228C159.286 183.829 158.785 184.33 158.284 184.33C157.683 184.33 157.082 183.829 157.082 183.228C156.981 182.627 157.482 182.126 158.184 182.126Z"
        :fill="myColors.main"
      />
      <path
        d="M181.826 198.355C181.225 198.355 180.724 197.955 180.724 197.354C180.724 196.753 181.225 196.151 181.826 196.151C182.427 196.151 183.028 196.753 183.028 197.354C183.028 197.855 182.527 198.355 181.826 198.355Z"
        :fill="myColors.main"
      />
      <path
        d="M161.99 198.355C161.289 198.355 160.888 197.855 160.888 197.253C160.888 196.753 161.489 196.151 161.99 196.151C162.591 196.151 163.092 196.652 163.092 197.253C163.092 197.955 162.692 198.355 161.99 198.355Z"
        :fill="myColors.main"
      />
      <path
        d="M62.4119 198.355C61.7106 198.355 61.3099 197.954 61.3099 197.253C61.3099 196.752 61.8108 196.151 62.3117 196.051C62.9128 195.951 63.5138 196.552 63.5138 197.153C63.614 197.854 63.1131 198.255 62.4119 198.355Z"
        :fill="myColors.main"
      />
      <path
        d="M42.4761 198.355C41.7748 198.355 41.3741 197.855 41.3741 197.253C41.3741 196.652 41.9752 196.051 42.5763 196.152C43.0772 196.152 43.5781 196.753 43.5781 197.253C43.5781 197.855 43.1774 198.355 42.4761 198.355Z"
        :fill="myColors.main"
      />
      <path
        d="M109.496 142.656C109.496 143.357 108.995 143.758 108.294 143.758C107.693 143.758 107.192 143.257 107.192 142.656C107.192 142.054 107.793 141.453 108.294 141.453C108.995 141.453 109.496 142.054 109.496 142.656Z"
        :fill="myColors.main"
      />
      <path
        d="M185.633 184.33C185.032 184.33 184.431 183.829 184.431 183.228C184.431 182.627 184.931 182.126 185.633 182.126C186.234 182.126 186.735 182.527 186.735 183.128C186.735 183.729 186.234 184.33 185.633 184.33Z"
        :fill="myColors.main"
      />
      <path
        d="M128.23 143.758C127.529 143.758 127.128 143.257 127.128 142.555C127.128 141.954 127.629 141.453 128.23 141.453C128.831 141.453 129.432 142.054 129.432 142.555C129.432 143.257 128.931 143.758 128.23 143.758Z"
        :fill="myColors.main"
      />
      <path
        d="M247.744 222.699C248.445 222.699 248.946 223.1 248.946 223.701C248.946 224.302 248.445 224.903 247.844 224.903C247.343 224.903 246.742 224.402 246.742 223.901C246.642 223.2 247.043 222.699 247.744 222.699Z"
        :fill="myColors.main"
      />
      <path
        d="M247.844 195.55C248.445 195.55 248.946 196.151 248.946 196.753C248.946 197.354 248.345 197.855 247.744 197.754C247.143 197.754 246.742 197.253 246.742 196.652C246.642 196.051 247.143 195.55 247.844 195.55Z"
        :fill="myColors.main"
      />
      <path
        d="M156.781 151.672C156.781 152.273 156.18 152.874 155.679 152.774C155.078 152.774 154.577 152.173 154.577 151.571C154.577 150.97 154.978 150.57 155.679 150.57C156.38 150.57 156.781 150.97 156.781 151.672Z"
        :fill="myColors.main"
      />
      <path
        d="M228.91 196.652C228.91 197.354 228.51 197.754 227.808 197.754C227.207 197.754 226.706 197.354 226.706 196.753C226.606 196.151 227.207 195.55 227.808 195.55C228.409 195.55 228.91 196.051 228.91 196.652Z"
        :fill="myColors.main"
      />
      <path
        d="M128.23 150.57C128.931 150.57 129.432 150.97 129.432 151.672C129.432 152.273 128.831 152.874 128.23 152.774C127.629 152.774 127.228 152.273 127.128 151.672C127.128 151.071 127.529 150.57 128.23 150.57Z"
        :fill="myColors.main"
      />
      <path
        d="M216.789 183.228C216.789 182.527 217.189 182.126 217.891 182.126C218.492 182.126 219.093 182.727 218.993 183.328C218.993 183.829 218.391 184.43 217.891 184.43C217.289 184.33 216.789 183.829 216.789 183.228Z"
        :fill="myColors.main"
      />
      <path
        d="M108.394 150.57C109.096 150.57 109.496 151.071 109.496 151.772C109.496 152.373 108.895 152.774 108.394 152.774C107.793 152.774 107.292 152.173 107.292 151.571C107.192 150.97 107.693 150.57 108.394 150.57Z"
        :fill="myColors.main"
      />
      <path
        d="M155.779 222.699C156.48 222.699 156.881 223.2 156.881 223.901C156.881 224.402 156.28 224.903 155.779 224.903C155.178 224.903 154.677 224.302 154.677 223.701C154.577 223.1 155.078 222.699 155.779 222.699Z"
        :fill="myColors.main"
      />
      <path
        d="M128.33 195.55C128.931 195.55 129.432 196.151 129.432 196.753C129.432 197.354 128.831 197.855 128.23 197.754C127.629 197.754 127.228 197.253 127.228 196.652C127.128 196.051 127.729 195.55 128.33 195.55Z"
        :fill="myColors.main"
      />
      <path
        d="M228.91 142.656C228.91 143.357 228.409 143.758 227.708 143.758C227.107 143.758 226.606 143.257 226.606 142.656C226.606 142.054 227.207 141.453 227.708 141.453C228.409 141.453 229.01 142.054 228.91 142.656Z"
        :fill="myColors.main"
      />
      <path
        d="M109.496 196.752C109.496 197.454 108.995 197.854 108.394 197.854C107.793 197.854 107.292 197.354 107.292 196.853C107.292 196.252 107.793 195.65 108.394 195.65C108.895 195.55 109.496 196.051 109.496 196.752Z"
        :fill="myColors.main"
      />
      <path
        d="M247.744 141.453C248.345 141.453 248.946 142.054 248.946 142.555C248.946 143.156 248.445 143.657 247.744 143.657C247.143 143.657 246.742 143.257 246.642 142.656C246.642 142.054 247.143 141.453 247.744 141.453Z"
        :fill="myColors.main"
      />
      <path
        d="M245.34 182.126C245.941 182.126 246.542 182.627 246.542 183.228C246.542 183.829 245.941 184.33 245.34 184.33C244.739 184.33 244.338 183.829 244.338 183.228C244.238 182.527 244.638 182.126 245.34 182.126Z"
        :fill="myColors.main"
      />
      <path
        d="M42.3759 144.258C41.7748 144.258 41.3741 143.758 41.3741 143.156C41.3741 142.555 41.9752 141.954 42.5763 142.055C43.1774 142.055 43.6783 142.656 43.6783 143.257C43.5781 143.858 43.0772 144.258 42.3759 144.258Z"
        :fill="myColors.main"
      />
      <path
        d="M62.4119 144.258C61.7106 144.258 61.2097 143.858 61.2097 143.156C61.2097 142.555 61.8108 141.954 62.3117 141.954C62.8126 141.954 63.4137 142.455 63.4137 143.056C63.614 143.657 63.1131 144.158 62.4119 144.258Z"
        :fill="myColors.main"
      />
      <path
        d="M258.764 183.228C258.764 183.93 258.263 184.33 257.662 184.33C257.161 184.33 256.56 183.729 256.56 183.228C256.56 182.627 257.061 182.126 257.662 182.126C258.363 182.126 258.764 182.527 258.764 183.228Z"
        :fill="myColors.main"
      />
      <path
        d="M93.3674 183.729C93.3674 184.431 92.8665 184.831 92.1652 184.831C91.5641 184.831 91.0632 184.33 91.0632 183.729C91.0632 183.128 91.6643 182.527 92.1652 182.527C92.8665 182.527 93.3674 183.028 93.3674 183.729Z"
        :fill="myColors.main"
      />
      <path
        d="M152.073 182.527C152.674 182.527 153.275 183.228 153.175 183.729C153.175 184.33 152.473 184.831 151.872 184.731C151.271 184.731 150.87 184.23 150.87 183.629C150.971 183.028 151.472 182.527 152.073 182.527Z"
        :fill="myColors.main"
      />
      <path
        d="M37.367 151.672C37.367 152.273 36.7659 152.874 36.1648 152.774C35.5638 152.774 35.0629 152.173 35.0629 151.571C35.0629 150.97 35.4636 150.57 36.1648 150.57C36.8661 150.57 37.367 150.97 37.367 151.672Z"
        :fill="myColors.main"
      />
      <path
        d="M161.99 144.258C161.289 144.258 160.788 143.858 160.788 143.156C160.788 142.555 161.389 141.954 161.99 141.954C162.491 141.954 162.992 142.455 163.092 143.056C163.092 143.757 162.692 144.158 161.99 144.258Z"
        :fill="myColors.main"
      />
      <path
        d="M181.926 144.258C181.225 144.258 180.824 143.858 180.824 143.156C180.824 142.655 181.325 142.054 181.826 141.954C182.427 141.954 183.028 142.455 183.028 143.056C183.028 143.757 182.627 144.158 181.926 144.258Z"
        :fill="myColors.main"
      />
      <path
        d="M37.367 187.736C37.367 188.337 36.7659 188.939 36.265 188.838C35.6639 188.838 35.163 188.237 35.163 187.636C35.163 187.035 35.5638 186.634 36.265 186.634C36.8661 186.634 37.367 187.035 37.367 187.736Z"
        :fill="myColors.main"
      />
      <path
        d="M150.971 147.664C150.971 146.963 151.371 146.462 152.073 146.462C152.674 146.462 153.275 147.063 153.275 147.664C153.275 148.266 152.674 148.766 152.073 148.766C151.371 148.766 150.971 148.366 150.971 147.664Z"
        :fill="myColors.main"
      />
      <path
        d="M108.394 186.634C109.096 186.634 109.496 187.135 109.396 187.837C109.296 188.438 108.795 188.838 108.294 188.838C107.693 188.838 107.192 188.237 107.192 187.636C107.292 187.035 107.793 186.634 108.394 186.634Z"
        :fill="myColors.main"
      />
      <path
        d="M161.99 189.339C161.389 189.339 160.788 188.738 160.888 188.137C160.888 187.536 161.389 187.035 162.091 187.035C162.692 187.035 163.092 187.436 163.192 188.137C163.092 188.838 162.591 189.339 161.99 189.339Z"
        :fill="myColors.main"
      />
      <path
        d="M38.6693 148.266C38.0683 148.266 37.4672 147.765 37.4672 147.164C37.4672 146.563 37.9681 146.062 38.5692 146.062C39.1702 146.062 39.6711 146.462 39.6711 147.064C39.7713 147.665 39.3706 148.266 38.6693 148.266Z"
        :fill="myColors.main"
      />
      <path
        d="M92.3656 148.766C91.7645 148.766 91.1634 148.266 91.1634 147.664C91.1634 147.063 91.7645 146.462 92.2654 146.462C92.8665 146.462 93.2672 146.863 93.3674 147.464C93.3674 148.165 92.9666 148.766 92.3656 148.766Z"
        :fill="myColors.main"
      />
      <path
        d="M129.432 187.736C129.432 188.337 128.831 188.838 128.23 188.838C127.629 188.738 127.128 188.237 127.228 187.636C127.228 187.035 127.729 186.634 128.33 186.634C128.931 186.634 129.432 187.135 129.432 187.736Z"
        :fill="myColors.main"
      />
      <path
        d="M66.1185 148.266C65.5175 148.266 64.9164 147.765 64.9164 147.164C64.9164 146.563 65.4173 146.062 66.1185 146.062C66.7196 146.062 67.2205 146.462 67.2205 147.064C67.2205 147.665 66.7196 148.266 66.1185 148.266Z"
        :fill="myColors.main"
      />
      <path
        d="M227.909 222.699C228.61 222.699 229.01 223.2 228.91 223.901C228.91 224.502 228.309 224.903 227.808 224.903C227.207 224.903 226.706 224.302 226.706 223.701C226.706 223.1 227.207 222.699 227.909 222.699Z"
        :fill="myColors.main"
      />
      <path
        d="M155.679 186.634C156.38 186.634 156.781 187.135 156.781 187.736C156.781 188.337 156.28 188.838 155.679 188.838C155.078 188.838 154.477 188.337 154.477 187.736C154.577 187.035 154.978 186.634 155.679 186.634Z"
        :fill="myColors.main"
      />
      <path
        d="M97.3745 147.063C97.3745 146.362 97.8754 145.962 98.5767 146.062C99.0776 146.162 99.5785 146.663 99.5785 147.164C99.5785 147.765 98.9774 148.266 98.3763 148.266C97.7753 148.266 97.2744 147.765 97.3745 147.063Z"
        :fill="myColors.main"
      />
      <path
        d="M258.764 147.264C258.764 147.865 258.163 148.366 257.562 148.266C257.061 148.165 256.56 147.564 256.56 147.063C256.56 146.462 257.161 145.961 257.762 145.961C258.463 146.062 258.864 146.563 258.764 147.264Z"
        :fill="myColors.main"
      />
      <path
        d="M43.5781 188.237C43.5781 188.838 43.0772 189.439 42.4761 189.439C41.875 189.439 41.3741 188.838 41.3741 188.237C41.3741 187.636 41.875 187.235 42.4761 187.235C43.1774 187.135 43.5781 187.536 43.5781 188.237Z"
        :fill="myColors.main"
      />
      <path
        d="M125.825 146.062C126.427 146.062 127.028 146.663 126.927 147.264C126.827 147.865 126.226 148.366 125.725 148.366C125.124 148.266 124.724 147.865 124.724 147.264C124.724 146.462 125.124 145.961 125.825 146.062Z"
        :fill="myColors.main"
      />
      <path
        d="M139.25 147.063C139.25 147.765 138.849 148.266 138.148 148.266C137.547 148.266 136.945 147.665 136.945 147.063C136.945 146.563 137.446 145.961 138.047 145.961C138.849 145.961 139.25 146.362 139.25 147.063Z"
        :fill="myColors.main"
      />
      <path
        d="M244.238 147.063C244.238 146.362 244.739 145.961 245.44 145.961C246.041 145.961 246.542 146.462 246.542 147.063C246.542 147.665 245.941 148.266 245.34 148.266C244.638 148.266 244.238 147.765 244.238 147.063Z"
        :fill="myColors.main"
      />
      <path
        d="M159.285 147.164C159.285 147.865 158.785 148.366 158.183 148.266C157.582 148.266 156.981 147.665 157.082 147.063C157.082 146.462 157.582 146.062 158.183 145.961C158.785 145.961 159.285 146.462 159.285 147.164Z"
        :fill="myColors.main"
      />
      <path
        d="M248.946 187.736C248.946 188.337 248.345 188.838 247.744 188.838C247.143 188.838 246.642 188.237 246.742 187.636C246.742 187.035 247.243 186.634 247.844 186.634C248.445 186.634 248.946 187.135 248.946 187.736Z"
        :fill="myColors.main"
      />
      <path
        d="M227.808 186.634C228.51 186.634 228.91 187.035 228.91 187.736C228.91 188.337 228.309 188.838 227.808 188.838C227.307 188.838 226.706 188.337 226.706 187.736C226.706 187.135 227.207 186.634 227.808 186.634Z"
        :fill="myColors.main"
      />
      <path
        d="M185.633 146.062C186.334 146.062 186.735 146.563 186.634 147.264C186.634 147.865 186.134 148.366 185.533 148.266C184.931 148.266 184.33 147.665 184.431 147.063C184.431 146.462 184.931 145.961 185.633 146.062Z"
        :fill="myColors.main"
      />
      <path
        d="M128.33 125.725C127.629 125.725 127.228 125.325 127.228 124.623C127.228 124.022 127.729 123.521 128.23 123.521C128.831 123.421 129.432 124.022 129.432 124.623C129.432 125.124 128.931 125.625 128.33 125.725Z"
        :fill="myColors.main"
      />
      <path
        d="M216.789 147.164C216.789 146.462 217.189 146.062 217.891 146.062C218.492 146.062 219.093 146.663 218.993 147.264C218.993 147.765 218.391 148.366 217.891 148.366C217.289 148.266 216.789 147.765 216.789 147.164Z"
        :fill="myColors.main"
      />
      <path
        d="M205.468 146.062C206.17 146.062 206.57 146.563 206.57 147.164C206.57 147.765 205.969 148.266 205.368 148.266C204.767 148.266 204.366 147.765 204.266 147.164C204.366 146.563 204.767 146.062 205.468 146.062Z"
        :fill="myColors.main"
      />
      <path
        d="M225.404 148.266C224.803 148.266 224.302 147.765 224.302 147.064C224.302 146.462 224.803 146.062 225.404 146.062C226.005 146.062 226.606 146.563 226.606 147.164C226.506 147.765 225.905 148.266 225.404 148.266Z"
        :fill="myColors.main"
      />
      <path
        d="M179.221 147.063C179.221 147.665 178.72 148.266 178.119 148.266C177.518 148.266 177.017 147.765 177.017 147.164C177.017 146.563 177.418 146.062 178.019 146.062C178.62 145.961 179.121 146.462 179.221 147.063Z"
        :fill="myColors.main"
      />
      <path
        d="M255.257 186.634C255.959 186.634 256.359 187.035 256.359 187.736C256.359 188.337 255.758 188.838 255.157 188.838C254.656 188.838 254.055 188.237 254.055 187.736C254.055 187.135 254.556 186.634 255.257 186.634Z"
        :fill="myColors.main"
      />
      <path
        d="M106.992 147.063C106.992 147.665 106.491 148.266 105.89 148.266C105.289 148.266 104.788 147.865 104.788 147.264C104.688 146.563 105.189 146.062 105.79 146.062C106.391 145.961 106.992 146.462 106.992 147.063Z"
        :fill="myColors.main"
      />
      <path
        d="M85.954 146.062C86.6553 146.062 87.056 146.563 87.056 147.264C87.056 147.865 86.4549 148.366 85.8539 148.366C85.2528 148.366 84.8521 147.765 84.8521 147.264C84.8521 146.462 85.353 145.961 85.954 146.062Z"
        :fill="myColors.main"
      />
      <path
        d="M135.743 186.634C136.445 186.634 136.845 187.035 136.845 187.736C136.845 188.337 136.344 188.838 135.743 188.838C135.242 188.838 134.641 188.337 134.641 187.736C134.641 187.135 135.042 186.634 135.743 186.634Z"
        :fill="myColors.main"
      />
      <path
        d="M58.605 148.266C58.0039 148.266 57.5031 147.665 57.5031 147.063C57.5031 146.462 58.0039 145.961 58.7052 145.961C59.3063 145.961 59.707 146.462 59.8072 147.063C59.707 147.665 59.2061 148.266 58.605 148.266Z"
        :fill="myColors.main"
      />
      <path
        d="M63.5138 188.237C63.5138 188.838 62.8125 189.339 62.3116 189.339C61.7106 189.339 61.2097 188.738 61.3098 188.037C61.41 187.436 61.8107 187.035 62.4118 187.035C63.1131 187.135 63.614 187.636 63.5138 188.237Z"
        :fill="myColors.main"
      />
      <path
        d="M181.926 187.135C182.627 187.135 183.128 187.636 183.028 188.237C183.028 188.838 182.427 189.339 181.826 189.339C181.325 189.339 180.824 188.838 180.724 188.237C180.724 187.636 181.225 187.135 181.926 187.135Z"
        :fill="myColors.main"
      />
      <path
        d="M132.137 148.766C131.436 148.766 131.035 148.266 131.035 147.564C131.035 146.963 131.536 146.462 132.137 146.462C132.738 146.462 133.339 146.963 133.339 147.564C133.239 148.266 132.738 148.766 132.137 148.766Z"
        :fill="myColors.main"
      />
      <path
        d="M58.605 193.246C57.9038 193.246 57.5031 192.845 57.5031 192.144C57.5031 191.443 58.0039 191.042 58.7052 191.142C59.3063 191.142 59.707 191.543 59.8072 192.144C59.707 192.845 59.2061 193.246 58.605 193.246Z"
        :fill="myColors.main"
      />
      <path
        d="M178.119 193.246C177.418 193.246 176.917 192.845 176.917 192.144C176.917 191.443 177.418 191.042 178.019 191.042C178.62 191.042 179.121 191.443 179.121 192.044C179.221 192.845 178.821 193.246 178.119 193.246Z"
        :fill="myColors.main"
      />
      <path
        d="M252.753 183.729C252.753 184.33 252.252 184.831 251.551 184.831C250.95 184.831 250.449 184.33 250.449 183.629C250.449 183.028 250.95 182.527 251.551 182.527C252.152 182.527 252.753 183.128 252.753 183.729Z"
        :fill="myColors.main"
      />
      <path
        d="M211.78 148.767C211.179 148.767 210.577 148.266 210.678 147.665C210.678 147.164 211.279 146.563 211.78 146.563C212.381 146.563 212.882 146.963 212.982 147.665C212.882 148.266 212.381 148.767 211.78 148.767Z"
        :fill="myColors.main"
      />
      <path
        d="M212.882 183.729C212.882 184.43 212.381 184.831 211.78 184.831C211.178 184.831 210.678 184.33 210.678 183.829C210.678 183.228 211.178 182.627 211.78 182.627C212.381 182.527 212.882 183.028 212.882 183.729Z"
        :fill="myColors.main"
      />
      <path
        d="M251.651 146.462C252.252 146.462 252.753 147.063 252.753 147.664C252.753 148.266 252.152 148.766 251.551 148.766C250.95 148.766 250.549 148.266 250.549 147.664C250.449 146.963 250.95 146.462 251.651 146.462Z"
        :fill="myColors.main"
      />
      <path
        d="M112.201 193.747C111.5 193.747 110.999 193.346 110.999 192.645C110.999 192.044 111.5 191.543 112.201 191.543C112.802 191.543 113.303 191.944 113.303 192.545C113.303 193.346 112.902 193.747 112.201 193.747Z"
        :fill="myColors.main"
      />
      <path
        d="M231.715 193.747C231.014 193.747 230.513 193.346 230.513 192.745C230.513 192.144 231.014 191.643 231.615 191.643C232.216 191.643 232.717 192.044 232.717 192.645C232.817 193.246 232.417 193.747 231.715 193.747Z"
        :fill="myColors.main"
      />
      <path
        d="M133.239 183.729C133.239 184.33 132.638 184.831 132.037 184.831C131.436 184.831 130.935 184.33 131.035 183.629C131.035 183.028 131.536 182.627 132.137 182.627C132.738 182.627 133.239 183.128 133.239 183.729Z"
        :fill="myColors.main"
      />
      <path
        d="M36.1648 197.855C35.4636 197.855 35.0629 197.354 35.0629 196.652C35.0629 196.051 35.5638 195.55 36.1648 195.55C36.7659 195.55 37.367 196.051 37.367 196.652C37.367 197.354 36.8661 197.855 36.1648 197.855Z"
        :fill="myColors.main"
      />
      <path
        d="M56.1005 197.855C55.3993 197.855 54.9986 197.354 54.9986 196.753C54.9986 196.152 55.4994 195.651 56.0003 195.651C56.6014 195.651 57.2025 196.252 57.2025 196.753C57.3027 197.253 56.7016 197.855 56.1005 197.855Z"
        :fill="myColors.main"
      />
      <path
        d="M155.679 125.725C154.978 125.725 154.577 125.224 154.577 124.623C154.577 124.022 155.178 123.521 155.779 123.521C156.28 123.521 156.881 124.022 156.881 124.623C156.881 125.124 156.38 125.625 155.679 125.725Z"
        :fill="myColors.main"
      />
      <path
        d="M225.404 184.33C224.803 184.33 224.302 183.829 224.302 183.228C224.302 182.627 224.703 182.126 225.304 182.126C225.905 182.126 226.506 182.527 226.506 183.128C226.606 183.729 226.005 184.33 225.404 184.33Z"
        :fill="myColors.main"
      />
      <path
        d="M212.882 219.694C212.882 220.395 212.481 220.796 211.78 220.796C211.178 220.796 210.678 220.295 210.678 219.794C210.678 219.193 211.178 218.592 211.78 218.592C212.381 218.592 212.882 219.093 212.882 219.694Z"
        :fill="myColors.main"
      />
      <path
        d="M176.717 196.652C176.717 197.253 176.216 197.754 175.515 197.754C174.914 197.754 174.413 197.354 174.413 196.753C174.413 196.051 174.813 195.55 175.414 195.55C176.116 195.55 176.717 196.151 176.717 196.652Z"
        :fill="myColors.main"
      />
      <path
        d="M175.615 141.453C176.216 141.453 176.817 142.054 176.717 142.656C176.717 143.257 176.116 143.758 175.515 143.758C174.914 143.758 174.513 143.357 174.513 142.656C174.513 141.954 175.014 141.453 175.615 141.453Z"
        :fill="myColors.main"
      />
      <path
        d="M135.743 150.57C136.445 150.57 136.845 150.97 136.845 151.672C136.845 152.273 136.344 152.774 135.743 152.774C135.242 152.774 134.641 152.273 134.641 151.672C134.541 151.071 135.042 150.57 135.743 150.57Z"
        :fill="myColors.main"
      />
      <path
        d="M205.468 184.33C204.867 184.33 204.366 183.729 204.366 183.128C204.366 182.527 204.967 182.026 205.568 182.126C206.17 182.226 206.57 182.627 206.57 183.228C206.57 183.829 206.069 184.33 205.468 184.33Z"
        :fill="myColors.main"
      />
      <path
        d="M155.679 143.758C154.978 143.758 154.577 143.257 154.577 142.555C154.577 141.954 155.078 141.453 155.679 141.453C156.28 141.453 156.881 141.954 156.881 142.555C156.781 143.257 156.28 143.758 155.679 143.758Z"
        :fill="myColors.main"
      />
      <path
        d="M176.717 124.623C176.717 125.224 176.116 125.725 175.515 125.725C174.914 125.725 174.513 125.324 174.513 124.623C174.513 123.922 175.014 123.421 175.615 123.421C176.216 123.421 176.817 124.022 176.717 124.623Z"
        :fill="myColors.main"
      />
      <path
        d="M135.843 222.699C136.545 222.699 136.945 223.2 136.945 223.801C136.945 224.402 136.445 224.803 135.843 224.903C135.242 224.903 134.741 224.302 134.741 223.801C134.641 223.1 135.142 222.699 135.843 222.699Z"
        :fill="myColors.main"
      />
      <path
        d="M179.221 183.228C179.221 183.829 178.62 184.33 178.019 184.33C177.418 184.23 176.917 183.729 177.017 183.128C177.117 182.527 177.518 182.126 178.119 182.126C178.72 182.126 179.221 182.627 179.221 183.228Z"
        :fill="myColors.main"
      />
      <path
        d="M57.3027 142.656C57.3027 143.257 56.7016 143.758 56.1005 143.758C55.4994 143.758 55.0987 143.357 55.0987 142.656C55.0987 141.954 55.5996 141.453 56.2007 141.453C56.7016 141.453 57.3027 142.054 57.3027 142.656Z"
        :fill="myColors.main"
      />
      <path
        d="M37.367 142.656C37.367 143.257 36.8661 143.758 36.1649 143.758C35.5638 143.758 35.0629 143.156 35.1631 142.555C35.1631 141.954 35.7642 141.554 36.2651 141.554C36.766 141.453 37.367 142.054 37.367 142.656Z"
        :fill="myColors.main"
      />
      <path
        d="M57.4029 201.261C57.4029 200.66 57.8036 200.159 58.5049 200.058C59.2061 200.058 59.6068 200.559 59.6068 201.16C59.6068 201.762 59.1059 202.262 58.605 202.262C58.1041 202.363 57.5031 201.862 57.4029 201.261Z"
        :fill="myColors.main"
      />
      <path
        d="M231.715 139.65C231.014 139.65 230.513 139.249 230.513 138.648C230.513 138.047 231.014 137.546 231.615 137.546C232.216 137.546 232.717 137.947 232.717 138.548C232.817 139.249 232.417 139.65 231.715 139.65Z"
        :fill="myColors.main"
      />
      <path
        d="M106.992 183.228C106.992 183.829 106.391 184.33 105.79 184.33C105.189 184.33 104.688 183.729 104.788 183.128C104.788 182.527 105.289 182.126 105.89 182.126C106.491 182.126 107.092 182.627 106.992 183.228Z"
        :fill="myColors.main"
      />
      <path
        d="M85.954 200.159C86.6553 200.159 87.1562 200.559 87.1562 201.261C87.1562 201.862 86.6553 202.463 86.0542 202.363C85.4531 202.363 84.9523 201.862 84.9523 201.261C84.8521 200.659 85.2528 200.159 85.954 200.159Z"
        :fill="myColors.main"
      />
      <path
        d="M255.257 150.57C255.959 150.57 256.359 150.97 256.359 151.672C256.359 152.273 255.758 152.774 255.157 152.774C254.656 152.774 254.055 152.173 254.055 151.672C254.055 151.071 254.556 150.57 255.257 150.57Z"
        :fill="myColors.main"
      />
      <path
        d="M105.89 200.159C106.491 200.159 107.092 200.659 106.992 201.261C106.992 201.862 106.391 202.363 105.79 202.363C105.189 202.363 104.788 201.862 104.788 201.261C104.788 200.559 105.289 200.159 105.89 200.159Z"
        :fill="myColors.main"
      />
      <path
        d="M62.4119 151.071C63.1131 151.071 63.5138 151.572 63.5138 152.173C63.5138 152.774 62.9128 153.275 62.3117 153.275C61.8108 153.275 61.3099 152.774 61.3099 152.173C61.3099 151.572 61.7106 151.071 62.4119 151.071Z"
        :fill="myColors.main"
      />
      <path
        d="M112.201 139.65C111.5 139.65 110.999 139.25 110.999 138.548C110.999 137.947 111.5 137.446 112.101 137.446C112.702 137.446 113.203 137.847 113.203 138.448C113.303 139.25 112.902 139.65 112.201 139.65Z"
        :fill="myColors.main"
      />
      <path
        d="M85.9541 182.126C86.6554 182.126 87.1563 182.527 87.1563 183.228C87.1563 183.829 86.6554 184.43 86.0543 184.43C85.4532 184.43 84.9523 183.93 84.9523 183.429C84.752 182.627 85.2529 182.126 85.9541 182.126Z"
        :fill="myColors.main"
      />
      <path
        d="M179.221 201.261C179.221 201.862 178.72 202.463 178.019 202.363C177.418 202.363 176.917 201.762 176.917 201.161C176.917 200.559 177.418 200.159 178.019 200.159C178.72 200.059 179.221 200.559 179.221 201.261Z"
        :fill="myColors.main"
      />
      <path
        d="M206.57 201.261C206.57 201.862 206.069 202.463 205.468 202.363C204.867 202.363 204.366 201.762 204.366 201.161C204.366 200.559 204.867 200.159 205.468 200.159C206.069 200.059 206.57 200.559 206.57 201.261Z"
        :fill="myColors.main"
      />
      <path
        d="M225.504 200.159C226.105 200.159 226.606 200.76 226.606 201.361C226.506 201.962 225.905 202.463 225.304 202.363C224.703 202.262 224.302 201.862 224.302 201.16C224.302 200.559 224.803 200.059 225.504 200.159Z"
        :fill="myColors.main"
      />
      <path
        d="M183.028 152.173C183.028 152.774 182.427 153.375 181.826 153.275C181.325 153.275 180.724 152.774 180.724 152.173C180.724 151.471 181.125 150.97 181.826 150.97C182.527 151.071 183.028 151.471 183.028 152.173Z"
        :fill="myColors.main"
      />
      <path
        d="M59.707 183.228C59.707 183.829 59.2061 184.431 58.605 184.33C58.0039 184.33 57.5031 183.829 57.5031 183.228C57.5031 182.627 57.9038 182.126 58.605 182.026C59.2061 182.026 59.707 182.527 59.707 183.228Z"
        :fill="myColors.main"
      />
      <path
        d="M178.119 139.149C177.418 139.149 176.917 138.749 176.917 138.047C176.917 137.446 177.318 137.046 177.919 136.945C178.62 136.945 179.121 137.246 179.121 137.947C179.221 138.749 178.821 139.149 178.119 139.149Z"
        :fill="myColors.main"
      />
      <path
        d="M133.239 201.762C133.239 202.363 132.638 202.864 132.037 202.864C131.436 202.864 130.935 202.363 131.035 201.661C131.035 201.06 131.536 200.66 132.137 200.66C132.738 200.66 133.239 201.161 133.239 201.762Z"
        :fill="myColors.main"
      />
      <path
        d="M211.679 202.863C211.078 202.863 210.577 202.363 210.577 201.761C210.577 201.16 211.178 200.559 211.679 200.559C212.28 200.559 212.781 201.06 212.781 201.761C212.882 202.463 212.381 202.863 211.679 202.863Z"
        :fill="myColors.main"
      />
      <path
        d="M252.753 201.661C252.753 202.262 252.252 202.763 251.651 202.763C251.05 202.763 250.549 202.363 250.549 201.761C250.549 201.06 250.95 200.559 251.551 200.559C252.152 200.559 252.753 201.06 252.753 201.661Z"
        :fill="myColors.main"
      />
      <path
        d="M135.843 204.667C136.545 204.667 136.945 205.168 136.945 205.769C136.945 206.37 136.445 206.771 135.843 206.871C135.242 206.871 134.741 206.27 134.741 205.769C134.641 205.067 135.142 204.667 135.843 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M58.5049 157.182C57.8036 157.182 57.4029 156.681 57.4029 156.08C57.4029 155.478 57.9038 155.078 58.605 155.078C59.2061 155.078 59.6068 155.579 59.6068 156.18C59.707 156.781 59.2061 157.182 58.5049 157.182Z"
        :fill="myColors.main"
      />
      <path
        d="M255.257 222.699C255.959 222.699 256.359 223.1 256.359 223.801C256.359 224.402 255.758 224.903 255.157 224.903C254.656 224.903 254.156 224.302 254.055 223.801C254.156 223.2 254.656 222.699 255.257 222.699Z"
        :fill="myColors.main"
      />
      <path
        d="M58.605 139.149C57.9038 139.149 57.5031 138.749 57.5031 138.047C57.5031 137.346 58.0039 136.945 58.7052 137.046C59.3063 137.046 59.707 137.446 59.8072 138.047C59.707 138.749 59.3063 139.149 58.605 139.149Z"
        :fill="myColors.main"
      />
      <path
        d="M255.258 204.667C255.959 204.667 256.36 205.067 256.36 205.769C256.36 206.37 255.758 206.871 255.258 206.871C254.757 206.871 254.156 206.27 254.156 205.769C254.055 205.168 254.556 204.667 255.258 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M175.515 177.518C176.116 177.518 176.717 178.119 176.717 178.62C176.717 179.221 176.216 179.722 175.515 179.722C174.914 179.722 174.413 179.321 174.413 178.72C174.513 178.119 174.914 177.518 175.515 177.518Z"
        :fill="myColors.main"
      />
      <path
        d="M155.679 177.518C156.28 177.518 156.881 178.019 156.881 178.62C156.881 179.221 156.48 179.722 155.879 179.722C155.178 179.822 154.677 179.321 154.677 178.72C154.577 178.119 155.078 177.518 155.679 177.518Z"
        :fill="myColors.main"
      />
      <path
        d="M63.5138 206.27C63.5138 206.871 62.9128 207.372 62.3117 207.372C61.8108 207.372 61.3099 206.871 61.2097 206.27C61.2097 205.568 61.6104 205.168 62.3117 205.067C63.0129 205.168 63.5138 205.668 63.5138 206.27Z"
        :fill="myColors.main"
      />
      <path
        d="M183.028 206.27C183.028 206.871 182.427 207.472 181.826 207.372C181.325 207.372 180.824 206.871 180.724 206.27C180.724 205.568 181.125 205.067 181.826 205.067C182.527 205.168 183.028 205.568 183.028 206.27Z"
        :fill="myColors.main"
      />
      <path
        d="M58.605 211.279C57.9038 211.279 57.5031 210.878 57.5031 210.177C57.5031 209.475 58.0039 209.075 58.7052 209.175C59.3063 209.175 59.707 209.576 59.8072 210.177C59.707 210.878 59.3063 211.279 58.605 211.279Z"
        :fill="myColors.main"
      />
      <path
        d="M57.3027 178.72C57.3027 179.321 56.7016 179.822 56.1005 179.822C55.3993 179.822 54.9986 179.321 55.0987 178.62C55.1989 178.019 55.5996 177.518 56.2007 177.618C56.7016 177.518 57.3027 178.219 57.3027 178.72Z"
        :fill="myColors.main"
      />
      <path
        d="M36.1649 177.518C36.766 177.518 37.367 178.019 37.367 178.62C37.367 179.221 36.9663 179.722 36.3652 179.722C35.664 179.822 35.1631 179.321 35.1631 178.72C35.0629 178.119 35.5638 177.518 36.1649 177.518Z"
        :fill="myColors.main"
      />
      <path
        d="M231.715 175.715C231.014 175.715 230.613 175.214 230.613 174.613C230.613 174.012 231.114 173.511 231.815 173.611C232.417 173.611 232.817 174.012 232.917 174.613C232.817 175.314 232.417 175.715 231.715 175.715Z"
        :fill="myColors.main"
      />
      <path
        d="M178.019 155.078C178.72 155.078 179.121 155.478 179.121 156.18C179.121 156.781 178.62 157.282 177.919 157.282C177.318 157.282 176.817 156.881 176.817 156.28C176.917 155.579 177.318 155.078 178.019 155.078Z"
        :fill="myColors.main"
      />
      <path
        d="M112.201 175.715C111.5 175.715 110.999 175.314 110.999 174.613C110.999 174.012 111.5 173.511 112.101 173.511C112.702 173.511 113.203 173.911 113.203 174.513C113.303 175.314 112.902 175.715 112.201 175.715Z"
        :fill="myColors.main"
      />
      <path
        d="M178.019 173.11C178.72 173.11 179.221 173.511 179.221 174.112C179.221 174.813 178.82 175.214 178.119 175.214C177.518 175.214 177.017 174.813 177.017 174.212C176.917 173.611 177.318 173.11 178.019 173.11Z"
        :fill="myColors.main"
      />
      <path
        d="M58.605 175.214C57.9038 175.214 57.5031 174.813 57.5031 174.112C57.5031 173.411 58.0039 173.01 58.7052 173.11C59.3063 173.11 59.8072 173.511 59.8072 174.112C59.707 174.813 59.3063 175.214 58.605 175.214Z"
        :fill="myColors.main"
      />
      <path
        d="M181.926 169.103C182.627 169.103 183.028 169.604 183.028 170.205C183.028 170.806 182.427 171.307 181.826 171.307C181.325 171.307 180.824 170.806 180.824 170.205C180.724 169.604 181.225 169.103 181.926 169.103Z"
        :fill="myColors.main"
      />
      <path
        d="M183.028 134.04C183.028 134.641 182.527 135.242 181.926 135.242C181.425 135.242 180.824 134.741 180.824 134.24C180.724 133.639 181.225 133.038 181.826 133.038C182.427 132.938 183.028 133.439 183.028 134.04Z"
        :fill="myColors.main"
      />
      <path
        d="M63.5138 170.205C63.5138 170.806 62.9128 171.407 62.3117 171.307C61.8108 171.307 61.3099 170.806 61.2097 170.205C61.2097 169.504 61.6104 169.003 62.3117 169.003C63.0129 169.103 63.5138 169.504 63.5138 170.205Z"
        :fill="myColors.main"
      />
      <path
        d="M178.019 209.175C178.72 209.175 179.121 209.576 179.121 210.277C179.121 210.878 178.62 211.379 177.919 211.279C177.318 211.279 176.817 210.878 176.817 210.277C176.917 209.576 177.318 209.175 178.019 209.175Z"
        :fill="myColors.main"
      />
      <path
        d="M256.359 169.604C256.359 170.205 255.859 170.806 255.257 170.806C254.656 170.806 254.156 170.205 254.156 169.604C254.156 169.003 254.656 168.602 255.257 168.602C255.959 168.602 256.359 169.003 256.359 169.604Z"
        :fill="myColors.main"
      />
      <path
        d="M112.101 211.779C111.4 211.779 110.999 211.279 110.999 210.677C110.999 210.076 111.6 209.676 112.201 209.676C112.802 209.676 113.203 210.177 113.203 210.778C113.303 211.379 112.802 211.88 112.101 211.779Z"
        :fill="myColors.main"
      />
      <path
        d="M231.615 211.779C230.914 211.779 230.513 211.279 230.513 210.677C230.513 210.076 231.114 209.676 231.715 209.676C232.316 209.676 232.717 210.177 232.717 210.778C232.817 211.379 232.316 211.88 231.615 211.779Z"
        :fill="myColors.main"
      />
      <path
        d="M36.265 213.583C36.8661 213.583 37.367 214.184 37.367 214.785C37.367 215.386 36.7659 215.887 36.1648 215.787C35.5637 215.787 35.163 215.286 35.163 214.685C35.0628 214.084 35.5637 213.583 36.265 213.583Z"
        :fill="myColors.main"
      />
      <path
        d="M135.743 168.602C136.445 168.602 136.845 169.003 136.945 169.604C136.945 170.205 136.445 170.806 135.843 170.806C135.343 170.806 134.741 170.305 134.741 169.804C134.541 169.103 135.042 168.602 135.743 168.602Z"
        :fill="myColors.main"
      />
      <path
        d="M112.201 157.682C111.5 157.682 111.099 157.182 111.099 156.581C111.099 155.979 111.6 155.579 112.301 155.579C112.902 155.579 113.303 156.08 113.303 156.681C113.303 157.282 112.802 157.783 112.201 157.682Z"
        :fill="myColors.main"
      />
      <path
        d="M62.4119 135.242C61.8108 135.242 61.3099 134.641 61.3099 134.04C61.3099 133.439 61.8108 133.038 62.4119 132.938C63.1131 132.938 63.614 133.439 63.614 134.04C63.5138 134.641 62.9128 135.242 62.4119 135.242Z"
        :fill="myColors.main"
      />
      <path
        d="M256.359 133.539C256.359 134.14 255.859 134.741 255.257 134.741C254.656 134.741 254.156 134.14 254.156 133.539C254.156 132.938 254.656 132.537 255.257 132.537C255.959 132.537 256.359 132.938 256.359 133.539Z"
        :fill="myColors.main"
      />
      <path
        d="M57.3027 214.785C57.3027 215.386 56.7016 215.887 56.1005 215.887C55.4994 215.887 55.0987 215.486 55.0987 214.785C55.0987 214.084 55.5996 213.583 56.2007 213.583C56.7016 213.583 57.3027 214.184 57.3027 214.785Z"
        :fill="myColors.main"
      />
      <path
        d="M252.753 165.697C252.753 166.298 252.152 166.799 251.551 166.799C250.95 166.799 250.449 166.298 250.549 165.597C250.549 164.996 251.05 164.595 251.651 164.595C252.252 164.595 252.753 165.096 252.753 165.697Z"
        :fill="myColors.main"
      />
      <path
        d="M211.679 164.495C212.28 164.495 212.781 164.996 212.781 165.597C212.781 166.198 212.381 166.699 211.78 166.699C211.178 166.799 210.577 166.298 210.577 165.697C210.577 165.196 211.178 164.595 211.679 164.495Z"
        :fill="myColors.main"
      />
      <path
        d="M231.615 155.579C232.316 155.579 232.717 155.979 232.717 156.681C232.717 157.282 232.316 157.683 231.715 157.783C231.014 157.783 230.513 157.382 230.513 156.781C230.513 156.08 231.014 155.579 231.615 155.579Z"
        :fill="myColors.main"
      />
      <path
        d="M155.679 213.583C156.28 213.583 156.881 214.084 156.881 214.685C156.881 215.286 156.48 215.787 155.879 215.787C155.178 215.787 154.677 215.386 154.677 214.785C154.577 214.184 155.078 213.583 155.679 213.583Z"
        :fill="myColors.main"
      />
      <path
        d="M36.1648 161.79C35.4636 161.79 35.0629 161.289 35.0629 160.588C35.0629 159.986 35.5638 159.486 36.1648 159.486C36.7659 159.486 37.367 159.986 37.367 160.588C37.367 161.289 36.8661 161.79 36.1648 161.79Z"
        :fill="myColors.main"
      />
      <path
        d="M132.137 166.799C131.436 166.799 131.035 166.298 131.035 165.697C131.035 165.096 131.436 164.595 132.037 164.595C132.638 164.595 133.239 165.096 133.239 165.697C133.239 166.198 132.738 166.799 132.137 166.799Z"
        :fill="myColors.main"
      />
      <path
        d="M57.3027 160.688C57.3027 161.289 56.7016 161.79 56.1005 161.79C55.3993 161.79 54.9986 161.289 55.0987 160.588C55.1989 159.987 55.5996 159.486 56.2007 159.586C56.7016 159.486 57.3027 160.187 57.3027 160.688Z"
        :fill="myColors.main"
      />
      <path
        d="M175.515 213.583C176.116 213.583 176.717 214.184 176.717 214.685C176.717 215.286 176.216 215.787 175.515 215.787C174.914 215.787 174.413 215.386 174.413 214.785C174.513 214.184 174.914 213.583 175.515 213.583Z"
        :fill="myColors.main"
      />
      <path
        d="M135.743 132.537C136.445 132.537 136.845 132.938 136.845 133.639C136.845 134.24 136.244 134.741 135.743 134.741C135.242 134.741 134.641 134.14 134.641 133.639C134.641 133.038 135.042 132.537 135.743 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M57.4029 219.293C57.4029 218.692 57.8036 218.191 58.5049 218.091C59.2061 218.091 59.6068 218.592 59.6068 219.193C59.6068 219.794 59.1059 220.295 58.605 220.295C58.1041 220.395 57.5031 219.894 57.4029 219.293Z"
        :fill="myColors.main"
      />
      <path
        d="M225.404 166.298C224.803 166.298 224.302 165.797 224.302 165.196C224.302 164.595 224.703 164.094 225.304 164.094C225.905 164.094 226.506 164.495 226.506 165.096C226.606 165.697 226.005 166.298 225.404 166.298Z"
        :fill="myColors.main"
      />
      <path
        d="M251.651 128.43C252.252 128.43 252.753 129.031 252.753 129.632C252.753 130.233 252.152 130.734 251.551 130.734C250.95 130.734 250.549 130.233 250.549 129.632C250.449 128.931 250.95 128.43 251.651 128.43Z"
        :fill="myColors.main"
      />
      <path
        d="M211.78 128.43C212.381 128.43 212.882 128.931 212.882 129.632C212.882 130.233 212.28 130.734 211.679 130.634C211.078 130.634 210.577 130.033 210.678 129.532C210.678 129.031 211.279 128.43 211.78 128.43Z"
        :fill="myColors.main"
      />
      <path
        d="M252.753 219.794C252.753 220.395 252.152 220.896 251.551 220.896C250.95 220.896 250.449 220.395 250.449 219.694C250.449 219.093 250.95 218.692 251.551 218.592C252.152 218.592 252.753 219.193 252.753 219.794Z"
        :fill="myColors.main"
      />
      <path
        d="M62.4119 223.2C63.1131 223.2 63.614 223.701 63.5138 224.302C63.5138 224.903 62.9128 225.404 62.3117 225.404C61.8108 225.404 61.3099 224.903 61.2097 224.302C61.3099 223.701 61.7106 223.2 62.4119 223.2Z"
        :fill="myColors.main"
      />
      <path
        d="M206.57 165.196C206.57 165.797 205.969 166.298 205.368 166.298C204.767 166.298 204.266 165.697 204.366 165.096C204.467 164.495 204.867 164.094 205.468 164.094C206.17 164.094 206.67 164.595 206.57 165.196Z"
        :fill="myColors.main"
      />
      <path
        d="M155.679 159.486C156.28 159.486 156.781 160.087 156.781 160.688C156.781 161.289 156.18 161.79 155.579 161.69C154.978 161.69 154.577 161.189 154.577 160.588C154.577 159.986 155.078 159.486 155.679 159.486Z"
        :fill="myColors.main"
      />
      <path
        d="M86.0542 220.395C85.4531 220.395 84.8521 219.894 84.8521 219.293C84.8521 218.692 85.2528 218.191 85.8539 218.191C86.5551 218.091 87.056 218.592 87.056 219.193C87.1562 219.794 86.6553 220.395 86.0542 220.395Z"
        :fill="myColors.main"
      />
      <path
        d="M175.615 161.79C174.914 161.79 174.513 161.289 174.513 160.588C174.513 159.986 175.014 159.486 175.615 159.486C176.216 159.486 176.817 160.087 176.817 160.588C176.817 161.189 176.216 161.79 175.615 161.79Z"
        :fill="myColors.main"
      />
      <path
        d="M132.037 128.43C132.638 128.43 133.239 128.931 133.239 129.532C133.239 130.133 132.738 130.634 132.137 130.634C131.536 130.634 131.035 130.233 131.035 129.632C130.935 129.031 131.436 128.53 132.037 128.43Z"
        :fill="myColors.main"
      />
      <path
        d="M178.119 166.298C177.518 166.298 177.017 165.797 177.017 165.096C177.017 164.495 177.418 164.094 178.019 163.994C178.72 163.994 179.221 164.394 179.221 165.096C179.221 165.697 178.72 166.298 178.119 166.298Z"
        :fill="myColors.main"
      />
      <path
        d="M105.99 218.191C106.591 218.191 107.092 218.792 107.092 219.393C106.992 219.994 106.391 220.495 105.79 220.395C105.189 220.295 104.788 219.894 104.788 219.193C104.788 218.592 105.289 218.091 105.99 218.191Z"
        :fill="myColors.main"
      />
      <path
        d="M225.304 128.029C225.905 128.029 226.506 128.53 226.506 129.131C226.506 129.732 225.905 130.233 225.404 130.334C224.803 130.334 224.402 129.933 224.302 129.332C224.302 128.53 224.703 128.029 225.304 128.029Z"
        :fill="myColors.main"
      />
      <path
        d="M205.468 130.233C204.867 130.233 204.366 129.632 204.366 129.031C204.366 128.43 204.867 128.029 205.468 127.929C206.17 127.929 206.67 128.33 206.67 129.031C206.57 129.732 206.069 130.233 205.468 130.233Z"
        :fill="myColors.main"
      />
      <path
        d="M179.221 129.031C179.221 129.632 178.72 130.233 178.119 130.233C177.518 130.233 177.017 129.732 176.917 129.131C176.917 128.53 177.318 128.029 177.919 128.029C178.62 127.929 179.121 128.43 179.221 129.031Z"
        :fill="myColors.main"
      />
      <path
        d="M179.221 219.293C179.221 219.894 178.72 220.495 178.119 220.395C177.518 220.395 177.017 219.794 177.017 219.193C177.017 218.592 177.518 218.191 178.119 218.091C178.72 218.091 179.221 218.592 179.221 219.293Z"
        :fill="myColors.main"
      />
      <path
        d="M57.3027 124.623C57.3027 125.224 56.7016 125.725 56.1005 125.725C55.3993 125.725 54.9986 125.224 55.0987 124.523C55.1989 123.922 55.5996 123.421 56.2007 123.521C56.7016 123.421 57.3027 124.122 57.3027 124.623Z"
        :fill="myColors.main"
      />
      <path
        d="M105.89 130.233C105.289 130.233 104.788 129.632 104.888 129.031C104.888 128.43 105.389 128.029 105.99 128.029C106.591 128.029 107.192 128.53 107.092 129.131C106.992 129.732 106.391 130.233 105.89 130.233Z"
        :fill="myColors.main"
      />
      <path
        d="M87.0561 129.131C87.0561 129.732 86.455 130.233 85.8539 130.233C85.2528 130.233 84.7519 129.632 84.8521 129.031C84.9523 128.43 85.353 128.029 85.9541 128.029C86.6553 128.029 87.1562 128.53 87.0561 129.131Z"
        :fill="myColors.main"
      />
      <path
        d="M105.79 166.298C105.189 166.298 104.688 165.697 104.788 165.096C104.788 164.495 105.289 164.094 105.89 164.094C106.491 164.094 107.092 164.595 106.992 165.196C106.992 165.797 106.391 166.298 105.79 166.298Z"
        :fill="myColors.main"
      />
      <path
        d="M59.707 129.131C59.707 129.732 59.2061 130.333 58.605 130.233C58.0039 130.233 57.5031 129.732 57.5031 129.131C57.5031 128.53 57.9038 128.029 58.605 127.929C59.2061 127.929 59.707 128.43 59.707 129.131Z"
        :fill="myColors.main"
      />
      <path
        d="M85.954 164.094C86.6553 164.094 87.056 164.595 87.056 165.196C87.056 165.797 86.4549 166.298 85.8539 166.298C85.2528 166.298 84.8521 165.797 84.7519 165.196C84.8521 164.495 85.353 164.094 85.954 164.094Z"
        :fill="myColors.main"
      />
      <path
        d="M183.028 224.302C183.028 224.903 182.427 225.504 181.826 225.404C181.325 225.404 180.824 224.903 180.724 224.302C180.724 223.601 181.125 223.1 181.826 223.1C182.527 223.2 183.028 223.601 183.028 224.302Z"
        :fill="myColors.main"
      />
      <path
        d="M206.57 219.293C206.57 219.894 205.969 220.395 205.368 220.395C204.767 220.395 204.266 219.794 204.366 219.193C204.467 218.592 204.867 218.191 205.468 218.191C206.17 218.191 206.67 218.692 206.57 219.293Z"
        :fill="myColors.main"
      />
      <path
        d="M225.304 218.191C225.905 218.191 226.506 218.692 226.506 219.293C226.506 219.894 225.905 220.495 225.404 220.495C224.803 220.495 224.302 220.094 224.302 219.493C224.302 218.692 224.703 218.191 225.304 218.191Z"
        :fill="myColors.main"
      />
      <path
        d="M133.239 219.794C133.239 220.395 132.738 220.896 132.137 220.896C131.536 220.896 131.035 220.495 131.035 219.894C131.035 219.193 131.436 218.692 132.137 218.692C132.638 218.592 133.239 219.193 133.239 219.794Z"
        :fill="myColors.main"
      />
      <path
        d="M59.707 165.196C59.707 165.797 59.2061 166.398 58.605 166.298C58.0039 166.298 57.5031 165.797 57.5031 165.196C57.5031 164.595 57.9038 163.994 58.605 163.994C59.2061 163.994 59.707 164.495 59.707 165.196Z"
        :fill="myColors.main"
      />
      <path
        d="M155.679 195.55C156.28 195.55 156.881 196.051 156.881 196.652C156.881 197.253 156.48 197.754 155.879 197.754C155.178 197.754 154.677 197.354 154.677 196.753C154.577 196.151 155.078 195.55 155.679 195.55Z"
        :fill="myColors.main"
      />
      <path
        d="M205.568 173.11C206.27 173.11 206.67 173.611 206.67 174.212C206.67 174.813 206.17 175.214 205.468 175.214C204.867 175.214 204.466 174.713 204.466 174.112C204.366 173.511 204.867 173.11 205.568 173.11Z"
        :fill="myColors.main"
      />
      <path
        d="M112.101 128.43C112.702 128.43 113.203 128.931 113.303 129.532C113.303 130.133 112.902 130.634 112.301 130.634C111.7 130.634 111.099 130.233 111.099 129.632C110.999 129.031 111.5 128.53 112.101 128.43Z"
        :fill="myColors.main"
      />
      <path
        d="M47.1846 165.196C47.1846 165.897 46.6837 166.298 46.0826 166.298C45.4815 166.298 45.0808 165.797 45.0808 165.196C45.0808 164.495 45.4815 164.094 46.0826 164.094C46.7838 163.994 47.1846 164.495 47.1846 165.196Z"
        :fill="myColors.main"
      />
      <path
        d="M262.671 206.269C262.671 206.971 262.17 207.472 261.569 207.371C261.068 207.371 260.367 206.67 260.467 206.169C260.467 205.668 260.968 205.167 261.569 205.167C262.17 205.167 262.671 205.568 262.671 206.269Z"
        :fill="myColors.main"
      />
      <path
        d="M201.762 162.291C201.06 162.291 200.66 161.79 200.76 161.089C200.76 160.487 201.361 159.987 201.862 159.987C202.363 159.987 203.064 160.688 202.964 161.189C202.964 161.79 202.463 162.291 201.762 162.291Z"
        :fill="myColors.main"
      />
      <path
        d="M202.964 206.169C202.964 206.77 202.363 207.372 201.862 207.372C201.261 207.372 200.76 206.871 200.76 206.169C200.76 205.568 201.161 205.168 201.762 205.067C202.363 205.168 202.964 205.668 202.964 206.169Z"
        :fill="myColors.main"
      />
      <path
        d="M143.157 206.37C143.157 206.971 142.656 207.472 142.055 207.472C141.554 207.472 140.852 206.871 140.852 206.269C140.852 205.668 141.453 205.167 142.055 205.167C142.756 205.167 143.157 205.668 143.157 206.37Z"
        :fill="myColors.main"
      />
      <path
        d="M82.3476 205.167C82.9487 205.167 83.5498 205.668 83.5498 206.269C83.5498 206.871 82.9487 207.472 82.3476 207.371C81.8467 207.371 81.3458 206.871 81.2456 206.269C81.1454 205.568 81.6463 205.167 82.3476 205.167Z"
        :fill="myColors.main"
      />
      <path
        d="M82.3476 162.291C81.6464 162.291 81.2457 161.89 81.2457 161.189C81.2457 160.588 81.7465 159.987 82.3476 160.087C82.8485 160.087 83.4496 160.688 83.4496 161.189C83.5498 161.69 83.0489 162.191 82.3476 162.291Z"
        :fill="myColors.main"
      />
      <path
        d="M262.671 134.14C262.671 134.842 262.17 135.343 261.569 135.242C260.968 135.242 260.467 134.541 260.467 134.04C260.467 133.439 261.068 133.038 261.569 133.038C262.27 133.038 262.671 133.439 262.671 134.14Z"
        :fill="myColors.main"
      />
      <path
        d="M236.424 160.688C236.424 161.389 235.923 161.79 235.322 161.79C234.721 161.79 234.22 161.289 234.22 160.688C234.22 160.087 234.821 159.486 235.422 159.486C235.923 159.486 236.424 159.986 236.424 160.688Z"
        :fill="myColors.main"
      />
      <path
        d="M85.954 211.279C85.2528 211.279 84.8521 210.778 84.8521 210.177C84.8521 209.576 85.2528 209.175 85.954 209.175C86.6553 209.175 87.1562 209.576 87.1562 210.177C87.056 210.878 86.6553 211.279 85.954 211.279Z"
        :fill="myColors.main"
      />
      <path
        d="M85.954 139.149C85.2528 139.149 84.8521 138.648 84.8521 138.047C84.8521 137.446 85.2528 137.045 85.954 137.045C86.6553 137.045 87.1562 137.446 87.1562 138.147C87.056 138.749 86.6553 139.149 85.954 139.149Z"
        :fill="myColors.main"
      />
      <path
        d="M208.975 160.688C208.975 161.389 208.574 161.79 207.873 161.79C207.272 161.79 206.771 161.289 206.771 160.788C206.771 160.287 207.372 159.586 207.873 159.586C208.574 159.486 208.975 159.987 208.975 160.688Z"
        :fill="myColors.main"
      />
      <path
        d="M166.699 165.296C166.699 165.897 166.198 166.298 165.597 166.298C164.996 166.298 164.495 165.797 164.595 165.096C164.595 164.495 165.096 164.094 165.697 164.094C166.398 164.094 166.799 164.595 166.699 165.296Z"
        :fill="myColors.main"
      />
      <path
        d="M143.157 134.14C143.157 134.741 142.756 135.242 142.155 135.242C141.554 135.242 140.852 134.541 140.953 134.04C141.053 133.439 141.554 133.038 142.055 133.038C142.756 133.038 143.157 133.439 143.157 134.14Z"
        :fill="myColors.main"
      />
      <path
        d="M205.468 211.279C204.767 211.279 204.366 210.878 204.366 210.177C204.366 209.576 204.767 209.175 205.468 209.175C206.17 209.175 206.67 209.576 206.67 210.177C206.57 210.878 206.17 211.279 205.468 211.279Z"
        :fill="myColors.main"
      />
      <path
        d="M232.817 201.761C232.817 202.463 232.316 202.863 231.715 202.863C231.114 202.863 230.613 202.362 230.613 201.862C230.613 201.26 231.114 200.659 231.715 200.659C232.216 200.559 232.817 201.06 232.817 201.761Z"
        :fill="myColors.main"
      />
      <path
        d="M147.064 160.588C147.064 159.886 147.564 159.486 148.166 159.486C148.666 159.486 149.268 160.087 149.268 160.588C149.268 161.189 148.767 161.69 148.065 161.69C147.464 161.79 147.064 161.289 147.064 160.588Z"
        :fill="myColors.main"
      />
      <path
        d="M116.91 160.688C116.91 161.389 116.409 161.79 115.707 161.69C115.106 161.69 114.605 161.088 114.605 160.588C114.605 159.986 115.206 159.486 115.808 159.486C116.509 159.486 117.01 160.087 116.91 160.688Z"
        :fill="myColors.main"
      />
      <path
        d="M192.946 201.661C192.946 202.362 192.545 202.863 191.944 202.863C191.243 202.863 190.842 202.463 190.842 201.761C190.842 201.16 191.243 200.659 191.844 200.659C192.445 200.559 192.846 201.06 192.946 201.661Z"
        :fill="myColors.main"
      />
      <path
        d="M173.01 201.762C173.01 202.463 172.509 202.864 171.908 202.864C171.307 202.864 170.906 202.363 170.906 201.762C170.906 201.06 171.407 200.66 172.008 200.66C172.609 200.66 173.01 201.161 173.01 201.762Z"
        :fill="myColors.main"
      />
      <path
        d="M88.4585 161.79C87.8575 161.79 87.2564 161.289 87.2564 160.688C87.2564 160.187 87.8575 159.486 88.3584 159.486C88.9594 159.486 89.3602 159.886 89.4603 160.487C89.5605 161.189 89.1598 161.69 88.4585 161.79Z"
        :fill="myColors.main"
      />
      <path
        d="M113.303 201.761C113.303 202.463 112.802 202.863 112.101 202.863C111.5 202.863 110.999 202.363 110.999 201.761C110.999 201.16 111.5 200.559 112.101 200.559C112.802 200.559 113.303 201.16 113.303 201.761Z"
        :fill="myColors.main"
      />
      <path
        d="M202.964 224.202C202.964 224.703 202.363 225.404 201.862 225.404C201.261 225.404 200.76 224.803 200.76 224.202C200.76 223.601 201.161 223.2 201.762 223.1C202.363 223.2 202.964 223.701 202.964 224.202Z"
        :fill="myColors.main"
      />
      <path
        d="M53.4959 165.597C53.4959 166.298 53.0952 166.799 52.4941 166.799C51.893 166.799 51.3921 166.298 51.3921 165.697C51.3921 165.096 51.7929 164.595 52.3939 164.595C52.995 164.495 53.4959 164.996 53.4959 165.597Z"
        :fill="myColors.main"
      />
      <path
        d="M71.3278 165.697C71.3278 164.996 71.8287 164.495 72.4298 164.595C73.0309 164.595 73.4316 164.996 73.4316 165.697C73.4316 166.398 73.0309 166.899 72.4298 166.899C71.7285 166.799 71.3278 166.398 71.3278 165.697Z"
        :fill="myColors.main"
      />
      <path
        d="M73.4316 201.761C73.4316 202.463 73.0309 202.863 72.3296 202.863C71.6284 202.863 71.2276 202.363 71.2276 201.661C71.2276 201.06 71.6284 200.559 72.2294 200.559C72.9307 200.559 73.4316 201.06 73.4316 201.761Z"
        :fill="myColors.main"
      />
      <path
        d="M112.201 164.495C112.802 164.495 113.303 165.096 113.303 165.697C113.303 166.298 112.802 166.699 112.201 166.799C111.6 166.799 110.999 166.298 110.999 165.697C111.099 165.096 111.6 164.495 112.201 164.495Z"
        :fill="myColors.main"
      />
      <path
        d="M53.4959 201.762C53.4959 202.463 52.995 202.864 52.2938 202.864C51.6927 202.763 51.292 202.263 51.292 201.661C51.292 201.06 51.7929 200.66 52.3939 200.66C53.0952 200.66 53.4959 201.161 53.4959 201.762Z"
        :fill="myColors.main"
      />
      <path
        d="M205.568 139.149C204.867 139.149 204.366 138.749 204.366 138.147C204.366 137.546 204.767 137.146 205.368 137.046C206.069 136.945 206.57 137.346 206.57 138.047C206.67 138.648 206.17 139.149 205.568 139.149Z"
        :fill="myColors.main"
      />
      <path
        d="M173.01 165.697C173.01 166.398 172.509 166.799 171.908 166.799C171.307 166.799 170.906 166.298 170.906 165.697C170.906 164.996 171.407 164.595 172.008 164.495C172.609 164.495 173.01 164.996 173.01 165.697Z"
        :fill="myColors.main"
      />
      <path
        d="M192.946 165.697C192.946 166.398 192.545 166.799 191.844 166.799C191.143 166.799 190.742 166.398 190.842 165.597C190.842 164.996 191.243 164.495 191.844 164.495C192.445 164.495 192.946 164.996 192.946 165.697Z"
        :fill="myColors.main"
      />
      <path
        d="M83.4496 134.14C83.4496 134.741 82.8485 135.343 82.2475 135.242C81.6464 135.242 81.1455 134.641 81.1455 134.04C81.1455 133.439 81.5462 133.038 82.2475 133.038C82.9487 133.038 83.4496 133.539 83.4496 134.14Z"
        :fill="myColors.main"
      />
      <path
        d="M231.715 164.495C232.316 164.495 232.817 165.096 232.817 165.697C232.817 166.298 232.316 166.699 231.715 166.799C231.114 166.799 230.513 166.298 230.513 165.697C230.513 165.096 231.114 164.495 231.715 164.495Z"
        :fill="myColors.main"
      />
      <path
        d="M164.595 201.16C164.595 200.459 164.996 200.058 165.597 200.058C166.298 200.058 166.699 200.459 166.699 201.261C166.699 201.962 166.198 202.363 165.497 202.363C164.996 202.262 164.595 201.862 164.595 201.16Z"
        :fill="myColors.main"
      />
      <path
        d="M47.1845 201.361C47.1845 201.962 46.6836 202.363 46.0825 202.363C45.4815 202.363 44.9806 201.862 45.0808 201.16C45.0808 200.559 45.5816 200.159 46.1827 200.159C46.884 200.159 47.2847 200.659 47.1845 201.361Z"
        :fill="myColors.main"
      />
      <path
        d="M143.157 224.302C143.157 224.903 142.656 225.404 142.155 225.404C141.654 225.404 140.953 224.803 140.953 224.302C140.953 223.701 141.554 223.2 142.155 223.2C142.756 223.2 143.157 223.601 143.157 224.302Z"
        :fill="myColors.main"
      />
      <path
        d="M89.5605 214.785C89.5605 215.486 89.0596 215.887 88.3584 215.887C87.7573 215.887 87.2564 215.386 87.2564 214.785C87.2564 214.284 87.8575 213.583 88.4585 213.583C89.0596 213.583 89.5605 214.084 89.5605 214.785Z"
        :fill="myColors.main"
      />
      <path
        d="M201.862 198.355C201.161 198.355 200.76 197.955 200.76 197.253C200.76 196.652 201.261 196.051 201.862 196.051C202.363 196.051 202.964 196.652 202.964 197.153C203.064 197.754 202.463 198.255 201.862 198.355Z"
        :fill="myColors.main"
      />
      <path
        d="M82.2475 198.355C81.5462 198.355 81.1455 197.855 81.1455 197.153C81.1455 196.552 81.6464 196.051 82.2475 196.051C82.7483 196.051 83.4496 196.652 83.4496 197.253C83.4496 197.854 82.9487 198.355 82.2475 198.355Z"
        :fill="myColors.main"
      />
      <path
        d="M89.5605 142.656C89.5605 143.257 89.1598 143.657 88.4585 143.657C87.8575 143.657 87.2564 143.156 87.2564 142.555C87.2564 141.954 87.8575 141.453 88.4585 141.453C89.0596 141.453 89.5605 142.054 89.5605 142.656Z"
        :fill="myColors.main"
      />
      <path
        d="M83.4496 224.202C83.4496 224.803 82.8485 225.404 82.3476 225.404C81.7465 225.404 81.2457 224.903 81.2457 224.202C81.2457 223.601 81.6464 223.2 82.2474 223.1C82.8485 223.2 83.4496 223.701 83.4496 224.202Z"
        :fill="myColors.main"
      />
      <path
        d="M116.91 142.656C116.91 143.357 116.509 143.758 115.808 143.758C115.206 143.758 114.706 143.257 114.706 142.756C114.706 142.255 115.307 141.554 115.808 141.554C116.409 141.453 116.91 141.954 116.91 142.656Z"
        :fill="myColors.main"
      />
      <path
        d="M147.064 142.555C147.064 141.854 147.564 141.453 148.166 141.453C148.666 141.453 149.268 142.054 149.268 142.555C149.268 143.156 148.767 143.657 148.065 143.657C147.464 143.757 147.064 143.257 147.064 142.555Z"
        :fill="myColors.main"
      />
      <path
        d="M236.424 196.652C236.424 197.354 236.023 197.754 235.322 197.754C234.721 197.754 234.22 197.253 234.22 196.753C234.22 196.252 234.721 195.55 235.322 195.55C235.923 195.55 236.424 196.051 236.424 196.652Z"
        :fill="myColors.main"
      />
      <path
        d="M208.975 196.753C208.975 197.454 208.474 197.855 207.873 197.855C207.272 197.855 206.771 197.354 206.771 196.753C206.771 196.252 207.372 195.55 207.873 195.55C208.574 195.55 209.075 196.051 208.975 196.753Z"
        :fill="myColors.main"
      />
      <path
        d="M147.064 196.652C147.064 195.951 147.564 195.55 148.266 195.55C148.767 195.55 149.368 196.151 149.368 196.652C149.368 197.253 148.867 197.754 148.166 197.754C147.464 197.855 147.064 197.354 147.064 196.652Z"
        :fill="myColors.main"
      />
      <path
        d="M208.975 142.656C208.975 143.357 208.474 143.757 207.772 143.657C207.171 143.557 206.67 143.056 206.771 142.555C206.771 141.954 207.372 141.453 207.973 141.453C208.574 141.453 209.075 142.054 208.975 142.656Z"
        :fill="myColors.main"
      />
      <path
        d="M89.5605 124.623C89.5605 125.324 89.0596 125.725 88.4585 125.725C87.8575 125.725 87.3566 125.224 87.3566 124.623C87.3566 124.122 87.9576 123.421 88.4585 123.421C89.0596 123.421 89.5605 123.922 89.5605 124.623Z"
        :fill="myColors.main"
      />
      <path
        d="M116.91 196.753C116.91 197.454 116.409 197.855 115.808 197.855C115.206 197.855 114.706 197.354 114.706 196.753C114.706 196.151 115.307 195.55 115.908 195.55C116.509 195.55 116.91 196.051 116.91 196.753Z"
        :fill="myColors.main"
      />
      <path
        d="M236.424 142.555C236.424 143.257 236.023 143.657 235.322 143.657C234.721 143.657 234.22 143.156 234.12 142.656C234.019 142.155 234.621 141.453 235.222 141.453C235.923 141.453 236.424 141.954 236.424 142.555Z"
        :fill="myColors.main"
      />
      <path
        d="M116.91 124.623C116.91 125.325 116.509 125.725 115.808 125.725C115.206 125.725 114.706 125.224 114.706 124.723C114.706 124.223 115.307 123.521 115.808 123.521C116.409 123.421 116.91 123.922 116.91 124.623Z"
        :fill="myColors.main"
      />
      <path
        d="M88.4585 195.55C89.0596 195.55 89.5605 196.051 89.5605 196.753C89.5605 197.354 89.1598 197.855 88.5587 197.855C87.9576 197.855 87.3566 197.354 87.3566 196.753C87.2564 196.151 87.8575 195.55 88.4585 195.55Z"
        :fill="myColors.main"
      />
      <path
        d="M82.2475 144.258C81.5462 144.258 81.1455 143.757 81.2457 143.056C81.2457 142.455 81.8467 141.954 82.3476 141.954C82.8485 141.954 83.5498 142.655 83.4496 143.156C83.4496 143.757 82.9487 144.258 82.2475 144.258Z"
        :fill="myColors.main"
      />
      <path
        d="M147.064 124.623C147.064 123.922 147.564 123.421 148.166 123.421C148.767 123.421 149.368 124.022 149.268 124.623C149.268 125.124 148.767 125.625 148.166 125.725C147.564 125.725 147.064 125.324 147.064 124.623Z"
        :fill="myColors.main"
      />
      <path
        d="M116.91 214.685C116.91 215.386 116.509 215.787 115.808 215.787C115.206 215.787 114.706 215.286 114.706 214.785C114.706 214.284 115.206 213.583 115.808 213.583C116.409 213.583 116.91 214.084 116.91 214.685Z"
        :fill="myColors.main"
      />
      <path
        d="M147.064 214.685C147.064 213.983 147.564 213.583 148.166 213.583C148.666 213.583 149.268 214.184 149.268 214.685C149.268 215.286 148.767 215.787 148.065 215.787C147.464 215.887 147.064 215.386 147.064 214.685Z"
        :fill="myColors.main"
      />
      <path
        d="M208.975 214.685C208.975 215.386 208.574 215.787 207.873 215.787C207.272 215.787 206.771 215.286 206.771 214.785C206.771 214.284 207.272 213.583 207.873 213.583C208.474 213.583 208.975 214.084 208.975 214.685Z"
        :fill="myColors.main"
      />
      <path
        d="M205.468 191.143C206.17 191.143 206.67 191.543 206.67 192.245C206.67 192.946 206.27 193.347 205.568 193.347C204.967 193.347 204.466 192.946 204.466 192.345C204.266 191.643 204.767 191.143 205.468 191.143Z"
        :fill="myColors.main"
      />
      <path
        d="M201.862 144.258C201.161 144.258 200.76 143.858 200.76 143.156C200.76 142.555 201.361 141.954 201.962 142.055C202.463 142.055 203.064 142.656 203.064 143.156C202.964 143.758 202.463 144.258 201.862 144.258Z"
        :fill="myColors.main"
      />
      <path
        d="M82.3476 171.307C81.7465 171.307 81.2457 170.706 81.2457 170.105C81.2457 169.404 81.7466 169.003 82.4478 169.103C83.0489 169.103 83.5498 169.704 83.5498 170.205C83.4496 170.706 82.8485 171.307 82.3476 171.307Z"
        :fill="myColors.main"
      />
      <path
        d="M236.424 214.785C236.424 215.486 235.923 215.887 235.222 215.787C234.621 215.686 234.12 215.186 234.12 214.685C234.12 214.084 234.721 213.583 235.322 213.583C236.023 213.583 236.424 214.184 236.424 214.785Z"
        :fill="myColors.main"
      />
      <path
        d="M82.2475 216.388C81.5462 216.388 81.1455 215.887 81.1455 215.186C81.1455 214.585 81.6464 214.084 82.2475 214.084C82.7483 214.084 83.4496 214.685 83.4496 215.286C83.4496 215.887 82.9487 216.388 82.2475 216.388Z"
        :fill="myColors.main"
      />
      <path
        d="M143.157 170.205C143.157 170.806 142.756 171.307 142.155 171.307C141.554 171.307 140.852 170.606 140.953 170.105C141.053 169.504 141.554 169.103 142.055 169.103C142.756 169.103 143.157 169.504 143.157 170.205Z"
        :fill="myColors.main"
      />
      <path
        d="M85.954 193.246C85.2528 193.246 84.8521 192.745 84.8521 192.144C84.8521 191.543 85.2528 191.143 85.954 191.143C86.6553 191.143 87.1562 191.543 87.1562 192.144C87.056 192.846 86.6553 193.246 85.954 193.246Z"
        :fill="myColors.main"
      />
      <path
        d="M262.671 188.237C262.671 188.938 262.17 189.439 261.569 189.439C261.068 189.439 260.367 188.738 260.367 188.237C260.367 187.736 260.868 187.235 261.469 187.135C262.17 187.035 262.671 187.536 262.671 188.237Z"
        :fill="myColors.main"
      />
      <path
        d="M201.762 169.103C202.363 169.103 202.964 169.604 202.964 170.205C202.964 170.806 202.263 171.407 201.762 171.307C201.261 171.307 200.76 170.806 200.76 170.205C200.66 169.504 201.161 169.103 201.762 169.103Z"
        :fill="myColors.main"
      />
      <path
        d="M202.964 188.137C202.964 188.738 202.363 189.339 201.862 189.339C201.261 189.339 200.76 188.738 200.76 188.137C200.76 187.536 201.161 187.135 201.762 187.035C202.363 187.135 202.964 187.636 202.964 188.137Z"
        :fill="myColors.main"
      />
      <path
        d="M143.157 188.237C143.157 188.838 142.656 189.339 142.155 189.339C141.654 189.339 140.953 188.738 140.953 188.237C140.953 187.636 141.554 187.135 142.155 187.135C142.756 187.135 143.157 187.536 143.157 188.237Z"
        :fill="myColors.main"
      />
      <path
        d="M262.671 170.205C262.671 170.806 262.17 171.307 261.669 171.307C261.168 171.307 260.467 170.706 260.467 170.205C260.467 169.604 260.968 169.103 261.669 169.103C262.27 169.103 262.671 169.504 262.671 170.205Z"
        :fill="myColors.main"
      />
      <path
        d="M83.4496 188.237C83.4496 188.838 82.8485 189.44 82.2475 189.339C81.6464 189.339 81.1455 188.738 81.1455 188.137C81.1455 187.536 81.5462 187.135 82.2475 187.135C82.9487 187.135 83.4496 187.636 83.4496 188.237Z"
        :fill="myColors.main"
      />
      <path
        d="M45.0808 147.064C45.0808 146.462 45.4815 146.062 46.1828 146.062C46.884 146.062 47.2847 146.563 47.1846 147.264C47.1846 147.865 46.6837 148.266 46.0826 148.266C45.4815 148.266 45.0808 147.765 45.0808 147.064Z"
        :fill="myColors.main"
      />
      <path
        d="M205.468 155.078C206.17 155.078 206.57 155.478 206.57 156.18C206.57 156.781 206.069 157.282 205.368 157.182C204.767 157.182 204.366 156.781 204.266 156.18C204.366 155.579 204.767 155.078 205.468 155.078Z"
        :fill="myColors.main"
      />
      <path
        d="M166.699 147.063C166.699 147.765 166.298 148.266 165.597 148.266C164.996 148.266 164.595 147.765 164.595 147.164C164.595 146.462 164.996 145.961 165.697 146.062C166.298 146.062 166.699 146.462 166.699 147.063Z"
        :fill="myColors.main"
      />
      <path
        d="M201.762 216.388C201.06 216.388 200.66 215.987 200.66 215.286C200.66 214.685 201.161 214.184 201.762 214.184C202.262 214.184 202.964 214.785 202.964 215.286C202.964 215.887 202.463 216.388 201.762 216.388Z"
        :fill="myColors.main"
      />
      <path
        d="M85.8539 175.214C85.2528 175.214 84.7519 174.613 84.8521 174.012C84.9523 173.411 85.353 173.11 86.0543 173.11C86.7555 173.11 87.1562 173.611 87.1562 174.212C87.0561 174.913 86.5552 175.314 85.8539 175.214Z"
        :fill="myColors.main"
      />
      <path
        d="M47.1846 219.293C47.1846 219.994 46.6837 220.395 46.0826 220.395C45.4815 220.395 45.0808 219.894 45.0808 219.293C45.0808 218.592 45.4815 218.191 46.0826 218.191C46.7838 218.091 47.1846 218.592 47.1846 219.293Z"
        :fill="myColors.main"
      />
      <path
        d="M53.4959 147.565C53.4959 148.266 53.0952 148.767 52.3939 148.666C51.7929 148.666 51.292 148.166 51.292 147.464C51.292 146.863 51.6927 146.463 52.2938 146.362C52.995 146.463 53.4959 146.963 53.4959 147.565Z"
        :fill="myColors.main"
      />
      <path
        d="M73.4316 147.665C73.4316 148.366 72.9307 148.767 72.3296 148.767C71.7285 148.767 71.3278 148.266 71.3278 147.564C71.3278 146.963 71.8287 146.563 72.4298 146.563C73.0309 146.563 73.4316 146.963 73.4316 147.665Z"
        :fill="myColors.main"
      />
      <path
        d="M112.301 148.766C111.7 148.766 111.099 148.266 111.099 147.664C111.099 147.063 111.6 146.462 112.201 146.462C112.702 146.462 113.303 146.963 113.303 147.464C113.403 148.165 112.902 148.766 112.301 148.766Z"
        :fill="myColors.main"
      />
      <path
        d="M208.975 124.623C208.975 125.324 208.474 125.725 207.772 125.625C207.171 125.525 206.67 125.024 206.771 124.523C206.771 123.922 207.372 123.421 207.973 123.421C208.574 123.421 209.075 124.022 208.975 124.623Z"
        :fill="myColors.main"
      />
      <path
        d="M232.817 129.632C232.817 130.333 232.316 130.734 231.715 130.734C231.114 130.734 230.613 130.233 230.613 129.632C230.613 129.031 231.114 128.43 231.715 128.43C232.216 128.43 232.817 129.031 232.817 129.632Z"
        :fill="myColors.main"
      />
      <path
        d="M190.842 129.532C190.842 128.931 191.243 128.43 191.844 128.43C192.445 128.43 192.946 128.831 192.946 129.532C192.946 130.233 192.545 130.634 191.844 130.634C191.143 130.734 190.742 130.233 190.842 129.532Z"
        :fill="myColors.main"
      />
      <path
        d="M173.01 129.632C173.01 130.333 172.609 130.734 171.908 130.734C171.307 130.734 170.906 130.233 170.906 129.632C170.906 128.931 171.307 128.43 172.008 128.43C172.509 128.43 173.01 128.931 173.01 129.632Z"
        :fill="myColors.main"
      />
      <path
        d="M201.762 135.242C201.161 135.242 200.66 134.641 200.76 134.04C200.76 133.339 201.261 132.938 201.962 133.038C202.563 133.139 203.064 133.639 202.964 134.14C202.964 134.741 202.263 135.343 201.762 135.242Z"
        :fill="myColors.main"
      />
      <path
        d="M173.01 147.665C173.01 148.366 172.509 148.767 171.908 148.767C171.307 148.767 170.906 148.266 170.906 147.665C170.906 146.963 171.407 146.563 172.008 146.563C172.609 146.563 173.01 147.063 173.01 147.665Z"
        :fill="myColors.main"
      />
      <path
        d="M192.946 147.664C192.946 148.366 192.545 148.766 191.844 148.766C191.143 148.766 190.842 148.266 190.842 147.564C190.842 146.963 191.243 146.462 191.844 146.462C192.445 146.462 192.946 146.963 192.946 147.664Z"
        :fill="myColors.main"
      />
      <path
        d="M232.817 183.729C232.817 184.431 232.316 184.831 231.715 184.831C231.114 184.831 230.613 184.33 230.613 183.729C230.613 183.128 231.114 182.527 231.715 182.527C232.216 182.527 232.817 183.128 232.817 183.729Z"
        :fill="myColors.main"
      />
      <path
        d="M232.817 147.664C232.817 148.266 232.316 148.766 231.615 148.766C231.014 148.766 230.513 148.266 230.513 147.664C230.513 147.063 231.014 146.563 231.615 146.462C232.316 146.462 232.817 147.063 232.817 147.664Z"
        :fill="myColors.main"
      />
      <path
        d="M190.842 183.629C190.842 183.028 191.243 182.527 191.844 182.527C192.445 182.527 192.946 183.028 192.946 183.629C192.946 184.33 192.545 184.731 191.844 184.731C191.143 184.831 190.742 184.33 190.842 183.629Z"
        :fill="myColors.main"
      />
      <path
        d="M236.424 124.523C236.424 125.224 236.023 125.625 235.322 125.625C234.721 125.625 234.12 125.124 234.12 124.523C234.12 124.022 234.721 123.421 235.222 123.421C235.923 123.421 236.424 123.922 236.424 124.523Z"
        :fill="myColors.main"
      />
      <path
        d="M173.01 183.729C173.01 184.43 172.609 184.831 171.908 184.831C171.307 184.831 170.906 184.43 170.806 183.829C170.806 183.128 171.207 182.627 171.808 182.627C172.509 182.527 173.01 183.028 173.01 183.729Z"
        :fill="myColors.main"
      />
      <path
        d="M232.817 219.794C232.817 220.395 232.316 220.896 231.615 220.896C231.014 220.896 230.513 220.395 230.513 219.794C230.513 219.193 231.014 218.692 231.615 218.592C232.316 218.592 232.817 219.193 232.817 219.794Z"
        :fill="myColors.main"
      />
      <path
        d="M82.3476 126.226C81.6464 126.226 81.2457 125.825 81.2457 125.124C81.2457 124.523 81.7465 124.022 82.3476 124.022C82.8485 124.022 83.5498 124.623 83.5498 125.124C83.4496 125.725 82.9487 126.226 82.3476 126.226Z"
        :fill="myColors.main"
      />
      <path
        d="M192.946 219.794C192.946 220.495 192.545 220.896 191.844 220.896C191.143 220.896 190.842 220.395 190.842 219.694C190.842 219.093 191.243 218.592 191.844 218.592C192.445 218.592 192.946 219.093 192.946 219.794Z"
        :fill="myColors.main"
      />
      <path
        d="M173.01 219.794C173.01 220.495 172.509 220.896 171.908 220.896C171.307 220.896 170.906 220.395 170.906 219.794C170.906 219.093 171.307 218.592 172.008 218.592C172.609 218.592 173.01 219.093 173.01 219.794Z"
        :fill="myColors.main"
      />
      <path
        d="M73.4316 129.632C73.4316 130.334 73.0308 130.734 72.3296 130.734C71.7285 130.734 71.2276 130.233 71.3278 129.532C71.3278 128.931 71.8287 128.53 72.4298 128.53C73.0308 128.43 73.4316 128.931 73.4316 129.632Z"
        :fill="myColors.main"
      />
      <path
        d="M112.201 184.831C111.6 184.831 110.999 184.33 111.099 183.729C111.099 183.128 111.7 182.627 112.301 182.627C112.802 182.627 113.303 183.128 113.403 183.729C113.303 184.33 112.802 184.831 112.201 184.831Z"
        :fill="myColors.main"
      />
      <path
        d="M71.3278 183.729C71.3278 183.028 71.7285 182.527 72.4298 182.527C73.0309 182.527 73.4316 182.928 73.5318 183.529C73.5318 184.23 73.2312 184.731 72.53 184.731C71.7285 184.831 71.3278 184.431 71.3278 183.729Z"
        :fill="myColors.main"
      />
      <path
        d="M53.4959 183.629C53.4959 184.33 53.0952 184.831 52.3939 184.731C51.7929 184.731 51.292 184.23 51.292 183.629C51.292 183.028 51.6927 182.527 52.2938 182.527C52.995 182.527 53.4959 183.028 53.4959 183.629Z"
        :fill="myColors.main"
      />
      <path
        d="M53.4959 129.532C53.4959 130.233 53.0952 130.734 52.3939 130.634C51.7929 130.634 51.292 130.133 51.292 129.432C51.292 128.831 51.6927 128.43 52.2938 128.33C52.995 128.43 53.4959 128.931 53.4959 129.532Z"
        :fill="myColors.main"
      />
      <path
        d="M112.201 220.896C111.6 220.896 110.999 220.395 111.099 219.794C111.099 219.193 111.7 218.692 112.301 218.692C112.802 218.692 113.303 219.193 113.403 219.794C113.303 220.395 112.802 220.896 112.201 220.896Z"
        :fill="myColors.main"
      />
      <path
        d="M166.699 129.131C166.699 129.833 166.198 130.233 165.597 130.233C164.996 130.233 164.595 129.732 164.595 129.131C164.595 128.43 164.996 128.029 165.597 128.029C166.298 127.929 166.699 128.43 166.699 129.131Z"
        :fill="myColors.main"
      />
      <path
        d="M166.699 219.293C166.699 219.994 166.198 220.495 165.597 220.395C164.996 220.395 164.595 219.894 164.595 219.293C164.595 218.592 164.996 218.191 165.597 218.191C166.298 218.091 166.699 218.592 166.699 219.293Z"
        :fill="myColors.main"
      />
      <path
        d="M166.699 183.228C166.699 183.93 166.198 184.33 165.597 184.33C164.996 184.33 164.595 183.829 164.595 183.228C164.595 182.527 164.996 182.126 165.597 182.126C166.298 182.026 166.699 182.527 166.699 183.228Z"
        :fill="myColors.main"
      />
      <path
        d="M82.3476 151.071C82.9487 151.071 83.5498 151.572 83.5498 152.173C83.5498 152.774 82.9487 153.375 82.3476 153.275C81.8467 153.275 81.3458 152.774 81.2456 152.173C81.1454 151.472 81.6463 151.071 82.3476 151.071Z"
        :fill="myColors.main"
      />
      <path
        d="M143.157 152.173C143.157 152.774 142.656 153.275 142.155 153.275C141.654 153.275 140.953 152.674 140.953 152.173C140.953 151.572 141.554 151.071 142.155 151.071C142.756 151.071 143.157 151.472 143.157 152.173Z"
        :fill="myColors.main"
      />
      <path
        d="M73.4316 219.794C73.4316 220.495 73.0309 220.896 72.3296 220.896C71.6284 220.896 71.2276 220.395 71.2276 219.694C71.2276 219.093 71.6284 218.592 72.2294 218.592C72.9307 218.592 73.4316 219.093 73.4316 219.794Z"
        :fill="myColors.main"
      />
      <path
        d="M201.762 153.275C201.161 153.275 200.66 152.674 200.66 152.072C200.66 151.371 201.161 150.97 201.862 150.97C202.463 150.97 202.964 151.572 202.964 152.072C202.964 152.674 202.363 153.275 201.762 153.275Z"
        :fill="myColors.main"
      />
      <path
        d="M47.1846 183.228C47.1846 183.93 46.6837 184.33 45.9825 184.33C45.3814 184.33 44.9807 183.829 45.0808 183.128C45.0808 182.427 45.4816 182.026 46.0826 182.026C46.7839 182.126 47.2848 182.527 47.1846 183.228Z"
        :fill="myColors.main"
      />
      <path
        d="M89.5605 178.72C89.5605 179.321 89.1598 179.722 88.4585 179.722C87.8575 179.722 87.2564 179.221 87.2564 178.62C87.2564 178.019 87.8575 177.518 88.4585 177.518C89.0596 177.518 89.5605 178.119 89.5605 178.72Z"
        :fill="myColors.main"
      />
      <path
        d="M45.0808 129.031C45.0808 128.33 45.4815 127.929 46.0826 127.929C46.7838 127.929 47.1846 128.33 47.1846 129.131C47.1846 129.833 46.6837 130.233 45.9824 130.233C45.4815 130.133 45.0808 129.732 45.0808 129.031Z"
        :fill="myColors.main"
      />
      <path
        d="M53.4959 219.794C53.4959 220.495 52.995 220.896 52.3939 220.896C51.7929 220.796 51.3921 220.295 51.3921 219.694C51.3921 219.092 51.893 218.692 52.4941 218.692C53.0952 218.692 53.4959 219.193 53.4959 219.794Z"
        :fill="myColors.main"
      />
      <path
        d="M116.91 178.72C116.91 179.421 116.409 179.822 115.808 179.822C115.206 179.822 114.706 179.321 114.706 178.72C114.706 178.119 115.307 177.518 115.908 177.518C116.509 177.518 116.91 178.019 116.91 178.72Z"
        :fill="myColors.main"
      />
      <path
        d="M201.862 180.323C201.161 180.323 200.76 179.922 200.76 179.221C200.76 178.62 201.361 178.019 201.862 178.119C202.363 178.119 202.964 178.72 202.964 179.221C202.964 179.722 202.463 180.223 201.862 180.323Z"
        :fill="myColors.main"
      />
      <path
        d="M201.862 126.226C201.161 126.226 200.76 125.825 200.76 125.124C200.76 124.523 201.361 123.922 201.962 124.022C202.463 124.022 203.064 124.623 203.064 125.124C202.964 125.725 202.463 126.226 201.862 126.226Z"
        :fill="myColors.main"
      />
      <path
        d="M147.064 178.62C147.064 177.919 147.564 177.518 148.266 177.518C148.767 177.518 149.368 178.119 149.368 178.62C149.368 179.221 148.867 179.722 148.166 179.722C147.464 179.822 147.064 179.321 147.064 178.62Z"
        :fill="myColors.main"
      />
      <path
        d="M262.671 152.173C262.671 152.774 262.17 153.275 261.669 153.275C261.168 153.275 260.467 152.674 260.467 152.173C260.467 151.572 260.968 151.071 261.669 151.071C262.27 151.071 262.671 151.472 262.671 152.173Z"
        :fill="myColors.main"
      />
      <path
        d="M82.2475 180.323C81.5462 180.323 81.1455 179.822 81.2457 179.121C81.2457 178.62 81.8467 178.019 82.3476 178.119C82.8485 178.119 83.5498 178.82 83.4496 179.321C83.4496 179.822 82.9487 180.323 82.2475 180.323Z"
        :fill="myColors.main"
      />
      <path
        d="M236.424 178.72C236.424 179.421 235.923 179.822 235.222 179.722C234.621 179.622 234.12 179.121 234.12 178.62C234.12 178.019 234.721 177.518 235.322 177.518C236.023 177.518 236.424 178.119 236.424 178.72Z"
        :fill="myColors.main"
      />
      <path
        d="M85.954 155.078C86.6553 155.078 87.056 155.478 87.056 156.18C87.056 156.781 86.5551 157.182 85.8539 157.182C85.2528 157.182 84.8521 156.681 84.8521 156.08C84.8521 155.478 85.353 155.078 85.954 155.078Z"
        :fill="myColors.main"
      />
      <path
        d="M208.975 178.72C208.975 179.421 208.474 179.822 207.873 179.822C207.272 179.822 206.771 179.321 206.771 178.72C206.771 178.219 207.372 177.518 207.873 177.518C208.574 177.518 208.975 178.019 208.975 178.72Z"
        :fill="myColors.main"
      />
      <path
        d="M194.448 178.62C194.448 178.019 194.949 177.518 195.45 177.518C196.051 177.518 196.552 178.019 196.552 178.72C196.552 179.421 196.152 179.822 195.55 179.822C194.849 179.822 194.448 179.321 194.448 178.62Z"
        :fill="myColors.main"
      />
      <path
        d="M216.488 178.72C216.488 179.421 215.987 179.822 215.386 179.822C214.785 179.822 214.384 179.421 214.384 178.72C214.384 178.019 214.885 177.518 215.486 177.518C216.087 177.518 216.488 178.119 216.488 178.72Z"
        :fill="myColors.main"
      />
      <path
        d="M189.139 178.72C189.139 179.421 188.738 179.822 188.037 179.822C187.436 179.822 187.035 179.421 187.035 178.72C187.035 178.019 187.436 177.518 188.137 177.518C188.638 177.518 189.139 178.019 189.139 178.72Z"
        :fill="myColors.main"
      />
      <path
        d="M238.828 219.293C238.828 219.894 238.327 220.395 237.726 220.395C237.125 220.395 236.624 219.794 236.724 219.193C236.724 218.592 237.125 218.191 237.826 218.191C238.427 218.191 238.928 218.592 238.828 219.293Z"
        :fill="myColors.main"
      />
      <path
        d="M78.5408 157.182C77.8395 157.182 77.4388 156.781 77.4388 156.08C77.4388 155.478 77.8395 155.078 78.4406 155.078C79.1419 155.078 79.6428 155.478 79.6428 156.08C79.6428 156.681 79.1419 157.182 78.5408 157.182Z"
        :fill="myColors.main"
      />
      <path
        d="M66.1185 157.182C65.5175 157.182 64.9164 156.681 64.9164 156.08C64.9164 155.479 65.4173 154.978 66.1185 154.978C66.8198 154.978 67.2205 155.378 67.2205 155.979C67.2205 156.781 66.8198 157.182 66.1185 157.182Z"
        :fill="myColors.main"
      />
      <path
        d="M66.1185 211.279C65.5175 211.279 64.9164 210.878 64.9164 210.277C64.9164 209.676 65.4173 209.175 66.0184 209.175C66.7196 209.175 67.1203 209.475 67.1203 210.177C67.2205 210.778 66.8198 211.279 66.1185 211.279Z"
        :fill="myColors.main"
      />
      <path
        d="M38.6693 155.078C39.3706 155.078 39.7713 155.579 39.6711 156.28C39.571 156.881 39.1702 157.282 38.5692 157.282C37.8679 157.282 37.4672 156.781 37.4672 156.18C37.5674 155.478 38.0683 155.078 38.6693 155.078Z"
        :fill="myColors.main"
      />
      <path
        d="M166.999 178.62C166.999 178.019 167.5 177.418 168.101 177.518C168.702 177.518 169.203 178.019 169.103 178.72C169.103 179.321 168.702 179.722 168.101 179.722C167.4 179.822 166.999 179.321 166.999 178.62Z"
        :fill="myColors.main"
      />
      <path
        d="M38.5692 211.279C37.9681 211.279 37.5674 210.778 37.5674 210.177C37.5674 209.475 38.1684 209.075 38.8697 209.175C39.4708 209.275 39.8715 209.676 39.7713 210.277C39.6711 210.978 39.2704 211.379 38.5692 211.279Z"
        :fill="myColors.main"
      />
      <path
        d="M242.735 224.302C242.735 224.903 242.234 225.404 241.633 225.404C241.032 225.404 240.631 224.903 240.631 224.302C240.631 223.601 241.032 223.2 241.733 223.2C242.334 223.2 242.735 223.601 242.735 224.302Z"
        :fill="myColors.main"
      />
      <path
        d="M242.735 206.269C242.735 206.871 242.234 207.472 241.633 207.472C241.032 207.472 240.631 206.971 240.531 206.47C240.531 205.769 240.932 205.268 241.533 205.268C242.234 205.167 242.735 205.568 242.735 206.269Z"
        :fill="myColors.main"
      />
      <path
        d="M105.89 155.078C106.491 155.078 106.992 155.579 106.992 156.18C106.992 156.781 106.391 157.182 105.79 157.182C105.189 157.182 104.788 156.781 104.788 156.08C104.788 155.478 105.189 155.078 105.89 155.078Z"
        :fill="myColors.main"
      />
      <path
        d="M240.531 152.173C240.531 151.472 240.932 151.071 241.633 151.071C242.334 151.071 242.735 151.572 242.635 152.273C242.635 152.874 242.134 153.375 241.633 153.375C241.132 153.375 240.631 152.874 240.531 152.173Z"
        :fill="myColors.main"
      />
      <path
        d="M220.696 206.269C220.696 205.568 221.096 205.167 221.798 205.167C222.399 205.167 222.799 205.568 222.799 206.269C222.799 206.971 222.399 207.472 221.697 207.472C221.096 207.371 220.696 206.871 220.696 206.269Z"
        :fill="myColors.main"
      />
      <path
        d="M96.9738 178.72C96.9738 179.421 96.4729 179.822 95.8718 179.822C95.2707 179.822 94.87 179.321 94.87 178.62C94.87 178.019 95.3709 177.618 95.972 177.618C96.573 177.518 97.0739 178.019 96.9738 178.72Z"
        :fill="myColors.main"
      />
      <path
        d="M222.799 134.14C222.799 134.842 222.298 135.343 221.697 135.343C221.096 135.343 220.595 134.842 220.696 134.14C220.696 133.439 221.096 133.139 221.697 133.038C222.399 133.038 222.799 133.439 222.799 134.14Z"
        :fill="myColors.main"
      />
      <path
        d="M240.531 134.14C240.531 133.439 241.032 133.038 241.633 133.038C242.334 133.038 242.735 133.539 242.635 134.241C242.635 134.842 242.134 135.242 241.533 135.343C241.032 135.242 240.531 134.741 240.531 134.14Z"
        :fill="myColors.main"
      />
      <path
        d="M222.799 152.173C222.799 152.874 222.298 153.375 221.697 153.275C221.096 153.275 220.696 152.774 220.696 152.073C220.696 151.472 221.096 151.071 221.697 151.071C222.399 151.071 222.799 151.472 222.799 152.173Z"
        :fill="myColors.main"
      />
      <path
        d="M77.0381 178.62C77.0381 179.321 76.6373 179.822 75.9361 179.722C75.335 179.722 74.9343 179.321 74.9343 178.62C74.9343 177.919 75.4352 177.418 76.0363 177.418C76.6373 177.518 77.0381 178.019 77.0381 178.62Z"
        :fill="myColors.main"
      />
      <path
        d="M67.4209 178.52C67.4209 177.919 67.9218 177.518 68.5229 177.518C69.1239 177.518 69.6248 178.119 69.5247 178.82C69.4245 179.421 69.0238 179.822 68.4227 179.722C67.8216 179.722 67.4209 179.221 67.4209 178.52Z"
        :fill="myColors.main"
      />
      <path
        d="M47.4851 178.62C47.4851 178.019 47.986 177.518 48.5871 177.518C49.1882 177.518 49.5889 178.119 49.5889 178.72C49.5889 179.321 49.1882 179.722 48.4869 179.722C47.8858 179.822 47.4851 179.321 47.4851 178.62Z"
        :fill="myColors.main"
      />
      <path
        d="M123.221 152.173C123.221 152.774 122.62 153.275 122.019 153.275C121.418 153.275 121.017 152.774 121.017 152.173C121.017 151.472 121.418 151.071 122.119 151.071C122.82 151.071 123.221 151.572 123.221 152.173Z"
        :fill="myColors.main"
      />
      <path
        d="M103.285 152.173C103.285 152.874 102.784 153.275 102.183 153.275C101.582 153.175 101.181 152.674 101.181 152.073C101.181 151.472 101.582 151.071 102.283 151.071C102.884 151.071 103.285 151.472 103.285 152.173Z"
        :fill="myColors.main"
      />
      <path
        d="M158.183 157.182C157.482 157.182 156.981 156.681 156.981 156.08C156.981 155.479 157.482 154.978 158.183 155.078C158.785 155.078 159.185 155.479 159.285 156.08C159.285 156.781 158.885 157.182 158.183 157.182Z"
        :fill="myColors.main"
      />
      <path
        d="M123.221 206.169C123.221 206.77 122.82 207.372 122.119 207.372C121.618 207.372 121.117 206.971 121.017 206.37C120.917 205.669 121.317 205.168 122.019 205.168C122.72 205.067 123.221 205.568 123.221 206.169Z"
        :fill="myColors.main"
      />
      <path
        d="M119.414 183.228C119.414 183.93 118.913 184.33 118.312 184.33C117.711 184.33 117.21 183.729 117.31 183.128C117.31 182.527 117.711 182.126 118.412 182.126C119.013 182.126 119.414 182.527 119.414 183.228Z"
        :fill="myColors.main"
      />
      <path
        d="M117.21 129.031C117.21 128.33 117.711 127.929 118.312 127.929C118.913 127.929 119.314 128.33 119.314 129.031C119.314 129.732 118.813 130.233 118.212 130.233C117.711 130.233 117.21 129.732 117.21 129.031Z"
        :fill="myColors.main"
      />
      <path
        d="M207.873 150.57C208.574 150.57 208.975 150.97 208.975 151.672C208.975 152.273 208.474 152.774 207.973 152.774C207.372 152.774 206.771 152.273 206.771 151.672C206.771 151.071 207.272 150.57 207.873 150.57Z"
        :fill="myColors.main"
      />
      <path
        d="M146.763 183.228C146.763 183.93 146.262 184.33 145.661 184.33C145.06 184.33 144.559 183.729 144.659 183.128C144.659 182.527 145.16 182.126 145.761 182.126C146.362 182.126 146.863 182.527 146.763 183.228Z"
        :fill="myColors.main"
      />
      <path
        d="M146.763 129.031C146.763 129.732 146.262 130.233 145.661 130.233C145.06 130.233 144.559 129.732 144.559 129.031C144.559 128.43 144.96 128.029 145.561 127.929C146.362 127.929 146.763 128.43 146.763 129.031Z"
        :fill="myColors.main"
      />
      <path
        d="M251.651 173.611C252.352 173.611 252.753 174.112 252.753 174.713C252.753 175.314 252.252 175.815 251.551 175.715C250.95 175.715 250.549 175.314 250.549 174.713C250.449 174.012 250.95 173.611 251.651 173.611Z"
        :fill="myColors.main"
      />
      <path
        d="M103.285 206.269C103.285 206.971 102.884 207.472 102.183 207.472C101.582 207.472 101.081 206.971 101.081 206.269C101.081 205.668 101.482 205.268 102.083 205.167C102.884 205.167 103.285 205.568 103.285 206.269Z"
        :fill="myColors.main"
      />
      <path
        d="M175.615 150.57C176.316 150.57 176.817 151.071 176.817 151.672C176.817 152.273 176.216 152.774 175.615 152.774C175.014 152.774 174.613 152.273 174.513 151.672C174.513 150.97 174.914 150.57 175.615 150.57Z"
        :fill="myColors.main"
      />
      <path
        d="M211.679 173.611C212.381 173.611 212.781 174.012 212.781 174.713C212.781 175.414 212.381 175.815 211.679 175.815C211.078 175.815 210.577 175.414 210.577 174.813C210.577 174.112 211.078 173.611 211.679 173.611Z"
        :fill="myColors.main"
      />
      <path
        d="M148.166 150.57C148.867 150.57 149.368 151.071 149.368 151.672C149.368 152.273 148.767 152.774 148.166 152.774C147.564 152.774 147.164 152.273 147.064 151.672C147.064 150.97 147.464 150.57 148.166 150.57Z"
        :fill="myColors.main"
      />
      <path
        d="M222.799 224.302C222.799 225.003 222.298 225.504 221.697 225.504C221.096 225.504 220.595 225.003 220.696 224.302C220.696 223.601 221.096 223.3 221.697 223.2C222.399 223.2 222.799 223.601 222.799 224.302Z"
        :fill="myColors.main"
      />
      <path
        d="M146.763 219.293C146.763 219.994 146.262 220.395 145.661 220.395C145.06 220.395 144.659 219.794 144.659 219.193C144.659 218.592 145.16 218.191 145.761 218.191C146.362 218.191 146.863 218.592 146.763 219.293Z"
        :fill="myColors.main"
      />
      <path
        d="M238.828 183.128C238.828 183.829 238.427 184.33 237.726 184.33C237.125 184.33 236.624 183.829 236.624 183.228C236.624 182.627 237.025 182.126 237.626 182.126C238.427 182.026 238.828 182.427 238.828 183.128Z"
        :fill="myColors.main"
      />
      <path
        d="M88.3584 150.57C89.0596 150.57 89.4603 150.97 89.4603 151.672C89.4603 152.273 89.0596 152.774 88.4585 152.774C87.8575 152.774 87.2564 152.273 87.2564 151.672C87.2564 151.071 87.7573 150.57 88.3584 150.57Z"
        :fill="myColors.main"
      />
      <path
        d="M236.724 129.131C236.724 128.43 237.125 128.029 237.826 128.029C238.427 128.029 238.828 128.43 238.828 129.031C238.828 129.732 238.427 130.233 237.826 130.233C237.225 130.233 236.724 129.732 236.724 129.131Z"
        :fill="myColors.main"
      />
      <path
        d="M119.414 165.296C119.314 165.897 118.813 166.398 118.212 166.298C117.611 166.198 117.21 165.697 117.31 164.996C117.41 164.394 117.811 163.994 118.412 164.094C119.013 164.094 119.414 164.595 119.414 165.296Z"
        :fill="myColors.main"
      />
      <path
        d="M38.5692 137.045C39.2704 137.045 39.6711 137.446 39.6711 138.147C39.6711 138.749 39.2704 139.149 38.6693 139.249C37.9681 139.249 37.4672 138.849 37.4672 138.248C37.4672 137.546 37.9681 137.045 38.5692 137.045Z"
        :fill="myColors.main"
      />
      <path
        d="M132.137 175.715C131.436 175.715 131.035 175.314 131.035 174.613C131.035 174.012 131.436 173.611 132.137 173.611C132.838 173.611 133.339 174.012 133.339 174.713C133.239 175.314 132.738 175.715 132.137 175.715Z"
        :fill="myColors.main"
      />
      <path
        d="M119.414 219.293C119.414 219.994 118.913 220.495 118.312 220.395C117.711 220.395 117.21 219.894 117.31 219.193C117.31 218.592 117.711 218.191 118.312 218.191C118.913 218.091 119.414 218.592 119.414 219.293Z"
        :fill="myColors.main"
      />
      <path
        d="M185.533 155.078C186.234 155.078 186.635 155.478 186.635 156.18C186.635 156.781 186.234 157.182 185.533 157.182C184.831 157.182 184.33 156.681 184.33 156.08C184.431 155.579 184.931 155.078 185.533 155.078Z"
        :fill="myColors.main"
      />
      <path
        d="M56.1005 150.57C56.8018 150.57 57.3027 151.071 57.2025 151.672C57.2025 152.273 56.6014 152.774 56.0003 152.774C55.3993 152.774 54.9986 152.273 54.9986 151.672C54.9986 150.97 55.3993 150.57 56.1005 150.57Z"
        :fill="myColors.main"
      />
      <path
        d="M92.2654 175.715C91.5641 175.715 91.0632 175.314 91.0632 174.613C91.0632 174.012 91.5641 173.511 92.2654 173.511C92.8665 173.511 93.2672 173.911 93.3674 174.513C93.3674 175.314 92.9666 175.715 92.2654 175.715Z"
        :fill="myColors.main"
      />
      <path
        d="M78.4406 209.175C79.1419 209.175 79.6428 209.676 79.6428 210.277C79.6428 210.878 79.1419 211.379 78.4406 211.379C77.8395 211.379 77.4388 210.978 77.3386 210.377C77.3386 209.676 77.7394 209.175 78.4406 209.175Z"
        :fill="myColors.main"
      />
      <path
        d="M225.404 175.214C224.703 175.214 224.302 174.813 224.302 174.112C224.302 173.511 224.703 173.11 225.304 173.11C226.005 173.11 226.506 173.511 226.506 174.112C226.606 174.713 226.105 175.214 225.404 175.214Z"
        :fill="myColors.main"
      />
      <path
        d="M216.488 124.623C216.488 125.324 215.987 125.725 215.386 125.725C214.785 125.725 214.384 125.324 214.384 124.623C214.384 123.922 214.885 123.421 215.486 123.421C216.087 123.421 216.488 124.022 216.488 124.623Z"
        :fill="myColors.main"
      />
      <path
        d="M66.0184 139.149C65.4173 139.149 64.9164 138.648 64.9164 138.047C64.9164 137.446 65.5175 137.045 66.1185 137.045C66.7196 137.045 67.1203 137.446 67.1203 138.147C67.1203 138.849 66.7196 139.249 66.0184 139.149Z"
        :fill="myColors.main"
      />
      <path
        d="M198.055 173.11C198.656 173.11 199.157 173.611 199.157 174.212C199.157 174.813 198.556 175.214 197.955 175.214C197.354 175.214 196.953 174.713 196.953 174.112C196.853 173.511 197.354 173.11 198.055 173.11Z"
        :fill="myColors.main"
      />
      <path
        d="M185.533 173.11C186.234 173.11 186.635 173.511 186.635 174.112C186.635 174.713 186.234 175.114 185.633 175.214C184.931 175.214 184.431 174.813 184.431 174.212C184.33 173.611 184.831 173.11 185.533 173.11Z"
        :fill="myColors.main"
      />
      <path
        d="M197.955 157.182C197.254 157.182 196.853 156.781 196.853 156.08C196.853 155.478 197.254 155.078 197.855 155.078C198.556 155.078 199.057 155.478 199.057 156.08C199.157 156.681 198.656 157.182 197.955 157.182Z"
        :fill="myColors.main"
      />
      <path
        d="M56.1005 186.634C56.8018 186.634 57.3027 187.135 57.2025 187.736C57.2025 188.337 56.5012 188.838 56.0003 188.838C55.3993 188.838 54.9986 188.337 54.9986 187.736C54.9986 187.035 55.3993 186.634 56.1005 186.634Z"
        :fill="myColors.main"
      />
      <path
        d="M56.1005 222.699C56.8018 222.699 57.3027 223.2 57.3027 223.801C57.3027 224.402 56.7016 224.903 56.1005 224.903C55.4994 224.903 55.0987 224.402 54.9986 223.801C54.9986 223.1 55.3993 222.699 56.1005 222.699Z"
        :fill="myColors.main"
      />
      <path
        d="M158.183 175.214C157.582 175.214 156.981 174.813 156.981 174.212C156.981 173.611 157.482 173.11 158.083 173.11C158.785 173.11 159.185 173.511 159.185 174.112C159.285 174.813 158.885 175.214 158.183 175.214Z"
        :fill="myColors.main"
      />
      <path
        d="M88.4586 186.634C89.1599 186.634 89.5606 187.035 89.5606 187.736C89.5606 188.337 89.0597 188.838 88.5588 188.838C87.9577 188.838 87.3566 188.337 87.3566 187.736C87.2564 187.135 87.7573 186.634 88.4586 186.634Z"
        :fill="myColors.main"
      />
      <path
        d="M88.4586 222.699C89.1598 222.699 89.5605 223.1 89.4603 223.801C89.4603 224.402 88.9594 224.903 88.3584 224.903C87.7573 224.903 87.1562 224.302 87.1562 223.801C87.2564 223.1 87.7573 222.699 88.4586 222.699Z"
        :fill="myColors.main"
      />
      <path
        d="M78.5408 137.045C79.1418 137.045 79.6427 137.546 79.6427 138.147C79.6427 138.749 79.0417 139.149 78.4406 139.149C77.8395 139.149 77.4388 138.749 77.4388 138.047C77.3386 137.446 77.8395 137.045 78.5408 137.045Z"
        :fill="myColors.main"
      />
      <path
        d="M216.488 160.588C216.488 161.289 216.087 161.69 215.386 161.69C214.785 161.69 214.384 161.289 214.284 160.688C214.284 159.986 214.685 159.486 215.286 159.486C215.987 159.486 216.488 159.986 216.488 160.588Z"
        :fill="myColors.main"
      />
      <path
        d="M207.873 204.667C208.574 204.667 208.975 205.067 208.975 205.769C208.975 206.37 208.474 206.871 207.973 206.871C207.372 206.871 206.771 206.37 206.771 205.769C206.771 205.168 207.272 204.667 207.873 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M105.89 175.214C105.189 175.214 104.788 174.813 104.788 174.112C104.788 173.511 105.189 173.11 105.89 173.11C106.591 173.11 107.092 173.511 107.092 174.212C107.092 174.813 106.491 175.214 105.89 175.214Z"
        :fill="myColors.main"
      />
      <path
        d="M148.166 186.634C148.867 186.634 149.368 187.135 149.268 187.736C149.268 188.337 148.566 188.838 148.065 188.838C147.464 188.838 147.064 188.337 147.064 187.736C147.064 187.035 147.464 186.634 148.166 186.634Z"
        :fill="myColors.main"
      />
      <path
        d="M196.552 124.523C196.552 125.224 196.152 125.725 195.45 125.625C194.849 125.625 194.448 125.224 194.448 124.523C194.448 123.822 194.949 123.321 195.55 123.321C196.152 123.421 196.552 123.922 196.552 124.523Z"
        :fill="myColors.main"
      />
      <path
        d="M56.1005 132.537C56.8018 132.537 57.3027 133.038 57.3027 133.639C57.3027 134.24 56.7016 134.741 56.1005 134.741C55.4994 134.741 55.0987 134.24 54.9986 133.639C54.9986 132.938 55.3993 132.537 56.1005 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M176.717 187.636C176.717 188.237 176.216 188.838 175.615 188.838C175.014 188.838 174.513 188.438 174.513 187.837C174.413 187.135 174.813 186.634 175.515 186.634C176.216 186.634 176.717 187.035 176.717 187.636Z"
        :fill="myColors.main"
      />
      <path
        d="M105.89 211.279C105.189 211.279 104.788 210.878 104.788 210.177C104.788 209.576 105.189 209.175 105.89 209.175C106.591 209.175 107.092 209.576 107.092 210.177C107.092 210.878 106.591 211.279 105.89 211.279Z"
        :fill="myColors.main"
      />
      <path
        d="M175.515 204.667C176.216 204.667 176.717 205.067 176.717 205.669C176.717 206.27 176.116 206.771 175.615 206.871C175.014 206.871 174.513 206.47 174.513 205.869C174.413 205.168 174.813 204.667 175.515 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M208.975 187.736C208.975 188.337 208.474 188.838 207.873 188.838C207.272 188.838 206.771 188.237 206.771 187.636C206.771 187.035 207.272 186.634 207.873 186.634C208.674 186.634 209.075 187.035 208.975 187.736Z"
        :fill="myColors.main"
      />
      <path
        d="M78.5408 175.214C77.8395 175.214 77.4388 174.813 77.4388 174.112C77.4388 173.511 77.8395 173.11 78.4406 173.01C79.1419 173.01 79.6428 173.411 79.6428 174.012C79.6428 174.713 79.1419 175.214 78.5408 175.214Z"
        :fill="myColors.main"
      />
      <path
        d="M238.828 147.063C238.828 147.765 238.427 148.266 237.726 148.266C237.125 148.266 236.624 147.765 236.624 147.164C236.624 146.563 237.025 146.062 237.626 146.062C238.427 145.962 238.828 146.362 238.828 147.063Z"
        :fill="myColors.main"
      />
      <path
        d="M158.083 209.175C158.785 209.175 159.185 209.576 159.286 210.177C159.286 210.778 158.885 211.178 158.284 211.279C157.582 211.279 157.082 210.878 157.082 210.277C156.981 209.676 157.482 209.175 158.083 209.175Z"
        :fill="myColors.main"
      />
      <path
        d="M66.0184 175.214C65.4173 175.214 64.9164 174.713 64.9164 174.112C64.9164 173.511 65.5175 173.11 66.1185 173.11C66.7196 173.11 67.1203 173.511 67.1203 174.212C67.1203 174.913 66.7196 175.314 66.0184 175.214Z"
        :fill="myColors.main"
      />
      <path
        d="M146.763 147.164C146.763 147.865 146.262 148.266 145.661 148.266C145.06 148.266 144.559 147.665 144.659 147.064C144.659 146.462 145.16 146.062 145.761 146.062C146.362 146.062 146.763 146.462 146.763 147.164Z"
        :fill="myColors.main"
      />
      <path
        d="M119.414 147.164C119.414 147.765 118.913 148.366 118.312 148.266C117.711 148.266 117.21 147.765 117.21 147.063C117.21 146.462 117.611 146.062 118.212 145.961C118.913 145.961 119.414 146.462 119.414 147.164Z"
        :fill="myColors.main"
      />
      <path
        d="M189.039 124.523C189.039 125.224 188.638 125.625 187.937 125.625C187.336 125.625 186.935 125.224 186.835 124.623C186.835 123.922 187.236 123.421 187.837 123.421C188.538 123.421 189.039 123.922 189.039 124.523Z"
        :fill="myColors.main"
      />
      <path
        d="M38.5692 175.214C37.8679 175.214 37.4672 174.713 37.4672 174.112C37.4672 173.511 38.0683 173.11 38.6693 173.11C39.2704 173.11 39.6711 173.611 39.6711 174.212C39.7713 174.913 39.2704 175.314 38.5692 175.214Z"
        :fill="myColors.main"
      />
      <path
        d="M103.285 188.137C103.285 188.838 102.884 189.339 102.283 189.339C101.682 189.339 101.181 188.838 101.181 188.137C101.181 187.436 101.582 187.135 102.183 187.035C102.884 187.035 103.285 187.436 103.285 188.137Z"
        :fill="myColors.main"
      />
      <path
        d="M123.221 188.338C123.121 188.939 122.62 189.439 122.019 189.339C121.418 189.239 121.117 188.738 121.117 188.137C121.117 187.436 121.618 187.035 122.319 187.135C122.92 187.135 123.321 187.636 123.221 188.338Z"
        :fill="myColors.main"
      />
      <path
        d="M242.735 170.205C242.735 170.806 242.234 171.407 241.633 171.407C241.032 171.407 240.631 170.906 240.531 170.405C240.531 169.704 240.932 169.203 241.533 169.203C242.234 169.103 242.735 169.504 242.735 170.205Z"
        :fill="myColors.main"
      />
      <path
        d="M146.763 165.196C146.763 165.897 146.262 166.398 145.661 166.298C145.06 166.298 144.559 165.797 144.659 165.096C144.659 164.495 145.06 164.094 145.661 164.094C146.362 163.994 146.763 164.495 146.763 165.196Z"
        :fill="myColors.main"
      />
      <path
        d="M148.166 204.667C148.867 204.667 149.368 205.067 149.368 205.669C149.368 206.169 148.767 206.871 148.266 206.871C147.665 206.871 147.164 206.37 147.164 205.769C147.064 205.067 147.464 204.667 148.166 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M222.799 170.205C222.799 170.906 222.298 171.307 221.697 171.307C221.096 171.207 220.696 170.706 220.696 170.105C220.696 169.504 221.096 169.103 221.798 169.103C222.399 169.103 222.799 169.504 222.799 170.205Z"
        :fill="myColors.main"
      />
      <path
        d="M222.799 188.237C222.799 188.939 222.298 189.44 221.697 189.339C221.096 189.339 220.696 188.838 220.696 188.137C220.696 187.536 221.096 187.135 221.697 187.135C222.399 187.135 222.799 187.536 222.799 188.237Z"
        :fill="myColors.main"
      />
      <path
        d="M242.735 188.137C242.735 188.738 242.234 189.339 241.633 189.339C241.032 189.339 240.631 188.838 240.531 188.337C240.531 187.636 240.832 187.135 241.533 187.135C242.234 187.035 242.735 187.536 242.735 188.137Z"
        :fill="myColors.main"
      />
      <path
        d="M148.166 222.699C148.867 222.699 149.368 223.1 149.368 223.701C149.368 224.202 148.767 224.903 148.266 224.903C147.665 224.903 147.164 224.402 147.164 223.801C147.064 223.1 147.464 222.699 148.166 222.699Z"
        :fill="myColors.main"
      />
      <path
        d="M38.6693 193.246C37.9681 193.246 37.5674 192.745 37.5674 192.144C37.5674 191.543 38.0683 191.042 38.7695 191.142C39.3706 191.142 39.7713 191.543 39.7713 192.144C39.7713 192.846 39.3706 193.246 38.6693 193.246Z"
        :fill="myColors.main"
      />
      <path
        d="M196.552 160.688C196.552 161.389 196.152 161.79 195.45 161.79C194.749 161.79 194.348 161.289 194.348 160.588C194.348 159.986 194.849 159.486 195.35 159.486C196.152 159.486 196.552 159.986 196.552 160.688Z"
        :fill="myColors.main"
      />
      <path
        d="M105.89 139.149C105.189 139.149 104.788 138.648 104.888 137.947C104.888 137.346 105.289 136.945 105.99 136.945C106.591 136.945 107.192 137.446 107.092 138.047C106.992 138.749 106.491 139.25 105.89 139.149Z"
        :fill="myColors.main"
      />
      <path
        d="M66.1185 193.246C65.5175 193.246 64.9164 192.745 64.9164 192.144C64.9164 191.543 65.4173 191.042 66.1185 191.042C66.8198 191.042 67.1203 191.443 67.2205 192.044C67.2205 192.845 66.7196 193.246 66.1185 193.246Z"
        :fill="myColors.main"
      />
      <path
        d="M78.4406 193.246C77.7394 193.246 77.3386 192.745 77.3386 192.144C77.3386 191.543 77.7394 191.143 78.4406 191.143C79.1419 191.143 79.6428 191.643 79.6428 192.245C79.6428 192.846 79.1419 193.246 78.4406 193.246Z"
        :fill="myColors.main"
      />
      <path
        d="M225.404 157.182C224.703 157.182 224.302 156.781 224.302 156.08C224.302 155.478 224.703 155.078 225.404 155.078C226.105 155.078 226.606 155.478 226.606 156.18C226.506 156.781 226.005 157.182 225.404 157.182Z"
        :fill="myColors.main"
      />
      <path
        d="M123.221 170.205C123.221 170.806 122.72 171.307 122.119 171.307C121.518 171.307 121.117 170.806 121.117 170.205C121.117 169.504 121.518 169.103 122.219 169.103C122.82 169.103 123.221 169.504 123.221 170.205Z"
        :fill="myColors.main"
      />
      <path
        d="M105.89 193.246C105.189 193.246 104.788 192.846 104.788 192.144C104.788 191.543 105.189 191.143 105.89 191.143C106.591 191.143 107.092 191.543 107.092 192.144C107.092 192.846 106.591 193.246 105.89 193.246Z"
        :fill="myColors.main"
      />
      <path
        d="M88.4586 132.537C89.1599 132.537 89.5606 132.938 89.5606 133.639C89.5606 134.24 89.0597 134.741 88.5588 134.741C87.9577 134.741 87.3566 134.14 87.3566 133.639C87.2564 133.038 87.7573 132.537 88.4586 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M185.533 209.175C186.234 209.175 186.635 209.576 186.635 210.177C186.635 210.778 186.234 211.178 185.633 211.279C184.931 211.279 184.431 210.878 184.431 210.277C184.33 209.676 184.831 209.175 185.533 209.175Z"
        :fill="myColors.main"
      />
      <path
        d="M197.955 211.279C197.254 211.279 196.853 210.878 196.853 210.177C196.853 209.576 197.254 209.175 197.855 209.175C198.556 209.175 199.057 209.576 199.057 210.177C199.157 210.778 198.656 211.279 197.955 211.279Z"
        :fill="myColors.main"
      />
      <path
        d="M149.368 133.539C149.368 134.04 148.767 134.741 148.266 134.741C147.665 134.741 147.164 134.24 147.164 133.639C147.164 132.938 147.564 132.638 148.166 132.537C148.767 132.537 149.368 132.938 149.368 133.539Z"
        :fill="myColors.main"
      />
      <path
        d="M158.183 193.246C157.582 193.246 156.981 192.846 156.981 192.245C156.981 191.643 157.482 191.143 158.083 191.143C158.785 191.143 159.185 191.543 159.185 192.144C159.285 192.846 158.885 193.246 158.183 193.246Z"
        :fill="myColors.main"
      />
      <path
        d="M103.285 170.205C103.285 170.906 102.784 171.307 102.183 171.307C101.582 171.207 101.181 170.706 101.181 170.105C101.181 169.504 101.582 169.103 102.283 169.103C102.884 169.103 103.285 169.504 103.285 170.205Z"
        :fill="myColors.main"
      />
      <path
        d="M169.203 124.523C169.203 125.224 168.803 125.625 168.101 125.625C167.5 125.625 167.1 125.224 166.999 124.623C166.999 123.922 167.4 123.421 168.001 123.421C168.602 123.421 169.103 123.922 169.203 124.523Z"
        :fill="myColors.main"
      />
      <path
        d="M185.533 191.143C186.234 191.143 186.635 191.543 186.635 192.144C186.635 192.745 186.234 193.146 185.633 193.246C184.931 193.246 184.431 192.846 184.431 192.245C184.33 191.643 184.831 191.143 185.533 191.143Z"
        :fill="myColors.main"
      />
      <path
        d="M197.955 193.246C197.254 193.246 196.853 192.745 196.853 192.144C196.853 191.543 197.254 191.143 197.955 191.143C198.656 191.143 199.157 191.643 199.157 192.245C199.157 192.846 198.556 193.246 197.955 193.246Z"
        :fill="myColors.main"
      />
      <path
        d="M89.5605 205.669C89.5605 206.27 89.0596 206.871 88.4585 206.871C87.8575 206.871 87.2564 206.27 87.2564 205.669C87.2564 205.067 87.7573 204.667 88.3584 204.667C89.0596 204.667 89.4603 204.967 89.5605 205.669Z"
        :fill="myColors.main"
      />
      <path
        d="M216.488 214.685C216.488 215.386 216.087 215.787 215.386 215.787C214.785 215.787 214.384 215.386 214.284 214.785C214.284 214.084 214.685 213.583 215.286 213.583C215.987 213.583 216.488 214.084 216.488 214.685Z"
        :fill="myColors.main"
      />
      <path
        d="M225.304 193.246C224.603 193.246 224.202 192.745 224.302 192.044C224.302 191.443 224.803 191.042 225.404 191.042C226.105 191.042 226.506 191.543 226.506 192.144C226.506 192.845 226.005 193.346 225.304 193.246Z"
        :fill="myColors.main"
      />
      <path
        d="M175.615 222.699C176.316 222.699 176.817 223.2 176.717 223.801C176.717 224.402 176.015 224.903 175.515 224.903C174.914 224.903 174.513 224.402 174.513 223.801C174.513 223.1 174.914 222.699 175.615 222.699Z"
        :fill="myColors.main"
      />
      <path
        d="M186.935 160.487C186.935 159.886 187.436 159.486 188.037 159.486C188.638 159.486 189.139 160.087 189.039 160.788C188.939 161.389 188.538 161.79 187.937 161.69C187.336 161.69 186.935 161.189 186.935 160.487Z"
        :fill="myColors.main"
      />
      <path
        d="M196.552 214.785C196.552 215.486 196.152 215.887 195.55 215.887C194.849 215.887 194.448 215.486 194.448 214.785C194.448 214.084 194.949 213.583 195.55 213.683C196.152 213.683 196.552 214.084 196.552 214.785Z"
        :fill="myColors.main"
      />
      <path
        d="M189.139 214.785C189.139 215.486 188.638 215.887 188.037 215.887C187.436 215.887 187.035 215.486 187.035 214.785C187.035 214.084 187.536 213.583 188.137 213.583C188.638 213.583 189.139 214.084 189.139 214.785Z"
        :fill="myColors.main"
      />
      <path
        d="M175.615 132.537C176.316 132.537 176.817 133.038 176.817 133.639C176.817 134.24 176.216 134.741 175.615 134.741C175.014 134.741 174.613 134.24 174.513 133.639C174.513 132.938 174.914 132.537 175.615 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M166.999 214.685C166.999 214.084 167.5 213.583 168.101 213.583C168.702 213.583 169.103 214.184 169.103 214.785C169.103 215.386 168.702 215.787 168.001 215.787C167.4 215.887 166.999 215.386 166.999 214.685Z"
        :fill="myColors.main"
      />
      <path
        d="M92.3656 193.747C91.6643 193.747 91.1634 193.346 91.1634 192.745C91.1634 192.144 91.6643 191.643 92.2654 191.643C92.8665 191.643 93.3674 192.044 93.3674 192.645C93.3674 193.346 92.9666 193.747 92.3656 193.747Z"
        :fill="myColors.main"
      />
      <path
        d="M225.404 211.279C224.703 211.279 224.302 210.878 224.302 210.177C224.302 209.576 224.703 209.175 225.304 209.175C226.005 209.175 226.506 209.576 226.506 210.177C226.506 210.778 226.105 211.279 225.404 211.279Z"
        :fill="myColors.main"
      />
      <path
        d="M132.137 193.747C131.436 193.747 131.035 193.346 131.035 192.645C131.035 192.044 131.436 191.643 132.037 191.543C132.738 191.543 133.239 191.944 133.239 192.545C133.239 193.246 132.838 193.747 132.137 193.747Z"
        :fill="myColors.main"
      />
      <path
        d="M57.3027 205.769C57.3027 206.37 56.7016 206.871 56.1006 206.871C55.4995 206.871 54.9986 206.27 55.0988 205.669C55.0988 205.067 55.4995 204.667 56.2007 204.667C56.8018 204.667 57.3027 205.168 57.3027 205.769Z"
        :fill="myColors.main"
      />
      <path
        d="M207.973 222.699C208.674 222.699 209.075 223.1 209.075 223.801C209.075 224.402 208.574 224.903 207.973 224.903C207.372 224.903 206.771 224.302 206.771 223.801C206.771 223.2 207.272 222.699 207.973 222.699Z"
        :fill="myColors.main"
      />
      <path
        d="M207.973 132.537C208.674 132.537 209.075 132.938 209.075 133.639C209.075 134.24 208.574 134.741 208.073 134.741C207.472 134.741 206.871 134.24 206.871 133.639C206.771 133.038 207.272 132.537 207.973 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M211.679 193.747C210.978 193.747 210.477 193.246 210.577 192.645C210.577 192.044 211.078 191.643 211.679 191.643C212.381 191.643 212.781 192.044 212.781 192.745C212.882 193.346 212.381 193.847 211.679 193.747Z"
        :fill="myColors.main"
      />
      <path
        d="M92.2654 209.676C92.9666 209.676 93.3674 210.076 93.3674 210.778C93.3674 211.379 92.9666 211.779 92.2654 211.779C91.5641 211.779 91.0632 211.379 91.0632 210.778C91.0632 210.177 91.6643 209.676 92.2654 209.676Z"
        :fill="myColors.main"
      />
      <path
        d="M251.651 193.747C250.95 193.747 250.549 193.346 250.549 192.645C250.549 192.044 250.95 191.643 251.551 191.543C252.252 191.543 252.753 191.944 252.753 192.545C252.753 193.246 252.252 193.747 251.651 193.747Z"
        :fill="myColors.main"
      />
      <path
        d="M169.203 160.688C169.203 161.389 168.803 161.79 168.101 161.79C167.5 161.79 167.1 161.389 167.1 160.688C167.1 160.087 167.6 159.486 168.201 159.486C168.702 159.486 169.203 159.986 169.203 160.688Z"
        :fill="myColors.main"
      />
      <path
        d="M47.5853 196.552C47.5853 195.951 48.0862 195.55 48.6873 195.55C49.3885 195.55 49.7893 196.151 49.6891 196.853C49.5889 197.454 49.1882 197.855 48.5871 197.754C47.8858 197.754 47.4851 197.253 47.5853 196.552Z"
        :fill="myColors.main"
      />
      <path
        d="M121.117 134.14C121.117 133.439 121.518 133.038 122.119 132.938C122.82 132.938 123.221 133.339 123.221 134.04C123.221 134.641 122.72 135.142 122.119 135.142C121.518 135.242 121.117 134.741 121.117 134.14Z"
        :fill="myColors.main"
      />
      <path
        d="M69.6248 196.753C69.6248 197.454 69.1239 197.855 68.5229 197.855C67.9218 197.855 67.5211 197.454 67.5211 196.753C67.5211 196.051 68.022 195.55 68.623 195.55C69.1239 195.55 69.6248 196.051 69.6248 196.753Z"
        :fill="myColors.main"
      />
      <path
        d="M77.0381 196.652C77.0381 197.354 76.6373 197.855 75.9361 197.855C75.335 197.855 74.9343 197.454 74.9343 196.753C74.9343 196.051 75.335 195.55 76.0363 195.55C76.6373 195.55 77.0381 196.051 77.0381 196.652Z"
        :fill="myColors.main"
      />
      <path
        d="M207.873 168.602C208.574 168.602 208.975 169.003 208.975 169.704C208.975 170.305 208.474 170.806 207.973 170.806C207.372 170.806 206.771 170.305 206.771 169.704C206.771 169.103 207.272 168.602 207.873 168.602Z"
        :fill="myColors.main"
      />
      <path
        d="M96.9738 196.753C96.9738 197.454 96.4729 197.855 95.8718 197.855C95.2707 197.855 94.87 197.354 94.87 196.652C94.87 196.051 95.3709 195.651 95.972 195.55C96.573 195.55 96.9738 196.051 96.9738 196.753Z"
        :fill="myColors.main"
      />
      <path
        d="M94.87 124.623C94.87 124.022 95.3709 123.421 95.972 123.521C96.573 123.521 96.9738 124.022 96.9738 124.623C96.9738 125.325 96.573 125.725 95.972 125.826C95.2707 125.725 94.87 125.325 94.87 124.623Z"
        :fill="myColors.main"
      />
      <path
        d="M175.615 168.602C176.316 168.602 176.817 169.103 176.817 169.704C176.817 170.305 176.216 170.806 175.615 170.806C175.014 170.806 174.613 170.305 174.513 169.704C174.513 169.003 174.914 168.602 175.615 168.602Z"
        :fill="myColors.main"
      />
      <path
        d="M216.488 142.555C216.488 143.257 216.087 143.657 215.386 143.657C214.785 143.657 214.384 143.257 214.284 142.656C214.284 141.954 214.685 141.453 215.286 141.453C215.987 141.453 216.488 141.954 216.488 142.555Z"
        :fill="myColors.main"
      />
      <path
        d="M77.0381 124.623C77.0381 125.324 76.6373 125.725 76.0363 125.725C75.335 125.725 74.9343 125.324 74.9343 124.523C74.9343 123.922 75.4352 123.421 75.9361 123.421C76.6373 123.421 77.1382 123.922 77.0381 124.623Z"
        :fill="myColors.main"
      />
      <path
        d="M148.166 168.602C148.867 168.602 149.368 169.003 149.368 169.604C149.368 170.105 148.767 170.806 148.266 170.806C147.665 170.806 147.164 170.305 147.164 169.704C147.064 169.003 147.464 168.602 148.166 168.602Z"
        :fill="myColors.main"
      />
      <path
        d="M196.552 142.555C196.552 143.257 196.152 143.758 195.45 143.657C194.849 143.657 194.448 143.257 194.448 142.555C194.448 141.854 194.949 141.353 195.55 141.353C196.152 141.453 196.552 141.954 196.552 142.555Z"
        :fill="myColors.main"
      />
      <path
        d="M169.203 196.752C169.203 197.454 168.702 197.854 168.101 197.854C167.5 197.854 167.1 197.354 167.1 196.752C167.1 196.151 167.6 195.55 168.201 195.65C168.702 195.55 169.203 196.051 169.203 196.752Z"
        :fill="myColors.main"
      />
      <path
        d="M186.935 142.656C186.935 141.954 187.436 141.453 188.037 141.554C188.638 141.554 189.139 142.055 189.039 142.756C189.039 143.357 188.638 143.758 188.037 143.858C187.436 143.758 186.935 143.357 186.935 142.656Z"
        :fill="myColors.main"
      />
      <path
        d="M186.935 196.652C186.935 195.951 187.436 195.55 188.037 195.55C188.638 195.55 189.039 196.151 189.039 196.753C189.039 197.354 188.638 197.754 187.937 197.754C187.336 197.855 186.935 197.354 186.935 196.652Z"
        :fill="myColors.main"
      />
      <path
        d="M196.552 196.652C196.552 197.354 196.152 197.855 195.45 197.855C194.849 197.855 194.448 197.454 194.448 196.753C194.448 196.051 194.849 195.55 195.55 195.55C196.051 195.55 196.552 196.051 196.552 196.652Z"
        :fill="myColors.main"
      />
      <path
        d="M92.2654 157.682C91.6643 157.682 91.0632 157.182 91.0632 156.58C91.0632 155.979 91.6643 155.479 92.2654 155.479C92.8665 155.479 93.2672 155.879 93.3674 156.48C93.3674 157.282 92.9666 157.682 92.2654 157.682Z"
        :fill="myColors.main"
      />
      <path
        d="M216.488 196.753C216.488 197.454 215.987 197.855 215.386 197.855C214.785 197.855 214.384 197.454 214.384 196.753C214.384 196.051 214.885 195.55 215.486 195.55C216.087 195.55 216.488 196.051 216.488 196.753Z"
        :fill="myColors.main"
      />
      <path
        d="M166.999 142.555C166.999 141.954 167.5 141.353 168.101 141.453C168.702 141.453 169.203 141.954 169.103 142.655C169.103 143.257 168.702 143.657 168.101 143.657C167.4 143.757 166.999 143.257 166.999 142.555Z"
        :fill="myColors.main"
      />
      <path
        d="M132.137 157.682C131.436 157.682 131.035 157.282 131.035 156.581C131.035 155.979 131.436 155.579 132.137 155.579C132.838 155.579 133.339 155.979 133.339 156.681C133.239 157.282 132.738 157.682 132.137 157.682Z"
        :fill="myColors.main"
      />
      <path
        d="M96.9739 160.588C96.9739 161.289 96.5731 161.69 95.8719 161.69C95.1706 161.69 94.7699 161.289 94.7699 160.588C94.7699 159.986 95.1706 159.486 95.7717 159.486C96.4729 159.486 96.9739 159.986 96.9739 160.588Z"
        :fill="myColors.main"
      />
      <path
        d="M238.828 165.196C238.828 165.797 238.327 166.298 237.726 166.298C237.125 166.298 236.724 165.697 236.724 165.096C236.724 164.495 237.225 164.094 237.826 164.094C238.427 164.094 238.928 164.495 238.828 165.196Z"
        :fill="myColors.main"
      />
      <path
        d="M96.9738 142.656C96.9738 143.357 96.4729 143.758 95.8718 143.758C95.2707 143.758 94.87 143.257 94.87 142.555C94.87 141.954 95.3709 141.554 95.972 141.554C96.573 141.453 97.0739 142.054 96.9738 142.656Z"
        :fill="myColors.main"
      />
      <path
        d="M88.4586 168.602C89.1598 168.602 89.5605 169.103 89.4603 169.804C89.4603 170.405 88.9594 170.806 88.3584 170.806C87.7573 170.806 87.1562 170.205 87.2564 169.604C87.2564 169.003 87.8575 168.602 88.4586 168.602Z"
        :fill="myColors.main"
      />
      <path
        d="M103.285 224.302C103.285 225.003 102.784 225.504 102.183 225.504C101.582 225.504 101.081 225.003 101.081 224.302C101.081 223.701 101.482 223.3 102.083 223.2C102.884 223.2 103.285 223.601 103.285 224.302Z"
        :fill="myColors.main"
      />
      <path
        d="M96.9739 214.685C96.9739 215.386 96.5731 215.787 95.8719 215.787C95.1706 215.787 94.7699 215.386 94.7699 214.685C94.7699 214.084 95.1706 213.583 95.7717 213.583C96.4729 213.583 96.9739 214.084 96.9739 214.685Z"
        :fill="myColors.main"
      />
      <path
        d="M158.183 139.149C157.482 139.149 156.981 138.648 156.981 138.047C156.981 137.446 157.482 136.945 158.183 137.045C158.785 137.045 159.185 137.446 159.285 138.047C159.285 138.748 158.885 139.149 158.183 139.149Z"
        :fill="myColors.main"
      />
      <path
        d="M132.137 211.78C131.436 211.78 131.035 211.379 131.035 210.678C131.035 210.076 131.436 209.676 132.037 209.576C132.738 209.576 133.239 209.976 133.239 210.577C133.239 211.279 132.838 211.78 132.137 211.78Z"
        :fill="myColors.main"
      />
      <path
        d="M77.0381 142.555C77.0381 143.257 76.6373 143.657 76.0363 143.657C75.335 143.657 74.9343 143.257 74.9343 142.555C74.9343 141.854 75.4352 141.353 76.0363 141.453C76.6373 141.554 77.0381 141.954 77.0381 142.555Z"
        :fill="myColors.main"
      />
      <path
        d="M67.4209 142.555C67.4209 141.954 67.9218 141.453 68.5229 141.453C69.1239 141.453 69.5247 142.054 69.5247 142.656C69.5247 143.257 69.1239 143.657 68.4227 143.657C67.8216 143.757 67.4209 143.257 67.4209 142.555Z"
        :fill="myColors.main"
      />
      <path
        d="M121.117 224.202C121.117 223.501 121.618 223.1 122.319 223.2C122.92 223.2 123.321 223.701 123.321 224.402C123.221 225.003 122.72 225.404 122.219 225.404C121.518 225.404 121.017 224.903 121.117 224.202Z"
        :fill="myColors.main"
      />
      <path
        d="M56.2007 168.602C56.8018 168.702 57.3027 169.103 57.2025 169.704C57.2025 170.305 56.6015 170.806 56.0004 170.806C55.3993 170.806 54.8984 170.205 54.9986 169.604C55.0988 168.903 55.4995 168.502 56.2007 168.602Z"
        :fill="myColors.main"
      />
      <path
        d="M77.0381 160.688C77.0381 161.389 76.6373 161.79 76.0363 161.79C75.335 161.79 74.9343 161.389 74.9343 160.588C74.9343 159.986 75.4352 159.486 75.9361 159.486C76.6373 159.486 77.1382 159.986 77.0381 160.688Z"
        :fill="myColors.main"
      />
      <path
        d="M185.633 137.045C186.334 137.045 186.735 137.546 186.635 138.248C186.534 138.849 186.234 139.249 185.533 139.249C184.831 139.249 184.33 138.749 184.431 138.147C184.431 137.446 184.931 137.045 185.633 137.045Z"
        :fill="myColors.main"
      />
      <path
        d="M49.6891 142.656C49.6891 143.357 49.2883 143.758 48.5871 143.758C47.986 143.758 47.5853 143.357 47.5853 142.656C47.5853 142.054 48.0862 141.453 48.6873 141.453C49.1882 141.453 49.6891 141.954 49.6891 142.656Z"
        :fill="myColors.main"
      />
      <path
        d="M67.4209 124.523C67.4209 123.922 67.9218 123.421 68.623 123.421C69.2241 123.521 69.6248 124.022 69.6248 124.723C69.5247 125.324 69.1239 125.725 68.5229 125.725C67.8216 125.625 67.4209 125.224 67.4209 124.523Z"
        :fill="myColors.main"
      />
      <path
        d="M251.551 139.65C250.85 139.65 250.449 139.149 250.449 138.548C250.449 137.947 250.85 137.546 251.551 137.546C252.252 137.546 252.753 138.047 252.653 138.648C252.753 139.249 252.252 139.75 251.551 139.65Z"
        :fill="myColors.main"
      />
      <path
        d="M211.679 139.65C211.078 139.55 210.678 139.149 210.678 138.548C210.678 137.947 211.279 137.446 211.88 137.546C212.581 137.546 212.982 138.047 212.882 138.749C212.781 139.35 212.28 139.75 211.679 139.65Z"
        :fill="myColors.main"
      />
      <path
        d="M74.9343 214.785C74.9343 214.084 75.4352 213.583 76.0363 213.683C76.6373 213.683 77.0381 214.184 77.0381 214.785C77.0381 215.486 76.6373 215.887 76.0363 215.887C75.335 215.887 74.9343 215.486 74.9343 214.785Z"
        :fill="myColors.main"
      />
      <path
        d="M69.6248 214.785C69.6248 215.486 69.1239 215.887 68.5229 215.887C67.9218 215.887 67.5211 215.486 67.5211 214.785C67.5211 214.084 68.022 213.583 68.623 213.583C69.1239 213.583 69.6248 214.084 69.6248 214.785Z"
        :fill="myColors.main"
      />
      <path
        d="M69.6248 160.588C69.6248 161.289 69.2241 161.69 68.623 161.79C68.0219 161.79 67.6212 161.389 67.521 160.788C67.4208 160.087 67.9217 159.586 68.5228 159.586C69.0237 159.486 69.5246 159.987 69.6248 160.588Z"
        :fill="myColors.main"
      />
      <path
        d="M132.137 137.546C132.838 137.546 133.239 138.047 133.239 138.648C133.239 139.249 132.738 139.75 132.037 139.65C131.436 139.65 131.035 139.249 131.035 138.648C131.035 137.947 131.436 137.546 132.137 137.546Z"
        :fill="myColors.main"
      />
      <path
        d="M119.414 201.16C119.414 201.862 119.013 202.363 118.312 202.363C117.711 202.363 117.21 201.862 117.21 201.261C117.21 200.66 117.611 200.159 118.212 200.159C118.913 200.058 119.414 200.459 119.414 201.16Z"
        :fill="myColors.main"
      />
      <path
        d="M92.2654 137.546C92.9666 137.546 93.3674 138.047 93.3674 138.648C93.3674 139.249 92.9666 139.65 92.2654 139.65C91.5641 139.65 91.0632 139.149 91.0632 138.548C91.1634 138.047 91.6643 137.546 92.2654 137.546Z"
        :fill="myColors.main"
      />
      <path
        d="M47.4851 160.588C47.4851 159.986 47.986 159.486 48.5871 159.486C49.1882 159.486 49.5889 160.087 49.5889 160.688C49.5889 161.289 49.1882 161.69 48.4869 161.69C47.8858 161.79 47.4851 161.289 47.4851 160.588Z"
        :fill="myColors.main"
      />
      <path
        d="M146.763 201.261C146.763 201.962 146.262 202.363 145.661 202.363C145.06 202.363 144.559 201.761 144.659 201.16C144.659 200.559 145.06 200.159 145.761 200.159C146.362 200.159 146.763 200.559 146.763 201.261Z"
        :fill="myColors.main"
      />
      <path
        d="M49.6891 214.685C49.6891 215.386 49.2883 215.787 48.6873 215.887C48.0862 215.887 47.6855 215.486 47.5853 214.885C47.5853 214.184 47.986 213.683 48.5871 213.683C49.088 213.583 49.6891 214.084 49.6891 214.685Z"
        :fill="myColors.main"
      />
      <path
        d="M47.4851 124.523C47.4851 123.922 47.986 123.421 48.5871 123.421C49.1882 123.421 49.5889 124.022 49.5889 124.623C49.5889 125.224 49.1882 125.625 48.4869 125.625C47.8858 125.725 47.4851 125.224 47.4851 124.523Z"
        :fill="myColors.main"
      />
      <path
        d="M211.78 155.579C212.481 155.579 212.882 156.08 212.882 156.681C212.882 157.282 212.381 157.682 211.679 157.682C211.078 157.682 210.577 157.182 210.678 156.581C210.577 156.08 211.178 155.579 211.78 155.579Z"
        :fill="myColors.main"
      />
      <path
        d="M251.551 211.779C250.85 211.779 250.449 211.379 250.449 210.677C250.449 210.076 250.85 209.676 251.551 209.676C252.252 209.676 252.753 210.076 252.753 210.778C252.753 211.379 252.252 211.779 251.551 211.779Z"
        :fill="myColors.main"
      />
      <path
        d="M197.955 137.045C198.556 137.045 199.157 137.546 199.057 138.147C199.057 138.749 198.556 139.249 197.855 139.149C197.254 139.149 196.853 138.749 196.753 138.147C196.853 137.446 197.254 137.045 197.955 137.045Z"
        :fill="myColors.main"
      />
      <path
        d="M103.285 134.14C103.285 134.842 102.784 135.343 102.183 135.343C101.582 135.343 101.081 134.842 101.181 134.14C101.181 133.539 101.582 133.139 102.183 133.038C102.884 133.038 103.285 133.439 103.285 134.14Z"
        :fill="myColors.main"
      />
      <path
        d="M225.404 139.149C224.703 139.149 224.302 138.648 224.302 138.047C224.302 137.446 224.703 137.045 225.404 137.045C226.105 137.045 226.606 137.546 226.606 138.147C226.506 138.749 226.005 139.149 225.404 139.149Z"
        :fill="myColors.main"
      />
      <path
        d="M211.78 211.78C211.078 211.78 210.577 211.279 210.577 210.678C210.577 210.076 211.078 209.676 211.579 209.576C212.28 209.576 212.781 209.876 212.781 210.577C212.882 211.379 212.481 211.78 211.78 211.78Z"
        :fill="myColors.main"
      />
      <path
        d="M236.724 201.16C236.724 200.459 237.225 200.058 237.826 200.058C238.427 200.058 238.828 200.459 238.828 201.16C238.828 201.862 238.327 202.363 237.726 202.363C237.225 202.363 236.724 201.862 236.724 201.16Z"
        :fill="myColors.main"
      />
      <path
        d="M251.551 157.682C250.85 157.682 250.449 157.282 250.449 156.581C250.449 155.979 250.85 155.579 251.551 155.579C252.252 155.579 252.753 155.979 252.753 156.681C252.753 157.282 252.252 157.682 251.551 157.682Z"
        :fill="myColors.main"
      />
      <path
        d="M245.24 139.149C244.538 139.149 244.238 138.749 244.238 138.047C244.238 137.346 244.638 136.945 245.44 136.945C246.041 136.945 246.542 137.446 246.542 138.047C246.442 138.749 245.941 139.25 245.24 139.149Z"
        :fill="myColors.main"
      />
      <path
        d="M152.073 211.78C151.371 211.78 150.971 211.479 150.971 210.778C150.87 210.076 151.371 209.576 151.972 209.576C152.674 209.576 153.175 210.076 153.175 210.678C153.175 211.279 152.674 211.78 152.073 211.78Z"
        :fill="myColors.main"
      />
      <path
        d="M235.322 132.537C236.023 132.537 236.424 132.938 236.424 133.639C236.424 134.24 236.023 134.741 235.422 134.741C234.821 134.741 234.22 134.24 234.22 133.639C234.12 133.038 234.721 132.537 235.322 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M152.073 139.65C151.371 139.65 150.971 139.35 150.971 138.648C150.971 137.947 151.371 137.446 152.073 137.446C152.774 137.446 153.275 137.947 153.275 138.548C153.175 139.25 152.674 139.65 152.073 139.65Z"
        :fill="myColors.main"
      />
      <path
        d="M262.671 197.253C262.671 197.955 262.27 198.255 261.669 198.255C261.068 198.255 260.467 197.754 260.467 197.153C260.467 196.652 261.068 195.951 261.669 195.951C262.17 196.051 262.671 196.652 262.671 197.253Z"
        :fill="myColors.main"
      />
      <path
        d="M242.735 197.254C242.735 197.955 242.334 198.356 241.733 198.356C241.032 198.356 240.631 197.955 240.631 197.254C240.631 196.652 241.132 196.152 241.633 196.152C242.234 196.051 242.735 196.552 242.735 197.254Z"
        :fill="myColors.main"
      />
      <path
        d="M142.055 198.355C141.453 198.355 140.852 197.855 140.852 197.253C140.852 196.753 141.554 196.151 142.055 196.151C142.656 196.151 143.157 196.753 143.056 197.354C143.157 197.955 142.756 198.355 142.055 198.355Z"
        :fill="myColors.main"
      />
      <path
        d="M121.117 197.153C121.117 196.552 121.618 196.051 122.119 196.051C122.72 196.051 123.221 196.552 123.221 197.253C123.221 197.955 122.72 198.355 122.019 198.355C121.418 198.255 121.117 197.855 121.117 197.153Z"
        :fill="myColors.main"
      />
      <path
        d="M151.972 157.682C151.271 157.682 150.971 157.282 150.971 156.58C150.971 155.879 151.371 155.479 152.073 155.479C152.674 155.479 153.175 155.979 153.175 156.58C153.175 157.282 152.674 157.783 151.972 157.682Z"
        :fill="myColors.main"
      />
      <path
        d="M116.91 169.704C116.91 170.305 116.409 170.806 115.808 170.806C115.307 170.806 114.706 170.205 114.706 169.704C114.706 169.103 115.206 168.602 115.808 168.602C116.609 168.602 116.91 169.003 116.91 169.704Z"
        :fill="myColors.main"
      />
      <path
        d="M236.424 223.801C236.424 224.402 235.923 224.903 235.322 224.903C234.821 224.903 234.22 224.302 234.22 223.801C234.22 223.2 234.721 222.699 235.322 222.699C236.023 222.699 236.424 223.1 236.424 223.801Z"
        :fill="myColors.main"
      />
      <path
        d="M235.322 168.602C236.023 168.602 236.424 169.003 236.424 169.704C236.424 170.305 235.923 170.806 235.422 170.806C234.821 170.806 234.22 170.305 234.22 169.704C234.12 169.103 234.721 168.602 235.322 168.602Z"
        :fill="myColors.main"
      />
      <path
        d="M123.221 143.156C123.221 143.858 122.82 144.258 122.219 144.258C121.518 144.258 121.117 143.858 121.117 143.156C121.117 142.555 121.518 142.055 122.119 142.055C122.72 141.954 123.221 142.455 123.221 143.156Z"
        :fill="myColors.main"
      />
      <path
        d="M125.825 137.045C126.527 137.045 127.028 137.446 127.028 138.047C127.028 138.648 126.527 139.149 125.825 139.149C125.124 139.149 124.824 138.749 124.724 138.147C124.724 137.446 125.024 137.045 125.825 137.045Z"
        :fill="myColors.main"
      />
      <path
        d="M245.34 211.279C244.638 211.279 244.138 210.878 244.138 210.177C244.138 209.475 244.538 209.175 245.139 209.075C245.841 209.075 246.342 209.576 246.342 210.177C246.442 210.878 246.041 211.279 245.34 211.279Z"
        :fill="myColors.main"
      />
      <path
        d="M152.173 191.643C152.774 191.744 153.175 192.244 153.175 192.745C153.175 193.346 152.573 193.747 151.872 193.747C151.271 193.647 150.87 193.246 150.87 192.645C150.971 191.944 151.472 191.543 152.173 191.643Z"
        :fill="myColors.main"
      />
      <path
        d="M143.157 143.257C143.157 143.858 142.756 144.258 142.055 144.258C141.454 144.258 140.852 143.657 140.953 143.156C140.953 142.656 141.654 142.055 142.155 142.055C142.756 142.055 143.157 142.555 143.157 143.257Z"
        :fill="myColors.main"
      />
      <path
        d="M245.34 193.246C244.638 193.246 244.238 192.846 244.238 192.144C244.238 191.443 244.638 191.143 245.24 191.143C245.941 191.143 246.442 191.643 246.442 192.245C246.442 192.846 245.941 193.246 245.34 193.246Z"
        :fill="myColors.main"
      />
      <path
        d="M245.34 155.078C245.941 155.078 246.442 155.579 246.442 156.08C246.442 156.681 245.941 157.182 245.24 157.182C244.538 157.182 244.138 156.781 244.138 156.08C244.238 155.378 244.638 155.078 245.34 155.078Z"
        :fill="myColors.main"
      />
      <path
        d="M115.808 132.537C116.509 132.537 116.91 132.938 116.91 133.639C116.91 134.24 116.509 134.741 115.908 134.741C115.307 134.741 114.706 134.24 114.706 133.74C114.605 133.038 115.206 132.537 115.808 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M125.825 193.246C125.124 193.246 124.724 192.846 124.724 192.144C124.724 191.443 125.024 191.143 125.725 191.143C126.427 191.143 126.927 191.543 126.927 192.144C127.028 192.745 126.527 193.246 125.825 193.246Z"
        :fill="myColors.main"
      />
      <path
        d="M115.808 204.667C116.509 204.667 116.91 205.067 116.91 205.769C116.91 206.37 116.409 206.871 115.908 206.871C115.307 206.871 114.706 206.27 114.706 205.769C114.706 205.168 115.206 204.667 115.808 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M121.117 215.286C121.117 214.585 121.618 214.084 122.219 214.084C122.82 214.084 123.221 214.585 123.321 215.186C123.321 215.887 122.92 216.388 122.219 216.388C121.518 216.388 121.117 215.987 121.117 215.286Z"
        :fill="myColors.main"
      />
      <path
        d="M141.954 216.388C141.353 216.388 140.852 215.787 140.852 215.186C140.852 214.685 141.554 214.184 141.954 214.084C142.555 214.084 143.056 214.685 143.056 215.386C143.056 216.087 142.656 216.388 141.954 216.388Z"
        :fill="myColors.main"
      />
      <path
        d="M242.735 143.056C242.735 143.758 242.334 144.158 241.733 144.158C241.032 144.158 240.631 143.758 240.631 143.056C240.631 142.455 241.032 141.954 241.633 141.854C242.134 141.954 242.735 142.455 242.735 143.056Z"
        :fill="myColors.main"
      />
      <path
        d="M262.671 143.157C262.671 143.858 262.27 144.158 261.669 144.158C261.068 144.158 260.467 143.657 260.467 143.056C260.467 142.555 261.068 141.854 261.669 141.854C262.17 141.954 262.671 142.555 262.671 143.157Z"
        :fill="myColors.main"
      />
      <path
        d="M235.322 186.634C236.023 186.634 236.424 187.035 236.424 187.736C236.424 188.337 235.923 188.838 235.422 188.838C234.821 188.838 234.22 188.337 234.22 187.736C234.12 187.135 234.721 186.634 235.322 186.634Z"
        :fill="myColors.main"
      />
      <path
        d="M242.735 215.286C242.735 215.987 242.334 216.388 241.733 216.388C241.032 216.388 240.631 215.987 240.631 215.286C240.631 214.685 241.132 214.184 241.633 214.184C242.234 214.084 242.735 214.584 242.735 215.286Z"
        :fill="myColors.main"
      />
      <path
        d="M125.825 209.175C126.427 209.175 127.028 209.676 126.927 210.277C126.827 210.878 126.326 211.279 125.725 211.279C125.124 211.279 124.724 210.878 124.724 210.177C124.724 209.576 125.124 209.175 125.825 209.175Z"
        :fill="myColors.main"
      />
      <path
        d="M262.671 215.286C262.671 215.987 262.27 216.287 261.669 216.287C261.068 216.287 260.467 215.787 260.467 215.185C260.467 214.685 261.068 213.983 261.669 213.983C262.17 214.083 262.671 214.685 262.671 215.286Z"
        :fill="myColors.main"
      />
      <path
        d="M115.808 222.699C116.509 222.699 116.91 223 116.91 223.801C116.91 224.402 116.509 224.903 115.908 224.903C115.307 225.003 114.706 224.402 114.706 223.901C114.605 223.2 115.206 222.699 115.808 222.699Z"
        :fill="myColors.main"
      />
      <path
        d="M116.91 187.736C116.91 188.337 116.308 188.838 115.707 188.738C115.206 188.638 114.605 188.137 114.605 187.636C114.605 187.035 115.106 186.534 115.808 186.534C116.609 186.634 117.01 187.035 116.91 187.736Z"
        :fill="myColors.main"
      />
      <path
        d="M124.724 174.012C124.824 173.411 125.325 173.01 126.026 173.11C126.627 173.21 127.028 173.711 126.927 174.312C126.827 174.913 126.326 175.314 125.625 175.214C125.024 175.214 124.623 174.713 124.724 174.012Z"
        :fill="myColors.main"
      />
      <path
        d="M235.322 204.667C236.023 204.667 236.424 205.067 236.424 205.769C236.424 206.37 235.923 206.871 235.422 206.871C234.821 206.871 234.22 206.27 234.22 205.769C234.22 205.168 234.721 204.667 235.322 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M262.671 161.289C262.671 161.89 262.27 162.291 261.569 162.291C260.968 162.291 260.367 161.69 260.467 161.189C260.467 160.688 261.168 160.087 261.669 160.087C262.17 160.087 262.671 160.588 262.671 161.289Z"
        :fill="myColors.main"
      />
      <path
        d="M245.34 173.11C245.941 173.11 246.442 173.611 246.442 174.112C246.442 174.713 245.941 175.214 245.24 175.214C244.538 175.214 244.138 174.713 244.238 174.012C244.238 173.411 244.638 173.11 245.34 173.11Z"
        :fill="myColors.main"
      />
      <path
        d="M152.073 175.715C151.371 175.715 150.971 175.414 150.971 174.713C150.971 174.012 151.371 173.511 152.073 173.511C152.774 173.511 153.275 174.012 153.275 174.613C153.175 175.314 152.674 175.715 152.073 175.715Z"
        :fill="myColors.main"
      />
      <path
        d="M121.117 125.024C121.117 124.423 121.618 123.922 122.119 123.922C122.72 123.922 123.221 124.423 123.221 125.124C123.221 125.825 122.72 126.226 122.019 126.126C121.418 126.126 121.017 125.725 121.117 125.024Z"
        :fill="myColors.main"
      />
      <path
        d="M116.91 151.571C116.91 152.173 116.409 152.774 115.808 152.774C115.206 152.774 114.605 152.173 114.605 151.672C114.605 151.071 115.106 150.67 115.707 150.57C116.509 150.57 116.91 150.87 116.91 151.571Z"
        :fill="myColors.main"
      />
      <path
        d="M142.055 126.226C141.454 126.226 140.852 125.625 140.953 125.024C140.953 124.523 141.654 123.922 142.255 123.922C142.856 124.022 143.257 124.523 143.257 125.224C143.056 125.825 142.656 126.226 142.055 126.226Z"
        :fill="myColors.main"
      />
      <path
        d="M236.424 151.672C236.424 152.273 235.823 152.774 235.222 152.674C234.721 152.573 234.12 152.072 234.22 151.572C234.22 150.971 234.721 150.47 235.422 150.47C236.123 150.57 236.524 150.97 236.424 151.672Z"
        :fill="myColors.main"
      />
      <path
        d="M123.221 161.089C123.221 161.79 122.82 162.191 122.219 162.191C121.518 162.191 121.117 161.79 121.117 161.089C121.117 160.488 121.518 159.987 122.119 159.887C122.72 159.987 123.221 160.488 123.221 161.089Z"
        :fill="myColors.main"
      />
      <path
        d="M261.569 180.323C260.968 180.323 260.367 179.722 260.467 179.221C260.467 178.72 261.168 178.119 261.669 178.119C262.27 178.219 262.671 178.72 262.671 179.421C262.571 179.922 262.17 180.323 261.569 180.323Z"
        :fill="myColors.main"
      />
      <path
        d="M242.735 179.121C242.735 179.822 242.334 180.223 241.633 180.223C240.932 180.223 240.631 179.822 240.631 179.121C240.631 178.52 241.032 178.019 241.633 177.919C242.134 178.019 242.735 178.52 242.735 179.121Z"
        :fill="myColors.main"
      />
      <path
        d="M143.157 161.289C143.157 161.89 142.756 162.291 142.055 162.291C141.454 162.291 140.852 161.69 140.953 161.189C140.953 160.688 141.654 160.087 142.155 160.087C142.756 160.087 143.157 160.588 143.157 161.289Z"
        :fill="myColors.main"
      />
      <path
        d="M125.825 155.078C126.427 155.078 127.028 155.579 126.927 156.18C126.927 156.781 126.326 157.282 125.725 157.182C125.124 157.182 124.724 156.781 124.724 156.08C124.724 155.478 125.124 155.078 125.825 155.078Z"
        :fill="myColors.main"
      />
      <path
        d="M143.157 179.321C143.157 179.922 142.756 180.323 142.055 180.323C141.454 180.323 140.852 179.722 140.953 179.221C140.953 178.72 141.654 178.119 142.155 178.119C142.756 178.119 143.157 178.62 143.157 179.321Z"
        :fill="myColors.main"
      />
      <path
        d="M123.221 179.121C123.221 179.822 122.82 180.223 122.219 180.223C121.518 180.223 121.117 179.822 121.117 179.121C121.117 178.52 121.518 178.019 122.119 177.919C122.72 178.019 123.221 178.52 123.221 179.121Z"
        :fill="myColors.main"
      />
      <path
        d="M261.569 126.226C260.968 126.226 260.367 125.625 260.467 125.124C260.467 124.623 261.168 124.022 261.669 124.022C262.27 124.122 262.671 124.623 262.671 125.325C262.571 125.825 262.17 126.226 261.569 126.226Z"
        :fill="myColors.main"
      />
      <path
        d="M242.735 125.124C242.735 125.825 242.334 126.226 241.733 126.226C241.032 126.226 240.631 125.825 240.631 125.124C240.631 124.523 241.032 124.022 241.633 124.022C242.234 123.922 242.735 124.423 242.735 125.124Z"
        :fill="myColors.main"
      />
      <path
        d="M242.735 161.189C242.735 161.79 242.334 162.191 241.633 162.191C240.932 162.191 240.531 161.79 240.531 160.989C240.531 160.387 241.032 159.887 241.633 159.887C242.234 159.987 242.735 160.588 242.735 161.189Z"
        :fill="myColors.main"
      />
      <path
        d="M103.285 143.056C103.285 143.758 102.985 144.158 102.283 144.158C101.582 144.158 101.181 143.758 101.181 143.056C101.181 142.355 101.682 141.854 102.283 141.854C102.784 141.954 103.285 142.455 103.285 143.056Z"
        :fill="myColors.main"
      />
      <path
        d="M72.2295 175.715C71.6284 175.615 71.2277 175.214 71.3279 174.513C71.428 173.811 71.8288 173.511 72.53 173.511C73.1311 173.611 73.5318 174.012 73.4316 174.713C73.3315 175.514 72.9307 175.815 72.2295 175.715Z"
        :fill="myColors.main"
      />
      <path
        d="M214.384 205.769C214.384 205.067 214.685 204.667 215.386 204.667C216.087 204.567 216.588 204.967 216.588 205.669C216.588 206.37 216.187 206.871 215.486 206.871C214.885 206.871 214.384 206.37 214.384 205.769Z"
        :fill="myColors.main"
      />
      <path
        d="M101.181 125.124C101.181 124.423 101.682 123.922 102.283 124.022C102.884 124.022 103.285 124.523 103.285 125.124C103.285 125.825 102.884 126.226 102.183 126.226C101.482 126.126 101.181 125.725 101.181 125.124Z"
        :fill="myColors.main"
      />
      <path
        d="M94.87 223.701C94.87 223 95.2707 222.699 95.972 222.699C96.6732 222.699 97.0739 223.1 97.0739 223.801C97.0739 224.402 96.573 224.903 95.972 224.903C95.3709 224.903 94.87 224.402 94.87 223.701Z"
        :fill="myColors.main"
      />
      <path
        d="M222.799 125.024C222.799 125.725 222.499 126.126 221.798 126.126C221.096 126.126 220.696 125.826 220.696 125.024C220.696 124.323 221.196 123.822 221.798 123.822C222.298 123.922 222.799 124.423 222.799 125.024Z"
        :fill="myColors.main"
      />
      <path
        d="M103.285 179.121C103.285 179.822 102.985 180.223 102.283 180.223C101.582 180.223 101.181 179.922 101.081 179.121C101.081 178.42 101.582 177.919 102.183 177.919C102.784 178.019 103.285 178.52 103.285 179.121Z"
        :fill="myColors.main"
      />
      <path
        d="M222.799 179.121C222.799 179.822 222.499 180.223 221.898 180.223C221.196 180.323 220.796 179.922 220.696 179.221C220.696 178.52 221.196 178.019 221.798 178.019C222.298 178.019 222.799 178.52 222.799 179.121Z"
        :fill="myColors.main"
      />
      <path
        d="M216.488 151.672C216.488 152.373 215.987 152.874 215.386 152.874C214.785 152.874 214.384 152.373 214.384 151.772C214.384 151.071 214.785 150.67 215.486 150.67C216.087 150.57 216.488 150.971 216.488 151.672Z"
        :fill="myColors.main"
      />
      <path
        d="M94.87 151.672C94.87 150.97 95.1705 150.57 95.8718 150.57C96.573 150.57 97.0739 150.87 97.0739 151.571C97.0739 152.273 96.573 152.774 95.972 152.774C95.3709 152.774 94.87 152.273 94.87 151.672Z"
        :fill="myColors.main"
      />
      <path
        d="M220.696 161.189C220.696 160.487 221.196 159.987 221.798 159.987C222.399 159.987 222.799 160.487 222.799 161.089C222.799 161.79 222.399 162.191 221.697 162.191C220.996 162.191 220.696 161.89 220.696 161.189Z"
        :fill="myColors.main"
      />
      <path
        d="M95.8718 186.634C96.573 186.634 96.9738 187.035 96.9738 187.736C96.9738 188.337 96.4729 188.838 95.972 188.838C95.3709 188.838 94.87 188.337 94.87 187.636C94.87 187.035 95.2707 186.634 95.8718 186.634Z"
        :fill="myColors.main"
      />
      <path
        d="M222.799 215.286C222.799 215.987 222.399 216.288 221.798 216.288C221.096 216.288 220.696 215.887 220.696 215.186C220.696 214.585 221.196 214.084 221.697 214.084C222.298 214.084 222.799 214.685 222.799 215.286Z"
        :fill="myColors.main"
      />
      <path
        d="M96.9738 205.769C96.9738 206.37 96.573 206.871 95.972 206.871C95.3709 206.871 94.87 206.37 94.87 205.769C94.87 205.067 95.2707 204.667 95.972 204.667C96.6732 204.667 96.9738 205.067 96.9738 205.769Z"
        :fill="myColors.main"
      />
      <path
        d="M216.488 187.736C216.488 188.337 216.087 188.838 215.486 188.838C214.885 188.838 214.384 188.337 214.384 187.736C214.384 187.035 214.785 186.735 215.386 186.634C216.087 186.634 216.488 187.035 216.488 187.736Z"
        :fill="myColors.main"
      />
      <path
        d="M222.799 143.056C222.799 143.757 222.499 144.158 221.898 144.158C221.196 144.258 220.796 143.858 220.696 143.156C220.696 142.455 221.196 141.954 221.798 141.954C222.298 141.954 222.799 142.455 222.799 143.056Z"
        :fill="myColors.main"
      />
      <path
        d="M103.285 215.186C103.285 215.887 102.985 216.288 102.383 216.288C101.682 216.388 101.181 215.987 101.181 215.286C101.181 214.585 101.682 214.084 102.283 214.084C102.784 214.084 103.285 214.585 103.285 215.186Z"
        :fill="myColors.main"
      />
      <path
        d="M96.9738 133.639C96.9738 134.341 96.4729 134.842 95.8718 134.741C95.2707 134.741 94.87 134.24 94.87 133.639C94.87 132.938 95.1705 132.537 95.8718 132.537C96.573 132.537 96.9738 132.938 96.9738 133.639Z"
        :fill="myColors.main"
      />
      <path
        d="M216.488 223.801C216.488 224.402 215.987 224.903 215.486 224.903C214.885 224.903 214.384 224.402 214.384 223.701C214.384 223 214.785 222.699 215.386 222.699C216.087 222.699 216.488 223.1 216.488 223.801Z"
        :fill="myColors.main"
      />
      <path
        d="M214.384 169.604C214.384 168.903 214.785 168.602 215.486 168.602C216.187 168.602 216.588 169.003 216.588 169.704C216.588 170.305 216.087 170.806 215.486 170.806C214.785 170.806 214.384 170.305 214.384 169.604Z"
        :fill="myColors.main"
      />
      <path
        d="M103.285 161.089C103.285 161.79 102.985 162.191 102.383 162.191C101.682 162.291 101.181 161.89 101.181 161.189C101.181 160.487 101.682 159.987 102.283 159.987C102.784 159.987 103.285 160.487 103.285 161.089Z"
        :fill="myColors.main"
      />
      <path
        d="M72.3296 191.643C73.0309 191.643 73.4316 192.044 73.4316 192.745C73.4316 193.447 73.0309 193.747 72.4298 193.747C71.7286 193.747 71.2277 193.346 71.3278 192.645C71.2277 192.044 71.6284 191.643 72.3296 191.643Z"
        :fill="myColors.main"
      />
      <path
        d="M72.3296 209.676C73.0309 209.676 73.4316 210.076 73.4316 210.778C73.4316 211.479 73.0309 211.779 72.3296 211.779C71.6284 211.779 71.2276 211.379 71.2276 210.677C71.2276 210.076 71.7285 209.676 72.3296 209.676Z"
        :fill="myColors.main"
      />
      <path
        d="M216.488 133.639C216.488 134.341 215.987 134.842 215.386 134.741C214.785 134.741 214.384 134.24 214.384 133.639C214.384 132.938 214.785 132.537 215.386 132.537C216.087 132.537 216.488 132.938 216.488 133.639Z"
        :fill="myColors.main"
      />
      <path
        d="M103.285 197.254C103.285 197.955 102.985 198.356 102.283 198.356C101.582 198.356 101.181 197.955 101.181 197.254C101.181 196.552 101.682 196.051 102.283 196.152C102.884 196.152 103.285 196.552 103.285 197.254Z"
        :fill="myColors.main"
      />
      <path
        d="M220.696 197.254C220.696 196.552 221.196 196.051 221.798 196.152C222.399 196.152 222.799 196.652 222.799 197.254C222.799 197.955 222.399 198.356 221.697 198.356C220.996 198.255 220.696 197.855 220.696 197.254Z"
        :fill="myColors.main"
      />
      <path
        d="M73.4316 138.548C73.4316 139.249 73.0309 139.65 72.3296 139.65C71.7285 139.65 71.2276 139.249 71.2276 138.648C71.2276 138.047 71.6284 137.546 72.2294 137.546C72.9307 137.546 73.4316 137.847 73.4316 138.548Z"
        :fill="myColors.main"
      />
      <path
        d="M73.4316 156.681C73.4316 157.382 73.0309 157.783 72.3296 157.783C71.7285 157.783 71.3278 157.382 71.2276 156.681C71.2276 156.08 71.6284 155.679 72.2294 155.579C73.0309 155.579 73.4316 155.979 73.4316 156.681Z"
        :fill="myColors.main"
      />
      <path
        d="M96.9739 169.604C96.9739 170.305 96.4729 170.806 95.8719 170.806C95.2708 170.806 94.8701 170.305 94.7699 169.704C94.7699 169.003 95.0704 168.602 95.6715 168.602C96.5731 168.502 96.9739 168.903 96.9739 169.604Z"
        :fill="myColors.main"
      />
      <path
        d="M217.991 137.045C218.592 137.045 219.093 137.546 219.093 138.047C219.093 138.648 218.592 139.149 217.891 139.149C217.189 139.149 216.789 138.648 216.889 137.947C216.889 137.346 217.289 137.045 217.991 137.045Z"
        :fill="myColors.main"
      />
      <path
        d="M238.828 138.148C238.828 138.849 238.327 139.25 237.726 139.25C237.125 139.25 236.724 138.849 236.724 138.148C236.724 137.446 237.125 137.046 237.826 137.146C238.528 137.046 238.928 137.446 238.828 138.148Z"
        :fill="myColors.main"
      />
      <path
        d="M257.662 137.045C258.363 137.045 258.764 137.346 258.764 138.047C258.864 138.749 258.463 139.249 257.762 139.249C257.061 139.249 256.56 138.749 256.56 138.147C256.56 137.546 257.061 137.045 257.662 137.045Z"
        :fill="myColors.main"
      />
      <path
        d="M191.944 211.78C191.243 211.78 190.942 211.479 190.842 210.778C190.742 210.076 191.143 209.576 191.844 209.576C192.545 209.576 192.946 209.876 192.946 210.577C192.946 211.379 192.645 211.78 191.944 211.78Z"
        :fill="myColors.main"
      />
      <path
        d="M48.5871 222.699C49.2884 222.699 49.5889 223 49.6891 223.701C49.6891 224.402 49.2884 224.903 48.5871 224.903C47.986 224.903 47.4851 224.402 47.4851 223.701C47.5853 223.1 47.986 222.699 48.5871 222.699Z"
        :fill="myColors.main"
      />
      <path
        d="M67.4209 169.704C67.4209 169.003 67.7214 168.602 68.4227 168.602C69.1239 168.602 69.6248 169.003 69.6248 169.604C69.6248 170.305 69.1239 170.806 68.5229 170.806C67.9218 170.806 67.5211 170.305 67.4209 169.704Z"
        :fill="myColors.main"
      />
      <path
        d="M169.203 169.704C169.203 170.405 168.702 170.906 168.101 170.806C167.5 170.806 167.1 170.305 167.1 169.704C167.1 169.003 167.5 168.602 168.201 168.602C168.803 168.602 169.203 169.003 169.203 169.704Z"
        :fill="myColors.main"
      />
      <path
        d="M192.946 138.548C192.946 139.249 192.545 139.65 191.844 139.65C191.143 139.65 190.842 139.35 190.842 138.648C190.842 137.947 191.143 137.546 191.744 137.546C192.445 137.546 192.846 137.847 192.946 138.548Z"
        :fill="myColors.main"
      />
      <path
        d="M48.5871 168.602C49.2883 168.602 49.5889 168.903 49.6891 169.604C49.6891 170.305 49.2883 170.806 48.6873 170.806C48.0862 170.806 47.5853 170.305 47.5853 169.604C47.5853 169.003 47.986 168.602 48.5871 168.602Z"
        :fill="myColors.main"
      />
      <path
        d="M217.891 175.214C217.189 175.214 216.889 174.813 216.889 174.212C216.889 173.511 217.289 173.11 217.991 173.11C218.592 173.11 219.093 173.611 219.093 174.112C219.093 174.813 218.492 175.214 217.891 175.214Z"
        :fill="myColors.main"
      />
      <path
        d="M138.148 137.045C138.849 137.045 139.25 137.346 139.25 138.047C139.35 138.749 138.949 139.249 138.248 139.249C137.547 139.249 137.046 138.749 137.046 138.147C137.046 137.546 137.547 137.045 138.148 137.045Z"
        :fill="myColors.main"
      />
      <path
        d="M186.935 169.704C186.935 169.003 187.336 168.702 187.937 168.602C188.638 168.602 189.039 169.003 189.039 169.704C189.039 170.305 188.538 170.806 188.037 170.806C187.436 170.806 186.935 170.305 186.935 169.704Z"
        :fill="myColors.main"
      />
      <path
        d="M192.946 192.645C192.946 193.346 192.545 193.747 191.844 193.747C191.143 193.747 190.842 193.447 190.842 192.745C190.842 192.044 191.143 191.643 191.744 191.643C192.445 191.643 192.846 191.944 192.946 192.645Z"
        :fill="myColors.main"
      />
      <path
        d="M236.724 174.112C236.724 173.411 237.125 173.11 237.826 173.11C238.528 173.11 238.928 173.511 238.928 174.212C238.928 174.813 238.528 175.214 237.826 175.214C237.125 175.214 236.724 174.813 236.724 174.112Z"
        :fill="myColors.main"
      />
      <path
        d="M257.762 173.11C258.463 173.11 258.764 173.511 258.764 174.112C258.764 174.813 258.363 175.214 257.662 175.214C257.061 175.214 256.56 174.713 256.56 174.212C256.56 173.611 257.061 173.11 257.762 173.11Z"
        :fill="myColors.main"
      />
      <path
        d="M188.037 132.537C188.638 132.537 189.039 132.938 189.139 133.539C189.139 134.24 188.638 134.741 188.037 134.741C187.436 134.741 186.935 134.24 187.035 133.539C187.035 132.838 187.336 132.537 188.037 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M192.946 156.681C192.946 157.382 192.545 157.783 191.844 157.783C191.143 157.783 190.842 157.382 190.842 156.781C190.842 156.08 191.143 155.679 191.844 155.679C192.445 155.579 192.946 155.979 192.946 156.681Z"
        :fill="myColors.main"
      />
      <path
        d="M257.662 211.279C257.061 211.279 256.56 210.778 256.56 210.277C256.56 209.676 257.061 209.175 257.762 209.175C258.463 209.175 258.864 209.576 258.864 210.277C258.764 210.978 258.363 211.279 257.662 211.279Z"
        :fill="myColors.main"
      />
      <path
        d="M48.7875 204.667C49.4888 204.767 49.7893 205.268 49.6891 205.969C49.5889 206.57 49.088 206.971 48.487 206.871C47.8859 206.771 47.4852 206.169 47.5853 205.568C47.6855 204.867 48.0862 204.567 48.7875 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M257.762 191.143C258.463 191.143 258.764 191.543 258.864 192.144C258.864 192.846 258.463 193.246 257.762 193.246C257.161 193.246 256.66 192.745 256.66 192.245C256.56 191.643 257.061 191.143 257.762 191.143Z"
        :fill="myColors.main"
      />
      <path
        d="M238.828 192.144C238.828 192.845 238.427 193.246 237.726 193.246C237.125 193.246 236.724 192.845 236.724 192.144C236.724 191.443 237.125 191.042 237.826 191.042C238.528 191.142 238.828 191.543 238.828 192.144Z"
        :fill="myColors.main"
      />
      <path
        d="M217.891 191.143C218.492 191.143 218.993 191.643 218.993 192.144C218.993 192.745 218.492 193.246 217.79 193.246C217.089 193.246 216.688 192.745 216.789 192.044C216.889 191.443 217.289 191.143 217.891 191.143Z"
        :fill="myColors.main"
      />
      <path
        d="M168.101 132.537C168.803 132.537 169.203 132.938 169.103 133.74C169.103 134.341 168.602 134.842 168.001 134.741C167.4 134.741 166.899 134.14 166.999 133.539C167.1 132.838 167.5 132.537 168.101 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M48.5871 132.537C49.2883 132.537 49.5889 132.838 49.6891 133.539C49.6891 134.24 49.2883 134.741 48.6873 134.741C48.0862 134.741 47.5853 134.24 47.5853 133.539C47.5853 132.938 47.986 132.537 48.5871 132.537Z"
        :fill="myColors.main"
      />
      <path
        d="M67.4209 223.801C67.4209 223.1 67.8216 222.799 68.4227 222.699C69.1239 222.699 69.5247 223.1 69.5247 223.801C69.5247 224.402 69.1239 224.903 68.5229 224.903C67.9218 224.903 67.4209 224.402 67.4209 223.801Z"
        :fill="myColors.main"
      />
      <path
        d="M139.25 192.245C139.25 192.946 138.849 193.246 138.248 193.347C137.547 193.347 137.046 192.846 137.046 192.245C137.046 191.643 137.647 191.143 138.248 191.243C138.949 191.143 139.35 191.543 139.25 192.245Z"
        :fill="myColors.main"
      />
      <path
        d="M237.826 209.175C238.527 209.175 238.828 209.576 238.828 210.177C238.828 210.878 238.427 211.279 237.726 211.279C237.025 211.279 236.624 210.778 236.724 210.076C236.824 209.475 237.125 209.175 237.826 209.175Z"
        :fill="myColors.main"
      />
      <path
        d="M118.412 191.143C119.113 191.143 119.414 191.543 119.414 192.144C119.414 192.846 119.013 193.246 118.312 193.246C117.611 193.246 117.21 192.745 117.31 192.044C117.31 191.443 117.711 191.143 118.412 191.143Z"
        :fill="myColors.main"
      />
      <path
        d="M98.3764 191.143C99.0776 191.143 99.5785 191.643 99.5785 192.245C99.5785 192.846 99.0776 193.246 98.4766 193.246C97.7753 193.246 97.3746 192.846 97.3746 192.144C97.3746 191.543 97.6751 191.143 98.3764 191.143Z"
        :fill="myColors.main"
      />
      <path
        d="M67.4209 205.769C67.4209 205.067 67.7214 204.667 68.4227 204.667C69.1239 204.667 69.6248 205.067 69.6248 205.669C69.6248 206.37 69.1239 206.871 68.5229 206.871C67.9218 206.871 67.5211 206.37 67.4209 205.769Z"
        :fill="myColors.main"
      />
      <path
        d="M217.991 209.175C218.592 209.175 219.093 209.676 219.093 210.177C219.093 210.778 218.592 211.279 217.891 211.279C217.189 211.279 216.789 210.778 216.889 210.076C216.889 209.475 217.289 209.175 217.991 209.175Z"
        :fill="myColors.main"
      />
      <path
        d="M186.935 223.801C186.935 223.1 187.336 222.799 187.937 222.699C188.638 222.699 189.039 223.1 189.039 223.801C189.039 224.402 188.538 224.903 188.037 224.903C187.436 224.903 186.935 224.402 186.935 223.801Z"
        :fill="myColors.main"
      />
      <path
        d="M98.5767 209.175C99.1778 209.275 99.5785 209.776 99.5785 210.277C99.4784 210.878 98.9775 211.279 98.2762 211.279C97.5749 211.178 97.2744 210.678 97.3746 209.976C97.4748 209.475 97.8755 209.075 98.5767 209.175Z"
        :fill="myColors.main"
      />
      <path
        d="M67.4209 133.639C67.4209 132.938 67.7214 132.537 68.4227 132.537C69.1239 132.537 69.6248 132.938 69.6248 133.539C69.6248 134.24 69.1239 134.741 68.5229 134.741C67.9218 134.741 67.5211 134.24 67.4209 133.639Z"
        :fill="myColors.main"
      />
      <path
        d="M192.946 174.613C192.946 175.314 192.545 175.715 191.844 175.715C191.143 175.715 190.842 175.414 190.842 174.713C190.842 174.012 191.143 173.611 191.744 173.611C192.445 173.611 192.846 173.912 192.946 174.613Z"
        :fill="myColors.main"
      />
      <path
        d="M118.312 137.045C119.013 137.045 119.314 137.346 119.414 138.047C119.414 138.749 119.013 139.149 118.312 139.149C117.611 139.149 117.21 138.749 117.21 138.047C117.31 137.346 117.611 137.045 118.312 137.045Z"
        :fill="myColors.main"
      />
      <path
        d="M139.25 174.312C139.25 174.913 138.749 175.314 138.148 175.314C137.446 175.314 136.945 174.813 137.046 174.112C137.146 173.511 137.647 173.11 138.348 173.11C139.049 173.21 139.35 173.611 139.25 174.312Z"
        :fill="myColors.main"
      />
      <path
        d="M186.935 187.736C186.935 187.035 187.236 186.634 187.837 186.634C188.538 186.534 189.039 186.935 189.039 187.636C189.039 188.337 188.538 188.838 187.937 188.838C187.436 188.838 186.935 188.337 186.935 187.736Z"
        :fill="myColors.main"
      />
      <path
        d="M169.203 187.736C169.203 188.337 168.702 188.838 168.201 188.838C167.6 188.838 167.1 188.337 167.1 187.636C167.1 186.935 167.6 186.534 168.302 186.634C168.803 186.634 169.203 187.035 169.203 187.736Z"
        :fill="myColors.main"
      />
      <path
        d="M257.762 155.078C258.463 155.078 258.764 155.478 258.764 156.18C258.764 156.881 258.363 157.282 257.662 157.282C257.061 157.282 256.56 156.781 256.56 156.18C256.56 155.579 257.061 155.078 257.762 155.078Z"
        :fill="myColors.main"
      />
      <path
        d="M169.203 223.701C169.203 224.402 168.702 224.903 168.101 224.903C167.5 224.903 167.1 224.402 166.999 223.801C166.999 223.1 167.4 222.699 168.101 222.699C168.803 222.699 169.103 223.1 169.203 223.701Z"
        :fill="myColors.main"
      />
      <path
        d="M98.4766 137.045C99.0776 137.045 99.5785 137.446 99.5785 138.047C99.5785 138.648 99.0776 139.149 98.3764 139.149C97.6751 139.149 97.2744 138.749 97.2744 138.047C97.3746 137.346 97.7753 137.045 98.4766 137.045Z"
        :fill="myColors.main"
      />
      <path
        d="M138.148 157.182C137.446 157.182 136.945 156.681 137.046 156.08C137.046 155.478 137.547 155.078 138.148 155.078C138.849 155.078 139.25 155.478 139.25 156.18C139.25 156.881 138.849 157.182 138.148 157.182Z"
        :fill="myColors.main"
      />
      <path
        d="M67.4209 187.736C67.4209 187.035 67.7214 186.634 68.3225 186.634C69.0237 186.534 69.5247 186.935 69.5247 187.636C69.5247 188.337 69.0238 188.838 68.4227 188.838C67.9218 188.838 67.5211 188.438 67.4209 187.736Z"
        :fill="myColors.main"
      />
      <path
        d="M49.6891 187.736C49.6891 188.337 49.1882 188.838 48.6873 188.838C48.0862 188.838 47.5853 188.337 47.5853 187.636C47.5853 187.035 47.986 186.634 48.6873 186.634C49.2883 186.634 49.6891 187.035 49.6891 187.736Z"
        :fill="myColors.main"
      />
      <path
        d="M49.6891 151.672C49.6891 152.373 49.1882 152.874 48.5871 152.774C47.986 152.774 47.5853 152.273 47.5853 151.672C47.5853 150.97 47.986 150.57 48.6873 150.57C49.2883 150.57 49.6891 150.97 49.6891 151.672Z"
        :fill="myColors.main"
      />
      <path
        d="M67.4209 151.672C67.4209 150.971 67.7214 150.57 68.3225 150.57C69.0237 150.47 69.5247 150.87 69.5247 151.572C69.5247 152.273 69.0238 152.774 68.4227 152.774C67.9218 152.774 67.5211 152.373 67.4209 151.672Z"
        :fill="myColors.main"
      />
      <path
        d="M238.828 156.18C238.828 156.881 238.327 157.282 237.626 157.182C237.025 157.182 236.624 156.781 236.624 156.08C236.624 155.378 237.025 154.978 237.826 155.078C238.528 155.178 238.928 155.479 238.828 156.18Z"
        :fill="myColors.main"
      />
      <path
        d="M119.414 174.112C119.414 174.813 119.013 175.214 118.312 175.214C117.711 175.214 117.31 174.813 117.31 174.212C117.31 173.511 117.711 173.11 118.412 173.11C119.013 173.11 119.414 173.511 119.414 174.112Z"
        :fill="myColors.main"
      />
      <path
        d="M168.101 204.667C168.803 204.667 169.103 204.967 169.203 205.669C169.203 206.37 168.803 206.871 168.202 206.871C167.6 206.871 167.1 206.37 167.1 205.669C166.999 205.067 167.4 204.667 168.101 204.667Z"
        :fill="myColors.main"
      />
      <path
        d="M169.203 151.571C169.203 152.273 168.803 152.774 168.101 152.774C167.5 152.774 167.1 152.273 166.999 151.672C166.999 150.97 167.4 150.57 168.101 150.57C168.803 150.57 169.103 150.87 169.203 151.571Z"
        :fill="myColors.main"
      />
      <path
        d="M186.935 151.672C186.935 150.97 187.236 150.57 187.937 150.57C188.638 150.57 189.139 150.97 189.139 151.571C189.139 152.273 188.638 152.774 188.037 152.774C187.436 152.774 186.935 152.273 186.935 151.672Z"
        :fill="myColors.main"
      />
      <path
        d="M118.312 155.078C119.013 155.078 119.314 155.478 119.314 156.08C119.314 156.781 118.913 157.182 118.212 157.182C117.511 157.182 117.11 156.681 117.21 155.979C117.31 155.378 117.711 155.078 118.312 155.078Z"
        :fill="myColors.main"
      />
      <path
        d="M118.312 209.175C119.013 209.175 119.314 209.475 119.414 210.177C119.414 210.878 119.013 211.279 118.312 211.279C117.611 211.279 117.21 210.878 117.21 210.177C117.31 209.475 117.611 209.175 118.312 209.175Z"
        :fill="myColors.main"
      />
      <path
        d="M138.248 209.175C138.949 209.175 139.25 209.576 139.25 210.177C139.25 210.878 138.849 211.279 138.148 211.279C137.547 211.279 137.046 210.778 137.046 210.177C137.046 209.676 137.647 209.175 138.248 209.175Z"
        :fill="myColors.main"
      />
      <path
        d="M217.991 155.078C218.592 155.078 219.093 155.579 219.093 156.08C219.093 156.681 218.592 157.182 217.891 157.182C217.189 157.182 216.789 156.681 216.889 155.979C216.889 155.378 217.289 155.078 217.991 155.078Z"
        :fill="myColors.main"
      />
      <path
        d="M186.935 205.769C186.935 205.067 187.236 204.667 187.837 204.667C188.538 204.567 189.039 204.967 189.039 205.669C189.039 206.37 188.538 206.871 187.937 206.871C187.436 206.871 186.935 206.37 186.935 205.769Z"
        :fill="myColors.main"
      />
      <path
        d="M98.4766 173.11C99.0777 173.11 99.5785 173.611 99.5785 174.112C99.5785 174.713 99.0776 175.214 98.3764 175.214C97.6751 175.214 97.2744 174.713 97.3746 174.012C97.3746 173.411 97.7753 173.11 98.4766 173.11Z"
        :fill="myColors.main"
      />
      <path
        d="M98.3764 155.078C99.0776 155.078 99.5785 155.579 99.4784 156.18C99.4784 156.781 98.9774 157.182 98.3764 157.182C97.6751 157.182 97.2744 156.781 97.2744 156.08C97.3746 155.478 97.7753 155.078 98.3764 155.078Z"
        :fill="myColors.main"
      />
      <path
        d="M144.659 210.177C144.659 209.475 145.16 209.075 145.861 209.175C146.563 209.275 146.863 209.576 146.863 210.277C146.863 210.978 146.362 211.379 145.661 211.279C144.96 211.279 144.659 210.878 144.659 210.177Z"
        :fill="myColors.main"
      />
      <path
        d="M196.552 151.672C196.552 152.273 196.152 152.774 195.55 152.774C194.949 152.774 194.448 152.273 194.448 151.571C194.448 150.87 194.849 150.57 195.45 150.57C196.152 150.57 196.552 150.97 196.552 151.672Z"
        :fill="myColors.main"
      />
      <path
        d="M77.0381 151.672C77.0381 152.373 76.5372 152.874 75.9361 152.774C75.4352 152.774 74.9343 152.273 74.9343 151.672C74.9343 150.97 75.335 150.57 76.0363 150.57C76.7375 150.57 77.0381 150.97 77.0381 151.672Z"
        :fill="myColors.main"
      />
      <path
        d="M74.9343 187.736C74.9343 187.035 75.2348 186.634 75.9361 186.634C76.6373 186.634 77.0381 186.935 77.0381 187.736C77.0381 188.438 76.5372 188.939 75.9361 188.939C75.4352 188.838 74.9343 188.337 74.9343 187.736Z"
        :fill="myColors.main"
      />
      <path
        d="M146.763 156.18C146.763 156.881 146.362 157.282 145.661 157.282C145.06 157.282 144.659 156.881 144.659 156.18C144.659 155.478 145.06 155.078 145.761 155.178C146.463 155.078 146.763 155.478 146.763 156.18Z"
        :fill="myColors.main"
      />
      <path
        d="M196.552 187.837C196.552 188.438 196.051 188.939 195.45 188.939C194.849 188.939 194.348 188.337 194.449 187.736C194.449 187.035 194.849 186.735 195.55 186.735C196.252 186.634 196.652 187.035 196.552 187.837Z"
        :fill="myColors.main"
      />
      <path
        d="M77.0381 205.769C77.0381 206.47 76.5372 206.971 75.9361 206.871C75.4352 206.871 74.9343 206.37 74.9343 205.769C74.9343 205.067 75.335 204.667 76.0363 204.667C76.7375 204.667 77.0381 205.067 77.0381 205.769Z"
        :fill="myColors.main"
      />
      <path
        d="M196.552 205.769C196.552 206.37 196.152 206.871 195.55 206.871C194.949 206.871 194.448 206.37 194.448 205.669C194.448 204.967 194.849 204.667 195.45 204.667C196.152 204.667 196.552 205.067 196.552 205.769Z"
        :fill="myColors.main"
      />
      <path
        d="M77.0381 133.74C76.9379 134.341 76.437 134.741 75.9361 134.741C75.335 134.641 74.9343 134.14 74.9343 133.439C75.0345 132.838 75.4352 132.437 76.0363 132.537C76.7375 132.537 77.1382 133.038 77.0381 133.74Z"
        :fill="myColors.main"
      />
      <path
        d="M196.552 223.801C196.552 224.402 196.051 224.903 195.55 224.903C194.949 224.903 194.448 224.302 194.448 223.701C194.448 223 194.849 222.699 195.55 222.699C196.252 222.699 196.552 223.1 196.552 223.801Z"
        :fill="myColors.main"
      />
      <path
        d="M146.763 174.212C146.763 174.913 146.362 175.314 145.661 175.314C145.06 175.314 144.659 174.913 144.659 174.212C144.659 173.511 145.06 173.11 145.761 173.21C146.463 173.11 146.763 173.511 146.763 174.212Z"
        :fill="myColors.main"
      />
      <path
        d="M74.9343 223.801C74.9343 223.1 75.2348 222.699 75.9361 222.699C76.6373 222.699 77.0381 223 77.0381 223.701C77.0381 224.402 76.5372 224.903 75.9361 224.903C75.4352 224.903 74.9343 224.402 74.9343 223.801Z"
        :fill="myColors.main"
      />
      <path
        d="M196.552 169.804C196.452 170.405 195.951 170.806 195.45 170.806C194.849 170.706 194.448 170.205 194.448 169.504C194.549 168.903 194.949 168.502 195.55 168.602C196.252 168.602 196.652 169.103 196.552 169.804Z"
        :fill="myColors.main"
      />
      <path
        d="M196.552 133.639C196.552 134.341 196.051 134.842 195.45 134.741C194.949 134.741 194.448 134.24 194.448 133.639C194.448 132.938 194.849 132.537 195.55 132.537C196.252 132.537 196.552 132.938 196.552 133.639Z"
        :fill="myColors.main"
      />
      <path
        d="M146.763 138.147C146.763 138.849 146.262 139.249 145.561 139.149C144.96 139.149 144.559 138.749 144.559 138.047C144.559 137.346 144.96 136.945 145.761 137.046C146.463 137.146 146.863 137.446 146.763 138.147Z"
        :fill="myColors.main"
      />
      <path
        d="M77.0381 169.704C77.0381 170.305 76.6373 170.806 76.0363 170.806C75.4352 170.806 74.9343 170.305 74.9343 169.704C74.9343 169.003 75.335 168.602 76.0363 168.602C76.7375 168.602 77.0381 169.003 77.0381 169.704Z"
        :fill="myColors.main"
      />
      <path
        d="M146.763 192.144C146.763 192.845 146.362 193.246 145.661 193.246C145.06 193.246 144.659 192.845 144.659 192.144C144.659 191.443 145.06 191.042 145.761 191.042C146.463 191.142 146.763 191.543 146.763 192.144Z"
        :fill="myColors.main"
      />
      <path
        d="M164.595 174.112C164.595 173.411 164.895 173.11 165.597 173.11C166.298 173.11 166.699 173.511 166.699 174.212C166.699 174.813 166.298 175.214 165.697 175.314C164.996 175.314 164.595 174.913 164.595 174.112Z"
        :fill="myColors.main"
      />
      <path
        d="M53.4959 174.713C53.4959 175.414 53.1954 175.715 52.4941 175.815C51.7929 175.815 51.3921 175.414 51.3921 174.813C51.3921 174.212 51.7929 173.811 52.3939 173.711C53.0952 173.611 53.4959 174.012 53.4959 174.713Z"
        :fill="myColors.main"
      />
      <path
        d="M173.01 174.613C173.01 175.314 172.71 175.715 172.108 175.715C171.407 175.715 170.906 175.414 170.906 174.713C170.906 174.012 171.307 173.611 172.008 173.611C172.609 173.611 173.01 174.012 173.01 174.613Z"
        :fill="myColors.main"
      />
      <path
        d="M164.595 156.08C164.595 155.378 164.895 155.078 165.597 155.078C166.298 155.078 166.699 155.478 166.699 156.18C166.699 156.781 166.298 157.182 165.697 157.182C164.996 157.282 164.595 156.781 164.595 156.08Z"
        :fill="myColors.main"
      />
      <path
        d="M45.0808 156.08C45.0808 155.378 45.3813 155.078 46.0826 155.078C46.7838 155.078 47.1846 155.478 47.1846 156.18C47.1846 156.781 46.7838 157.182 46.1828 157.182C45.4815 157.282 45.0808 156.781 45.0808 156.08Z"
        :fill="myColors.main"
      />
      <path
        d="M47.1846 174.212C47.1846 174.913 46.7838 175.314 46.0826 175.314C45.4815 175.314 45.0808 174.913 45.0808 174.212C45.0808 173.511 45.3813 173.21 46.0826 173.21C46.7838 173.11 47.1846 173.511 47.1846 174.212Z"
        :fill="myColors.main"
      />
      <path
        d="M166.699 210.277C166.699 210.978 166.298 211.379 165.597 211.379C164.996 211.379 164.595 210.978 164.595 210.277C164.595 209.576 164.895 209.275 165.597 209.275C166.298 209.175 166.699 209.576 166.699 210.277Z"
        :fill="myColors.main"
      />
      <path
        d="M47.1846 138.147C47.1846 138.849 46.6837 139.249 45.9824 139.149C45.3813 139.149 44.9806 138.749 44.9806 138.047C44.9806 137.346 45.3813 137.045 45.9824 137.045C46.884 137.045 47.1846 137.446 47.1846 138.147Z"
        :fill="myColors.main"
      />
      <path
        d="M45.0808 192.144C45.0808 191.443 45.3813 191.143 46.0826 191.143C46.7838 191.143 47.1846 191.543 47.1846 192.245C47.1846 192.846 46.7838 193.246 46.1828 193.347C45.4815 193.347 45.0808 192.946 45.0808 192.144Z"
        :fill="myColors.main"
      />
      <path
        d="M165.697 191.143C166.398 191.143 166.799 191.543 166.699 192.245C166.699 192.846 166.298 193.246 165.597 193.246C164.896 193.246 164.495 192.846 164.595 192.044C164.595 191.443 164.996 191.143 165.697 191.143Z"
        :fill="myColors.main"
      />
      <path
        d="M173.01 156.681C173.01 157.382 172.71 157.783 172.008 157.783C171.307 157.783 170.906 157.382 170.806 156.781C170.806 156.18 171.207 155.779 171.808 155.679C172.509 155.579 173.01 155.979 173.01 156.681Z"
        :fill="myColors.main"
      />
      <path
        d="M173.01 192.645C173.01 193.346 172.71 193.747 172.008 193.747C171.307 193.747 170.806 193.447 170.806 192.745C170.806 192.044 171.207 191.643 171.908 191.643C172.609 191.643 173.01 192.044 173.01 192.645Z"
        :fill="myColors.main"
      />
      <path
        d="M46.1828 209.175C46.884 209.175 47.2848 209.576 47.1846 210.277C47.1846 210.878 46.7839 211.279 46.0826 211.279C45.3813 211.279 44.9806 210.878 45.0808 210.177C45.0808 209.475 45.4815 209.175 46.1828 209.175Z"
        :fill="myColors.main"
      />
      <path
        d="M173.01 138.648C173.01 139.35 172.609 139.65 172.008 139.65C171.307 139.65 170.906 139.249 170.906 138.548C170.906 137.947 171.307 137.546 171.908 137.546C172.609 137.546 173.01 137.947 173.01 138.648Z"
        :fill="myColors.main"
      />
      <path
        d="M166.699 138.047C166.699 138.749 166.298 139.149 165.597 139.149C164.996 139.149 164.595 138.749 164.595 138.147C164.595 137.446 164.895 137.146 165.597 137.045C166.298 137.045 166.699 137.446 166.699 138.047Z"
        :fill="myColors.main"
      />
      <path
        d="M173.01 210.778C173.01 211.479 172.609 211.779 172.008 211.779C171.307 211.779 170.906 211.379 170.906 210.677C170.906 210.076 171.307 209.676 172.008 209.676C172.609 209.676 173.01 210.076 173.01 210.778Z"
        :fill="myColors.main"
      />
      <path
        d="M52.4941 157.682C51.7929 157.682 51.3921 157.282 51.3921 156.581C51.3921 155.979 51.7929 155.579 52.4941 155.579C53.1954 155.579 53.5961 155.979 53.5961 156.681C53.4959 157.382 53.0952 157.682 52.4941 157.682Z"
        :fill="myColors.main"
      />
      <path
        d="M52.3939 211.779C51.6927 211.779 51.292 211.379 51.292 210.677C51.292 210.076 51.6927 209.676 52.3939 209.676C53.0952 209.676 53.4959 210.076 53.4959 210.778C53.4959 211.479 53.0952 211.88 52.3939 211.779Z"
        :fill="myColors.main"
      />
      <path
        d="M53.4959 192.745C53.4959 193.447 53.0952 193.747 52.4941 193.747C51.7929 193.747 51.3921 193.346 51.3921 192.645C51.3921 191.944 51.7929 191.543 52.4941 191.643C53.1954 191.643 53.4959 192.044 53.4959 192.745Z"
        :fill="myColors.main"
      />
      <path
        d="M52.3939 139.65C51.6927 139.65 51.292 139.249 51.292 138.548C51.292 137.947 51.6927 137.546 52.3939 137.546C53.0952 137.546 53.4959 137.947 53.3957 138.648C53.4959 139.35 53.0952 139.75 52.3939 139.65Z"
        :fill="myColors.main"
      />
      <path
        d="M32.1577 220.796C32.1577 219.994 32.1577 219.393 32.1577 218.692C33.1595 218.692 33.6604 218.992 33.6604 219.694C33.6604 220.395 33.1595 220.796 32.1577 220.796Z"
        :fill="myColors.main"
      />
      <path
        d="M32.1577 184.731C32.1577 184.03 32.1577 183.429 32.1577 182.727C32.7587 182.527 33.1595 182.627 33.3598 183.128C33.5602 183.429 33.5602 183.93 33.46 184.23C33.2596 184.731 32.8589 184.931 32.1577 184.731Z"
        :fill="myColors.main"
      />
      <path
        d="M32.1577 148.666C32.1577 147.865 32.1577 147.264 32.1577 146.563C33.0593 146.563 33.6604 146.863 33.6604 147.564C33.7605 148.266 33.1595 148.666 32.1577 148.666Z"
        :fill="myColors.main"
      />
      <path
        d="M32.1577 166.699C32.1577 165.997 32.1577 165.296 32.1577 164.595C33.1595 164.595 33.6604 164.996 33.6604 165.597C33.6604 166.298 33.1595 166.699 32.1577 166.699Z"
        :fill="myColors.main"
      />
      <path
        d="M32.1577 202.763C32.1577 202.062 32.1577 201.361 32.1577 200.66C33.1595 200.66 33.6604 201.06 33.6604 201.661C33.6604 202.363 33.1595 202.763 32.1577 202.763Z"
        :fill="myColors.main"
      />
      <path
        d="M32.1577 137.647C33.2596 137.647 33.6604 138.047 33.6604 138.749C33.5602 139.45 33.1595 139.65 32.1577 139.55C32.1577 138.949 32.1577 138.348 32.1577 137.647Z"
        :fill="myColors.main"
      />
      <path
        d="M32.1577 175.715C32.1577 175.014 32.1577 174.413 32.1577 173.811C32.6586 173.611 33.1595 173.611 33.3598 174.112C33.46 174.413 33.46 174.913 33.3598 175.214C33.2596 175.715 32.7587 175.815 32.1577 175.715Z"
        :fill="myColors.main"
      />
      <path
        d="M32.2578 191.743C33.2596 191.743 33.7605 192.044 33.7605 192.745C33.7605 193.346 33.2596 193.647 32.2578 193.647C32.2578 193.046 32.2578 192.445 32.2578 191.743Z"
        :fill="myColors.main"
      />
      <path
        d="M32.1577 155.679C33.1595 155.679 33.6604 156.08 33.6604 156.681C33.6604 157.282 33.1595 157.582 32.1577 157.582C32.1577 156.981 32.1577 156.38 32.1577 155.679Z"
        :fill="myColors.main"
      />
      <path
        d="M32.1577 211.679C32.1577 211.078 32.1577 210.477 32.1577 209.776C32.6586 209.575 33.1595 209.575 33.3598 210.076C33.46 210.377 33.46 210.878 33.3598 211.178C33.2596 211.779 32.8589 211.88 32.1577 211.679Z"
        :fill="myColors.main"
      />
      <path
        d="M261.168 225.304C260.367 224.402 260.266 224.102 260.767 223.601C261.168 223.1 261.669 223.1 262.571 223.601C262.07 224.202 261.569 224.703 261.168 225.304Z"
        :fill="myColors.main"
      />
      <path
        d="M35.7641 125.525C36.265 125.024 36.7659 124.623 37.2668 124.223C37.2668 125.425 36.9662 125.725 35.7641 125.525Z"
        :fill="myColors.main"
      />
      <path
        d="M255.859 134.241L255.458 133.84C255.558 133.84 255.558 133.94 255.658 134.04C255.758 134.14 255.758 134.14 255.859 134.241Z"
        :fill="myColors.background"
        :stroke="myColors.foreground"
        stroke-miterlimit="10"
      />
      <path
        d="M255.358 133.84C255.157 133.64 254.857 133.439 254.556 133.339C254.156 133.139 253.655 133.038 253.154 133.038H236.223C234.32 133.038 232.817 134.541 232.817 136.445C232.817 136.945 232.917 137.346 233.118 137.847C233.218 138.047 233.318 138.248 233.418 138.448L231.715 136.745C231.315 136.244 231.114 135.543 231.114 134.741C231.114 132.838 232.617 131.335 234.52 131.335H251.451C252.252 131.335 253.054 131.636 253.655 132.137L253.755 132.237L254.055 132.538L254.155 132.638L255.358 133.84Z"
        :fill="myColors.main"
      />
      <path
        d="M271.787 116.409C268.181 113.103 263.372 110.999 258.163 110.799C257.862 110.799 257.562 110.799 257.261 110.799H43.1774C39.3706 110.799 35.8643 111.8 32.7588 113.503C30.1541 114.906 27.7498 116.909 25.9465 119.314C24.8446 120.716 23.943 122.219 23.2417 123.822C22.2399 126.026 21.6388 128.53 21.4385 131.135C21.4385 131.636 21.3383 132.137 21.3383 132.738V221.797C21.3383 222.098 21.3383 222.499 21.3383 222.799C21.5386 228.209 23.8428 233.118 27.3491 236.824L6.01082 215.286C2.60472 211.479 0.500946 206.37 0.500946 200.86V111.8C0.500946 111.299 0.500946 110.698 0.601126 110.197C0.801485 107.593 1.40256 105.188 2.40436 102.884C3.10561 101.281 4.00723 99.7788 5.1092 98.3763C6.91244 95.9719 9.21657 94.0685 11.9214 92.5658C15.027 90.8628 18.5333 89.9612 22.3401 89.9612H236.424C242.435 89.9612 247.844 92.3655 251.851 96.3727L271.787 116.409Z"
        :fill="codingIllustrationColors.backgroundBox.background"
        :stroke="codingIllustrationColors.backgroundBox.line"
        stroke-miterlimit="10"
      />
      <path
        d="M105.689 96.7737C105.489 96.5733 105.289 96.2728 105.088 96.0724C104.688 95.5715 104.187 95.1708 103.786 94.7701C102.283 93.3675 100.58 92.2656 98.7771 91.4641C96.1724 90.262 93.3674 89.5607 90.2618 89.5607C86.8557 89.5607 83.5498 90.4623 80.7447 91.965C78.5408 93.1672 76.5372 94.8702 74.8342 96.7737C73.9325 97.7755 73.2313 98.9776 72.6302 100.08C71.1275 102.885 70.3261 106.09 70.3261 109.496V175.415C70.3261 178.52 71.0273 181.425 72.2295 183.93C73.0309 185.733 74.1329 187.336 75.4352 188.738L75.5354 188.839C75.6356 188.939 75.7358 189.039 75.8359 189.139L76.0363 189.34C76.437 189.74 76.9379 190.241 77.4388 190.642C80.9451 193.547 85.353 195.35 90.2618 195.35C95.1706 195.35 99.6787 193.547 103.085 190.642C107.393 186.935 110.198 181.526 110.198 175.415V109.496C110.298 104.688 108.595 100.28 105.689 96.7737ZM98.3763 175.415C98.3763 179.823 94.7699 183.429 90.362 183.429H90.2618C85.8539 183.429 82.2474 179.823 82.2474 175.415V109.496C82.2474 105.589 85.0525 102.284 88.8593 101.582C89.3602 101.482 89.8611 101.482 90.362 101.482C94.7699 101.482 98.3763 105.089 98.3763 109.496V175.415Z"
        :fill="codingIllustrationColors.leftMostZero.background"
        :stroke="codingIllustrationColors.leftMostZero.line"
        stroke-miterlimit="10"
      />
      <path
        d="M103.786 94.7699C102.283 93.3674 100.58 92.2654 98.777 91.464C96.1723 90.2618 93.3673 89.5606 90.2617 89.5606C86.8556 89.5606 83.5497 90.4622 80.7447 91.9649C78.5407 93.167 76.5371 94.8701 74.8341 96.7735C73.9325 97.7753 73.2312 98.9775 72.6301 100.079C71.1274 102.884 70.326 106.09 70.326 109.496V175.414C70.326 178.52 71.0273 181.425 72.2294 183.93C73.0309 185.733 74.1328 187.336 75.4352 188.738L67.6212 180.924L67.521 180.824C67.4208 180.724 67.3206 180.624 67.2204 180.524L65.9181 179.221C62.7124 175.715 60.7088 171.007 60.7088 165.797V99.9792C60.7088 95.1706 62.4118 90.7627 65.317 87.2564C68.9235 82.8485 74.5336 80.0435 80.6445 80.0435C86.0542 80.0435 90.963 82.1473 94.5695 85.6536L103.786 94.7699Z"
        :fill="codingIllustrationColors.leftMostZero.depthFill"
        :stroke="codingIllustrationColors.leftMostZero.depthOutline"
        stroke-miterlimit="10"
      />
      <path
        d="M98.3763 109.496V175.415C98.3763 179.822 94.7698 183.429 90.3619 183.429H90.2617C85.8538 183.429 82.2474 179.822 82.2474 175.415V173.812C85.954 173.11 88.8592 169.804 88.8592 165.897V101.582C89.3601 101.482 89.861 101.482 90.3619 101.482C94.7698 101.482 98.3763 105.088 98.3763 109.496Z"
        :fill="codingIllustrationColors.leftMostZero.depthFill"
        :stroke="codingIllustrationColors.leftMostZero.depthOutline"
        stroke-miterlimit="10"
      />
      <path d="M67.6223 180.926L67.3206 180.624" stroke="#FA713F" stroke-miterlimit="10" />
      <path
        d="M240.832 15.2274C239.329 13.8249 237.626 12.7229 235.823 11.9214C233.218 10.7193 230.413 10.018 227.307 10.018C223.901 10.018 220.595 10.9197 217.79 12.4223C215.586 13.6245 213.583 15.3276 211.88 17.231C210.978 18.2328 210.277 19.4349 209.676 20.5369C208.173 23.3419 207.372 26.5477 207.372 29.9538V95.8719C207.372 98.9775 208.073 101.883 209.275 104.387C210.077 106.19 211.179 107.793 212.481 109.196L204.667 101.382L204.567 101.282C204.466 101.181 204.366 101.081 204.266 100.981L202.964 99.6787C199.758 96.1724 197.754 91.464 197.754 86.2547V20.4367C197.754 15.6281 199.457 11.2202 202.363 7.7139C205.969 3.306 211.579 0.500977 217.69 0.500977C223.1 0.500977 228.009 2.60475 231.615 6.11103L240.832 15.2274Z"
        :fill="myColors.main"
        :stroke="codingIllustrationColors.rightMostTopZero.depthOutline"
      />
      <path
        d="M235.422 29.9538V95.8719C235.422 100.28 231.816 103.886 227.408 103.886H227.307C222.9 103.886 219.293 100.28 219.293 95.8719V94.269C223 93.5678 225.905 90.2618 225.905 86.3548V22.0396C226.406 21.9394 226.907 21.9394 227.408 21.9394C231.816 21.9394 235.422 25.5459 235.422 29.9538Z"
        :fill="myColors.main"
      />
      <path
        d="M204.668 101.383L204.366 101.081"
        :stroke="myColors.foreground"
        stroke-miterlimit="10"
      />
      <path
        d="M242.635 17.2309C242.435 17.0306 242.234 16.73 242.034 16.5297C241.633 16.0288 241.132 15.6281 240.731 15.2273C239.229 13.8248 237.526 12.7229 235.723 11.9214C233.118 10.7193 230.313 10.018 227.207 10.018C223.801 10.018 220.495 10.9196 217.69 12.4223C215.486 13.6245 213.483 15.3275 211.78 17.2309C210.878 18.2327 210.177 19.4349 209.576 20.5369C208.073 23.3419 207.271 26.5476 207.271 29.9537V95.8719C207.271 98.9774 207.973 101.883 209.175 104.387C209.976 106.19 211.078 107.793 212.381 109.196L212.481 109.296C212.581 109.396 212.681 109.496 212.781 109.596L212.982 109.797C213.382 110.198 213.883 110.698 214.384 111.099C217.891 114.004 222.298 115.808 227.207 115.808C232.116 115.808 236.624 114.004 240.03 111.099C244.338 107.393 247.143 101.983 247.143 95.8719V29.9537C247.243 25.1451 245.54 20.637 242.635 17.2309ZM235.422 95.8719C235.422 100.28 231.815 103.886 227.408 103.886H227.307C222.9 103.886 219.293 100.28 219.293 95.8719V29.9537C219.293 26.0467 222.098 22.7408 225.905 22.0396C226.406 21.9394 226.907 21.9394 227.408 21.9394C231.815 21.9394 235.422 25.5458 235.422 29.9537V95.8719Z"
        fill="#FFFDF7"
        :stroke="codingIllustrationColors.rightMostTopZero.outline"
        stroke-miterlimit="10"
      />
      <path
        d="M152.674 129.933L149.568 130.033L140.051 120.416H143.157L152.674 129.933Z"
        :fill="codingIllustrationColors.firstTopMostOne.depthHighlight"
      />
      <path
        d="M152.674 50.8912V129.933L143.157 120.416V41.274L152.674 50.8912Z"
        :fill="myColors.main"
        :stroke="codingIllustrationColors.firstTopMostOne.depthOutline"
      />
      <path
        d="M149.568 130.033V144.759L140.051 135.142V120.416L149.568 130.033Z"
        :fill="myColors.main"
      />
      <path
        d="M164.595 129.933H167.701V144.759H149.568V129.933H152.674V50.8912H164.595L169.604 65.818L164.595 71.0273V129.933Z"
        fill="#FFFDF7"
        :stroke="codingIllustrationColors.firstTopMostOne.outline"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M164.595 50.8912H152.674L143.157 41.274H155.078L164.595 50.8912Z"
        :stroke="myColors.foreground"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M167.7 129.933H164.595V126.827L167.7 129.933Z"
        :fill="myColors.background"
        :stroke="myColors.foreground"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M232.517 156.781C231.014 155.379 229.311 154.277 227.508 153.475C224.903 152.273 222.098 151.572 218.992 151.572C215.586 151.572 212.28 152.473 209.475 153.976C207.271 155.178 205.268 156.881 203.565 158.785C202.663 159.786 201.962 160.989 201.361 162.091C199.858 164.896 199.057 168.101 199.057 171.507V237.426C199.057 240.531 199.758 243.436 200.96 245.941C201.762 247.744 202.864 249.347 204.166 250.749L196.352 242.935L196.252 242.835C196.151 242.735 196.051 242.635 195.951 242.535L194.649 241.232C191.443 237.726 189.439 233.018 189.439 227.808V161.89C189.439 157.082 191.143 152.674 194.048 149.167C197.654 144.759 203.264 141.954 209.375 141.954C214.785 141.954 219.694 144.058 223.3 147.565L232.517 156.781Z"
        :fill="codingIllustrationColors.rightMostBottomZero.depthBg"
        stroke="#FA713F"
        stroke-miterlimit="10"
      />
      <path
        d="M227.107 171.507V237.426C227.107 241.833 223.501 245.44 219.093 245.44H218.992C214.585 245.44 210.978 241.833 210.978 237.426V235.823C214.685 235.121 217.59 231.815 217.59 227.909V163.593C218.091 163.493 218.592 163.493 219.093 163.493C223.501 163.493 227.107 167.1 227.107 171.507Z"
        :fill="codingIllustrationColors.rightMostBottomZero.depthBg"
        :stroke="codingIllustrationColors.rightMostBottomZero.depthLn"
      />
      <path
        d="M234.32 158.785C234.12 158.584 233.919 158.284 233.719 158.083C233.318 157.583 232.817 157.182 232.417 156.781C230.914 155.379 229.211 154.277 227.408 153.475C224.803 152.273 221.998 151.572 218.892 151.572C215.486 151.572 212.18 152.473 209.375 153.976C207.171 155.178 205.168 156.881 203.465 158.785C202.563 159.786 201.862 160.989 201.261 162.091C199.758 164.896 198.957 168.101 198.957 171.507V237.426C198.957 240.531 199.658 243.436 200.86 245.941C201.661 247.744 202.763 249.347 204.066 250.75L204.166 250.85C204.266 250.95 204.366 251.05 204.466 251.15L204.667 251.351C205.067 251.751 205.568 252.252 206.069 252.653C209.576 255.558 213.983 257.361 218.892 257.361C223.801 257.361 228.309 255.558 231.715 252.653C236.023 248.946 238.828 243.537 238.828 237.426V171.507C238.928 166.699 237.225 162.191 234.32 158.785ZM227.107 237.426C227.107 241.834 223.501 245.44 219.093 245.44H218.992C214.585 245.44 210.978 241.834 210.978 237.426V171.507C210.978 167.6 213.783 164.295 217.59 163.593C218.091 163.493 218.592 163.493 219.093 163.493C223.501 163.493 227.107 167.1 227.107 171.507V237.426Z"
        fill="#FFFDF7"
        :stroke="myColors.foreground"
        stroke-miterlimit="10"
      />
      <path
        d="M88.759 240.13C87.2563 238.728 85.5533 237.626 83.7501 236.824C81.1454 235.622 78.3404 234.921 75.2348 234.921C71.8287 234.921 68.5228 235.823 65.7177 237.325C63.4136 238.528 61.41 240.231 59.8072 242.134C58.9055 243.136 58.2043 244.338 57.6032 245.44C56.1005 248.245 55.2991 251.451 55.2991 254.857V320.775C55.2991 323.881 56.0003 326.786 57.2025 329.29C58.0039 331.093 59.1059 332.696 60.4082 334.099L52.5942 326.285L52.494 326.185C52.3939 326.084 52.2937 325.984 52.1935 325.884L50.8912 324.582C47.6854 321.075 45.6818 316.367 45.6818 311.158V245.24C45.6818 240.431 47.3849 236.023 50.2901 232.517C53.8966 228.109 59.5066 225.304 65.6176 225.304C71.0273 225.304 75.9361 227.408 79.5425 230.914L88.759 240.13Z"
        :fill="myColors.main"
        :stroke="codingIllustrationColors.leftMostZero.depthOutline"
      />
      <path
        d="M83.3494 254.857V320.775C83.3494 325.183 79.7429 328.789 75.335 328.789H75.1346C70.7267 328.789 67.1203 325.183 67.1203 320.775V319.172C70.8269 318.471 73.7321 315.165 73.7321 311.258V246.943C74.233 246.843 74.7339 246.843 75.2348 246.843C79.7429 246.843 83.3494 250.349 83.3494 254.857Z"
        :fill="myColors.main"
      />
      <path
        d="M90.6624 242.134C90.4621 241.934 90.2617 241.633 90.0614 241.433C89.6606 240.932 89.1597 240.531 88.759 240.131C87.2563 238.728 85.5533 237.626 83.75 236.825C81.1454 235.623 78.3403 234.921 75.2348 234.921C71.8287 234.921 68.5227 235.823 65.7177 237.326C63.4136 238.528 61.41 240.231 59.8071 242.134C58.9055 243.136 58.2043 244.338 57.6032 245.44C56.1005 248.245 55.299 251.451 55.299 254.857V320.775C55.299 323.881 56.0003 326.786 57.2025 329.29C58.0039 331.094 59.1059 332.697 60.4082 334.099L60.5084 334.199C60.6086 334.299 60.7087 334.4 60.8089 334.5L61.0093 334.7C61.41 335.101 61.9109 335.602 62.4118 336.002C65.9181 338.908 70.326 340.711 75.2348 340.711C80.1436 340.711 84.6517 338.908 88.0578 336.002C92.3655 332.296 95.1705 326.886 95.1705 320.775V254.857C95.1705 250.048 93.4675 245.54 90.6624 242.134ZM83.3493 320.775C83.3493 325.183 79.7429 328.79 75.335 328.79H75.1346C70.7267 328.79 67.1202 325.183 67.1202 320.775V254.857C67.1202 250.95 69.9253 247.644 73.7321 246.943C74.233 246.843 74.7339 246.843 75.2348 246.843C79.6427 246.843 83.2491 250.449 83.2491 254.857V320.775H83.3493Z"
        fill="#FFFDF7"
        :stroke="myColors.foreground"
        stroke-miterlimit="10"
      />
      <path
        d="M139.55 273.891H136.445L126.927 264.374H130.033L139.55 273.891Z"
        fill="#FFFDF7"
        stroke="#F37140"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M139.55 194.749V273.891L130.033 264.374V185.232L139.55 194.749Z"
        :fill="codingIllustrationColors.bottomOne.depthFill"
        :stroke="codingIllustrationColors.bottomOne.depthOutline"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M136.445 273.891V288.617L126.927 279.1V264.374L136.445 273.891Z"
        :fill="codingIllustrationColors.bottomOne.depthFill"
        :stroke="codingIllustrationColors.bottomOne.depthOutline"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M151.471 194.749H139.55L130.033 185.232H141.954L151.471 194.749Z"
        fill="#FFFDF7"
        stroke="#F37140"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M154.577 273.891H151.471V270.785L154.577 273.891Z"
        fill="#FFFDF7"
        stroke="#F37140"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.2417 123.722L2.40433 102.985"
        :stroke="codingIllustrationColors.backgroundBox.line"
        stroke-miterlimit="10"
      />
      <path
        d="M21.4385 131.035L0.60112 110.198"
        :stroke="codingIllustrationColors.backgroundBox.line"
        stroke-miterlimit="10"
      />
      <path
        d="M25.9465 119.214L5.10919 98.4766"
        :stroke="codingIllustrationColors.backgroundBox.line"
        stroke-miterlimit="10"
      />
      <path
        d="M32.7587 113.503L11.9214 92.6661"
        :stroke="codingIllustrationColors.backgroundBox.line"
        stroke-miterlimit="10"
      />
      <path
        d="M46.283 110.799L25.4456 89.9612"
        :stroke="codingIllustrationColors.backgroundBox.line"
        stroke-miterlimit="10"
      />
      <path
        d="M151.471 273.891H154.577V288.617H136.445V273.891H139.55V194.749H151.471L156.48 209.776L151.471 214.985V273.891Z"
        fill="#FFFDF7"
        stroke="#FA713F"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M256.66 136.445C256.66 138.348 255.157 139.851 253.254 139.851H236.324C235.422 139.851 234.52 139.45 233.919 138.849C233.819 138.749 233.719 138.548 233.519 138.448C233.418 138.248 233.318 138.047 233.218 137.847C233.018 137.446 232.917 136.945 232.917 136.445C232.917 134.541 234.42 133.038 236.324 133.038H253.254C253.755 133.038 254.155 133.139 254.656 133.339C254.957 133.439 255.257 133.64 255.458 133.84L255.859 134.241C256.359 134.842 256.66 135.643 256.66 136.445Z"
        :fill="codingIllustrationColors.light.highlight"
        stroke="#FA713F"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</template>

<script>
import { store } from "@/store/store.js";
import { colors, codingColors } from "@/core/colors.js";
export default {
  store: store,

  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.isDark, this.$store);
    },
    codingIllustrationColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style scoped lang="css">
</style>