<template>
  <main>
    <!--        <Mobile v-if="!isDesktop" />-->
    <Desktop />
  </main>
</template>

<script>
import Mobile from "@/layouts/Mobile.vue";
import Desktop from "@/layouts/Desktop.vue";
import { store } from "../../store/store";

export default {
  name: "appBody",
  store: store,
  components: {
    Mobile,
    Desktop,
  },
  mounted: function () {
    if (window.innerWidth >= 768) {
      this.$store.commit("setForDesktop");
    } else {
      this.$store.commit("setForMobile");
    }
  },
  computed: {
    UXDesignTitle: function () {
      return "UX Design";
    },
    UXSkillDescription: function () {
      return "I employ established and effective user experience practices\
       to provide value both to the end user business.";
    },
    uxTitle: function () {
      return this.$store.getters.uxTitle;
    },
    uxBody: function () {
      return this.$store.getters.uxBody;
    },
    codingTitle: function () {
      return this.$store.getters.codingTitle;
    },
    codingBody: function () {
      return this.$store.getters.codingBody;
    },
    illustrationTitle: function () {
      return this.$store.getters.illustrationTitle;
    },
    illusrationBody: function () {
      return this.$store.getters.illustrationBody;
    },
    isDesktop: function () {
      return this.$store.getters.isDesktop;
    },
  },
};
</script>

<style>
main {
  display: grid;
  inline-size: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
}
</style>
