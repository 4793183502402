<template>
  <svg width="90" height="209" viewBox="0 0 90 209" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M77.868 26.6504C75.1591 24.1221 72.0889 22.1355 68.8381 20.6907C64.1426 18.5235 59.0858 17.2593 53.4873 17.2593C47.347 17.2593 41.3873 18.8847 36.3305 21.5937C32.3574 23.7609 28.7454 26.831 25.6752 30.2624C24.0499 32.0684 22.7857 34.2355 21.7021 36.2221C18.9931 41.2789 17.5483 47.058 17.5483 53.1983V172.032C17.5483 177.63 18.8125 182.868 20.9797 187.383C22.4245 190.633 24.4111 193.523 26.7588 196.051L12.6722 181.965L12.4916 181.784C12.311 181.603 12.1304 181.423 11.9498 181.242L9.60205 178.894C3.82292 172.574 0.210938 164.085 0.210938 154.694V36.0415C0.210938 27.3728 3.28111 19.4265 8.51845 13.1056C15.02 5.15928 25.1335 0.102539 36.1499 0.102539C45.9022 0.102539 54.7515 3.89509 61.253 10.216L77.868 26.6504Z"
      :fill="myColors.engineering.topRightZero.depth"
      :stroke="myColors.engineering.topRightZero.mainOutline"
    />
    <path
      d="M68.1151 53.1978V172.031C68.1151 179.978 61.6136 186.479 53.6673 186.479H53.4866C45.5403 186.479 39.0388 179.978 39.0388 172.031V169.142C45.7209 167.877 50.9583 161.918 50.9583 154.874V38.9306C51.8613 38.75 52.7643 38.75 53.6673 38.75C61.6136 38.75 68.1151 45.2515 68.1151 53.1978Z"
      :fill="myColors.engineering.topRightZero.depth"
      :stroke="myColors.engineering.topRightZero.mainOutline"
    />
    <path d="M12.6743 181.967L12.1304 181.423" stroke="black" stroke-miterlimit="10" />
    <path
      d="M81.1187 30.2621C80.7575 29.9009 80.3964 29.3591 80.0352 28.9979C79.3128 28.0949 78.4098 27.3725 77.6874 26.6501C74.9784 24.1218 71.9083 22.1352 68.6575 20.6904C63.9619 18.5232 58.9052 17.259 53.3067 17.259C47.1663 17.259 41.2066 18.8844 36.1499 21.5934C32.1767 23.7606 28.5647 26.8307 25.4946 30.2621C23.8692 32.0681 22.605 34.2352 21.5214 36.2218C18.8125 41.2785 17.3677 47.0577 17.3677 53.198V172.031C17.3677 177.63 18.6319 182.867 20.7991 187.382C22.2438 190.633 24.2304 193.523 26.5782 196.051L26.7588 196.232C26.9394 196.412 27.12 196.593 27.3006 196.773L27.6618 197.135C28.3842 197.857 29.2871 198.76 30.1901 199.482C36.5111 204.72 44.4574 207.97 53.3067 207.97C62.156 207.97 70.2829 204.72 76.4232 199.482C84.1889 192.8 89.2456 183.048 89.2456 172.031V53.198C89.4262 44.5293 86.3561 36.4024 81.1187 30.2621ZM68.1157 172.031C68.1157 179.978 61.6142 186.479 53.6679 186.479H53.4873C45.5409 186.479 39.0394 179.978 39.0394 172.031V53.198C39.0394 46.1547 44.0962 40.195 50.9589 38.9308C51.8619 38.7502 52.7649 38.7502 53.6679 38.7502C61.6142 38.7502 68.1157 45.2517 68.1157 53.198V172.031Z"
      fill="#FFFDF7"
      :stroke="myColors.engineering.topRightZero.mainOutline"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
import { store } from "@/store/store.js";
import { codingColors } from "@/core/colors.js";
export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style scoped>
</style>