<template>
  <button
    :style="[{ 'border-color': myColors.main }]"
    class="cta"
    @click="goToDestination"
  >
    {{ label }}
  </button>
</template>

<script>
import { store } from "@/store/store.js";
import Vue from "vue";
import VueRouter from "vue-router";
import { colors } from "@/core/colors";

Vue.use(VueRouter);

export default {
  props: {
    label: {
      type: String,
      default: "learn more",
    },
    path: {
      type: String,
      default: "#",
    },
    buttonWidth: {
      type: String,
      default: "100%",
    },
  },
  store: store,
  methods: {
    goToDestination: function () {
      this.$router.push(this.path);
    },
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
    isDesktop: function () {
      return this.$store.getters.isDesktop;
    },
    spacing: function () {
      return this.$store.getters.isDesktop
        ? {
            "margin-bottom": "0",
          }
        : { "margin-bottom": "2.5rem" };
    },
  },
};
</script>

<style lang="css" scoped>
button {
  border: 0.1rem solid;
  background: white;
  padding-block: 0.6rem;
  text-transform: uppercase;
  inline-size: 100%;
  transition: all ease-in-out 100ms;
}

button:hover {
  background: var(--color-foreground-accent-active);
  cursor: pointer;
}

@media (max-width: 700px) {
  button {
    margin-block-end: 0;
  }
}

@media (min-width: 705px) {
  button {
    margin-block-start: 1rem;
  }
}
</style>
