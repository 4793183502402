<template>
  <svg width="55" height="188" viewBox="0 0 55 188" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.299 160.639H17.7005L0.543945 143.483H6.14242L23.299 160.639Z"
      fill="#FFFDF7"
      stroke="#F37140"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.2991 17.967V160.639L6.14233 143.483V0.810181L23.2991 17.967Z"
      :fill="myColors.engineering.bottomOne.depthDark"
      :stroke="myColors.engineering.bottomOne.depthDarkOutline"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.7005 160.639V187.187L0.543945 170.03V143.483L17.7005 160.639Z"
      :fill="myColors.engineering.bottomOne.depthDark"
      :stroke="myColors.engineering.bottomOne.depthDarkOutline"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.7898 17.9668H23.2989L6.14233 0.810181H27.6332L44.7898 17.9668Z"
      fill="#FFFDF7"
      stroke="#F37140"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.7904 160.639H50.3889V187.187H17.7007V160.639H23.2992V17.967H44.7904L53.8203 45.0567L44.7904 54.4478V160.639Z"
      fill="#FFFDF7"
      stroke="#FA713F"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { store } from "@/store/store.js";
import { codingColors } from "@/core/colors.js";
export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style>
</style>