<template>
  <svg
    id="cc_icon-christian_coker_logo"
    width="21"
    height="22"
    viewBox="0 0 21 22"
    x="108"
    y="0"
    :style="{ fill: primary }"
  >
    <path
      d="M2.982 2.919h13.934c0.408 0 0.722 0.314 0.722 0.722s-0.314 0.722-0.722 0.722h-4.394c-0.816 0-1.475 0.659-1.475 1.475s0.659 1.475 1.475 1.475h4.394c2.040-0.031 3.641-1.695 3.609-3.735-0.031-1.946-1.632-3.546-3.609-3.578h-13.934c-0.785 0-1.444 0.659-1.444 1.475s0.659 1.444 1.444 1.444z"
    />
    <path
      d="M16.916 8.787h-13.181c-0.408 0-0.722-0.314-0.722-0.722s0.314-0.722 0.722-0.722h5.116c0.816 0 1.475-0.659 1.475-1.475s-0.659-1.475-1.475-1.475h-5.116c-2.040-0.031-3.703 1.601-3.735 3.609s1.569 3.703 3.609 3.735c0.031 0 0.094 0 0.126 0h13.181c0.408 0 0.722 0.314 0.722 0.722s-0.314 0.722-0.722 0.722h-14.656c-1.224 0-2.197 0.973-2.197 2.197v5.147c0 0.816 0.659 1.475 1.475 1.475s1.475-0.659 1.475-1.475v-2.919c0-0.816 0.659-1.475 1.475-1.475s1.475 0.659 1.475 1.475v2.919c0 0.816 0.659 1.475 1.475 1.475s1.475-0.659 1.475-1.475v-2.919c0-0.816 0.659-1.475 1.475-1.475s1.475 0.659 1.475 1.475v2.919c0 0.816 0.659 1.475 1.475 1.475s1.475-0.659 1.475-1.475v-2.919c0-0.816 0.659-1.475 1.475-1.475s1.475 0.659 1.475 1.475v2.919c0 0.816 0.659 1.475 1.475 1.475s1.475-0.659 1.475-1.475v-8.066c-0.125-2.009-1.758-3.672-3.797-3.672z"
    />
  </svg>
</template>

<script>
import { store } from "@/store/store";
export default {
  name: "Logo",
  store: store,
  data() {
    return {
      primaryColor: "var(--primaryColor-light)",
    };
  },
  computed: {
    primary: function() {
      if (this.$store.getters.isDark) {
        return this.$store.getters.darkPrimary;
      }
      return this.$store.getters.lightPrimary;
    },
  },
};
</script>

<style scpoed>
#cc_icon-christian_coker_logo {
  margin: 0 16px 0 0;
}
/* #cc_icon-christian_coker_logo path {
  fill: red;
} */
</style>
