<template>
  <div class="radio-box-container">
    <svg
      width="504"
      height="279"
      viewBox="0 0 504 279"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M163.495 176.014H163.312" stroke="black" stroke-miterlimit="10" />
      <path
        d="M492.542 51.9436C491.639 50.86 490.736 49.957 489.652 49.054C483.151 43.0943 474.482 39.3017 465.091 38.9406C464.549 38.9406 464.007 38.9406 463.466 38.9406H77.528C70.6653 38.9406 64.3444 40.7465 58.7459 43.8167C54.0503 46.3451 49.716 49.957 46.4652 54.2914C44.4786 56.8197 42.8533 59.5287 41.5891 62.4183C39.7831 66.3914 38.6995 70.9064 38.3383 75.6019C38.3383 76.5049 38.1577 77.4079 38.1577 78.4915V239.043C38.1577 239.585 38.1577 240.307 38.1577 240.849C38.5189 250.601 42.6727 259.451 48.9936 266.133L50.2578 267.397C57.3011 274.079 66.8728 278.233 77.3475 278.233H463.285C484.957 278.233 502.655 260.715 502.655 238.862V78.3109C502.655 68.1974 498.863 58.9869 492.542 51.9436ZM483.151 235.792C483.151 248.253 473.037 258.186 460.757 258.186H80.237C72.4713 258.186 65.6086 254.213 61.6354 248.253C59.2877 244.642 57.8429 240.488 57.8429 235.792V81.2005C57.8429 80.2975 57.8429 79.3945 58.0235 78.6721C58.3847 75.2407 59.6489 71.99 61.4548 69.1004C64.525 64.4049 69.04 60.9735 74.4579 59.5287C76.2639 58.9869 78.2504 58.8063 80.237 58.8063H460.576C465.452 58.8063 469.967 60.4317 473.76 62.9601C479.358 67.1138 483.151 73.6153 483.151 81.2005V235.792Z"
        fill="#FBFAFA"
        stroke="#424242"
        stroke-miterlimit="10"
      />
      <path
        d="M483.151 81.2004V235.792C483.151 248.253 473.038 258.186 460.757 258.186H80.2374C72.4717 258.186 65.609 254.213 61.6358 248.253C59.288 244.641 57.8433 240.488 57.8433 235.792V81.2004C57.8433 80.2974 57.8433 79.3944 58.0239 78.6721C58.3851 75.2407 59.6492 71.9899 61.4552 69.1004C64.5254 64.4048 69.0403 60.9735 74.4583 59.5287C76.2642 58.9869 78.2508 58.8063 80.2374 58.8063H460.577C465.453 58.8063 469.968 60.4317 473.76 62.96C479.359 67.1138 483.151 73.7959 483.151 81.2004Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M483.151 81.2004V235.792C483.151 248.253 473.038 258.186 460.757 258.186H80.2374C72.4717 258.186 65.609 254.213 61.6358 248.253C59.288 244.641 57.8433 240.488 57.8433 235.792V81.2004C57.8433 68.7392 67.9567 58.8063 80.2374 58.8063H460.577C465.453 58.8063 469.968 60.4317 473.76 62.96C479.359 67.1138 483.151 73.7959 483.151 81.2004Z"
        :fill="myColors.engineering.radioBox.meshBackground"
        stroke="#424242"
        stroke-miterlimit="10"
      />
      <path
        d="M483.151 81.2006V235.792C483.151 248.254 473.038 258.187 460.757 258.187H80.2373C72.4716 258.187 65.6089 254.213 61.6357 248.254C61.9969 248.254 62.1775 248.254 62.5387 248.254H459.854C467.62 248.254 473.941 241.933 473.941 234.167V62.9602C479.359 67.114 483.151 73.7961 483.151 81.2006Z"
        :fill="myColors.engineering.radioBox.rimOutline"
      />
      <path
        d="M143.266 137.005C143.266 138.089 142.182 138.992 141.099 138.992C140.015 138.992 139.112 137.908 139.293 136.825C139.293 135.741 140.196 135.019 141.279 135.019C142.363 135.019 143.266 135.922 143.266 137.005Z"
        fill="#F37140"
      />
      <path
        d="M461.84 128.878C461.84 130.142 460.937 130.865 459.854 130.865C458.77 130.865 457.867 129.962 457.867 128.878C457.867 127.795 458.77 126.711 459.854 126.711C460.937 126.711 461.84 127.614 461.84 128.878Z"
        fill="#F37140"
      />
      <path
        d="M356.733 138.992C355.649 138.992 354.746 138.089 354.746 137.005C354.746 135.922 355.468 135.019 356.552 135.019C357.636 135.019 358.719 135.741 358.719 136.825C358.719 137.908 357.816 138.992 356.733 138.992Z"
        fill="#F37140"
      />
      <path
        d="M242.414 128.878C242.414 127.795 243.317 126.711 244.401 126.711C245.484 126.711 246.387 127.614 246.387 128.878C246.387 129.962 245.665 130.865 244.581 130.865C243.498 130.865 242.414 129.962 242.414 128.878Z"
        fill="#F37140"
      />
      <path
        d="M244.401 159.219C245.484 159.219 246.387 160.122 246.387 161.206C246.387 162.289 245.665 163.192 244.581 163.192C243.317 163.192 242.414 162.47 242.414 161.386C242.414 160.483 243.317 159.219 244.401 159.219Z"
        fill="#F37140"
      />
      <path
        d="M459.854 163.372C458.59 163.372 457.687 162.469 457.687 161.386C457.687 160.302 458.771 159.219 459.854 159.399C460.938 159.399 461.66 160.302 461.841 161.386C461.841 162.469 461.118 163.372 459.854 163.372Z"
        fill="#F37140"
      />
      <path
        d="M143.266 169.332C143.266 170.416 142.182 171.499 141.279 171.499C140.196 171.499 139.293 170.777 139.293 169.693C139.112 168.429 140.015 167.526 141.099 167.526C142.182 167.346 143.266 168.249 143.266 169.332Z"
        fill="#F37140"
      />
      <path
        d="M356.552 171.5C355.468 171.5 354.565 170.597 354.565 169.333C354.565 168.249 355.288 167.346 356.371 167.346C357.455 167.346 358.539 168.249 358.539 169.333C358.719 170.416 357.636 171.5 356.552 171.5Z"
        fill="#F37140"
      />
      <path
        d="M356.552 106.484C355.468 106.484 354.565 105.581 354.565 104.317C354.565 103.234 355.288 102.331 356.371 102.331C357.455 102.331 358.539 103.234 358.539 104.317C358.719 105.401 357.636 106.484 356.552 106.484Z"
        fill="#F37140"
      />
      <path
        d="M246.387 63.8633C246.387 65.1275 245.665 65.8499 244.401 65.8499C243.317 65.8499 242.414 64.9469 242.414 64.0439C242.414 62.9603 243.317 61.8768 244.401 61.8768C245.484 61.6962 246.387 62.5991 246.387 63.8633Z"
        fill="#F37140"
      />
      <path
        d="M143.266 104.498C143.266 105.581 142.182 106.484 141.099 106.484C140.015 106.484 139.112 105.581 139.112 104.317C139.112 103.234 140.015 102.511 141.099 102.511C142.363 102.511 143.266 103.414 143.266 104.498Z"
        fill="#F37140"
      />
      <path
        d="M461.84 96.3708C461.84 97.6349 460.937 98.3573 459.854 98.3573C458.77 98.3573 457.867 97.4543 457.867 96.3708C457.867 95.2872 458.77 94.2036 459.854 94.2036C460.937 94.2036 461.84 95.1066 461.84 96.3708Z"
        fill="#F37140"
      />
      <path
        d="M244.401 195.881C243.317 195.881 242.414 194.797 242.414 193.713C242.414 192.81 243.498 191.727 244.401 191.727C245.484 191.727 246.387 192.63 246.387 193.894C246.387 195.158 245.665 195.881 244.401 195.881Z"
        fill="#F37140"
      />
      <path
        d="M246.387 96.3708C246.387 97.6349 245.484 98.3573 244.22 98.3573C243.137 98.3573 242.234 97.4543 242.234 96.3708C242.234 95.2872 243.317 94.2036 244.22 94.2036C245.484 94.2036 246.387 95.2872 246.387 96.3708Z"
        fill="#F37140"
      />
      <path
        d="M461.84 193.894C461.84 195.158 461.118 195.88 459.854 195.88C458.77 195.88 457.867 194.977 457.867 194.074C457.867 192.991 458.77 191.907 459.854 191.907C460.937 191.727 461.84 192.63 461.84 193.894Z"
        fill="#F37140"
      />
      <path
        d="M141.099 200.034C142.182 200.034 143.266 200.937 143.266 202.02C143.266 203.104 142.182 204.007 141.099 204.007C140.015 204.007 139.112 202.923 139.293 201.84C139.293 200.756 140.015 200.034 141.099 200.034Z"
        fill="#F37140"
      />
      <path
        d="M358.719 202.02C358.719 203.104 357.636 204.007 356.552 204.007C355.468 204.007 354.565 203.104 354.565 201.84C354.565 200.756 355.468 200.034 356.552 200.034C357.636 200.034 358.719 200.937 358.719 202.02Z"
        fill="#F37140"
      />
      <path
        d="M244.401 228.388C243.317 228.388 242.414 227.485 242.414 226.221C242.414 225.318 243.498 224.234 244.401 224.234C245.484 224.234 246.387 225.137 246.387 226.401C246.387 227.665 245.665 228.388 244.401 228.388Z"
        fill="#F37140"
      />
      <path
        d="M459.854 224.234C460.937 224.234 461.84 225.318 461.84 226.401C461.84 227.665 460.937 228.388 459.673 228.207C458.59 228.207 457.867 227.304 457.867 226.221C457.867 225.318 458.951 224.234 459.854 224.234Z"
        fill="#F37140"
      />
      <path
        d="M141.099 232.542C142.182 232.542 143.266 233.445 143.266 234.528C143.266 235.612 142.182 236.515 141.099 236.515C140.015 236.515 139.112 235.431 139.293 234.348C139.293 233.264 140.015 232.542 141.099 232.542Z"
        fill="#F37140"
      />
      <path
        d="M356.733 73.9768C355.649 73.9768 354.746 73.0738 354.746 71.9902C354.746 70.9066 355.468 70.0037 356.552 70.0037C357.636 70.0037 358.719 70.726 358.719 71.8096C358.719 72.8932 357.816 73.9768 356.733 73.9768Z"
        fill="#F37140"
      />
      <path
        d="M461.84 63.8631C461.84 65.1272 460.937 65.8496 459.854 65.8496C458.77 65.8496 457.867 64.9466 457.867 63.8631C457.867 62.7795 458.77 61.6959 459.854 61.6959C460.937 61.6959 461.84 62.5989 461.84 63.8631Z"
        fill="#F37140"
      />
      <path
        d="M143.266 71.8097C143.266 72.8932 142.363 73.9768 141.279 73.9768C140.196 73.9768 139.293 73.2544 139.293 72.1709C139.112 70.9067 140.015 70.0037 141.099 70.0037C142.182 69.8231 143.266 70.7261 143.266 71.8097Z"
        fill="#F37140"
      />
      <path
        d="M356.552 232.542C357.636 232.542 358.719 233.445 358.719 234.528C358.719 235.612 357.816 236.695 356.733 236.695C355.649 236.695 354.746 235.973 354.746 234.889C354.566 233.445 355.288 232.542 356.552 232.542Z"
        fill="#F37140"
      />
      <path
        d="M71.3881 136.824C71.3881 138.088 70.6657 138.991 69.4015 138.991C68.3179 138.991 67.2344 137.908 67.2344 137.005C67.2344 135.921 68.1373 135.018 69.2209 135.018C70.4851 134.837 71.3881 135.74 71.3881 136.824Z"
        fill="#F37140"
      />
      <path
        d="M273.658 233.264C274.741 233.264 275.825 234.167 275.825 235.251C275.825 236.334 274.741 237.237 273.658 237.237C272.574 237.237 271.852 236.515 271.671 235.431C271.671 234.348 272.574 233.445 273.658 233.264Z"
        fill="#F37140"
      />
      <path
        d="M120.691 137.004C120.691 138.269 119.788 138.991 118.523 138.991C117.44 138.991 116.537 137.727 116.537 136.824C116.717 135.74 117.62 135.018 118.704 135.018C119.968 135.018 120.871 135.74 120.691 137.004Z"
        fill="#F37140"
      />
      <path
        d="M166.021 233.264C167.105 233.264 168.008 234.167 168.008 235.431C168.008 236.515 167.105 237.237 166.021 237.418C164.938 237.418 163.854 236.515 163.854 235.431C164.035 234.348 164.938 233.264 166.021 233.264Z"
        fill="#F37140"
      />
      <path
        d="M291.717 66.7529C290.453 66.7529 289.731 65.8499 289.731 64.7663C289.731 63.8634 290.815 62.7798 291.717 62.7798C292.801 62.7798 293.704 63.6828 293.704 64.7663C293.704 66.0305 292.982 66.7529 291.717 66.7529Z"
        fill="#F37140"
      />
      <path
        d="M327.476 66.7529C326.392 66.7529 325.489 66.0305 325.489 64.9469C325.489 63.8634 326.392 62.7798 327.476 62.7798C328.559 62.7798 329.462 63.8634 329.462 64.9469C329.643 65.8499 328.74 66.7529 327.476 66.7529Z"
        fill="#F37140"
      />
      <path
        d="M466.175 234.347C466.175 235.612 465.272 236.515 464.188 236.515C463.105 236.515 462.021 235.431 462.202 234.347C462.202 233.444 463.105 232.541 464.188 232.361C465.452 232.361 466.175 233.264 466.175 234.347Z"
        fill="#F37140"
      />
      <path
        d="M439.988 234.528C439.988 233.264 440.711 232.542 441.975 232.542C443.058 232.542 443.961 233.445 443.961 234.528C443.961 235.612 443.058 236.695 441.975 236.695C440.891 236.515 439.988 235.612 439.988 234.528Z"
        fill="#F37140"
      />
      <path
        d="M327.656 131.768C326.392 131.768 325.67 131.045 325.67 129.781C325.67 128.878 326.573 127.795 327.476 127.614C328.559 127.614 329.643 128.517 329.643 129.601C329.643 130.865 328.92 131.587 327.656 131.768Z"
        fill="#F37140"
      />
      <path
        d="M390.504 234.348C390.504 233.084 391.407 232.361 392.672 232.542C393.575 232.542 394.478 233.625 394.478 234.528C394.478 235.612 393.394 236.515 392.31 236.515C391.227 236.515 390.504 235.612 390.504 234.348Z"
        fill="#F37140"
      />
      <path
        d="M291.717 131.768C290.453 131.768 289.55 130.865 289.731 129.781C289.731 128.698 290.814 127.614 291.898 127.795C292.801 127.795 293.704 128.698 293.884 129.781C293.704 130.865 292.981 131.587 291.717 131.768Z"
        fill="#F37140"
      />
      <path
        d="M69.4015 73.9768C68.3179 73.9768 67.2344 73.0738 67.2344 71.9902C67.2344 70.9066 68.1373 70.0037 69.2209 70.0037C70.3045 70.0037 71.2075 70.726 71.2075 71.8096C71.3881 72.8932 70.6657 73.9768 69.4015 73.9768Z"
        fill="#F37140"
      />
      <path
        d="M112.022 66.7529C110.938 66.7529 110.216 66.0306 110.216 64.7664C110.216 63.6828 111.299 62.5992 112.202 62.5992C113.286 62.5992 114.189 63.6828 114.189 64.7664C114.189 66.0306 113.286 66.7529 112.022 66.7529Z"
        fill="#F37140"
      />
      <path
        d="M175.232 136.825C175.232 135.561 176.135 134.838 177.218 134.838C178.121 134.838 179.205 135.922 179.205 136.825C179.205 137.908 178.121 138.992 177.038 138.811C175.954 138.992 175.051 138.089 175.232 136.825Z"
        fill="#F37140"
      />
      <path
        d="M120.691 71.9902C120.691 73.2544 119.788 73.9768 118.523 73.9768C117.44 73.9768 116.537 72.7126 116.537 71.8096C116.717 70.726 117.62 70.0037 118.704 70.0037C119.968 70.0037 120.871 70.726 120.691 71.9902Z"
        fill="#F37140"
      />
      <path
        d="M76.264 66.7528C74.9998 66.7528 74.0968 65.8498 74.2774 64.7663C74.2774 63.6827 75.361 62.5991 76.4446 62.7797C77.3475 62.7797 78.2505 63.8633 78.4311 64.7663C78.2505 65.8498 77.5281 66.7528 76.264 66.7528Z"
        fill="#F37140"
      />
      <path
        d="M112.022 131.767C110.938 131.767 110.216 131.045 110.035 129.961C110.035 128.878 111.119 127.794 112.022 127.794C113.105 127.794 114.008 128.878 114.008 129.961C114.189 130.864 113.286 131.767 112.022 131.767Z"
        fill="#F37140"
      />
      <path
        d="M175.232 71.9902C175.232 70.726 175.954 70.0037 177.218 70.0037C178.302 70.0037 179.386 71.0872 179.205 71.9902C179.205 72.8932 178.302 73.9768 177.218 73.9768C176.135 73.9768 175.232 73.2544 175.232 71.9902Z"
        fill="#F37140"
      />
      <path
        d="M76.2639 131.767C74.9997 131.767 74.2773 130.864 74.2773 129.781C74.2773 128.878 75.3609 127.794 76.2639 127.794C77.3475 127.794 78.2504 128.697 78.2504 129.961C78.2504 131.045 77.5281 131.767 76.2639 131.767Z"
        fill="#F37140"
      />
      <path
        d="M336.144 234.348C336.144 235.612 335.422 236.515 334.158 236.515C333.074 236.515 331.991 235.612 331.991 234.528C331.991 233.625 332.894 232.542 333.797 232.542C335.241 232.361 336.144 233.084 336.144 234.348Z"
        fill="#F37140"
      />
      <path
        d="M224.535 71.8094C224.535 70.5453 225.438 69.8229 226.521 69.8229C227.605 69.8229 228.508 70.7259 228.508 71.8094C228.508 72.893 227.424 73.9766 226.341 73.9766C225.438 73.9766 224.535 73.0736 224.535 71.8094Z"
        fill="#F37140"
      />
      <path
        d="M250.722 71.8094C250.722 73.0736 249.999 73.9766 248.735 73.9766C247.652 73.9766 246.568 72.893 246.568 71.8094C246.568 70.9065 247.471 69.8229 248.555 69.8229C249.999 69.8229 250.722 70.5453 250.722 71.8094Z"
        fill="#F37140"
      />
      <path
        d="M448.476 128.879C448.476 129.962 447.573 130.865 446.309 130.865C445.225 130.865 444.323 129.782 444.503 128.698C444.684 127.615 445.587 126.892 446.49 126.892C447.393 126.712 448.476 127.795 448.476 128.879Z"
        fill="#F37140"
      />
      <path
        d="M286.841 71.9902C286.841 73.2544 285.938 73.9768 284.674 73.9768C283.771 73.9768 282.688 72.8932 282.688 71.9902C282.688 70.9066 283.59 70.0037 284.674 70.0037C285.938 70.0037 286.841 70.726 286.841 71.9902Z"
        fill="#F37140"
      />
      <path
        d="M284.855 232.541C286.119 232.541 286.841 233.444 286.841 234.709C286.841 235.792 285.938 236.695 284.855 236.695C283.771 236.695 282.688 235.612 282.688 234.709C282.687 233.444 283.771 232.361 284.855 232.541Z"
        fill="#F37140"
      />
      <path
        d="M226.522 135.019C227.605 135.019 228.689 136.103 228.508 137.186C228.328 138.27 227.244 139.173 226.16 139.173C225.077 138.992 224.354 138.27 224.354 137.186C224.535 135.741 225.438 134.839 226.522 135.019Z"
        fill="#F37140"
      />
      <path
        d="M334.338 70.0037C335.603 70.0037 336.325 70.9067 336.144 72.1709C335.964 73.2544 335.241 73.9768 334.158 73.9768C333.074 73.9768 331.991 72.8932 332.171 71.8097C332.171 70.7261 333.255 69.8231 334.338 70.0037Z"
        fill="#F37140"
      />
      <path
        d="M448.476 63.8631C448.476 64.9466 447.573 65.8496 446.309 65.8496C445.045 65.8496 444.323 64.9467 444.323 63.6825C444.323 62.5989 445.225 61.6959 446.309 61.6959C447.393 61.6959 448.476 62.7795 448.476 63.8631Z"
        fill="#F37140"
      />
      <path
        d="M410.551 126.711C411.635 126.711 412.537 127.795 412.537 128.878C412.537 129.962 411.635 130.684 410.551 130.684C409.287 130.684 408.384 129.781 408.384 128.698C408.384 127.795 409.467 126.711 410.551 126.711Z"
        fill="#F37140"
      />
      <path
        d="M390.504 71.8094C390.504 70.5453 391.407 69.8229 392.672 69.8229C393.575 69.8229 394.658 70.9065 394.658 71.8094C394.658 72.893 393.575 73.9766 392.491 73.796C391.407 73.9766 390.504 73.0736 390.504 71.8094Z"
        fill="#F37140"
      />
      <path
        d="M250.722 136.825C250.722 138.089 249.999 138.992 248.916 138.992C247.832 138.992 246.749 137.908 246.749 137.005C246.749 136.102 247.651 135.019 248.735 135.019C249.999 134.838 250.722 135.561 250.722 136.825Z"
        fill="#F37140"
      />
      <path
        d="M250.722 234.528C250.722 235.792 249.819 236.515 248.555 236.515C247.652 236.515 246.749 235.431 246.568 234.528C246.568 233.445 247.652 232.542 248.735 232.542C250.18 232.542 250.902 233.264 250.722 234.528Z"
        fill="#F37140"
      />
      <path
        d="M439.988 71.8094C439.988 70.5453 440.891 69.8229 441.975 69.8229C443.058 69.8229 443.961 70.7259 443.961 71.8094C443.961 72.893 442.878 73.9766 441.975 73.9766C440.891 73.9766 439.988 73.0736 439.988 71.8094Z"
        fill="#F37140"
      />
      <path
        d="M466.175 71.8094C466.175 73.0736 465.272 73.9766 464.188 73.9766C463.105 73.9766 462.021 72.893 462.202 71.8094C462.202 70.9065 463.105 70.0035 464.188 69.8229C465.452 69.8229 466.175 70.7259 466.175 71.8094Z"
        fill="#F37140"
      />
      <path
        d="M224.535 234.528C224.535 233.264 225.257 232.542 226.521 232.542C227.605 232.542 228.508 233.445 228.508 234.348C228.508 235.431 227.605 236.515 226.521 236.515C225.438 236.515 224.535 235.612 224.535 234.528Z"
        fill="#F37140"
      />
      <path
        d="M286.841 137.005C286.841 138.269 285.938 138.992 284.674 138.992C283.771 138.992 282.688 137.908 282.688 137.005C282.688 135.922 283.59 135.019 284.674 135.019C285.938 135.019 286.841 135.741 286.841 137.005Z"
        fill="#F37140"
      />
      <path
        d="M166.021 74.8798C164.938 74.8798 163.854 73.9768 164.035 72.8933C164.035 71.8097 165.118 70.9067 166.202 70.9067C167.285 70.9067 168.008 71.8097 168.008 72.8933C168.008 73.9768 167.285 74.8798 166.021 74.8798Z"
        fill="#F37140"
      />
      <path
        d="M177.218 232.542C178.302 232.542 179.205 233.625 179.205 234.709C179.205 235.792 177.941 236.695 177.038 236.695C175.954 236.515 175.232 235.792 175.232 234.709C175.232 233.264 175.954 232.361 177.218 232.542Z"
        fill="#F37140"
      />
      <path
        d="M336.144 136.825C336.144 138.089 335.422 138.992 334.158 138.992C333.074 138.992 331.991 137.908 331.991 137.005C331.991 135.922 332.894 135.019 334.158 135.019C335.422 135.019 336.144 135.741 336.144 136.825Z"
        fill="#F37140"
      />
      <path
        d="M273.658 70.7258C274.741 70.7258 275.825 71.6288 275.825 72.7124C275.825 73.796 274.741 74.6989 273.658 74.6989C272.574 74.6989 271.852 73.9765 271.671 72.893C271.671 71.8094 272.574 70.9064 273.658 70.7258Z"
        fill="#F37140"
      />
      <path
        d="M291.717 245.545C290.634 245.545 289.731 244.461 289.731 243.378C289.731 242.294 290.634 241.391 291.898 241.391C292.982 241.391 293.704 242.114 293.704 243.378C293.704 244.642 292.801 245.545 291.717 245.545Z"
        fill="#F37140"
      />
      <path
        d="M233.023 128.878C233.023 129.962 232.12 130.865 230.856 130.865C229.772 130.865 229.05 130.142 228.869 128.878C228.869 127.795 229.772 126.711 230.856 126.711C231.94 126.711 233.023 127.795 233.023 128.878Z"
        fill="#F37140"
      />
      <path
        d="M390.504 137.005C390.504 135.741 391.227 135.019 392.491 135.019C393.394 135.019 394.478 135.922 394.478 137.005C394.478 138.089 393.575 139.172 392.491 139.172C391.407 138.992 390.504 138.089 390.504 137.005Z"
        fill="#F37140"
      />
      <path
        d="M120.691 234.348C120.691 235.612 119.969 236.515 118.705 236.515C117.621 236.515 116.538 235.431 116.538 234.528C116.538 233.445 117.441 232.542 118.705 232.542C119.969 232.542 120.691 233.264 120.691 234.348Z"
        fill="#F37140"
      />
      <path
        d="M195.097 130.865C193.833 130.865 192.93 129.962 192.93 128.879C192.93 127.795 194.014 126.712 195.097 126.892C196.181 126.892 196.903 127.795 197.084 128.879C197.084 129.962 196.181 130.865 195.097 130.865Z"
        fill="#F37140"
      />
      <path
        d="M64.8864 78.1302C66.1505 78.1302 66.8729 79.0332 66.8729 80.1168C66.8729 81.0198 65.97 82.1033 64.8864 82.1033C63.8028 82.1033 62.7192 81.2004 62.7192 80.1168C62.8998 78.8526 63.6222 78.1302 64.8864 78.1302Z"
        fill="#F37140"
      />
      <path
        d="M439.988 136.825C439.988 135.561 440.891 134.838 442.155 135.019C443.239 135.2 444.142 136.102 443.961 137.005C443.961 138.089 442.878 138.992 441.794 138.992C440.711 138.992 439.988 138.089 439.988 136.825Z"
        fill="#F37140"
      />
      <path
        d="M69.2209 232.542C70.4851 232.542 71.2075 233.264 71.2075 234.528C71.2075 235.612 70.4851 236.515 69.4015 236.515C68.3179 236.515 67.2344 235.612 67.2344 234.528C67.2344 233.445 68.1373 232.542 69.2209 232.542Z"
        fill="#F37140"
      />
      <path
        d="M327.476 229.291C326.392 229.291 325.489 228.568 325.489 227.485C325.489 226.401 326.392 225.318 327.476 225.318C328.559 225.318 329.462 226.401 329.462 227.485C329.643 228.388 328.74 229.291 327.476 229.291Z"
        fill="#F37140"
      />
      <path
        d="M291.717 229.291C290.453 229.291 289.55 228.388 289.731 227.304C289.731 226.221 290.814 225.137 291.898 225.318C292.801 225.318 293.704 226.401 293.704 227.304C293.704 228.388 292.981 229.291 291.717 229.291Z"
        fill="#F37140"
      />
      <path
        d="M112.203 229.291C111.12 229.291 110.217 228.568 110.217 227.485C110.217 226.401 111.12 225.318 112.203 225.318C113.287 225.318 114.37 226.401 114.37 227.485C114.19 228.388 113.287 229.291 112.203 229.291Z"
        fill="#F37140"
      />
      <path
        d="M76.0835 229.291C74.8193 229.291 74.0969 228.388 74.0969 227.124C74.0969 226.221 75.1805 225.137 76.0835 225.137C77.167 225.137 78.07 226.221 78.07 227.304C78.2506 228.568 77.5282 229.291 76.0835 229.291Z"
        fill="#F37140"
      />
      <path
        d="M194.917 78.1305C196.181 78.1305 196.904 78.8529 196.904 80.117C196.904 81.2006 195.82 82.1036 194.737 82.1036C193.834 82.1036 192.75 81.2006 192.75 80.117C192.931 79.0335 193.834 78.1305 194.917 78.1305Z"
        fill="#F37140"
      />
      <path
        d="M410.551 65.85C409.287 65.85 408.384 64.947 408.384 63.8634C408.384 62.7799 409.467 61.6963 410.551 61.8769C411.635 61.8769 412.357 62.7799 412.537 63.8634C412.537 64.947 411.635 65.85 410.551 65.85Z"
        fill="#F37140"
      />
      <path
        d="M231.036 78.1305C232.301 78.1305 233.023 79.0335 233.023 80.117C233.023 81.02 231.939 82.1036 231.036 82.1036C229.953 82.1036 229.05 81.2006 229.05 80.117C228.869 78.8529 229.772 78.1305 231.036 78.1305Z"
        fill="#F37140"
      />
      <path
        d="M466.175 137.005C466.175 138.27 465.272 139.173 464.188 138.992C463.105 138.992 462.021 137.908 462.202 136.825C462.202 135.922 463.285 135.019 464.188 134.838C465.452 134.838 466.175 135.741 466.175 137.005Z"
        fill="#F37140"
      />
      <path
        d="M446.309 224.234C447.393 224.234 448.476 225.318 448.476 226.221C448.476 227.304 447.573 228.207 446.309 228.207C445.225 228.207 444.503 227.485 444.323 226.401C444.323 225.318 445.225 224.234 446.309 224.234Z"
        fill="#F37140"
      />
      <path
        d="M280.34 78.1305C281.604 78.1305 282.326 78.8529 282.326 80.117C282.326 81.2006 281.423 82.1036 280.34 82.1036C279.256 82.1036 278.173 81.2006 278.173 80.117C278.353 78.8529 279.076 78.1305 280.34 78.1305Z"
        fill="#F37140"
      />
      <path
        d="M410.551 228.388C409.287 228.388 408.384 227.485 408.384 226.401C408.384 225.318 409.467 224.234 410.551 224.415C411.635 224.415 412.357 225.318 412.537 226.401C412.537 227.485 411.635 228.388 410.551 228.388Z"
        fill="#F37140"
      />
      <path
        d="M168.008 137.728C168.008 138.992 167.285 139.714 166.021 139.714C164.938 139.714 164.035 138.811 164.035 137.908C163.854 136.825 164.938 135.741 166.021 135.741C167.105 135.741 168.008 136.644 168.008 137.728Z"
        fill="#F37140"
      />
      <path
        d="M271.852 137.908C271.852 136.644 272.574 135.741 273.838 135.741C274.922 135.741 276.006 136.825 276.006 137.908C276.006 138.992 274.922 139.895 273.838 139.895C272.574 139.895 271.852 139.172 271.852 137.908Z"
        fill="#F37140"
      />
      <path
        d="M67.0536 242.655C67.0536 243.739 65.97 244.642 64.8865 244.642C63.8029 244.642 62.8999 243.558 63.0805 242.475C63.0805 241.391 63.9835 240.669 65.0671 240.669C66.1506 240.669 67.0536 241.572 67.0536 242.655Z"
        fill="#F37140"
      />
      <path
        d="M233.023 226.401C233.023 227.485 232.12 228.388 230.856 228.207C229.772 228.207 229.05 227.304 229.05 226.221C229.05 225.137 229.953 224.054 231.037 224.054C231.94 224.234 233.023 225.318 233.023 226.401Z"
        fill="#F37140"
      />
      <path
        d="M197.084 226.401C197.084 227.665 196.181 228.388 194.917 228.388C193.833 228.388 192.93 227.485 192.93 226.401C192.93 225.318 194.014 224.234 194.917 224.234C196.181 224.234 197.084 225.318 197.084 226.401Z"
        fill="#F37140"
      />
      <path
        d="M410.551 78.1305C411.815 78.1305 412.537 79.0335 412.537 80.117C412.537 81.2006 411.634 81.923 410.551 82.1036C409.467 82.1036 408.384 81.02 408.564 80.117C408.384 78.8529 409.287 78.1305 410.551 78.1305Z"
        fill="#F37140"
      />
      <path
        d="M67.0537 144.952C67.0537 146.035 65.9701 147.119 65.0671 147.119C63.9835 147.119 63.0806 146.035 63.0806 144.952C63.0806 143.868 63.803 143.146 65.0671 143.146C66.1507 143.146 66.8731 143.868 67.0537 144.952Z"
        fill="#F37140"
      />
      <path
        d="M446.309 78.1305C447.573 78.1305 448.296 79.0335 448.296 80.117C448.296 81.02 447.393 82.1036 446.309 82.1036C445.226 82.1036 444.323 81.2006 444.142 80.117C444.323 78.8529 445.045 78.1305 446.309 78.1305Z"
        fill="#F37140"
      />
      <path
        d="M194.917 143.146C196.181 143.146 196.904 143.868 196.904 145.132C196.904 146.216 195.82 147.119 194.737 147.119C193.834 147.119 192.75 146.216 192.75 145.132C192.931 144.049 193.834 143.146 194.917 143.146Z"
        fill="#F37140"
      />
      <path
        d="M76.4445 83.0065C75.3609 83.0065 74.2773 82.1036 74.2773 81.02C74.2773 79.9364 75.1803 79.0334 76.2639 79.0334C77.3475 79.0334 78.2504 79.7558 78.2504 80.8394C78.2504 81.923 77.5281 83.0065 76.4445 83.0065Z"
        fill="#F37140"
      />
      <path
        d="M233.023 145.132C233.023 146.216 231.94 147.299 231.037 147.119C229.953 147.119 229.05 146.035 229.05 144.952C229.05 143.868 229.772 143.146 231.037 143.146C232.12 143.146 233.023 143.868 233.023 145.132Z"
        fill="#F37140"
      />
      <path
        d="M282.326 145.132C282.326 146.216 281.243 147.299 280.34 147.119C279.256 147.119 278.353 146.035 278.353 144.952C278.353 143.868 279.075 143.146 280.34 143.146C281.604 143.146 282.326 143.868 282.326 145.132Z"
        fill="#F37140"
      />
      <path
        d="M410.551 143.146C411.635 143.146 412.357 143.868 412.357 145.132C412.357 146.216 411.454 147.299 410.37 147.119C409.287 147.119 408.384 146.035 408.384 144.952C408.384 143.868 409.467 143.146 410.551 143.146Z"
        fill="#F37140"
      />
      <path
        d="M446.309 143.146C447.573 143.146 448.476 143.868 448.476 145.132C448.476 146.216 447.393 147.299 446.49 147.119C445.406 147.119 444.503 146.216 444.503 145.132C444.322 144.049 445.045 143.146 446.309 143.146Z"
        fill="#F37140"
      />
      <path
        d="M78.2504 146.036C78.2504 147.119 77.3475 148.203 76.2639 148.203C75.1803 148.203 74.2773 147.119 74.2773 146.036C74.2773 144.952 75.1803 144.23 76.2639 144.23C77.5281 144.049 78.2504 144.771 78.2504 146.036Z"
        fill="#F37140"
      />
      <path
        d="M293.704 80.8396C293.704 81.9232 292.801 83.0068 291.717 83.0068C290.634 83.0068 289.55 81.9232 289.55 81.0202C289.55 79.9366 290.273 79.0337 291.356 79.0337C292.801 78.8531 293.704 79.5755 293.704 80.8396Z"
        fill="#F37140"
      />
      <path
        d="M293.704 145.855C293.704 146.938 292.801 148.022 291.717 148.022C290.634 148.022 289.55 146.938 289.55 146.035C289.55 144.952 290.273 144.049 291.356 144.049C292.801 143.868 293.704 144.591 293.704 145.855Z"
        fill="#F37140"
      />
      <path
        d="M195.097 159.219C196.181 159.219 197.084 160.303 197.084 161.386C197.084 162.47 196.181 163.192 195.097 163.192C193.833 163.192 192.93 162.289 192.93 161.206C192.93 160.303 194.014 159.219 195.097 159.219Z"
        fill="#F37140"
      />
      <path
        d="M233.023 161.386C233.023 162.47 232.12 163.373 230.856 163.373C229.772 163.373 229.05 162.47 229.05 161.386C229.05 160.303 229.953 159.219 231.037 159.219C231.94 159.219 233.023 160.303 233.023 161.386Z"
        fill="#F37140"
      />
      <path
        d="M291.717 209.064C292.982 209.064 293.704 209.967 293.523 211.231C293.523 212.315 292.44 213.218 291.537 213.218C290.453 213.218 289.55 212.134 289.55 211.05C289.731 209.786 290.634 209.064 291.717 209.064Z"
        fill="#F37140"
      />
      <path
        d="M78.2504 243.558C78.2504 244.642 77.3475 245.725 76.2639 245.725C75.1803 245.725 74.2773 244.642 74.2773 243.558C74.2773 242.475 75.1803 241.752 76.2639 241.752C77.5281 241.572 78.2504 242.294 78.2504 243.558Z"
        fill="#F37140"
      />
      <path
        d="M76.2642 209.064C77.5284 209.064 78.2508 209.786 78.2508 211.05C78.2508 212.134 77.3478 213.037 76.4448 213.037C75.3612 213.037 74.2776 212.134 74.2776 211.05C74.0971 209.967 75 209.064 76.2642 209.064Z"
        fill="#F37140"
      />
      <path
        d="M410.37 163.373C409.106 163.373 408.384 162.47 408.384 161.206C408.384 160.122 409.648 159.219 410.551 159.219C411.635 159.4 412.357 160.303 412.357 161.206C412.357 162.47 411.634 163.373 410.37 163.373Z"
        fill="#F37140"
      />
      <path
        d="M448.476 161.386C448.476 162.469 447.573 163.372 446.309 163.372C445.225 163.372 444.503 162.65 444.323 161.566C444.323 160.483 445.225 159.399 446.309 159.399C447.393 159.219 448.476 160.302 448.476 161.386Z"
        fill="#F37140"
      />
      <path
        d="M446.309 208.161C447.573 208.161 448.476 209.064 448.296 210.147C448.296 211.05 447.393 212.134 446.309 212.134C445.226 212.134 444.142 211.231 444.142 210.147C444.323 208.883 445.045 208.161 446.309 208.161Z"
        fill="#F37140"
      />
      <path
        d="M410.551 208.161C411.815 208.161 412.537 209.064 412.537 210.328C412.537 211.412 411.634 212.134 410.551 212.315C409.467 212.315 408.384 211.231 408.564 210.328C408.384 208.883 409.287 208.161 410.551 208.161Z"
        fill="#F37140"
      />
      <path
        d="M280.34 208.161C281.604 208.161 282.507 208.883 282.507 210.147C282.507 211.231 281.423 212.315 280.34 212.134C279.256 212.134 278.353 211.231 278.353 210.147C278.353 209.064 279.075 208.161 280.34 208.161Z"
        fill="#F37140"
      />
      <path
        d="M76.2642 164.276C75 164.276 74.0971 163.373 74.2776 162.289C74.2776 161.205 75.3612 160.122 76.4448 160.302C77.3478 160.302 78.2507 161.205 78.4313 162.289C78.2507 163.373 77.5284 164.095 76.2642 164.276Z"
        fill="#F37140"
      />
      <path
        d="M230.856 208.161C232.12 208.161 233.023 208.883 233.023 210.147C233.023 211.231 231.939 212.315 231.036 212.134C229.953 212.134 229.05 211.231 229.05 210.147C228.869 209.064 229.592 208.161 230.856 208.161Z"
        fill="#F37140"
      />
      <path
        d="M195.097 208.161C196.362 208.161 197.084 209.064 197.084 210.328C197.084 211.412 196 212.134 195.097 212.315C194.014 212.315 193.111 211.231 193.111 210.147C192.93 208.883 193.833 208.161 195.097 208.161Z"
        fill="#F37140"
      />
      <path
        d="M64.8864 208.161C66.1506 208.161 67.0535 208.883 67.0535 210.147C67.0535 211.231 65.97 212.315 65.067 212.134C63.9834 212.134 63.0804 211.231 63.0804 210.147C62.8998 209.064 63.6222 208.161 64.8864 208.161Z"
        fill="#F37140"
      />
      <path
        d="M112.203 164.276C110.939 164.276 110.217 163.373 110.217 162.289C110.217 161.386 111.3 160.302 112.203 160.302C113.287 160.302 114.37 161.386 114.37 162.289C114.19 163.373 113.287 164.276 112.203 164.276Z"
        fill="#F37140"
      />
      <path
        d="M291.717 164.276C290.453 164.276 289.55 163.373 289.731 162.289C289.731 161.205 290.814 160.122 291.898 160.302C292.801 160.302 293.704 161.205 293.884 162.289C293.704 163.373 292.981 164.095 291.717 164.276Z"
        fill="#F37140"
      />
      <path
        d="M271.852 202.924C271.852 201.659 272.574 200.756 273.838 200.756C274.922 200.756 276.006 201.84 276.006 202.924C276.006 204.007 274.922 204.91 273.838 204.91C272.574 204.91 271.852 204.188 271.852 202.924Z"
        fill="#F37140"
      />
      <path
        d="M327.656 164.276C326.392 164.276 325.67 163.553 325.67 162.289C325.67 161.386 326.573 160.302 327.656 160.302C328.74 160.302 329.823 161.205 329.823 162.289C329.643 163.373 328.74 164.276 327.656 164.276Z"
        fill="#F37140"
      />
      <path
        d="M69.4014 171.5C68.3178 171.5 67.2343 170.416 67.4149 169.333C67.4149 168.249 68.4984 167.346 69.582 167.346C70.6656 167.346 71.388 168.249 71.388 169.333C71.388 170.597 70.485 171.5 69.4014 171.5Z"
        fill="#F37140"
      />
      <path
        d="M166.021 200.756C167.105 200.756 168.008 201.659 168.008 202.743C168.008 203.827 167.285 204.73 166.202 204.73C165.118 204.73 164.035 203.827 164.035 202.743C163.854 202.021 164.938 200.937 166.021 200.756Z"
        fill="#F37140"
      />
      <path
        d="M466.175 202.021C466.175 203.285 465.272 204.007 464.188 204.007C463.285 204.007 462.202 203.104 462.202 202.021C462.202 200.937 463.105 200.034 464.188 199.853C465.453 199.853 466.175 200.756 466.175 202.021Z"
        fill="#F37140"
      />
      <path
        d="M441.975 200.034C443.058 200.034 444.142 200.937 444.142 202.02C444.142 203.104 443.058 204.007 441.975 204.007C440.891 204.007 440.169 203.104 440.169 202.02C439.988 200.756 440.711 200.034 441.975 200.034Z"
        fill="#F37140"
      />
      <path
        d="M291.717 115.514C290.634 115.514 289.55 114.611 289.55 113.528C289.55 112.444 290.453 111.541 291.537 111.541C292.62 111.541 293.523 112.264 293.523 113.347C293.704 114.431 292.801 115.514 291.717 115.514Z"
        fill="#F37140"
      />
      <path
        d="M390.504 202.02C390.504 200.756 391.227 200.034 392.491 200.034C393.575 200.034 394.658 201.117 394.478 202.02C394.478 202.923 393.575 204.007 392.491 204.007C391.407 204.007 390.504 203.285 390.504 202.02Z"
        fill="#F37140"
      />
      <path
        d="M118.885 167.527C120.149 167.527 120.872 168.43 120.872 169.694C120.872 170.777 119.969 171.68 118.885 171.68C117.802 171.68 116.718 170.597 116.718 169.694C116.718 168.43 117.621 167.527 118.885 167.527Z"
        fill="#F37140"
      />
      <path
        d="M194.917 240.669C196.181 240.669 196.904 241.391 196.904 242.655C196.904 243.739 195.82 244.642 194.737 244.642C193.834 244.642 192.75 243.739 192.75 242.655C192.931 241.572 193.834 240.669 194.917 240.669Z"
        fill="#F37140"
      />
      <path
        d="M336.144 202.02C336.144 203.285 335.241 204.007 333.977 204.007C332.894 204.007 331.991 202.743 331.991 201.84C332.171 200.756 333.074 200.034 334.158 200.034C335.422 200.034 336.144 200.756 336.144 202.02Z"
        fill="#F37140"
      />
      <path
        d="M197.084 63.8631C197.084 65.1272 196.181 65.8496 194.917 65.8496C193.833 65.8496 192.93 64.9466 192.93 63.8631C192.93 62.7795 194.014 61.6959 194.917 61.6959C196.181 61.6959 197.084 62.7795 197.084 63.8631Z"
        fill="#F37140"
      />
      <path
        d="M284.855 204.007C283.771 204.007 282.688 203.104 282.688 202.02C282.688 200.937 283.59 200.034 284.674 200.034C285.758 200.034 286.661 200.756 286.661 201.84C286.841 202.923 285.938 204.007 284.855 204.007Z"
        fill="#F37140"
      />
      <path
        d="M250.722 201.84C250.722 203.104 249.999 204.007 248.735 204.007C247.652 204.007 246.568 202.924 246.568 202.021C246.568 201.118 247.471 200.034 248.555 200.034C249.999 199.853 250.722 200.576 250.722 201.84Z"
        fill="#F37140"
      />
      <path
        d="M224.535 201.84C224.535 200.576 225.438 199.853 226.702 200.034C227.786 200.034 228.689 201.118 228.689 202.021C228.689 203.104 227.605 204.188 226.521 204.007C225.257 204.007 224.535 203.104 224.535 201.84Z"
        fill="#F37140"
      />
      <path
        d="M177.038 167.527C178.121 167.527 179.205 168.43 179.205 169.513C179.205 170.597 178.121 171.5 177.038 171.5C175.954 171.5 175.232 170.597 175.232 169.513C175.051 168.249 175.774 167.527 177.038 167.527Z"
        fill="#F37140"
      />
      <path
        d="M78.2504 113.527C78.2504 114.611 77.3475 115.694 76.2639 115.694C75.1803 115.694 74.2773 114.611 74.2773 113.527C74.2773 112.444 75.1803 111.721 76.2639 111.721C77.5281 111.541 78.2504 112.263 78.2504 113.527Z"
        fill="#F37140"
      />
      <path
        d="M175.232 202.02C175.232 200.756 175.954 200.034 177.218 200.034C178.302 200.034 179.386 201.117 179.205 202.201C179.205 203.104 178.121 204.188 177.218 204.188C175.954 204.007 175.232 203.104 175.232 202.02Z"
        fill="#F37140"
      />
      <path
        d="M446.309 110.638C447.573 110.638 448.476 111.541 448.296 112.625C448.296 113.528 447.393 114.611 446.309 114.611C445.226 114.611 444.142 113.708 444.142 112.625C444.323 111.361 445.045 110.638 446.309 110.638Z"
        fill="#F37140"
      />
      <path
        d="M230.856 240.669C232.12 240.669 233.023 241.391 233.023 242.655C233.023 243.739 231.94 244.822 230.856 244.642C229.772 244.642 229.05 243.739 228.869 242.655C228.869 241.572 229.592 240.669 230.856 240.669Z"
        fill="#F37140"
      />
      <path
        d="M120.691 202.021C120.691 203.285 119.788 204.188 118.705 204.007C117.621 204.007 116.537 202.924 116.718 201.84C116.718 200.757 117.621 200.034 118.705 200.034C119.969 199.854 120.691 200.757 120.691 202.021Z"
        fill="#F37140"
      />
      <path
        d="M410.37 110.638C411.634 110.638 412.357 111.361 412.357 112.625C412.357 113.708 411.454 114.792 410.37 114.611C409.467 114.611 408.384 113.708 408.384 112.625C408.384 111.541 409.106 110.638 410.37 110.638Z"
        fill="#F37140"
      />
      <path
        d="M226.521 167.527C227.605 167.527 228.689 168.43 228.689 169.513C228.689 170.597 227.605 171.5 226.521 171.5C225.438 171.5 224.715 170.597 224.715 169.513C224.535 168.249 225.257 167.527 226.521 167.527Z"
        fill="#F37140"
      />
      <path
        d="M250.722 169.513C250.722 170.777 249.819 171.5 248.735 171.5C247.651 171.5 246.749 170.416 246.749 169.333C246.749 168.43 247.832 167.527 248.735 167.527C249.999 167.346 250.902 168.249 250.722 169.513Z"
        fill="#F37140"
      />
      <path
        d="M71.3879 202.021C71.3879 203.285 70.4849 204.007 69.4013 204.007C68.3178 204.007 67.4148 202.924 67.4148 201.84C67.4148 200.757 68.3178 200.034 69.4013 200.034C70.4849 199.854 71.3879 200.757 71.3879 202.021Z"
        fill="#F37140"
      />
      <path
        d="M284.855 167.527C286.119 167.527 286.841 168.43 286.841 169.513C286.841 170.597 285.938 171.5 285.035 171.5C283.952 171.5 282.868 170.597 282.868 169.513C282.688 168.43 283.591 167.527 284.855 167.527Z"
        fill="#F37140"
      />
      <path
        d="M327.476 196.783C326.392 196.783 325.489 196.061 325.489 194.977C325.489 193.894 326.392 192.81 327.476 192.81C328.559 192.81 329.643 193.894 329.643 194.977C329.643 195.88 328.74 196.783 327.476 196.783Z"
        fill="#F37140"
      />
      <path
        d="M291.717 196.783C290.453 196.783 289.731 195.88 289.731 194.797C289.731 193.894 290.815 192.81 291.717 192.81C292.801 192.81 293.704 193.713 293.704 194.797C293.704 196.061 292.982 196.783 291.717 196.783Z"
        fill="#F37140"
      />
      <path
        d="M112.203 196.783C110.939 196.783 110.217 196.061 110.217 194.796C110.217 193.893 111.12 192.81 112.023 192.629C113.106 192.449 114.19 193.532 114.19 194.616C114.37 195.88 113.467 196.602 112.203 196.783Z"
        fill="#F37140"
      />
      <path
        d="M76.2639 196.783C74.9997 196.783 74.2773 195.88 74.2773 194.797C74.2773 193.713 75.3609 192.63 76.4445 192.81C77.3475 192.81 78.2504 193.894 78.2504 194.797C78.2504 195.88 77.5281 196.783 76.2639 196.783Z"
        fill="#F37140"
      />
      <path
        d="M197.084 96.3708C197.084 97.6349 196.181 98.3573 194.917 98.3573C193.833 98.3573 192.93 97.4543 192.93 96.3708C192.93 95.2872 194.014 94.2036 194.917 94.2036C196.181 94.2036 197.084 95.2872 197.084 96.3708Z"
        fill="#F37140"
      />
      <path
        d="M334.338 171.5C333.255 171.5 332.171 170.597 332.171 169.513C332.171 168.43 333.074 167.527 334.338 167.527C335.422 167.527 336.325 168.249 336.325 169.333C336.325 170.416 335.422 171.5 334.338 171.5Z"
        fill="#F37140"
      />
      <path
        d="M230.856 98.3573C229.592 98.3573 228.869 97.4543 228.869 96.1902C228.869 95.1066 229.772 94.2036 230.856 94.2036C231.94 94.2036 233.023 95.2872 233.023 96.1902C233.023 97.4543 232.12 98.3573 230.856 98.3573Z"
        fill="#F37140"
      />
      <path
        d="M446.309 240.669C447.573 240.669 448.476 241.391 448.476 242.475C448.476 243.558 447.573 244.642 446.49 244.642C445.587 244.642 444.503 243.739 444.503 242.836C444.322 241.572 445.045 240.669 446.309 240.669Z"
        fill="#F37140"
      />
      <path
        d="M446.49 191.727C447.573 191.727 448.476 192.81 448.476 193.894C448.476 194.978 447.393 195.88 446.309 195.7C445.225 195.7 444.503 194.797 444.503 193.713C444.322 192.63 445.225 191.727 446.49 191.727Z"
        fill="#F37140"
      />
      <path
        d="M282.326 112.625C282.326 113.708 281.243 114.792 280.34 114.611C279.256 114.611 278.353 113.528 278.353 112.444C278.353 111.361 279.075 110.638 280.34 110.638C281.604 110.638 282.326 111.361 282.326 112.625Z"
        fill="#F37140"
      />
      <path
        d="M412.357 193.713C412.357 194.978 411.634 195.7 410.37 195.7C409.287 195.7 408.384 194.978 408.384 193.894C408.203 192.81 409.287 191.727 410.37 191.727C411.454 191.727 412.357 192.63 412.357 193.713Z"
        fill="#F37140"
      />
      <path
        d="M230.856 110.638C232.12 110.638 233.023 111.361 233.023 112.625C233.023 113.708 231.94 114.792 230.856 114.611C229.772 114.611 229.05 113.708 228.869 112.625C228.869 111.541 229.592 110.638 230.856 110.638Z"
        fill="#F37140"
      />
      <path
        d="M390.504 169.513C390.504 168.249 391.227 167.527 392.491 167.527C393.575 167.527 394.658 168.61 394.478 169.694C394.478 170.597 393.394 171.68 392.491 171.68C391.407 171.5 390.504 170.597 390.504 169.513Z"
        fill="#F37140"
      />
      <path
        d="M195.097 110.638C196.362 110.638 197.084 111.541 197.084 112.805C197.084 113.889 196 114.611 195.097 114.611C194.014 114.611 193.111 113.528 193.111 112.444C192.93 111.361 193.833 110.638 195.097 110.638Z"
        fill="#F37140"
      />
      <path
        d="M280.52 240.669C281.784 240.669 282.507 241.572 282.507 242.836C282.507 243.739 281.423 244.642 280.52 244.642C279.437 244.642 278.534 243.558 278.534 242.475C278.353 241.391 279.256 240.669 280.52 240.669Z"
        fill="#F37140"
      />
      <path
        d="M231.036 191.727C232.12 191.727 233.023 192.81 233.023 193.894C233.023 194.978 231.939 195.88 230.856 195.7C229.772 195.7 229.05 194.797 229.05 193.713C228.869 192.63 229.953 191.727 231.036 191.727Z"
        fill="#F37140"
      />
      <path
        d="M412.357 96.3708C412.357 97.6349 411.454 98.3573 410.19 98.3573C409.106 98.3573 408.203 97.4543 408.203 96.3708C408.203 95.2872 409.287 94.2036 410.19 94.2036C411.454 94.2036 412.537 95.2872 412.357 96.3708Z"
        fill="#F37140"
      />
      <path
        d="M197.084 193.894C197.084 195.158 196.181 195.88 195.097 195.88C194.014 195.88 193.111 194.977 193.111 194.074C193.111 192.991 194.014 191.907 195.097 191.907C196 191.727 197.084 192.63 197.084 193.894Z"
        fill="#F37140"
      />
      <path
        d="M446.309 94.2036C447.393 94.2036 448.476 95.2872 448.476 96.1902C448.476 97.2737 447.573 98.1767 446.309 98.1767C445.225 98.1767 444.503 97.4543 444.323 96.3708C444.323 95.2872 445.225 94.2036 446.309 94.2036Z"
        fill="#F37140"
      />
      <path
        d="M441.975 167.527C443.058 167.527 444.142 168.43 444.142 169.513C444.142 170.597 443.058 171.5 441.975 171.5C440.891 171.5 440.169 170.597 440.169 169.513C439.988 168.249 440.711 167.527 441.975 167.527Z"
        fill="#F37140"
      />
      <path
        d="M76.0833 99.26C74.9997 99.26 74.2773 98.357 74.2773 97.2735C74.2773 96.1899 75.3609 95.1063 76.4445 95.2869C77.5281 95.2869 78.431 96.3705 78.431 97.4541C78.2504 98.5376 77.3475 99.26 76.0833 99.26Z"
        fill="#F37140"
      />
      <path
        d="M112.202 99.2598C110.938 99.2598 110.035 98.5374 110.035 97.2732C110.035 96.1896 111.119 95.1061 112.022 95.1061C112.925 95.1061 114.008 96.0091 114.008 97.0926C114.369 98.1762 113.466 99.0792 112.202 99.2598Z"
        fill="#F37140"
      />
      <path
        d="M466.175 169.513C466.175 170.777 465.272 171.5 464.188 171.5C463.285 171.5 462.202 170.416 462.202 169.513C462.202 168.43 463.105 167.527 464.188 167.527C465.453 167.527 466.175 168.249 466.175 169.513Z"
        fill="#F37140"
      />
      <path
        d="M168.008 170.416C168.008 171.68 167.105 172.403 165.841 172.403C164.757 172.403 163.854 171.5 163.854 170.416C163.854 169.332 164.938 168.249 165.841 168.249C167.105 168.249 168.008 169.152 168.008 170.416Z"
        fill="#F37140"
      />
      <path
        d="M273.838 168.249C274.922 168.249 276.005 169.513 275.825 170.416C275.825 171.5 274.561 172.403 273.477 172.222C272.394 172.222 271.671 171.319 271.671 170.235C271.852 169.152 272.755 168.249 273.838 168.249Z"
        fill="#F37140"
      />
      <path
        d="M67.0536 112.624C67.0536 113.708 65.97 114.791 64.8864 114.611C63.8029 114.611 62.8999 113.527 62.8999 112.444C62.8999 111.36 63.6223 110.638 64.8864 110.638C66.1506 110.638 67.0536 111.36 67.0536 112.624Z"
        fill="#F37140"
      />
      <path
        d="M291.717 99.2601C290.453 99.2601 289.55 98.5378 289.55 97.2736C289.55 96.19 290.634 95.1064 291.717 95.1064C292.62 95.1064 293.523 96.0094 293.704 97.093C293.704 98.3572 292.982 99.0796 291.717 99.2601Z"
        fill="#F37140"
      />
      <path
        d="M327.656 99.2601C326.392 99.2601 325.67 98.5378 325.67 97.2736C325.67 96.3706 326.573 95.287 327.476 95.1064C328.559 95.1064 329.643 96.0094 329.643 97.093C329.643 98.3572 328.92 99.0796 327.656 99.2601Z"
        fill="#F37140"
      />
      <path
        d="M67.0537 177.64C67.0537 178.723 65.9701 179.807 65.0671 179.626C63.9835 179.626 63.0806 178.543 63.0806 177.459C63.0806 176.376 63.803 175.653 65.0671 175.653C66.1507 175.653 67.0537 176.376 67.0537 177.64Z"
        fill="#F37140"
      />
      <path
        d="M271.852 105.401C271.852 104.136 272.574 103.234 273.838 103.234C274.922 103.234 276.006 104.317 276.006 105.401C276.006 106.484 274.922 107.387 273.838 107.387C272.574 107.387 271.852 106.665 271.852 105.401Z"
        fill="#F37140"
      />
      <path
        d="M195.097 175.653C196.361 175.653 197.084 176.556 196.903 177.82C196.723 178.904 195.82 179.626 194.917 179.626C193.833 179.626 192.93 178.543 192.93 177.459C193.111 176.376 194.014 175.653 195.097 175.653Z"
        fill="#F37140"
      />
      <path
        d="M291.717 180.529C290.634 180.529 289.55 179.446 289.731 178.362C289.731 177.278 290.634 176.375 291.898 176.375C292.981 176.375 293.704 177.098 293.884 178.362C293.704 179.626 292.801 180.529 291.717 180.529Z"
        fill="#F37140"
      />
      <path
        d="M69.4015 106.484C68.3179 106.484 67.2344 105.581 67.2344 104.497C67.2344 103.414 68.1373 102.511 69.2209 102.511C70.3045 102.511 71.2075 103.233 71.2075 104.317C71.3881 105.4 70.6657 106.484 69.4015 106.484Z"
        fill="#F37140"
      />
      <path
        d="M166.202 107.387C165.118 107.387 164.035 106.484 164.035 105.401C164.035 104.317 165.118 103.234 166.021 103.234C167.105 103.234 167.827 103.956 168.008 105.039C168.008 106.304 167.285 107.387 166.202 107.387Z"
        fill="#F37140"
      />
      <path
        d="M233.023 177.64C233.023 178.723 231.94 179.626 230.856 179.626C229.772 179.446 228.869 178.543 229.05 177.459C229.05 176.376 229.953 175.653 231.037 175.653C232.12 175.653 233.023 176.556 233.023 177.64Z"
        fill="#F37140"
      />
      <path
        d="M118.884 106.484C117.801 106.484 116.717 105.581 116.717 104.497C116.717 103.414 117.62 102.511 118.884 102.511C119.968 102.511 120.871 103.233 120.871 104.317C120.871 105.4 119.968 106.484 118.884 106.484Z"
        fill="#F37140"
      />
      <path
        d="M410.551 240.669C411.815 240.669 412.537 241.572 412.357 242.836C412.357 243.919 411.273 244.642 410.37 244.642C409.287 244.642 408.384 243.558 408.384 242.475C408.384 241.391 409.287 240.669 410.551 240.669Z"
        fill="#F37140"
      />
      <path
        d="M280.34 175.653C281.604 175.653 282.326 176.556 282.326 177.64C282.326 178.723 281.423 179.626 280.34 179.626C279.256 179.626 278.173 178.723 278.173 177.64C278.353 176.376 279.076 175.653 280.34 175.653Z"
        fill="#F37140"
      />
      <path
        d="M175.232 104.317C175.232 103.053 176.135 102.331 177.399 102.511C178.302 102.692 179.205 103.595 179.205 104.498C179.205 105.581 178.121 106.484 177.038 106.484C175.954 106.484 175.051 105.581 175.232 104.317Z"
        fill="#F37140"
      />
      <path
        d="M466.175 104.678C466.175 105.762 465.091 106.665 464.008 106.484C463.105 106.304 462.202 105.22 462.202 104.317C462.202 103.234 463.285 102.331 464.369 102.331C465.633 102.511 466.356 103.414 466.175 104.678Z"
        fill="#F37140"
      />
      <path
        d="M78.2504 178.543C78.2504 179.627 77.3475 180.71 76.2639 180.71C75.1803 180.71 74.2773 179.627 74.2773 178.543C74.2773 177.459 75.1803 176.737 76.2639 176.737C77.5281 176.556 78.2504 177.279 78.2504 178.543Z"
        fill="#F37140"
      />
      <path
        d="M226.521 102.511C227.605 102.511 228.689 103.595 228.508 104.678C228.327 105.762 227.244 106.665 226.341 106.665C225.257 106.484 224.535 105.762 224.535 104.678C224.535 103.234 225.257 102.331 226.521 102.511Z"
        fill="#F37140"
      />
      <path
        d="M250.722 104.317C250.722 105.581 249.999 106.484 248.735 106.484C247.652 106.484 246.568 105.401 246.568 104.317C246.568 103.414 247.471 102.331 248.555 102.331C249.999 102.331 250.722 103.053 250.722 104.317Z"
        fill="#F37140"
      />
      <path
        d="M439.988 104.317C439.988 103.053 440.891 102.331 442.155 102.331C443.239 102.331 444.142 103.234 444.142 104.317C444.142 105.401 443.058 106.484 441.975 106.484C440.711 106.484 439.988 105.581 439.988 104.317Z"
        fill="#F37140"
      />
      <path
        d="M286.841 104.498C286.841 105.762 285.938 106.665 284.855 106.484C283.771 106.484 282.687 105.401 282.868 104.317C282.868 103.234 283.771 102.511 284.855 102.331C285.938 102.331 286.841 103.234 286.841 104.498Z"
        fill="#F37140"
      />
      <path
        d="M448.476 177.64C448.476 178.723 447.393 179.626 446.309 179.626C445.225 179.626 444.323 178.543 444.503 177.459C444.503 176.376 445.406 175.653 446.49 175.653C447.573 175.653 448.476 176.556 448.476 177.64Z"
        fill="#F37140"
      />
      <path
        d="M410.37 175.653C411.634 175.653 412.357 176.376 412.357 177.64C412.357 178.723 411.273 179.626 410.37 179.626C409.467 179.626 408.384 178.723 408.384 177.64C408.384 176.556 409.287 175.653 410.37 175.653Z"
        fill="#F37140"
      />
      <path
        d="M334.338 102.511C335.603 102.511 336.325 103.414 336.144 104.678C336.144 105.762 335.241 106.665 334.158 106.484C333.074 106.484 331.991 105.401 332.171 104.317C332.171 103.234 333.074 102.331 334.338 102.511Z"
        fill="#F37140"
      />
      <path
        d="M231.037 65.85C229.772 65.85 229.05 65.1276 229.05 63.8634C229.05 62.7799 229.953 61.8769 230.856 61.8769C231.94 61.6963 233.023 62.7799 233.023 63.8634C233.023 64.7664 232.12 65.6694 231.037 65.85Z"
        fill="#F37140"
      />
      <path
        d="M390.504 104.498C390.504 103.234 391.227 102.511 392.491 102.511C393.575 102.511 394.658 103.595 394.478 104.678C394.478 105.581 393.394 106.665 392.491 106.665C391.407 106.484 390.504 105.581 390.504 104.498Z"
        fill="#F37140"
      />
      <path
        d="M370.097 102.511C371.361 102.511 372.083 103.414 372.083 104.498C372.083 105.581 371 106.484 369.916 106.484C368.833 106.484 368.11 105.581 367.93 104.498C368.11 103.414 368.833 102.511 370.097 102.511Z"
        fill="#F37140"
      />
      <path
        d="M406.036 106.484C404.952 106.484 404.049 105.581 404.049 104.317C404.049 103.234 404.952 102.511 406.036 102.511C407.119 102.511 408.203 103.414 408.203 104.498C408.022 105.581 406.939 106.484 406.036 106.484Z"
        fill="#F37140"
      />
      <path
        d="M322.78 104.317C322.78 105.401 321.877 106.484 320.794 106.484C319.71 106.484 318.807 105.581 318.807 104.498C318.807 103.414 319.53 102.511 320.613 102.511C321.697 102.331 322.6 103.234 322.78 104.317Z"
        fill="#F37140"
      />
      <path
        d="M459.854 175.653C461.118 175.653 461.841 176.376 461.841 177.64C461.841 178.723 460.757 179.626 459.674 179.626C458.771 179.626 457.687 178.543 457.687 177.64C457.687 176.556 458.59 175.653 459.854 175.653Z"
        fill="#F37140"
      />
      <path
        d="M192.569 104.317C192.569 105.401 191.666 106.484 190.582 106.484C189.499 106.484 188.596 105.762 188.596 104.678C188.415 103.414 189.318 102.511 190.402 102.511C191.485 102.331 192.569 103.234 192.569 104.317Z"
        fill="#F37140"
      />
      <path
        d="M154.644 102.511C155.908 102.511 156.63 103.414 156.63 104.678C156.63 105.762 155.547 106.665 154.463 106.665C153.379 106.665 152.657 105.581 152.657 104.678C152.657 103.234 153.56 102.331 154.644 102.511Z"
        fill="#F37140"
      />
      <path
        d="M244.401 175.653C245.665 175.653 246.387 176.376 246.387 177.64C246.387 178.723 245.484 179.626 244.401 179.626C243.498 179.626 242.414 178.723 242.414 177.64C242.414 176.556 243.136 175.653 244.401 175.653Z"
        fill="#F37140"
      />
      <path
        d="M105.34 106.484C104.256 106.484 103.353 105.4 103.353 104.317C103.353 103.233 104.256 102.33 105.52 102.33C106.604 102.33 107.326 103.233 107.507 104.317C107.326 105.4 106.423 106.484 105.34 106.484Z"
        fill="#F37140"
      />
      <path
        d="M114.19 178.543C114.19 179.626 112.925 180.529 112.022 180.529C110.939 180.529 110.036 179.446 110.216 178.181C110.397 177.098 111.119 176.375 112.203 176.375C113.467 176.556 114.37 177.459 114.19 178.543Z"
        fill="#F37140"
      />
      <path
        d="M327.656 176.557C328.921 176.557 329.824 177.46 329.643 178.543C329.643 179.627 328.559 180.53 327.476 180.53C326.573 180.53 325.67 179.627 325.489 178.543C325.489 177.46 326.392 176.557 327.656 176.557Z"
        fill="#F37140"
      />
      <path
        d="M237.899 107.387C236.635 107.387 235.913 106.484 235.913 105.22C235.913 104.137 236.816 103.234 237.899 103.234C238.983 103.234 240.066 104.137 240.066 105.22C239.886 106.484 238.983 107.387 237.899 107.387Z"
        fill="#F37140"
      />
      <path
        d="M105.34 187.573C104.076 187.573 103.354 186.85 103.354 185.586C103.354 184.322 104.257 183.6 105.521 183.78C106.604 183.78 107.327 184.503 107.507 185.586C107.327 186.85 106.424 187.573 105.34 187.573Z"
        fill="#F37140"
      />
      <path
        d="M320.794 187.573C319.529 187.573 318.626 186.85 318.626 185.586C318.626 184.322 319.529 183.6 320.613 183.6C321.697 183.6 322.6 184.322 322.6 185.406C322.78 186.85 322.058 187.573 320.794 187.573Z"
        fill="#F37140"
      />
      <path
        d="M455.339 170.416C455.339 171.5 454.436 172.403 453.172 172.403C452.089 172.403 451.186 171.5 451.186 170.235C451.186 169.152 452.089 168.249 453.172 168.249C454.256 168.249 455.339 169.332 455.339 170.416Z"
        fill="#F37140"
      />
      <path
        d="M381.475 107.387C380.391 107.387 379.308 106.484 379.488 105.401C379.488 104.498 380.572 103.414 381.475 103.414C382.558 103.414 383.461 104.137 383.642 105.401C383.461 106.484 382.558 107.387 381.475 107.387Z"
        fill="#F37140"
      />
      <path
        d="M383.461 170.416C383.461 171.68 382.558 172.402 381.474 172.402C380.391 172.402 379.488 171.5 379.488 170.597C379.488 169.513 380.391 168.429 381.474 168.429C382.558 168.249 383.461 169.152 383.461 170.416Z"
        fill="#F37140"
      />
      <path
        d="M453.353 103.234C454.436 103.234 455.339 104.317 455.339 105.401C455.339 106.484 454.256 107.387 453.172 107.387C452.088 107.387 451.366 106.484 451.366 105.401C451.185 104.136 452.088 103.234 453.353 103.234Z"
        fill="#F37140"
      />
      <path
        d="M201.96 188.476C200.696 188.476 199.793 187.753 199.793 186.489C199.793 185.406 200.696 184.503 201.96 184.503C203.044 184.503 203.947 185.225 203.947 186.309C203.947 187.753 203.224 188.476 201.96 188.476Z"
        fill="#F37140"
      />
      <path
        d="M417.413 188.476C416.149 188.476 415.246 187.753 415.246 186.67C415.246 185.586 416.149 184.683 417.233 184.683C418.316 184.683 419.219 185.406 419.219 186.489C419.4 187.573 418.678 188.476 417.413 188.476Z"
        fill="#F37140"
      />
      <path
        d="M239.886 170.416C239.886 171.499 238.802 172.402 237.718 172.402C236.635 172.402 235.732 171.499 235.913 170.235C235.913 169.152 236.815 168.429 237.899 168.429C238.983 168.429 239.886 169.332 239.886 170.416Z"
        fill="#F37140"
      />
      <path
        d="M64.8864 195.881C63.6223 195.881 62.8999 194.978 62.8999 193.713C62.8999 192.63 63.8029 191.727 64.8864 191.727C65.97 191.727 67.0536 192.63 67.0536 193.713C67.0536 194.978 66.1506 195.881 64.8864 195.881Z"
        fill="#F37140"
      />
      <path
        d="M100.825 195.881C99.561 195.881 98.8386 194.978 98.8386 193.894C98.8386 192.81 99.7416 191.907 100.645 191.907C101.728 191.907 102.812 192.991 102.812 193.894C102.992 194.797 101.909 195.881 100.825 195.881Z"
        fill="#F37140"
      />
      <path
        d="M280.34 65.8498C279.075 65.8498 278.353 64.9468 278.353 63.8633C278.353 62.7797 279.437 61.8767 280.52 61.8767C281.423 61.8767 282.507 62.7797 282.507 63.8633C282.507 64.7662 281.604 65.6692 280.34 65.8498Z"
        fill="#F37140"
      />
      <path
        d="M406.036 171.5C404.952 171.5 404.049 170.597 404.049 169.513C404.049 168.43 404.772 167.527 405.855 167.527C406.939 167.527 408.022 168.249 408.022 169.333C408.203 170.416 407.119 171.5 406.036 171.5Z"
        fill="#F37140"
      />
      <path
        d="M383.461 235.251C383.461 236.515 382.738 237.237 381.474 237.237C380.391 237.237 379.488 236.334 379.488 235.431C379.488 234.348 380.391 233.264 381.474 233.264C382.558 233.264 383.461 234.167 383.461 235.251Z"
        fill="#F37140"
      />
      <path
        d="M318.265 193.713C318.265 194.797 317.362 195.7 316.098 195.7C315.014 195.7 314.111 194.978 314.111 193.894C314.111 192.63 314.834 191.727 315.917 191.727C317.181 191.727 318.265 192.81 318.265 193.713Z"
        fill="#F37140"
      />
      <path
        d="M316.279 94.2036C317.362 94.2036 318.446 95.2872 318.265 96.3708C318.265 97.4543 317.182 98.3573 316.098 98.3573C315.014 98.3573 314.292 97.6349 314.292 96.3708C314.292 95.1066 315.195 94.2036 316.279 94.2036Z"
        fill="#F37140"
      />
      <path
        d="M244.401 110.638C245.665 110.638 246.387 111.361 246.387 112.625C246.387 113.708 245.485 114.611 244.401 114.611C243.498 114.611 242.414 113.708 242.414 112.625C242.234 111.541 243.137 110.638 244.401 110.638Z"
        fill="#F37140"
      />
      <path
        d="M370.097 171.499C369.013 171.499 368.11 170.416 368.11 169.332C368.11 168.249 369.194 167.346 370.277 167.526C371.361 167.707 372.083 168.429 372.083 169.513C372.083 170.596 371.18 171.499 370.097 171.499Z"
        fill="#F37140"
      />
      <path
        d="M280.34 98.3573C279.075 98.3573 278.353 97.4543 278.353 96.1902C278.353 95.1066 279.256 94.2036 280.34 94.2036C281.423 94.2036 282.507 95.1066 282.507 96.1902C282.326 97.4543 281.423 98.3573 280.34 98.3573Z"
        fill="#F37140"
      />
      <path
        d="M318.265 63.8631C318.265 64.9466 317.182 65.8496 316.098 65.8496C315.014 65.8496 314.292 65.1272 314.292 63.8631C314.292 62.5989 315.195 61.6959 316.279 61.6959C317.362 61.6959 318.446 62.7795 318.265 63.8631Z"
        fill="#F37140"
      />
      <path
        d="M244.581 240.669C245.846 240.669 246.568 241.572 246.568 242.655C246.568 243.739 245.665 244.461 244.581 244.642C243.498 244.642 242.595 243.558 242.595 242.655C242.414 241.391 243.317 240.669 244.581 240.669Z"
        fill="#F37140"
      />
      <path
        d="M322.78 169.513C322.78 170.597 321.697 171.5 320.613 171.5C319.529 171.319 318.626 170.416 318.807 169.333C318.988 168.249 319.71 167.527 320.794 167.527C321.877 167.527 322.78 168.43 322.78 169.513Z"
        fill="#F37140"
      />
      <path
        d="M102.992 96.3704C102.992 97.454 101.908 98.3569 100.825 98.3569C99.7412 98.3569 99.0188 97.6346 99.0188 96.3704C99.0188 95.1062 99.9218 94.2032 101.005 94.2032C101.908 94.2032 102.992 95.2868 102.992 96.3704Z"
        fill="#F37140"
      />
      <path
        d="M67.0536 96.3705C67.0536 97.4541 66.1506 98.3571 64.8865 98.3571C63.8029 98.3571 62.8999 97.2735 63.0805 96.1899C63.0805 95.1064 64.1641 94.384 65.0671 94.384C65.97 94.2034 67.0536 95.2869 67.0536 96.3705Z"
        fill="#F37140"
      />
      <path
        d="M103.173 202.021C103.173 200.937 103.896 200.034 105.16 199.853C106.424 199.853 107.146 200.756 107.146 201.84C107.146 202.924 106.243 203.826 105.34 203.826C104.438 204.007 103.354 203.104 103.173 202.021Z"
        fill="#F37140"
      />
      <path
        d="M417.413 90.9529C416.149 90.9529 415.246 90.2305 415.246 89.1469C415.246 88.0634 416.149 87.1604 417.233 87.1604C418.316 87.1604 419.219 87.8828 419.219 88.9664C419.4 90.2305 418.678 90.9529 417.413 90.9529Z"
        fill="#F37140"
      />
      <path
        d="M192.569 169.513C192.569 170.597 191.485 171.5 190.402 171.5C189.318 171.5 188.415 170.416 188.596 169.333C188.596 168.249 189.499 167.527 190.582 167.527C191.666 167.527 192.75 168.43 192.569 169.513Z"
        fill="#F37140"
      />
      <path
        d="M154.643 200.034C155.908 200.034 156.811 200.756 156.811 202.02C156.811 203.104 155.908 204.188 154.824 204.007C153.74 204.007 152.838 203.104 152.838 202.02C152.657 200.937 153.379 200.034 154.643 200.034Z"
        fill="#F37140"
      />
      <path
        d="M459.854 110.638C461.118 110.638 461.841 111.361 461.841 112.625C461.841 113.708 460.757 114.611 459.674 114.611C458.771 114.611 457.687 113.528 457.687 112.625C457.687 111.541 458.59 110.638 459.854 110.638Z"
        fill="#F37140"
      />
      <path
        d="M190.582 200.034C191.666 200.034 192.75 200.937 192.569 202.02C192.569 203.104 191.485 204.007 190.402 204.007C189.318 204.007 188.596 203.104 188.596 202.02C188.596 200.756 189.499 200.034 190.582 200.034Z"
        fill="#F37140"
      />
      <path
        d="M112.202 111.541C113.467 111.541 114.189 112.444 114.189 113.527C114.189 114.611 113.105 115.514 112.022 115.514C111.119 115.514 110.216 114.611 110.216 113.527C110.216 112.444 110.938 111.541 112.202 111.541Z"
        fill="#F37140"
      />
      <path
        d="M201.96 90.953C200.696 90.953 199.793 90.2306 199.793 88.9664C199.793 87.8828 200.696 86.9799 201.78 86.9799C202.863 86.9799 203.766 87.7022 203.766 88.7858C203.947 90.2306 203.224 90.953 201.96 90.953Z"
        fill="#F37140"
      />
      <path
        d="M154.644 167.527C155.908 167.527 156.811 168.249 156.811 169.513C156.811 170.597 155.908 171.68 154.824 171.68C153.741 171.68 152.838 170.777 152.838 169.874C152.476 168.43 153.379 167.527 154.644 167.527Z"
        fill="#F37140"
      />
      <path
        d="M322.78 202.021C322.78 203.104 321.877 204.188 320.613 204.007C319.529 204.007 318.626 202.924 318.626 201.84C318.626 200.757 319.529 200.034 320.613 200.034C321.877 199.854 322.78 200.757 322.78 202.021Z"
        fill="#F37140"
      />
      <path
        d="M372.083 202.021C372.083 203.104 371.18 204.188 370.097 204.007C369.013 204.007 368.11 202.924 368.11 201.84C368.11 200.757 369.013 200.034 370.097 200.034C371.18 199.854 372.083 200.757 372.083 202.021Z"
        fill="#F37140"
      />
      <path
        d="M406.216 200.034C407.3 200.034 408.203 201.118 408.203 202.201C408.022 203.285 406.939 204.188 405.855 204.007C404.772 203.827 404.049 203.104 404.049 201.84C404.049 200.756 404.952 199.854 406.216 200.034Z"
        fill="#F37140"
      />
      <path
        d="M329.643 113.528C329.643 114.611 328.559 115.695 327.476 115.514C326.573 115.514 325.489 114.611 325.489 113.528C325.489 112.263 326.212 111.36 327.476 111.36C328.74 111.541 329.643 112.263 329.643 113.528Z"
        fill="#F37140"
      />
      <path
        d="M107.327 169.513C107.327 170.597 106.424 171.68 105.34 171.5C104.257 171.5 103.354 170.597 103.354 169.513C103.354 168.43 104.076 167.527 105.34 167.346C106.424 167.346 107.327 168.249 107.327 169.513Z"
        fill="#F37140"
      />
      <path
        d="M320.794 90.05C319.529 90.05 318.626 89.3276 318.626 88.0635C318.626 86.9799 319.349 86.2575 320.432 86.0769C321.697 86.0769 322.6 86.6187 322.6 87.8829C322.78 89.3276 322.058 90.05 320.794 90.05Z"
        fill="#F37140"
      />
      <path
        d="M239.886 202.924C239.886 204.007 238.802 204.91 237.718 204.91C236.635 204.91 235.732 204.007 235.913 202.743C235.913 201.66 236.815 200.937 237.899 200.937C238.983 200.937 239.886 201.84 239.886 202.924Z"
        fill="#F37140"
      />
      <path
        d="M381.294 204.91C380.21 204.91 379.307 204.007 379.307 202.924C379.307 201.84 380.391 200.756 381.294 200.756C382.377 200.756 383.28 201.659 383.28 202.924C383.461 204.188 382.558 204.91 381.294 204.91Z"
        fill="#F37140"
      />
      <path
        d="M455.339 202.743C455.339 203.827 454.436 204.73 453.352 204.73C452.269 204.73 451.366 204.007 451.366 202.924C451.366 201.659 452.088 200.756 453.172 200.756C454.255 200.756 455.339 201.659 455.339 202.743Z"
        fill="#F37140"
      />
      <path
        d="M244.581 208.161C245.846 208.161 246.568 209.064 246.568 210.147C246.568 211.231 245.665 211.953 244.581 212.134C243.498 212.134 242.595 211.05 242.595 210.147C242.414 208.883 243.317 208.161 244.581 208.161Z"
        fill="#F37140"
      />
      <path
        d="M105.159 122.557C103.895 122.557 103.173 121.654 103.173 120.57C103.173 119.487 104.076 118.764 105.34 118.764C106.423 118.764 107.146 119.667 107.146 120.751C107.326 121.834 106.423 122.557 105.159 122.557Z"
        fill="#F37140"
      />
      <path
        d="M459.854 240.669C461.118 240.669 461.841 241.391 461.841 242.655C461.841 243.739 460.757 244.642 459.674 244.642C458.771 244.642 457.868 243.558 457.687 242.655C457.868 241.572 458.771 240.669 459.854 240.669Z"
        fill="#F37140"
      />
      <path
        d="M105.34 90.0496C104.075 90.0496 103.353 89.3272 103.353 88.063C103.353 86.7989 104.256 86.0765 105.52 86.2571C106.604 86.2571 107.326 86.9795 107.507 88.063C107.326 89.3272 106.604 90.0496 105.34 90.0496Z"
        fill="#F37140"
      />
      <path
        d="M459.854 208.161C461.118 208.161 461.841 208.883 461.841 210.147C461.841 211.231 460.757 212.134 459.854 212.134C458.951 212.134 457.867 211.05 457.867 210.147C457.687 209.064 458.59 208.161 459.854 208.161Z"
        fill="#F37140"
      />
      <path
        d="M316.098 159.219C317.181 159.219 318.265 160.303 318.265 161.206C318.265 162.289 317.362 163.192 316.098 163.192C315.014 163.192 314.111 162.47 314.111 161.386C314.292 160.303 315.014 159.219 316.098 159.219Z"
        fill="#F37140"
      />
      <path
        d="M280.34 159.219C281.423 159.219 282.507 160.122 282.507 161.206C282.507 162.289 281.785 163.192 280.701 163.192C279.437 163.373 278.534 162.47 278.534 161.386C278.353 160.303 279.256 159.219 280.34 159.219Z"
        fill="#F37140"
      />
      <path
        d="M114.19 211.051C114.19 212.134 113.106 213.037 112.022 213.037C111.119 213.037 110.216 212.134 110.036 211.051C110.036 209.786 110.758 209.064 112.022 208.883C113.287 209.064 114.19 209.967 114.19 211.051Z"
        fill="#F37140"
      />
      <path
        d="M329.643 211.051C329.643 212.134 328.559 213.218 327.476 213.037C326.573 213.037 325.67 212.134 325.489 211.051C325.489 209.786 326.212 208.883 327.476 208.883C328.74 209.064 329.643 209.786 329.643 211.051Z"
        fill="#F37140"
      />
      <path
        d="M105.34 220.081C104.076 220.081 103.354 219.358 103.354 218.094C103.354 216.83 104.257 216.108 105.521 216.288C106.604 216.288 107.327 217.011 107.507 218.094C107.327 219.358 106.604 220.081 105.34 220.081Z"
        fill="#F37140"
      />
      <path
        d="M102.993 161.386C102.993 162.47 101.909 163.373 100.825 163.373C99.5612 163.373 98.8388 162.47 99.0194 161.206C99.2 160.122 99.9224 159.219 101.006 159.4C101.909 159.219 102.993 160.483 102.993 161.386Z"
        fill="#F37140"
      />
      <path
        d="M64.8865 159.219C65.97 159.219 67.0536 160.122 67.0536 161.206C67.0536 162.289 66.3312 163.192 65.2476 163.192C63.9835 163.373 63.0805 162.47 63.0805 161.386C62.8999 160.303 63.8029 159.219 64.8865 159.219Z"
        fill="#F37140"
      />
      <path
        d="M417.414 155.968C416.149 155.968 415.427 155.065 415.427 153.982C415.427 152.898 416.33 151.995 417.594 152.176C418.678 152.176 419.4 152.898 419.581 153.982C419.4 155.246 418.678 155.968 417.414 155.968Z"
        fill="#F37140"
      />
      <path
        d="M320.613 118.765C321.877 118.765 322.6 119.487 322.6 120.751C322.6 121.835 321.697 122.738 320.433 122.738C319.349 122.738 318.446 122.016 318.446 120.932C318.627 119.668 319.349 118.765 320.613 118.765Z"
        fill="#F37140"
      />
      <path
        d="M201.96 155.968C200.696 155.968 199.793 155.246 199.793 153.981C199.793 152.898 200.696 151.995 201.78 151.995C202.863 151.995 203.766 152.717 203.766 153.801C203.947 155.246 203.224 155.968 201.96 155.968Z"
        fill="#F37140"
      />
      <path
        d="M320.613 151.272C321.877 151.272 322.78 151.995 322.78 153.078C322.78 154.343 322.058 155.065 320.794 155.065C319.71 155.065 318.807 154.343 318.807 153.259C318.626 152.175 319.349 151.272 320.613 151.272Z"
        fill="#F37140"
      />
      <path
        d="M105.34 155.065C104.076 155.065 103.354 154.343 103.354 153.079C103.354 151.814 104.257 151.092 105.521 151.273C106.604 151.273 107.507 151.995 107.507 153.079C107.327 154.343 106.604 155.065 105.34 155.065Z"
        fill="#F37140"
      />
      <path
        d="M327.657 144.049C328.921 144.049 329.643 144.952 329.643 146.035C329.643 147.119 328.56 148.022 327.476 148.022C326.573 148.022 325.67 147.119 325.67 146.035C325.489 144.952 326.392 144.049 327.657 144.049Z"
        fill="#F37140"
      />
      <path
        d="M329.643 80.8395C329.643 81.923 328.74 83.0066 327.657 83.0066C326.754 83.0066 325.67 82.1036 325.67 81.2006C325.49 80.1171 326.392 79.0335 327.476 79.0335C328.56 78.8529 329.643 79.7559 329.643 80.8395Z"
        fill="#F37140"
      />
      <path
        d="M114.19 146.035C114.19 147.119 113.106 148.203 112.022 148.022C111.119 148.022 110.216 147.119 110.036 146.035C110.036 144.771 110.758 143.868 112.022 143.868C113.287 144.049 114.19 144.771 114.19 146.035Z"
        fill="#F37140"
      />
      <path
        d="M320.613 216.288C321.877 216.288 322.6 217.01 322.6 218.274C322.6 219.358 321.697 220.261 320.433 220.08C319.349 220.08 318.446 219.358 318.446 218.274C318.627 217.01 319.349 216.288 320.613 216.288Z"
        fill="#F37140"
      />
      <path
        d="M461.84 144.952C461.84 146.035 460.937 147.119 459.854 147.119C458.77 147.119 457.867 146.035 457.867 144.952C457.867 143.868 458.77 143.146 459.854 143.146C461.118 143.146 461.84 143.868 461.84 144.952Z"
        fill="#F37140"
      />
      <path
        d="M201.78 220.983C200.515 220.983 199.793 220.08 199.793 218.997C199.793 217.913 200.877 217.191 201.96 217.191C203.044 217.191 203.766 218.094 203.766 219.177C203.947 220.261 203.044 221.164 201.78 220.983Z"
        fill="#F37140"
      />
      <path
        d="M417.233 220.983C415.969 220.983 415.246 220.08 415.246 218.997C415.246 217.913 416.33 217.191 417.413 217.191C418.497 217.191 419.219 218.094 419.219 219.177C419.4 220.261 418.497 221.164 417.233 220.983Z"
        fill="#F37140"
      />
      <path
        d="M65.0669 224.234C66.1505 224.234 67.0535 225.318 67.0535 226.401C67.0535 227.485 65.9699 228.388 64.8863 228.207C63.8028 228.207 63.0804 227.304 63.0804 226.221C62.8998 225.137 63.8028 224.234 65.0669 224.234Z"
        fill="#F37140"
      />
      <path
        d="M244.401 143.146C245.665 143.146 246.387 143.868 246.568 144.952C246.568 146.035 245.665 147.119 244.581 147.119C243.678 147.119 242.595 146.216 242.595 145.313C242.234 144.049 243.137 143.146 244.401 143.146Z"
        fill="#F37140"
      />
      <path
        d="M201.96 123.46C200.696 123.46 199.974 122.557 199.974 121.474C199.974 120.39 200.877 119.668 202.141 119.668C203.224 119.668 203.947 120.571 203.947 121.655C203.947 122.738 203.044 123.641 201.96 123.46Z"
        fill="#F37140"
      />
      <path
        d="M112.202 83.0066C111.119 83.0066 110.216 81.923 110.216 80.8395C110.216 79.7559 111.119 79.0335 112.202 78.8529C113.467 78.8529 114.37 79.7559 114.37 80.8395C114.189 81.923 113.105 83.0066 112.202 83.0066Z"
        fill="#F37140"
      />
      <path
        d="M461.84 79.9364C461.84 81.02 460.937 82.1036 459.854 82.1036C458.77 82.1036 457.867 81.02 457.867 79.9364C457.867 78.8529 458.77 78.1305 459.854 78.1305C461.118 78.1305 461.84 78.8529 461.84 79.9364Z"
        fill="#F37140"
      />
      <path
        d="M102.992 226.401C102.992 227.485 101.909 228.388 100.825 228.388C99.7417 228.388 99.0193 227.665 99.0193 226.401C99.0193 225.137 99.9223 224.234 101.006 224.234C101.909 224.234 102.992 225.318 102.992 226.401Z"
        fill="#F37140"
      />
      <path
        d="M455.339 137.908C455.339 138.992 454.256 139.895 453.172 139.895C452.089 139.895 451.186 138.992 451.366 137.728C451.366 136.644 452.269 135.922 453.353 135.922C454.436 135.922 455.339 136.825 455.339 137.908Z"
        fill="#F37140"
      />
      <path
        d="M381.294 135.741C382.377 135.741 383.28 136.644 383.28 137.728C383.28 138.811 382.558 139.714 381.474 139.714C380.391 139.895 379.307 138.992 379.307 137.908C379.307 137.005 380.391 135.922 381.294 135.741Z"
        fill="#F37140"
      />
      <path
        d="M417.233 119.668C418.497 119.668 419.219 120.39 419.219 121.655C419.219 122.738 418.497 123.46 417.413 123.641C416.149 123.641 415.246 122.919 415.246 121.835C415.246 120.571 416.149 119.668 417.233 119.668Z"
        fill="#F37140"
      />
      <path
        d="M280.34 224.234C281.423 224.234 282.507 225.137 282.507 226.221C282.507 227.304 281.785 228.207 280.701 228.207C279.437 228.207 278.534 227.485 278.534 226.401C278.353 225.318 279.256 224.234 280.34 224.234Z"
        fill="#F37140"
      />
      <path
        d="M64.8864 130.864C63.6223 130.864 62.8999 129.961 62.8999 128.697C62.8999 127.613 63.8029 126.71 64.8864 126.71C65.97 126.71 67.0536 127.613 67.0536 128.697C67.0536 129.961 66.1506 130.864 64.8864 130.864Z"
        fill="#F37140"
      />
      <path
        d="M237.899 139.895C236.635 139.895 235.913 138.992 235.913 137.908C235.913 136.825 236.635 135.922 237.719 135.922C238.802 135.922 239.886 136.825 239.886 137.908C239.886 138.811 238.983 139.895 237.899 139.895Z"
        fill="#F37140"
      />
      <path
        d="M102.992 128.877C102.992 129.961 101.908 130.864 100.825 130.864C99.5607 130.864 98.8383 129.961 99.0189 128.697C99.1995 127.613 99.9219 126.71 101.005 126.891C101.908 126.71 102.992 127.974 102.992 128.877Z"
        fill="#F37140"
      />
      <path
        d="M316.098 224.234C317.181 224.234 318.265 225.318 318.265 226.221C318.265 227.304 317.362 228.207 316.098 228.207C315.014 228.207 314.111 227.485 314.111 226.401C314.292 225.318 315.014 224.234 316.098 224.234Z"
        fill="#F37140"
      />
      <path
        d="M244.401 78.1305C245.665 78.1305 246.387 78.8529 246.387 80.117C246.387 81.2006 245.304 82.1036 244.401 82.1036C243.498 82.1036 242.414 81.02 242.414 80.117C242.414 79.0335 243.136 78.1305 244.401 78.1305Z"
        fill="#F37140"
      />
      <path
        d="M103.173 234.528C103.173 233.444 103.896 232.541 105.16 232.361C106.424 232.361 107.146 233.264 107.146 234.347C107.146 235.431 106.243 236.334 105.34 236.334C104.438 236.515 103.354 235.612 103.173 234.528Z"
        fill="#F37140"
      />
      <path
        d="M406.036 138.992C404.952 138.992 404.049 138.089 404.049 137.005C404.049 135.922 404.772 135.019 405.855 135.019C406.939 135.019 408.022 135.741 408.022 136.825C408.203 137.908 407.119 138.992 406.036 138.992Z"
        fill="#F37140"
      />
      <path
        d="M453.353 70.7258C454.436 70.7258 455.339 71.8094 455.339 72.893C455.339 73.9765 454.256 74.8795 453.172 74.8795C452.088 74.8795 451.366 73.9765 451.366 72.893C451.185 71.6288 452.088 70.7258 453.353 70.7258Z"
        fill="#F37140"
      />
      <path
        d="M381.475 70.7258C382.558 70.7258 383.461 71.6288 383.461 72.893C383.461 73.9765 382.377 74.8795 381.294 74.6989C380.21 74.6989 379.307 73.6154 379.488 72.7124C379.488 71.8094 380.572 70.7258 381.475 70.7258Z"
        fill="#F37140"
      />
      <path
        d="M455.339 235.431C455.339 236.515 454.256 237.418 453.172 237.418C452.089 237.418 451.186 236.515 451.186 235.251C451.186 234.167 452.089 233.445 453.172 233.264C454.256 233.264 455.339 234.348 455.339 235.431Z"
        fill="#F37140"
      />
      <path
        d="M112.203 241.572C113.467 241.572 114.37 242.475 114.19 243.558C114.19 244.642 113.106 245.545 112.022 245.545C111.119 245.545 110.216 244.642 110.036 243.558C110.216 242.475 110.939 241.572 112.203 241.572Z"
        fill="#F37140"
      />
      <path
        d="M372.083 137.005C372.083 138.089 371 138.992 369.916 138.992C368.833 138.992 367.93 137.908 368.11 136.825C368.291 135.741 369.013 135.019 370.097 135.019C371.361 135.019 372.264 135.922 372.083 137.005Z"
        fill="#F37140"
      />
      <path
        d="M280.34 126.711C281.423 126.711 282.326 127.795 282.326 128.878C282.326 129.962 281.243 130.865 280.159 130.684C279.075 130.684 278.353 129.781 278.353 128.698C278.353 127.614 279.256 126.711 280.34 126.711Z"
        fill="#F37140"
      />
      <path
        d="M154.824 236.515C153.741 236.515 152.657 235.612 152.657 234.528C152.657 233.445 153.379 232.542 154.463 232.542C155.727 232.361 156.63 233.264 156.63 234.348C156.811 235.431 155.908 236.515 154.824 236.515Z"
        fill="#F37140"
      />
      <path
        d="M316.279 130.865C315.014 130.865 314.292 129.962 314.292 128.698C314.292 127.614 315.195 126.711 316.279 126.711C317.362 126.711 318.446 127.795 318.446 128.698C318.446 129.781 317.362 130.865 316.279 130.865Z"
        fill="#F37140"
      />
      <path
        d="M237.718 70.7258C238.802 70.7258 239.886 71.6288 239.886 72.7124C239.886 73.796 238.983 74.6989 237.899 74.6989C236.815 74.6989 235.913 73.9765 235.913 72.893C235.732 71.8094 236.635 70.9064 237.718 70.7258Z"
        fill="#F37140"
      />
      <path
        d="M320.794 138.992C319.71 138.992 318.807 138.089 318.807 136.825C318.807 135.741 319.53 135.019 320.613 134.838C321.877 134.838 322.78 135.561 322.78 136.825C322.78 137.908 321.877 138.992 320.794 138.992Z"
        fill="#F37140"
      />
      <path
        d="M190.763 232.542C191.847 232.542 192.75 233.625 192.75 234.709C192.569 235.793 191.485 236.695 190.402 236.515C189.318 236.334 188.596 235.612 188.596 234.348C188.596 233.264 189.499 232.361 190.763 232.542Z"
        fill="#F37140"
      />
      <path
        d="M405.855 70.0037C406.939 70.0037 408.022 70.9066 408.022 71.9902C408.022 73.0738 406.939 73.9768 406.036 74.1574C404.952 74.1574 404.23 73.435 404.049 72.3514C404.049 70.9066 404.772 70.0037 405.855 70.0037Z"
        fill="#F37140"
      />
      <path
        d="M370.097 73.9766C369.013 73.9766 368.11 72.893 368.11 71.8094C368.11 70.7259 369.013 70.0035 370.097 69.8229C371.361 69.8229 372.264 70.5453 372.264 71.8094C372.083 73.0736 371.18 73.9766 370.097 73.9766Z"
        fill="#F37140"
      />
      <path
        d="M322.78 71.8097C322.78 72.8932 321.877 73.9768 320.794 73.9768C319.71 73.9768 318.807 73.0738 318.626 71.9903C318.626 70.9067 319.349 70.0037 320.432 70.0037C321.697 69.8231 322.6 70.7261 322.78 71.8097Z"
        fill="#F37140"
      />
      <path
        d="M322.78 234.528C322.78 235.612 321.877 236.695 320.794 236.515C319.71 236.515 318.807 235.431 318.807 234.347C318.807 233.264 319.71 232.541 320.794 232.361C321.877 232.361 322.78 233.264 322.78 234.528Z"
        fill="#F37140"
      />
      <path
        d="M102.992 63.8634C102.992 64.947 101.908 65.85 100.825 65.85C99.5607 65.85 98.8383 64.947 99.0189 63.6828C99.1995 62.5993 99.9219 61.6963 101.005 61.8769C101.908 61.6963 102.992 62.9605 102.992 63.8634Z"
        fill="#F37140"
      />
      <path
        d="M190.582 73.9768C189.499 73.9768 188.596 72.8932 188.777 71.8096C188.777 70.726 189.68 70.0037 190.763 70.0037C191.847 70.0037 192.93 70.9066 192.75 71.9902C192.569 73.0738 191.485 73.9768 190.582 73.9768Z"
        fill="#F37140"
      />
      <path
        d="M156.63 71.9902C156.63 73.0738 155.547 73.9768 154.463 73.9768C153.38 73.9768 152.477 72.8932 152.657 71.8096C152.838 70.726 153.56 70.0037 154.644 70.0037C155.908 70.0037 156.811 70.9066 156.63 71.9902Z"
        fill="#F37140"
      />
      <path
        d="M190.402 138.992C189.318 138.992 188.415 137.908 188.596 136.825C188.596 135.741 189.499 135.019 190.582 135.019C191.666 135.019 192.75 135.922 192.569 137.005C192.569 138.089 191.485 138.992 190.402 138.992Z"
        fill="#F37140"
      />
      <path
        d="M107.326 71.99C107.326 73.0736 106.423 74.1572 105.34 73.9766C104.256 73.9766 103.353 73.0736 103.353 71.99C103.353 70.9064 104.075 70.0035 105.34 69.8229C106.423 69.8229 107.326 70.7258 107.326 71.99Z"
        fill="#F37140"
      />
      <path
        d="M154.644 135.019C155.908 135.019 156.63 135.922 156.63 137.005C156.63 138.089 155.547 138.992 154.463 138.992C153.38 138.992 152.657 138.089 152.477 137.005C152.657 135.741 153.56 135.019 154.644 135.019Z"
        fill="#F37140"
      />
      <path
        d="M329.643 243.558C329.643 244.642 328.559 245.726 327.476 245.545C326.573 245.545 325.67 244.642 325.489 243.558C325.489 242.294 326.212 241.391 327.476 241.391C328.74 241.572 329.643 242.294 329.643 243.558Z"
        fill="#F37140"
      />
      <path
        d="M372.083 234.528C372.083 235.612 371 236.515 369.916 236.515C368.833 236.515 367.93 235.431 368.11 234.348C368.291 233.264 369.013 232.542 370.097 232.542C371.361 232.542 372.264 233.445 372.083 234.528Z"
        fill="#F37140"
      />
      <path
        d="M405.855 232.542C406.939 232.542 408.022 233.445 408.022 234.528C408.022 235.612 406.939 236.695 406.036 236.695C404.952 236.695 404.049 235.973 404.049 234.889C404.049 233.445 404.772 232.542 405.855 232.542Z"
        fill="#F37140"
      />
      <path
        d="M239.886 235.431C239.886 236.515 238.983 237.418 237.899 237.418C236.816 237.418 235.913 236.695 235.913 235.612C235.913 234.348 236.635 233.445 237.899 233.445C238.802 233.264 239.886 234.348 239.886 235.431Z"
        fill="#F37140"
      />
      <path
        d="M107.326 137.005C107.326 138.088 106.423 139.172 105.34 138.991C104.256 138.991 103.353 138.088 103.353 137.005C103.353 135.921 104.075 134.837 105.34 134.837C106.423 134.837 107.326 135.74 107.326 137.005Z"
        fill="#F37140"
      />
      <path
        d="M280.34 191.727C281.423 191.727 282.507 192.63 282.507 193.713C282.507 194.797 281.785 195.7 280.701 195.7C279.437 195.7 278.534 194.978 278.534 193.894C278.353 192.81 279.256 191.727 280.34 191.727Z"
        fill="#F37140"
      />
      <path
        d="M370.277 151.272C371.542 151.272 372.264 152.175 372.264 153.259C372.264 154.343 371.361 155.065 370.097 155.065C369.013 155.065 368.291 154.162 368.291 153.078C368.11 151.995 369.013 151.272 370.277 151.272Z"
        fill="#F37140"
      />
      <path
        d="M201.78 70.7258C202.863 70.7258 203.766 71.6288 203.947 72.7124C203.947 73.796 203.224 74.6989 202.141 74.6989C201.057 74.6989 199.974 73.9765 199.974 72.893C199.793 71.8094 200.696 70.9064 201.78 70.7258Z"
        fill="#F37140"
      />
      <path
        d="M84.752 137.004C84.752 138.269 83.849 138.991 82.7654 138.991C81.6819 138.991 80.9595 138.088 80.9595 137.004C80.9595 135.74 81.6819 135.018 82.7654 135.018C84.0296 134.837 84.752 135.74 84.752 137.004Z"
        fill="#F37140"
      />
      <path
        d="M473.218 211.05C473.218 212.315 472.315 213.218 471.232 213.037C470.329 213.037 469.065 211.773 469.245 210.87C469.245 209.967 470.148 209.064 471.232 209.064C472.315 209.064 473.218 209.786 473.218 211.05Z"
        fill="#F37140"
      />
      <path
        d="M363.415 131.768C362.151 131.768 361.428 130.865 361.609 129.601C361.609 128.517 362.692 127.614 363.595 127.614C364.498 127.614 365.762 128.878 365.582 129.781C365.582 130.865 364.679 131.768 363.415 131.768Z"
        fill="#F37140"
      />
      <path
        d="M365.582 210.87C365.582 211.954 364.498 213.037 363.595 213.037C362.512 213.037 361.609 212.134 361.609 210.87C361.609 209.786 362.331 209.064 363.415 208.883C364.498 209.064 365.582 209.967 365.582 210.87Z"
        fill="#F37140"
      />
      <path
        d="M257.765 211.231C257.765 212.315 256.862 213.218 255.778 213.218C254.875 213.218 253.611 212.134 253.611 211.05C253.611 209.967 254.695 209.064 255.778 209.064C257.042 209.064 257.765 209.967 257.765 211.231Z"
        fill="#F37140"
      />
      <path
        d="M148.142 209.064C149.226 209.064 150.309 209.967 150.309 211.05C150.309 212.134 149.226 213.218 148.142 213.037C147.239 213.037 146.336 212.134 146.156 211.05C145.975 209.786 146.878 209.064 148.142 209.064Z"
        fill="#F37140"
      />
      <path
        d="M148.142 131.768C146.878 131.768 146.156 131.046 146.156 129.781C146.156 128.698 147.058 127.614 148.142 127.795C149.045 127.795 150.129 128.878 150.129 129.781C150.309 130.684 149.406 131.587 148.142 131.768Z"
        fill="#F37140"
      />
      <path
        d="M473.218 81.0201C473.218 82.2843 472.315 83.1873 471.232 83.0067C470.148 83.0067 469.245 81.7425 469.245 80.8395C469.245 79.756 470.329 79.0336 471.232 79.0336C472.496 79.0336 473.218 79.7559 473.218 81.0201Z"
        fill="#F37140"
      />
      <path
        d="M425.902 128.878C425.902 130.142 424.999 130.865 423.915 130.865C422.831 130.865 421.928 129.962 421.928 128.878C421.928 127.795 423.012 126.711 424.096 126.711C424.999 126.711 425.902 127.614 425.902 128.878Z"
        fill="#F37140"
      />
      <path
        d="M154.644 220.08C153.379 220.08 152.657 219.177 152.657 218.094C152.657 217.01 153.379 216.288 154.644 216.288C155.908 216.288 156.811 217.01 156.811 218.094C156.63 219.358 155.908 220.08 154.644 220.08Z"
        fill="#F37140"
      />
      <path
        d="M154.644 90.0498C153.379 90.0498 152.657 89.1469 152.657 88.0633C152.657 86.9797 153.379 86.2573 154.644 86.2573C155.908 86.2573 156.811 86.9797 156.811 88.2439C156.63 89.3274 155.908 90.0498 154.644 90.0498Z"
        fill="#F37140"
      />
      <path
        d="M376.418 128.879C376.418 130.143 375.696 130.865 374.431 130.865C373.348 130.865 372.445 129.962 372.445 129.059C372.445 128.156 373.528 126.892 374.431 126.892C375.696 126.711 376.418 127.614 376.418 128.879Z"
        fill="#F37140"
      />
      <path
        d="M300.206 137.186C300.206 138.269 299.303 138.992 298.219 138.992C297.135 138.992 296.232 138.089 296.413 136.825C296.413 135.741 297.316 135.019 298.4 135.019C299.664 135.019 300.386 135.922 300.206 137.186Z"
        fill="#F37140"
      />
      <path
        d="M257.765 81.0201C257.765 82.1037 257.042 83.0067 255.959 83.0067C254.875 83.0067 253.611 81.7425 253.792 80.8395C253.972 79.756 254.875 79.0336 255.778 79.0336C257.042 79.0336 257.765 79.7559 257.765 81.0201Z"
        fill="#F37140"
      />
      <path
        d="M370.097 220.08C368.833 220.08 368.11 219.358 368.11 218.094C368.11 217.01 368.833 216.288 370.097 216.288C371.361 216.288 372.264 217.01 372.264 218.094C372.083 219.358 371.361 220.08 370.097 220.08Z"
        fill="#F37140"
      />
      <path
        d="M419.4 202.923C419.4 204.188 418.497 204.91 417.414 204.91C416.33 204.91 415.427 204.007 415.427 203.104C415.427 202.02 416.33 200.937 417.414 200.937C418.317 200.756 419.4 201.659 419.4 202.923Z"
        fill="#F37140"
      />
      <path
        d="M264.808 128.698C264.808 127.434 265.711 126.711 266.795 126.711C267.698 126.711 268.781 127.795 268.781 128.698C268.781 129.781 267.878 130.684 266.614 130.684C265.53 130.865 264.808 129.962 264.808 128.698Z"
        fill="#F37140"
      />
      <path
        d="M210.448 128.878C210.448 130.142 209.545 130.865 208.281 130.684C207.198 130.684 206.295 129.601 206.295 128.698C206.295 127.614 207.378 126.711 208.462 126.711C209.726 126.711 210.629 127.795 210.448 128.878Z"
        fill="#F37140"
      />
      <path
        d="M347.522 202.743C347.522 204.007 346.8 204.91 345.716 204.91C344.452 204.91 343.729 204.188 343.729 202.923C343.729 201.84 344.452 200.937 345.535 200.937C346.619 200.756 347.341 201.659 347.522 202.743Z"
        fill="#F37140"
      />
      <path
        d="M311.583 202.924C311.583 204.188 310.68 204.91 309.596 204.91C308.513 204.91 307.791 204.007 307.791 202.924C307.791 201.659 308.693 200.937 309.777 200.937C310.861 200.937 311.583 201.84 311.583 202.924Z"
        fill="#F37140"
      />
      <path
        d="M159.159 130.865C158.075 130.865 156.991 129.962 156.991 128.878C156.991 127.975 158.075 126.711 158.978 126.711C160.062 126.711 160.784 127.434 160.965 128.517C161.145 129.781 160.423 130.684 159.159 130.865Z"
        fill="#F37140"
      />
      <path
        d="M203.947 202.924C203.947 204.188 203.044 204.91 201.78 204.91C200.696 204.91 199.793 204.007 199.793 202.924C199.793 201.84 200.696 200.756 201.78 200.756C203.044 200.756 203.947 201.84 203.947 202.924Z"
        fill="#F37140"
      />
      <path
        d="M365.582 243.378C365.582 244.281 364.498 245.545 363.595 245.545C362.512 245.545 361.609 244.461 361.609 243.378C361.609 242.294 362.331 241.572 363.415 241.391C364.498 241.572 365.582 242.475 365.582 243.378Z"
        fill="#F37140"
      />
      <path
        d="M96.1294 137.727C96.1294 138.991 95.407 139.894 94.3235 139.894C93.2399 139.894 92.3369 138.991 92.3369 137.907C92.3369 136.824 93.0593 135.921 94.1429 135.921C95.2264 135.74 96.1294 136.643 96.1294 137.727Z"
        fill="#F37140"
      />
      <path
        d="M128.275 137.908C128.275 136.643 129.178 135.74 130.262 135.921C131.346 135.921 132.068 136.643 132.068 137.908C132.068 139.172 131.346 140.075 130.262 140.075C128.998 139.894 128.275 139.172 128.275 137.908Z"
        fill="#F37140"
      />
      <path
        d="M132.069 202.924C132.069 204.188 131.346 204.91 130.082 204.91C128.818 204.91 128.096 204.007 128.096 202.743C128.096 201.659 128.818 200.756 129.902 200.756C131.166 200.756 132.069 201.659 132.069 202.924Z"
        fill="#F37140"
      />
      <path
        d="M201.96 135.741C203.044 135.741 203.947 136.825 203.947 137.908C203.947 138.992 203.044 139.714 201.96 139.895C200.877 139.895 199.793 138.992 199.793 137.908C199.974 136.825 200.877 135.741 201.96 135.741Z"
        fill="#F37140"
      />
      <path
        d="M96.1298 202.924C96.1298 204.188 95.2269 204.91 93.9627 204.91C92.8791 204.73 92.1567 203.827 92.1567 202.743C92.1567 201.66 93.0597 200.937 94.1433 200.937C95.4075 200.937 96.1298 201.84 96.1298 202.924Z"
        fill="#F37140"
      />
      <path
        d="M370.277 90.0499C369.013 90.0499 368.11 89.3275 368.11 88.244C368.11 87.1604 368.833 86.438 369.916 86.2574C371.18 86.0768 372.083 86.7992 372.083 88.0634C372.264 89.147 371.361 90.0499 370.277 90.0499Z"
        fill="#F37140"
      />
      <path
        d="M311.583 137.908C311.583 139.172 310.68 139.895 309.596 139.895C308.513 139.895 307.791 138.992 307.791 137.908C307.791 136.644 308.693 135.922 309.777 135.741C310.861 135.741 311.583 136.644 311.583 137.908Z"
        fill="#F37140"
      />
      <path
        d="M347.522 137.908C347.522 139.172 346.8 139.895 345.536 139.895C344.271 139.895 343.549 139.172 343.73 137.728C343.73 136.644 344.452 135.741 345.536 135.741C346.619 135.741 347.522 136.644 347.522 137.908Z"
        fill="#F37140"
      />
      <path
        d="M150.129 81.0201C150.129 82.1037 149.045 83.1873 147.961 83.0067C146.878 83.0067 145.975 81.9231 145.975 80.8395C145.975 79.756 146.697 79.0336 147.961 79.0336C149.226 79.0336 150.129 79.9365 150.129 81.0201Z"
        fill="#F37140"
      />
      <path
        d="M417.413 135.741C418.497 135.741 419.4 136.825 419.4 137.908C419.4 138.992 418.497 139.714 417.413 139.895C416.33 139.895 415.246 138.992 415.246 137.908C415.246 136.825 416.33 135.741 417.413 135.741Z"
        fill="#F37140"
      />
      <path
        d="M296.413 201.84C296.413 200.576 297.135 199.853 298.219 199.853C299.483 199.853 300.205 200.576 300.205 202.021C300.205 203.285 299.302 204.007 298.038 204.007C297.135 203.826 296.413 203.104 296.413 201.84Z"
        fill="#F37140"
      />
      <path
        d="M84.7522 202.201C84.7522 203.285 83.8492 204.007 82.7656 204.007C81.682 204.007 80.7791 203.104 80.9596 201.84C80.9596 200.756 81.8626 200.034 82.9462 200.034C84.2104 200.034 84.9327 200.937 84.7522 202.201Z"
        fill="#F37140"
      />
      <path
        d="M257.765 243.558C257.765 244.642 256.862 245.545 255.959 245.545C255.056 245.545 253.792 244.461 253.792 243.558C253.792 242.475 254.875 241.572 255.959 241.572C257.042 241.572 257.765 242.294 257.765 243.558Z"
        fill="#F37140"
      />
      <path
        d="M161.145 226.401C161.145 227.665 160.242 228.388 158.978 228.388C157.894 228.388 156.991 227.485 156.991 226.401C156.991 225.498 158.075 224.234 159.159 224.234C160.242 224.234 161.145 225.137 161.145 226.401Z"
        fill="#F37140"
      />
      <path
        d="M363.595 196.783C362.331 196.783 361.609 196.061 361.609 194.797C361.609 193.713 362.512 192.629 363.595 192.629C364.498 192.629 365.582 193.713 365.582 194.616C365.763 195.7 364.679 196.603 363.595 196.783Z"
        fill="#F37140"
      />
      <path
        d="M147.961 196.783C146.697 196.783 145.975 195.88 145.975 194.616C145.975 193.532 146.878 192.629 147.961 192.629C148.864 192.629 150.129 193.713 150.129 194.797C150.129 195.88 149.226 196.783 147.961 196.783Z"
        fill="#F37140"
      />
      <path
        d="M161.145 96.3708C161.145 97.4543 160.423 98.1767 159.159 98.1767C158.075 98.1767 156.991 97.2737 156.991 96.1902C156.991 95.1066 158.075 94.2036 159.159 94.2036C160.242 94.2036 161.145 95.2872 161.145 96.3708Z"
        fill="#F37140"
      />
      <path
        d="M150.129 243.378C150.129 244.461 149.045 245.545 148.142 245.545C147.058 245.545 146.156 244.642 146.156 243.378C146.156 242.294 146.878 241.572 147.961 241.391C149.045 241.572 150.129 242.475 150.129 243.378Z"
        fill="#F37140"
      />
      <path
        d="M210.448 96.371C210.448 97.6352 209.726 98.3576 208.462 98.3576C207.378 98.3576 206.475 97.4546 206.475 96.5516C206.475 95.6486 207.559 94.3845 208.462 94.3845C209.545 94.2039 210.448 95.1068 210.448 96.371Z"
        fill="#F37140"
      />
      <path
        d="M264.808 96.1902C264.808 94.926 265.711 94.2036 266.795 94.2036C267.698 94.2036 268.781 95.2872 268.781 96.1902C268.781 97.2737 267.878 98.1767 266.614 98.1767C265.53 98.3573 264.808 97.4543 264.808 96.1902Z"
        fill="#F37140"
      />
      <path
        d="M425.902 193.713C425.902 194.978 425.179 195.7 423.915 195.7C422.831 195.7 421.928 194.797 421.928 193.894C421.928 192.991 422.831 191.727 423.915 191.727C424.999 191.727 425.902 192.63 425.902 193.713Z"
        fill="#F37140"
      />
      <path
        d="M376.418 193.894C376.418 195.158 375.515 195.881 374.431 195.881C373.348 195.881 372.445 194.978 372.445 193.894C372.445 192.991 373.528 191.727 374.431 191.727C375.696 191.727 376.599 192.63 376.418 193.894Z"
        fill="#F37140"
      />
      <path
        d="M264.808 193.713C264.808 192.449 265.711 191.727 266.975 191.727C267.878 191.727 268.962 192.81 268.962 193.713C268.962 194.797 268.059 195.7 266.795 195.7C265.53 195.88 264.808 194.978 264.808 193.713Z"
        fill="#F37140"
      />
      <path
        d="M376.418 96.3708C376.418 97.6349 375.515 98.3573 374.25 98.1767C373.167 97.9961 372.264 97.0932 372.445 96.1902C372.445 95.1066 373.528 94.2036 374.612 94.2036C375.695 94.2036 376.598 95.2872 376.418 96.3708Z"
        fill="#F37140"
      />
      <path
        d="M161.145 63.8631C161.145 65.1272 160.242 65.8496 159.158 65.8496C158.075 65.8496 157.172 64.9466 157.172 63.8631C157.172 62.9601 158.255 61.6959 159.158 61.6959C160.242 61.6959 161.145 62.5989 161.145 63.8631Z"
        fill="#F37140"
      />
      <path
        d="M210.448 193.894C210.448 195.158 209.545 195.881 208.462 195.881C207.378 195.881 206.475 194.978 206.475 193.894C206.475 192.81 207.559 191.727 208.642 191.727C209.726 191.727 210.448 192.63 210.448 193.894Z"
        fill="#F37140"
      />
      <path
        d="M425.902 96.1902C425.902 97.4543 425.179 98.1767 423.915 98.1767C422.831 98.1767 421.928 97.2737 421.748 96.3708C421.567 95.4678 422.651 94.2036 423.734 94.2036C424.999 94.2036 425.902 95.1066 425.902 96.1902Z"
        fill="#F37140"
      />
      <path
        d="M210.448 63.8633C210.448 65.1275 209.726 65.8499 208.462 65.8499C207.378 65.8499 206.475 64.9469 206.475 64.0439C206.475 63.1409 207.559 61.8768 208.462 61.8768C209.545 61.6962 210.448 62.5991 210.448 63.8633Z"
        fill="#F37140"
      />
      <path
        d="M159.158 191.727C160.242 191.727 161.145 192.63 161.145 193.894C161.145 194.978 160.423 195.881 159.339 195.881C158.256 195.881 157.172 194.978 157.172 193.894C156.991 192.81 158.075 191.727 159.158 191.727Z"
        fill="#F37140"
      />
      <path
        d="M147.962 99.2601C146.697 99.2601 145.975 98.3572 146.156 97.093C146.156 96.0094 147.239 95.1064 148.142 95.1064C149.045 95.1064 150.309 96.3706 150.129 97.2736C150.129 98.3572 149.226 99.2601 147.962 99.2601Z"
        fill="#F37140"
      />
      <path
        d="M264.808 63.8631C264.808 62.5989 265.711 61.6959 266.795 61.6959C267.878 61.6959 268.962 62.7795 268.781 63.8631C268.781 64.7661 267.878 65.669 266.795 65.8496C265.711 65.8496 264.808 65.1272 264.808 63.8631Z"
        fill="#F37140"
      />
      <path
        d="M210.448 226.221C210.448 227.485 209.726 228.207 208.462 228.207C207.378 228.207 206.475 227.304 206.475 226.401C206.475 225.498 207.378 224.234 208.462 224.234C209.545 224.234 210.448 225.137 210.448 226.221Z"
        fill="#F37140"
      />
      <path
        d="M264.808 226.221C264.808 224.956 265.711 224.234 266.795 224.234C267.698 224.234 268.781 225.318 268.781 226.221C268.781 227.304 267.878 228.207 266.614 228.207C265.53 228.388 264.808 227.485 264.808 226.221Z"
        fill="#F37140"
      />
      <path
        d="M376.418 226.221C376.418 227.485 375.696 228.207 374.431 228.207C373.348 228.207 372.445 227.304 372.445 226.401C372.445 225.498 373.348 224.234 374.431 224.234C375.515 224.234 376.418 225.137 376.418 226.221Z"
        fill="#F37140"
      />
      <path
        d="M370.097 183.78C371.361 183.78 372.264 184.503 372.264 185.767C372.264 187.031 371.542 187.753 370.277 187.753C369.194 187.753 368.291 187.031 368.291 185.948C367.93 184.683 368.833 183.78 370.097 183.78Z"
        fill="#F37140"
      />
      <path
        d="M363.595 99.2604C362.331 99.2604 361.609 98.538 361.609 97.2738C361.609 96.1903 362.692 95.1067 363.776 95.2873C364.679 95.2873 365.763 96.3708 365.763 97.2738C365.582 98.3574 364.679 99.2604 363.595 99.2604Z"
        fill="#F37140"
      />
      <path
        d="M148.142 148.022C147.058 148.022 146.156 146.938 146.156 145.855C146.156 144.591 147.059 143.868 148.323 144.049C149.406 144.049 150.309 145.132 150.309 146.035C150.129 146.938 149.045 148.022 148.142 148.022Z"
        fill="#F37140"
      />
      <path
        d="M425.902 226.401C425.902 227.665 424.999 228.388 423.734 228.207C422.651 228.027 421.748 227.124 421.748 226.221C421.748 225.137 422.831 224.234 423.915 224.234C425.179 224.234 425.902 225.318 425.902 226.401Z"
        fill="#F37140"
      />
      <path
        d="M147.961 229.291C146.697 229.291 145.975 228.388 145.975 227.124C145.975 226.04 146.878 225.137 147.961 225.137C148.864 225.137 150.129 226.221 150.129 227.304C150.129 228.388 149.226 229.291 147.961 229.291Z"
        fill="#F37140"
      />
      <path
        d="M257.765 146.035C257.765 147.119 257.042 148.022 255.959 148.022C254.875 148.022 253.611 146.758 253.792 145.855C253.972 144.771 254.875 144.049 255.778 144.049C257.042 144.049 257.765 144.771 257.765 146.035Z"
        fill="#F37140"
      />
      <path
        d="M154.644 187.573C153.379 187.573 152.657 186.67 152.657 185.586C152.657 184.503 153.379 183.78 154.644 183.78C155.908 183.78 156.811 184.503 156.811 185.586C156.63 186.851 155.908 187.573 154.644 187.573Z"
        fill="#F37140"
      />
      <path
        d="M473.218 178.543C473.218 179.807 472.315 180.71 471.232 180.71C470.329 180.71 469.064 179.446 469.064 178.543C469.064 177.64 469.967 176.737 471.051 176.556C472.315 176.376 473.218 177.279 473.218 178.543Z"
        fill="#F37140"
      />
      <path
        d="M363.415 144.049C364.498 144.049 365.582 144.952 365.582 146.035C365.582 147.119 364.318 148.202 363.415 148.022C362.512 148.022 361.609 147.119 361.609 146.035C361.428 144.771 362.331 144.049 363.415 144.049Z"
        fill="#F37140"
      />
      <path
        d="M365.582 178.362C365.582 179.446 364.498 180.529 363.595 180.529C362.512 180.529 361.609 179.446 361.609 178.362C361.609 177.278 362.331 176.556 363.415 176.375C364.498 176.556 365.582 177.459 365.582 178.362Z"
        fill="#F37140"
      />
      <path
        d="M257.765 178.543C257.765 179.627 256.862 180.53 255.959 180.53C255.056 180.53 253.792 179.446 253.792 178.543C253.792 177.46 254.875 176.557 255.959 176.557C257.042 176.557 257.765 177.279 257.765 178.543Z"
        fill="#F37140"
      />
      <path
        d="M473.218 146.035C473.218 147.119 472.315 148.022 471.412 148.022C470.509 148.022 469.245 146.938 469.245 146.035C469.245 144.952 470.148 144.049 471.412 144.049C472.496 144.049 473.218 144.771 473.218 146.035Z"
        fill="#F37140"
      />
      <path
        d="M150.129 178.543C150.129 179.627 149.045 180.71 147.961 180.53C146.878 180.53 145.975 179.446 145.975 178.363C145.975 177.279 146.697 176.557 147.961 176.557C149.226 176.557 150.129 177.46 150.129 178.543Z"
        fill="#F37140"
      />
      <path
        d="M80.9595 104.317C80.9595 103.233 81.6819 102.511 82.946 102.511C84.2102 102.511 84.9326 103.414 84.752 104.678C84.752 105.762 83.849 106.484 82.7654 106.484C81.6819 106.484 80.9595 105.581 80.9595 104.317Z"
        fill="#F37140"
      />
      <path
        d="M370.097 118.765C371.361 118.765 372.083 119.487 372.083 120.751C372.083 121.835 371.18 122.738 369.916 122.557C368.833 122.557 368.11 121.835 367.93 120.751C368.11 119.668 368.833 118.765 370.097 118.765Z"
        fill="#F37140"
      />
      <path
        d="M300.205 104.317C300.205 105.581 299.483 106.484 298.219 106.484C297.135 106.484 296.413 105.581 296.413 104.498C296.413 103.233 297.135 102.33 298.399 102.511C299.483 102.511 300.205 103.233 300.205 104.317Z"
        fill="#F37140"
      />
      <path
        d="M363.415 229.291C362.151 229.291 361.428 228.568 361.428 227.304C361.428 226.221 362.331 225.318 363.415 225.318C364.318 225.318 365.582 226.401 365.582 227.304C365.582 228.388 364.679 229.291 363.415 229.291Z"
        fill="#F37140"
      />
      <path
        d="M154.463 155.065C153.38 155.065 152.477 153.981 152.657 152.898C152.838 151.814 153.56 151.272 154.824 151.272C156.088 151.272 156.811 152.175 156.811 153.259C156.63 154.523 155.727 155.246 154.463 155.065Z"
        fill="#F37140"
      />
      <path
        d="M84.752 234.528C84.752 235.792 83.849 236.515 82.7654 236.515C81.6819 236.515 80.9595 235.612 80.9595 234.528C80.9595 233.264 81.6819 232.542 82.7654 232.542C84.0296 232.361 84.752 233.264 84.752 234.528Z"
        fill="#F37140"
      />
      <path
        d="M96.1294 105.22C96.1294 106.484 95.407 107.387 94.1428 107.206C93.0592 107.206 92.1562 106.303 92.1562 105.039C92.1562 103.956 92.8786 103.233 93.9622 103.053C95.2264 103.233 96.1294 104.136 96.1294 105.22Z"
        fill="#F37140"
      />
      <path
        d="M132.068 105.4C132.068 106.664 131.165 107.387 130.081 107.387C128.998 107.387 128.275 106.484 128.275 105.22C128.275 104.136 129.178 103.414 130.262 103.414C131.346 103.414 132.068 104.136 132.068 105.4Z"
        fill="#F37140"
      />
      <path
        d="M202.141 107.387C201.057 107.387 199.974 106.484 199.974 105.401C199.974 104.317 200.877 103.234 201.96 103.234C202.863 103.234 203.947 104.136 203.947 105.039C204.127 106.304 203.224 107.387 202.141 107.387Z"
        fill="#F37140"
      />
      <path
        d="M376.418 63.8631C376.418 65.1272 375.515 65.8496 374.25 65.669C373.167 65.4884 372.264 64.5855 372.445 63.6825C372.445 62.5989 373.528 61.6959 374.612 61.6959C375.695 61.6959 376.598 62.7795 376.418 63.8631Z"
        fill="#F37140"
      />
      <path
        d="M419.4 72.893C419.4 74.1571 418.497 74.8795 417.414 74.8795C416.33 74.8795 415.427 73.9765 415.427 72.893C415.427 71.8094 416.33 70.7258 417.414 70.7258C418.317 70.7258 419.4 71.8094 419.4 72.893Z"
        fill="#F37140"
      />
      <path
        d="M343.73 72.7124C343.73 71.6288 344.452 70.7258 345.536 70.7258C346.619 70.7258 347.522 71.4482 347.522 72.7124C347.522 73.9766 346.8 74.6989 345.536 74.6989C344.271 74.8795 343.549 73.9766 343.73 72.7124Z"
        fill="#F37140"
      />
      <path
        d="M311.583 72.893C311.583 74.1571 310.861 74.8795 309.596 74.8795C308.513 74.8795 307.791 73.9765 307.791 72.893C307.791 71.6288 308.513 70.7258 309.777 70.7258C310.68 70.7258 311.583 71.6288 311.583 72.893Z"
        fill="#F37140"
      />
      <path
        d="M363.415 83.0068C362.331 83.0068 361.428 81.9232 361.609 80.8396C361.609 79.5755 362.512 78.8531 363.776 79.0337C364.86 79.2143 365.763 80.1172 365.582 81.0202C365.582 82.1038 364.318 83.1874 363.415 83.0068Z"
        fill="#F37140"
      />
      <path
        d="M311.583 105.401C311.583 106.665 310.68 107.387 309.596 107.387C308.513 107.387 307.791 106.484 307.791 105.401C307.791 104.137 308.693 103.414 309.777 103.414C310.861 103.414 311.583 104.317 311.583 105.401Z"
        fill="#F37140"
      />
      <path
        d="M347.522 105.401C347.522 106.665 346.8 107.387 345.535 107.387C344.271 107.387 343.729 106.484 343.729 105.22C343.729 104.137 344.452 103.234 345.535 103.234C346.619 103.234 347.522 104.136 347.522 105.401Z"
        fill="#F37140"
      />
      <path
        d="M419.4 170.416C419.4 171.68 418.497 172.403 417.414 172.403C416.33 172.403 415.427 171.5 415.427 170.416C415.427 169.332 416.33 168.249 417.414 168.249C418.317 168.249 419.4 169.332 419.4 170.416Z"
        fill="#F37140"
      />
      <path
        d="M419.4 105.401C419.4 106.484 418.497 107.387 417.233 107.387C416.149 107.387 415.246 106.484 415.246 105.401C415.246 104.317 416.149 103.414 417.233 103.234C418.497 103.234 419.4 104.317 419.4 105.401Z"
        fill="#F37140"
      />
      <path
        d="M343.73 170.235C343.73 169.152 344.452 168.249 345.536 168.249C346.619 168.249 347.522 169.152 347.522 170.235C347.522 171.5 346.8 172.222 345.536 172.222C344.271 172.403 343.549 171.5 343.73 170.235Z"
        fill="#F37140"
      />
      <path
        d="M425.902 63.6825C425.902 64.9467 425.179 65.669 423.915 65.669C422.831 65.669 421.748 64.7661 421.748 63.6825C421.748 62.7795 422.831 61.6959 423.734 61.6959C424.999 61.6959 425.902 62.5989 425.902 63.6825Z"
        fill="#F37140"
      />
      <path
        d="M311.583 170.416C311.583 171.68 310.861 172.402 309.596 172.402C308.513 172.402 307.79 171.68 307.61 170.597C307.61 169.332 308.332 168.429 309.416 168.429C310.68 168.249 311.583 169.152 311.583 170.416Z"
        fill="#F37140"
      />
      <path
        d="M419.4 235.431C419.4 236.515 418.497 237.418 417.233 237.418C416.149 237.418 415.246 236.515 415.246 235.431C415.246 234.348 416.149 233.445 417.233 233.264C418.497 233.264 419.4 234.348 419.4 235.431Z"
        fill="#F37140"
      />
      <path
        d="M148.142 66.7529C146.878 66.7529 146.156 66.0305 146.156 64.7663C146.156 63.6828 147.058 62.7798 148.142 62.7798C149.045 62.7798 150.309 63.8634 150.309 64.7663C150.129 65.8499 149.226 66.7529 148.142 66.7529Z"
        fill="#F37140"
      />
      <path
        d="M347.522 235.431C347.522 236.695 346.8 237.418 345.535 237.418C344.271 237.418 343.729 236.515 343.729 235.251C343.729 234.167 344.452 233.264 345.535 233.264C346.619 233.264 347.522 234.167 347.522 235.431Z"
        fill="#F37140"
      />
      <path
        d="M311.583 235.431C311.583 236.695 310.68 237.418 309.596 237.418C308.513 237.418 307.791 236.515 307.791 235.431C307.791 234.167 308.513 233.264 309.777 233.264C310.861 233.264 311.583 234.167 311.583 235.431Z"
        fill="#F37140"
      />
      <path
        d="M132.068 72.8931C132.068 74.1573 131.345 74.8797 130.081 74.8797C128.997 74.8797 128.094 73.9767 128.275 72.7125C128.275 71.6289 129.178 70.9066 130.262 70.9066C131.345 70.726 132.068 71.6289 132.068 72.8931Z"
        fill="#F37140"
      />
      <path
        d="M201.96 172.402C200.877 172.402 199.793 171.499 199.974 170.416C199.974 169.332 201.057 168.429 202.141 168.429C203.044 168.429 203.947 169.332 204.127 170.416C203.947 171.499 203.044 172.402 201.96 172.402Z"
        fill="#F37140"
      />
      <path
        d="M128.276 170.416C128.276 169.152 128.999 168.249 130.263 168.249C131.347 168.249 132.069 168.971 132.249 170.055C132.249 171.319 131.708 172.222 130.444 172.222C128.999 172.403 128.276 171.68 128.276 170.416Z"
        fill="#F37140"
      />
      <path
        d="M96.1298 170.235C96.1298 171.5 95.4075 172.403 94.1433 172.222C93.0597 172.222 92.1567 171.319 92.1567 170.235C92.1567 169.152 92.8791 168.249 93.9627 168.249C95.2269 168.249 96.1298 169.152 96.1298 170.235Z"
        fill="#F37140"
      />
      <path
        d="M96.1294 72.7124C96.1294 73.9766 95.407 74.8796 94.1428 74.699C93.0592 74.699 92.1562 73.796 92.1562 72.5318C92.1562 71.4482 92.8786 70.7259 93.9622 70.5453C95.2264 70.7259 96.1294 71.6289 96.1294 72.7124Z"
        fill="#F37140"
      />
      <path
        d="M201.96 237.418C200.877 237.418 199.793 236.515 199.974 235.431C199.974 234.348 201.057 233.445 202.141 233.445C203.044 233.445 203.947 234.348 204.127 235.431C203.947 236.515 203.044 237.418 201.96 237.418Z"
        fill="#F37140"
      />
      <path
        d="M300.205 71.9901C300.205 73.2543 299.302 73.9767 298.219 73.9767C297.135 73.9767 296.413 73.0737 296.413 71.9901C296.413 70.726 297.135 70.0036 298.219 70.0036C299.483 69.823 300.205 70.726 300.205 71.9901Z"
        fill="#F37140"
      />
      <path
        d="M300.205 234.528C300.205 235.792 299.302 236.695 298.219 236.515C297.135 236.515 296.413 235.612 296.413 234.528C296.413 233.264 297.135 232.542 298.219 232.542C299.483 232.361 300.205 233.264 300.205 234.528Z"
        fill="#F37140"
      />
      <path
        d="M300.205 169.513C300.205 170.777 299.302 171.5 298.219 171.5C297.135 171.5 296.413 170.597 296.413 169.513C296.413 168.249 297.135 167.527 298.219 167.527C299.483 167.346 300.205 168.249 300.205 169.513Z"
        fill="#F37140"
      />
      <path
        d="M148.142 111.541C149.226 111.541 150.309 112.444 150.309 113.528C150.309 114.611 149.226 115.695 148.142 115.514C147.239 115.514 146.336 114.611 146.156 113.528C145.975 112.264 146.878 111.541 148.142 111.541Z"
        fill="#F37140"
      />
      <path
        d="M257.765 113.528C257.765 114.611 256.862 115.514 255.959 115.514C255.056 115.514 253.792 114.431 253.792 113.528C253.792 112.444 254.875 111.541 255.959 111.541C257.042 111.541 257.765 112.264 257.765 113.528Z"
        fill="#F37140"
      />
      <path
        d="M132.069 235.431C132.069 236.695 131.346 237.418 130.082 237.418C128.818 237.418 128.096 236.515 128.096 235.251C128.096 234.167 128.818 233.264 129.902 233.264C131.166 233.264 132.069 234.167 132.069 235.431Z"
        fill="#F37140"
      />
      <path
        d="M363.415 115.514C362.331 115.514 361.428 114.431 361.428 113.347C361.428 112.083 362.331 111.36 363.595 111.36C364.679 111.36 365.582 112.444 365.582 113.347C365.582 114.431 364.498 115.514 363.415 115.514Z"
        fill="#F37140"
      />
      <path
        d="M84.7523 169.513C84.7523 170.777 83.8493 171.5 82.5851 171.5C81.5016 171.5 80.7792 170.597 80.9598 169.333C80.9598 168.069 81.6822 167.346 82.7657 167.346C84.0299 167.527 84.9329 168.249 84.7523 169.513Z"
        fill="#F37140"
      />
      <path
        d="M161.145 161.386C161.145 162.47 160.423 163.192 159.159 163.192C158.075 163.192 156.991 162.289 156.991 161.206C156.991 160.122 158.075 159.219 159.159 159.219C160.242 159.219 161.145 160.303 161.145 161.386Z"
        fill="#F37140"
      />
      <path
        d="M80.9595 71.8094C80.9595 70.5453 81.6819 69.8229 82.7654 69.8229C84.0296 69.8229 84.752 70.5453 84.752 71.99C84.752 73.2542 83.849 73.9766 82.5848 73.9766C81.6819 73.796 80.9595 73.0736 80.9595 71.8094Z"
        fill="#F37140"
      />
      <path
        d="M96.1299 235.431C96.1299 236.695 95.2269 237.418 94.1434 237.418C93.0598 237.237 92.3374 236.334 92.3374 235.251C92.3374 234.167 93.2404 233.445 94.324 233.445C95.4075 233.445 96.1299 234.348 96.1299 235.431Z"
        fill="#F37140"
      />
      <path
        d="M210.448 161.386C210.448 162.65 209.545 163.373 208.462 163.373C207.378 163.373 206.475 162.47 206.475 161.386C206.475 160.303 207.559 159.219 208.642 159.219C209.726 159.219 210.448 160.122 210.448 161.386Z"
        fill="#F37140"
      />
      <path
        d="M363.595 164.276C362.331 164.276 361.609 163.553 361.609 162.289C361.609 161.205 362.692 160.122 363.595 160.302C364.498 160.302 365.582 161.386 365.582 162.289C365.582 163.192 364.679 164.095 363.595 164.276Z"
        fill="#F37140"
      />
      <path
        d="M363.595 66.7528C362.331 66.7528 361.609 66.0304 361.609 64.7663C361.609 63.6827 362.692 62.5991 363.776 62.7797C364.679 62.7797 365.763 63.8633 365.763 64.7663C365.582 65.8498 364.679 66.7528 363.595 66.7528Z"
        fill="#F37140"
      />
      <path
        d="M264.808 161.206C264.808 159.942 265.711 159.219 266.975 159.219C267.878 159.219 268.962 160.303 268.962 161.206C268.962 162.289 268.059 163.192 266.795 163.192C265.53 163.373 264.808 162.47 264.808 161.206Z"
        fill="#F37140"
      />
      <path
        d="M473.218 113.528C473.218 114.611 472.315 115.514 471.412 115.514C470.509 115.514 469.245 114.431 469.245 113.528C469.245 112.444 470.148 111.541 471.412 111.541C472.496 111.541 473.218 112.264 473.218 113.528Z"
        fill="#F37140"
      />
      <path
        d="M147.962 164.276C146.697 164.276 145.975 163.373 146.156 162.108C146.156 161.205 147.239 160.122 148.142 160.303C149.045 160.303 150.309 161.567 150.129 162.47C150.129 163.373 149.226 164.276 147.962 164.276Z"
        fill="#F37140"
      />
      <path
        d="M425.902 161.386C425.902 162.65 424.999 163.373 423.734 163.192C422.651 163.012 421.748 162.109 421.748 161.206C421.748 160.122 422.831 159.219 423.915 159.219C425.179 159.219 425.902 160.303 425.902 161.386Z"
        fill="#F37140"
      />
      <path
        d="M154.644 118.765C155.908 118.765 156.63 119.487 156.63 120.751C156.63 121.835 155.727 122.557 154.463 122.557C153.379 122.557 152.657 121.654 152.657 120.571C152.657 119.487 153.56 118.765 154.644 118.765Z"
        fill="#F37140"
      />
      <path
        d="M376.418 161.386C376.418 162.65 375.515 163.373 374.431 163.373C373.348 163.373 372.445 162.47 372.445 161.386C372.445 160.483 373.528 159.219 374.431 159.219C375.696 159.219 376.418 160.122 376.418 161.386Z"
        fill="#F37140"
      />
      <path
        d="M350.231 161.206C350.231 160.122 351.134 159.219 352.037 159.219C353.12 159.219 354.023 160.122 354.023 161.386C354.023 162.65 353.301 163.373 352.217 163.373C350.953 163.373 350.231 162.47 350.231 161.206Z"
        fill="#F37140"
      />
      <path
        d="M389.962 161.386C389.962 162.65 389.059 163.373 387.976 163.373C386.892 163.373 386.17 162.65 386.17 161.386C386.17 160.122 387.073 159.219 388.156 159.219C389.24 159.219 389.962 160.303 389.962 161.386Z"
        fill="#F37140"
      />
      <path
        d="M340.659 161.386C340.659 162.65 339.937 163.373 338.673 163.373C337.589 163.373 336.867 162.65 336.867 161.386C336.867 160.122 337.589 159.219 338.854 159.219C339.756 159.219 340.659 160.122 340.659 161.386Z"
        fill="#F37140"
      />
      <path
        d="M430.236 234.528C430.236 235.612 429.333 236.515 428.249 236.515C427.166 236.515 426.263 235.431 426.444 234.348C426.444 233.264 427.166 232.542 428.43 232.542C429.514 232.542 430.417 233.264 430.236 234.528Z"
        fill="#F37140"
      />
      <path
        d="M141.279 122.557C140.015 122.557 139.293 121.835 139.293 120.571C139.293 119.487 140.015 118.765 141.099 118.765C142.363 118.765 143.266 119.487 143.266 120.571C143.266 121.654 142.363 122.557 141.279 122.557Z"
        fill="#F37140"
      />
      <path
        d="M118.884 122.557C117.801 122.557 116.717 121.654 116.717 120.57C116.717 119.487 117.62 118.584 118.884 118.584C120.149 118.584 120.871 119.306 120.871 120.39C120.871 121.834 120.149 122.557 118.884 122.557Z"
        fill="#F37140"
      />
      <path
        d="M118.885 220.08C117.802 220.08 116.718 219.358 116.718 218.274C116.718 217.191 117.621 216.288 118.705 216.288C119.969 216.288 120.691 216.83 120.691 218.094C120.872 219.177 120.149 220.08 118.885 220.08Z"
        fill="#F37140"
      />
      <path
        d="M69.4015 118.764C70.6657 118.764 71.3881 119.667 71.2075 120.931C71.0269 122.015 70.3045 122.737 69.2209 122.737C67.9568 122.737 67.2344 121.834 67.2344 120.751C67.415 119.487 68.3179 118.764 69.4015 118.764Z"
        fill="#F37140"
      />
      <path
        d="M300.747 161.205C300.747 160.122 301.65 159.038 302.734 159.219C303.817 159.219 304.72 160.122 304.54 161.386C304.54 162.47 303.817 163.192 302.734 163.192C301.47 163.373 300.747 162.47 300.747 161.205Z"
        fill="#F37140"
      />
      <path
        d="M69.2207 220.081C68.1372 220.081 67.4148 219.178 67.4148 218.094C67.4148 216.83 68.4984 216.108 69.7625 216.288C70.8461 216.469 71.5685 217.191 71.3879 218.275C71.2073 219.539 70.4849 220.261 69.2207 220.081Z"
        fill="#F37140"
      />
      <path
        d="M437.279 243.558C437.279 244.642 436.376 245.545 435.293 245.545C434.209 245.545 433.487 244.642 433.487 243.558C433.487 242.294 434.209 241.572 435.473 241.572C436.557 241.572 437.279 242.294 437.279 243.558Z"
        fill="#F37140"
      />
      <path
        d="M437.279 211.05C437.279 212.134 436.376 213.218 435.293 213.218C434.209 213.218 433.487 212.315 433.306 211.412C433.306 210.148 434.029 209.245 435.112 209.245C436.376 209.064 437.279 209.786 437.279 211.05Z"
        fill="#F37140"
      />
      <path
        d="M190.582 118.765C191.666 118.765 192.569 119.668 192.569 120.751C192.569 121.835 191.485 122.557 190.402 122.557C189.318 122.557 188.596 121.835 188.596 120.571C188.596 119.487 189.318 118.765 190.582 118.765Z"
        fill="#F37140"
      />
      <path
        d="M433.306 113.528C433.306 112.264 434.029 111.541 435.293 111.541C436.557 111.541 437.279 112.444 437.099 113.708C437.099 114.792 436.196 115.695 435.293 115.695C434.39 115.695 433.487 114.792 433.306 113.528Z"
        fill="#F37140"
      />
      <path
        d="M397.548 211.05C397.548 209.786 398.27 209.064 399.534 209.064C400.618 209.064 401.34 209.786 401.34 211.05C401.34 212.315 400.618 213.218 399.354 213.218C398.27 213.037 397.548 212.134 397.548 211.05Z"
        fill="#F37140"
      />
      <path
        d="M174.509 161.386C174.509 162.65 173.606 163.373 172.523 163.373C171.439 163.373 170.717 162.47 170.717 161.206C170.717 160.122 171.62 159.4 172.703 159.4C173.787 159.219 174.69 160.122 174.509 161.386Z"
        fill="#F37140"
      />
      <path
        d="M401.341 81.0201C401.341 82.2843 400.438 83.1873 399.354 83.1873C398.27 83.1873 397.367 82.2843 397.548 81.0201C397.548 79.7559 398.27 79.2142 399.354 79.0336C400.618 79.0336 401.341 79.7559 401.341 81.0201Z"
        fill="#F37140"
      />
      <path
        d="M433.306 81.0201C433.306 79.7559 434.209 79.0336 435.293 79.0336C436.557 79.0336 437.279 79.9365 437.099 81.2007C437.099 82.2843 436.196 83.0067 435.112 83.1873C434.209 83.0067 433.306 82.1037 433.306 81.0201Z"
        fill="#F37140"
      />
      <path
        d="M401.34 113.528C401.34 114.792 400.437 115.695 399.354 115.514C398.27 115.514 397.548 114.611 397.548 113.347C397.548 112.264 398.27 111.541 399.354 111.541C400.618 111.541 401.34 112.264 401.34 113.528Z"
        fill="#F37140"
      />
      <path
        d="M138.57 161.206C138.57 162.47 137.848 163.373 136.584 163.192C135.5 163.192 134.778 162.47 134.778 161.206C134.778 159.941 135.681 159.038 136.764 159.038C137.848 159.219 138.57 160.122 138.57 161.206Z"
        fill="#F37140"
      />
      <path
        d="M121.233 161.025C121.233 159.941 122.136 159.219 123.219 159.219C124.303 159.219 125.206 160.303 125.025 161.567C124.845 162.65 124.122 163.373 123.039 163.192C121.955 163.192 121.233 162.289 121.233 161.025Z"
        fill="#F37140"
      />
      <path
        d="M85.2939 161.206C85.2939 160.122 86.1969 159.219 87.2805 159.219C88.3641 159.219 89.0864 160.303 89.0864 161.386C89.0864 162.47 88.3641 163.192 87.0999 163.192C86.0163 163.373 85.2939 162.47 85.2939 161.206Z"
        fill="#F37140"
      />
      <path
        d="M221.826 113.528C221.826 114.611 220.743 115.514 219.659 115.514C218.575 115.514 217.853 114.611 217.853 113.528C217.853 112.264 218.575 111.541 219.84 111.541C221.104 111.541 221.826 112.444 221.826 113.528Z"
        fill="#F37140"
      />
      <path
        d="M185.887 113.528C185.887 114.792 184.984 115.514 183.9 115.514C182.817 115.334 182.094 114.431 182.094 113.347C182.094 112.264 182.817 111.541 184.081 111.541C185.165 111.541 185.887 112.264 185.887 113.528Z"
        fill="#F37140"
      />
      <path
        d="M284.855 122.558C283.59 122.558 282.688 121.655 282.688 120.571C282.688 119.487 283.59 118.584 284.855 118.765C285.938 118.765 286.661 119.487 286.841 120.571C286.841 121.835 286.119 122.558 284.855 122.558Z"
        fill="#F37140"
      />
      <path
        d="M221.826 210.87C221.826 211.954 221.104 213.037 219.839 213.037C218.936 213.037 218.033 212.315 217.853 211.231C217.672 209.967 218.395 209.064 219.659 209.064C220.923 208.883 221.826 209.786 221.826 210.87Z"
        fill="#F37140"
      />
      <path
        d="M214.963 169.513C214.963 170.777 214.06 171.5 212.977 171.5C211.893 171.5 210.99 170.416 211.171 169.333C211.171 168.249 211.893 167.527 213.157 167.527C214.241 167.527 214.963 168.249 214.963 169.513Z"
        fill="#F37140"
      />
      <path
        d="M210.99 71.8094C210.99 70.5453 211.893 69.8229 212.977 69.8229C214.06 69.8229 214.782 70.5453 214.782 71.8094C214.782 73.0736 213.88 73.9766 212.796 73.9766C211.893 73.9766 210.99 73.0736 210.99 71.8094Z"
        fill="#F37140"
      />
      <path
        d="M374.431 110.638C375.696 110.638 376.418 111.361 376.418 112.625C376.418 113.708 375.515 114.611 374.612 114.611C373.528 114.611 372.445 113.708 372.445 112.625C372.445 111.541 373.348 110.638 374.431 110.638Z"
        fill="#F37140"
      />
      <path
        d="M264.266 169.513C264.266 170.777 263.363 171.5 262.28 171.5C261.196 171.5 260.293 170.416 260.474 169.333C260.474 168.249 261.377 167.527 262.46 167.527C263.544 167.527 264.447 168.249 264.266 169.513Z"
        fill="#F37140"
      />
      <path
        d="M264.266 71.8094C264.266 73.0736 263.363 73.9766 262.28 73.9766C261.196 73.9766 260.293 73.0736 260.293 71.8094C260.293 70.7259 261.016 70.0035 262.099 69.8229C263.544 69.8229 264.266 70.7259 264.266 71.8094Z"
        fill="#F37140"
      />
      <path
        d="M453.353 152.176C454.617 152.176 455.339 153.079 455.339 154.162C455.339 155.246 454.436 156.149 453.172 155.968C452.088 155.968 451.366 155.246 451.366 154.162C451.185 152.898 452.088 152.176 453.353 152.176Z"
        fill="#F37140"
      />
      <path
        d="M185.887 211.05C185.887 212.315 185.165 213.218 183.9 213.218C182.817 213.218 181.914 212.315 181.914 211.05C181.914 209.967 182.636 209.244 183.72 209.064C185.165 209.064 185.887 209.786 185.887 211.05Z"
        fill="#F37140"
      />
      <path
        d="M316.279 110.638C317.543 110.638 318.446 111.541 318.446 112.625C318.446 113.708 317.362 114.611 316.279 114.611C315.195 114.611 314.473 113.708 314.292 112.625C314.292 111.361 315.014 110.638 316.279 110.638Z"
        fill="#F37140"
      />
      <path
        d="M381.294 152.176C382.558 152.176 383.28 152.898 383.28 154.162C383.28 155.426 382.558 156.149 381.294 156.149C380.21 156.149 379.307 155.426 379.307 154.343C379.307 153.079 380.21 152.176 381.294 152.176Z"
        fill="#F37140"
      />
      <path
        d="M266.795 110.638C268.059 110.638 268.962 111.541 268.962 112.625C268.962 113.708 267.878 114.611 266.795 114.611C265.711 114.611 264.989 113.708 264.808 112.625C264.808 111.361 265.53 110.638 266.795 110.638Z"
        fill="#F37140"
      />
      <path
        d="M401.341 243.558C401.341 244.822 400.438 245.725 399.354 245.725C398.27 245.725 397.367 244.822 397.548 243.558C397.548 242.294 398.27 241.752 399.354 241.572C400.618 241.572 401.341 242.294 401.341 243.558Z"
        fill="#F37140"
      />
      <path
        d="M264.266 234.528C264.266 235.792 263.363 236.515 262.28 236.515C261.196 236.515 260.474 235.431 260.474 234.348C260.474 233.264 261.377 232.542 262.46 232.542C263.544 232.542 264.447 233.264 264.266 234.528Z"
        fill="#F37140"
      />
      <path
        d="M430.236 169.332C430.236 170.596 429.513 171.499 428.249 171.499C427.166 171.499 426.263 170.596 426.263 169.513C426.263 168.429 426.985 167.526 428.069 167.526C429.513 167.346 430.236 168.068 430.236 169.332Z"
        fill="#F37140"
      />
      <path
        d="M158.978 110.638C160.242 110.638 160.965 111.361 160.965 112.625C160.965 113.708 160.242 114.611 159.159 114.611C158.075 114.611 156.991 113.708 156.991 112.625C156.991 111.541 157.894 110.638 158.978 110.638Z"
        fill="#F37140"
      />
      <path
        d="M426.443 71.9902C426.443 70.726 427.166 70.0037 428.43 70.0037C429.513 70.0037 430.236 70.726 430.236 71.8096C430.236 73.0738 429.513 73.9768 428.43 73.9768C427.346 73.9768 426.443 73.0738 426.443 71.9902Z"
        fill="#F37140"
      />
      <path
        d="M214.963 137.186C214.783 138.269 213.88 139.172 212.796 138.992C211.713 138.811 210.99 137.908 211.171 136.644C211.352 135.561 212.074 134.838 213.158 135.019C214.241 135.019 214.963 135.922 214.963 137.186Z"
        fill="#F37140"
      />
      <path
        d="M69.2209 86.2571C70.4851 86.2571 71.2075 86.9794 71.2075 88.2436C71.2075 89.3272 70.4851 90.0496 69.4015 90.2302C68.1373 90.2302 67.2344 89.5078 67.2344 88.4242C67.2344 87.1601 68.1373 86.2571 69.2209 86.2571Z"
        fill="#F37140"
      />
      <path
        d="M237.899 155.968C236.635 155.968 235.913 155.246 235.913 153.982C235.913 152.898 236.635 152.176 237.899 152.176C239.163 152.176 240.066 152.898 240.066 154.162C239.886 155.246 238.983 155.968 237.899 155.968Z"
        fill="#F37140"
      />
      <path
        d="M214.963 234.528C214.963 235.792 214.06 236.695 212.977 236.515C211.893 236.515 210.99 235.612 211.171 234.348C211.171 233.264 211.893 232.542 212.977 232.542C214.06 232.361 214.963 233.264 214.963 234.528Z"
        fill="#F37140"
      />
      <path
        d="M334.158 118.765C335.422 118.765 336.144 119.487 336.144 120.751C336.144 121.835 335.422 122.557 334.158 122.557C332.894 122.557 331.991 121.654 331.991 120.571C332.171 119.668 333.074 118.765 334.158 118.765Z"
        fill="#F37140"
      />
      <path
        d="M100.825 110.638C102.089 110.638 102.992 111.541 102.811 112.624C102.811 113.708 101.728 114.611 100.644 114.611C99.5605 114.611 98.8381 113.708 98.8381 112.624C98.8381 111.36 99.5605 110.638 100.825 110.638Z"
        fill="#F37140"
      />
      <path
        d="M166.021 155.968C164.757 155.968 163.854 155.246 163.854 153.981C163.854 152.898 164.757 151.995 166.021 151.995C167.105 151.995 167.827 152.717 168.008 153.801C168.008 155.246 167.285 155.968 166.021 155.968Z"
        fill="#F37140"
      />
      <path
        d="M141.099 216.288C142.363 216.288 143.266 217.191 143.266 218.274C143.266 219.358 142.363 220.261 141.099 220.261C140.015 220.261 139.293 219.539 139.112 218.455C139.112 217.191 139.834 216.288 141.099 216.288Z"
        fill="#F37140"
      />
      <path
        d="M406.036 155.065C404.772 155.065 404.049 154.343 404.049 153.078C404.049 151.995 404.772 151.272 405.855 151.272C407.119 151.272 408.022 151.995 408.022 153.078C408.203 154.162 407.3 155.065 406.036 155.065Z"
        fill="#F37140"
      />
      <path
        d="M389.962 63.8631C389.962 65.1272 389.059 65.8496 387.976 65.8496C386.892 65.8496 386.17 65.1272 386.17 63.8631C386.17 62.5989 387.073 61.6959 388.156 61.6959C389.24 61.6959 389.962 62.7795 389.962 63.8631Z"
        fill="#F37140"
      />
      <path
        d="M118.704 90.0496C117.62 90.0496 116.717 89.1466 116.717 88.063C116.717 86.9795 117.801 86.2571 118.884 86.2571C119.968 86.2571 120.69 86.9794 120.69 88.2436C120.69 89.5078 119.968 90.2302 118.704 90.0496Z"
        fill="#F37140"
      />
      <path
        d="M356.733 151.272C357.816 151.272 358.719 152.175 358.719 153.259C358.719 154.343 357.636 155.065 356.552 155.065C355.469 155.065 354.746 154.162 354.746 153.078C354.566 151.995 355.469 151.272 356.733 151.272Z"
        fill="#F37140"
      />
      <path
        d="M334.158 151.272C335.422 151.272 336.145 151.995 336.145 153.078C336.145 154.162 335.422 154.884 334.339 155.065C333.074 155.065 332.171 154.343 332.171 153.259C331.991 152.175 332.894 151.272 334.158 151.272Z"
        fill="#F37140"
      />
      <path
        d="M356.552 122.557C355.288 122.557 354.565 121.835 354.565 120.571C354.565 119.487 355.288 118.765 356.371 118.765C357.636 118.765 358.539 119.487 358.539 120.571C358.719 121.654 357.816 122.557 356.552 122.557Z"
        fill="#F37140"
      />
      <path
        d="M100.825 175.653C102.089 175.653 102.992 176.556 102.812 177.64C102.812 178.723 101.548 179.626 100.645 179.626C99.561 179.626 98.8386 178.723 98.8386 177.64C98.8386 176.376 99.561 175.653 100.825 175.653Z"
        fill="#F37140"
      />
      <path
        d="M100.825 240.669C102.089 240.669 102.992 241.572 102.992 242.655C102.992 243.739 101.909 244.642 100.825 244.642C99.7416 244.642 99.0192 243.739 98.8386 242.655C98.8386 241.391 99.561 240.669 100.825 240.669Z"
        fill="#F37140"
      />
      <path
        d="M284.855 155.065C283.771 155.065 282.688 154.343 282.688 153.259C282.688 152.175 283.59 151.272 284.674 151.272C285.938 151.272 286.661 151.995 286.661 153.078C286.841 154.343 286.119 155.065 284.855 155.065Z"
        fill="#F37140"
      />
      <path
        d="M159.158 175.653C160.423 175.653 161.145 176.376 161.145 177.64C161.145 178.723 160.242 179.626 159.339 179.626C158.256 179.626 157.172 178.723 157.172 177.64C156.991 176.556 157.894 175.653 159.158 175.653Z"
        fill="#F37140"
      />
      <path
        d="M159.159 240.669C160.423 240.669 161.145 241.391 160.964 242.655C160.964 243.739 160.062 244.642 158.978 244.642C157.894 244.642 156.811 243.558 156.811 242.655C156.991 241.391 157.894 240.669 159.159 240.669Z"
        fill="#F37140"
      />
      <path
        d="M141.279 86.2573C142.363 86.2573 143.266 87.1603 143.266 88.2439C143.266 89.3274 142.182 90.0498 141.099 90.0498C140.015 90.0498 139.293 89.3274 139.293 88.0633C139.112 86.9797 140.015 86.2573 141.279 86.2573Z"
        fill="#F37140"
      />
      <path
        d="M389.962 128.698C389.962 129.962 389.24 130.684 387.976 130.684C386.892 130.684 386.17 129.962 385.989 128.878C385.989 127.614 386.712 126.711 387.795 126.711C389.059 126.711 389.962 127.614 389.962 128.698Z"
        fill="#F37140"
      />
      <path
        d="M374.431 208.161C375.696 208.161 376.418 208.883 376.418 210.147C376.418 211.231 375.515 212.134 374.612 212.134C373.528 212.134 372.445 211.231 372.445 210.147C372.445 209.064 373.348 208.161 374.431 208.161Z"
        fill="#F37140"
      />
      <path
        d="M190.582 155.065C189.318 155.065 188.596 154.343 188.596 153.078C188.596 151.995 189.318 151.272 190.582 151.272C191.847 151.272 192.75 151.995 192.75 153.259C192.75 154.343 191.666 155.065 190.582 155.065Z"
        fill="#F37140"
      />
      <path
        d="M266.795 175.653C268.059 175.653 268.962 176.556 268.781 177.64C268.781 178.723 267.517 179.626 266.614 179.626C265.53 179.626 264.808 178.723 264.808 177.64C264.808 176.376 265.53 175.653 266.795 175.653Z"
        fill="#F37140"
      />
      <path
        d="M354.023 63.6827C354.023 64.9468 353.301 65.8498 352.037 65.6692C350.953 65.6692 350.231 64.9468 350.231 63.6827C350.231 62.4185 351.134 61.5155 352.217 61.5155C353.301 61.6961 354.023 62.5991 354.023 63.6827Z"
        fill="#F37140"
      />
      <path
        d="M100.825 78.1302C102.089 78.1302 102.992 79.0332 102.992 80.1168C102.992 81.2004 101.908 82.1033 100.825 82.1033C99.7411 82.1033 99.0187 81.2004 98.8381 80.1168C98.8381 78.8526 99.5605 78.1302 100.825 78.1302Z"
        fill="#F37140"
      />
      <path
        d="M318.265 177.459C318.265 178.543 317.362 179.626 316.279 179.626C315.195 179.626 314.292 178.904 314.292 177.82C314.112 176.556 314.834 175.653 316.098 175.653C317.362 175.653 318.265 176.376 318.265 177.459Z"
        fill="#F37140"
      />
      <path
        d="M190.582 220.08C189.318 220.08 188.596 219.358 188.596 218.094C188.596 217.01 189.318 216.288 190.582 216.288C191.847 216.288 192.75 217.01 192.75 218.094C192.75 219.358 191.847 220.08 190.582 220.08Z"
        fill="#F37140"
      />
      <path
        d="M316.098 208.161C317.362 208.161 318.265 208.883 318.265 209.967C318.265 211.05 317.182 211.953 316.279 212.134C315.195 212.134 314.292 211.412 314.292 210.328C314.112 209.064 314.834 208.161 316.098 208.161Z"
        fill="#F37140"
      />
      <path
        d="M376.418 177.64C376.418 178.723 375.515 179.626 374.431 179.626C373.348 179.626 372.445 178.543 372.445 177.459C372.445 176.376 373.348 175.653 374.431 175.653C375.876 175.653 376.599 176.376 376.418 177.64Z"
        fill="#F37140"
      />
      <path
        d="M141.279 155.065C140.015 155.065 139.293 154.343 139.293 153.079C139.293 151.995 140.015 151.273 141.099 151.092C142.363 151.092 143.266 151.814 143.266 152.898C143.266 154.162 142.363 155.065 141.279 155.065Z"
        fill="#F37140"
      />
      <path
        d="M430.236 104.317C430.236 105.581 429.513 106.484 428.249 106.484C427.166 106.484 426.263 105.581 426.263 104.498C426.263 103.414 426.985 102.511 428.069 102.511C429.513 102.331 430.236 103.053 430.236 104.317Z"
        fill="#F37140"
      />
      <path
        d="M284.674 216.288C285.938 216.288 286.661 217.01 286.841 218.094C286.841 219.177 286.119 219.9 285.035 220.08C283.771 220.08 282.868 219.358 282.868 218.274C282.688 217.191 283.59 216.288 284.674 216.288Z"
        fill="#F37140"
      />
      <path
        d="M118.705 155.065C117.621 155.065 116.718 154.162 116.718 153.078C116.718 151.995 117.802 151.272 118.885 151.272C119.969 151.272 120.691 151.995 120.691 153.259C120.691 154.523 119.969 155.246 118.705 155.065Z"
        fill="#F37140"
      />
      <path
        d="M264.266 104.498C264.266 105.762 263.363 106.484 262.28 106.484C261.196 106.484 260.293 105.401 260.474 104.317C260.474 103.234 261.377 102.511 262.46 102.511C263.544 102.511 264.266 103.234 264.266 104.498Z"
        fill="#F37140"
      />
      <path
        d="M214.963 104.498C214.963 105.581 214.06 106.665 212.977 106.484C211.893 106.484 210.99 105.581 210.99 104.317C210.99 103.234 211.712 102.511 212.796 102.331C214.06 102.331 214.963 103.234 214.963 104.498Z"
        fill="#F37140"
      />
      <path
        d="M340.479 63.6825C340.479 64.9467 339.757 65.669 338.492 65.669C337.409 65.669 336.686 64.9466 336.506 63.8631C336.506 62.5989 337.228 61.6959 338.312 61.6959C339.576 61.6959 340.479 62.5989 340.479 63.6825Z"
        fill="#F37140"
      />
      <path
        d="M69.2209 155.065C67.9568 155.065 67.2344 154.162 67.2344 153.078C67.2344 151.995 68.3179 151.272 69.4015 151.272C70.4851 151.272 71.2075 152.175 71.2075 153.259C71.3881 154.523 70.4851 155.246 69.2209 155.065Z"
        fill="#F37140"
      />
      <path
        d="M185.887 178.362C185.887 179.626 185.165 180.529 184.081 180.529C182.997 180.529 182.094 179.626 182.094 178.362C182.094 177.098 182.817 176.556 183.9 176.375C185.165 176.375 185.887 177.098 185.887 178.362Z"
        fill="#F37140"
      />
      <path
        d="M221.826 178.724C221.646 179.807 220.743 180.71 219.659 180.53C218.575 180.349 218.034 179.446 218.034 178.362C218.034 177.098 218.937 176.376 220.201 176.557C221.284 176.557 222.007 177.459 221.826 178.724Z"
        fill="#F37140"
      />
      <path
        d="M437.279 146.036C437.279 147.119 436.376 148.203 435.293 148.203C434.209 148.203 433.487 147.3 433.306 146.397C433.306 145.133 434.029 144.23 435.112 144.23C436.376 144.049 437.279 144.771 437.279 146.036Z"
        fill="#F37140"
      />
      <path
        d="M264.266 137.005C264.266 138.27 263.363 139.173 262.28 138.992C261.196 138.992 260.293 138.089 260.474 136.825C260.474 135.741 261.196 135.019 262.28 135.019C263.544 134.838 264.266 135.741 264.266 137.005Z"
        fill="#F37140"
      />
      <path
        d="M266.795 208.161C268.059 208.161 268.962 208.883 268.962 209.967C268.962 210.87 267.878 212.134 266.975 212.134C265.892 212.134 264.989 211.231 264.989 210.147C264.808 208.883 265.531 208.161 266.795 208.161Z"
        fill="#F37140"
      />
      <path
        d="M401.34 146.035C401.34 147.299 400.437 148.022 399.354 148.022C398.27 147.841 397.548 146.938 397.548 145.855C397.548 144.771 398.27 144.049 399.534 144.049C400.618 144.049 401.34 144.771 401.34 146.035Z"
        fill="#F37140"
      />
      <path
        d="M401.34 178.543C401.34 179.807 400.437 180.71 399.354 180.53C398.27 180.53 397.548 179.627 397.548 178.363C397.548 177.279 398.27 176.557 399.354 176.557C400.618 176.557 401.34 177.279 401.34 178.543Z"
        fill="#F37140"
      />
      <path
        d="M437.279 178.362C437.279 179.446 436.376 180.53 435.293 180.53C434.209 180.53 433.487 179.627 433.306 178.724C433.306 177.459 433.848 176.556 435.112 176.556C436.376 176.376 437.279 177.279 437.279 178.362Z"
        fill="#F37140"
      />
      <path
        d="M266.795 240.669C268.059 240.669 268.962 241.391 268.962 242.475C268.962 243.378 267.878 244.642 266.975 244.642C265.892 244.642 264.989 243.739 264.989 242.655C264.808 241.391 265.531 240.669 266.795 240.669Z"
        fill="#F37140"
      />
      <path
        d="M69.4013 187.573C68.1372 187.573 67.4148 186.67 67.4148 185.586C67.4148 184.503 68.3178 183.6 69.5819 183.78C70.6655 183.78 71.3879 184.503 71.3879 185.586C71.3879 186.85 70.6655 187.573 69.4013 187.573Z"
        fill="#F37140"
      />
      <path
        d="M354.024 128.878C354.024 130.142 353.301 130.865 352.037 130.865C350.773 130.865 350.051 129.962 350.051 128.698C350.051 127.614 350.954 126.711 351.856 126.711C353.301 126.711 354.024 127.614 354.024 128.878Z"
        fill="#F37140"
      />
      <path
        d="M190.582 90.05C189.318 90.05 188.596 89.147 188.776 87.8829C188.776 86.7993 189.499 86.0769 190.763 86.0769C191.847 86.0769 192.93 86.9799 192.75 88.0635C192.569 89.3276 191.666 90.2306 190.582 90.05Z"
        fill="#F37140"
      />
      <path
        d="M118.885 187.573C117.802 187.573 116.718 186.67 116.718 185.586C116.718 184.503 117.621 183.6 118.885 183.6C120.149 183.6 120.691 184.322 120.872 185.406C120.872 186.85 119.969 187.573 118.885 187.573Z"
        fill="#F37140"
      />
      <path
        d="M141.099 187.573C139.834 187.573 139.112 186.67 139.112 185.586C139.112 184.503 139.834 183.78 141.099 183.78C142.363 183.78 143.266 184.683 143.266 185.767C143.266 186.851 142.363 187.573 141.099 187.573Z"
        fill="#F37140"
      />
      <path
        d="M406.036 122.557C404.772 122.557 404.049 121.835 404.049 120.571C404.049 119.487 404.772 118.765 406.036 118.765C407.3 118.765 408.203 119.487 408.203 120.751C408.022 121.835 407.119 122.557 406.036 122.557Z"
        fill="#F37140"
      />
      <path
        d="M221.826 146.035C221.826 147.119 220.923 148.022 219.84 148.022C218.756 148.022 218.034 147.119 218.034 146.035C218.034 144.771 218.756 144.049 220.02 144.049C221.104 144.049 221.826 144.771 221.826 146.035Z"
        fill="#F37140"
      />
      <path
        d="M190.582 187.573C189.318 187.573 188.596 186.851 188.596 185.586C188.596 184.503 189.318 183.78 190.582 183.78C191.847 183.78 192.75 184.503 192.75 185.586C192.75 186.851 191.847 187.573 190.582 187.573Z"
        fill="#F37140"
      />
      <path
        d="M159.158 78.1305C160.423 78.1305 161.145 78.8529 161.145 80.117C161.145 81.2006 160.242 82.1036 159.339 82.1036C158.256 82.1036 157.172 81.02 157.172 80.117C156.991 79.0335 157.894 78.1305 159.158 78.1305Z"
        fill="#F37140"
      />
      <path
        d="M334.158 216.288C335.422 216.288 336.145 217.01 336.145 218.094C336.145 219.177 335.422 219.9 334.339 220.08C333.074 220.08 332.171 219.358 332.171 218.274C331.991 217.191 332.894 216.288 334.158 216.288Z"
        fill="#F37140"
      />
      <path
        d="M356.552 220.08C355.288 220.08 354.565 219.358 354.565 218.094C354.565 217.01 355.288 216.288 356.371 216.288C357.636 216.288 358.539 217.01 358.539 218.094C358.719 219.177 357.816 220.08 356.552 220.08Z"
        fill="#F37140"
      />
      <path
        d="M268.962 79.9364C268.962 80.8394 267.878 82.1036 266.975 82.1036C265.892 82.1036 264.989 81.2006 264.989 80.117C264.989 78.8529 265.711 78.3111 266.795 78.1305C267.878 78.1305 268.962 78.8529 268.962 79.9364Z"
        fill="#F37140"
      />
      <path
        d="M284.855 187.573C283.771 187.573 282.688 186.851 282.688 185.767C282.688 184.683 283.59 183.78 284.674 183.78C285.938 183.78 286.661 184.503 286.661 185.586C286.841 186.851 286.119 187.573 284.855 187.573Z"
        fill="#F37140"
      />
      <path
        d="M185.887 146.035C185.887 147.299 184.984 148.022 183.9 148.022C182.817 147.841 182.094 146.938 182.094 145.855C182.094 144.771 182.817 144.049 184.081 144.049C185.165 144.049 185.887 144.771 185.887 146.035Z"
        fill="#F37140"
      />
      <path
        d="M304.72 63.6825C304.72 64.9467 303.998 65.669 302.734 65.669C301.65 65.669 300.928 64.9466 300.747 63.8631C300.747 62.5989 301.47 61.6959 302.553 61.6959C303.637 61.6959 304.54 62.5989 304.72 63.6825Z"
        fill="#F37140"
      />
      <path
        d="M334.158 183.78C335.422 183.78 336.145 184.503 336.145 185.586C336.145 186.67 335.422 187.392 334.339 187.573C333.074 187.573 332.171 186.851 332.171 185.767C331.991 184.683 332.894 183.78 334.158 183.78Z"
        fill="#F37140"
      />
      <path
        d="M356.552 187.573C355.288 187.573 354.565 186.67 354.565 185.586C354.565 184.503 355.288 183.78 356.552 183.78C357.816 183.78 358.719 184.683 358.719 185.767C358.719 186.851 357.636 187.573 356.552 187.573Z"
        fill="#F37140"
      />
      <path
        d="M161.145 209.967C161.145 211.05 160.242 212.134 159.159 212.134C158.075 212.134 156.991 211.05 156.991 209.967C156.991 208.883 157.894 208.161 158.978 208.161C160.242 208.161 160.965 208.703 161.145 209.967Z"
        fill="#F37140"
      />
      <path
        d="M389.962 226.221C389.962 227.485 389.24 228.207 387.976 228.207C386.892 228.207 386.17 227.485 385.989 226.401C385.989 225.137 386.712 224.234 387.795 224.234C389.059 224.234 389.962 225.137 389.962 226.221Z"
        fill="#F37140"
      />
      <path
        d="M405.855 187.573C404.591 187.573 403.869 186.67 404.049 185.406C404.049 184.322 404.952 183.6 406.036 183.6C407.3 183.6 408.023 184.503 408.023 185.586C408.023 186.85 407.12 187.753 405.855 187.573Z"
        fill="#F37140"
      />
      <path
        d="M316.279 240.669C317.543 240.669 318.446 241.572 318.265 242.655C318.265 243.739 317.001 244.642 316.098 244.642C315.014 244.642 314.292 243.739 314.292 242.655C314.292 241.391 315.014 240.669 316.279 240.669Z"
        fill="#F37140"
      />
      <path
        d="M336.686 128.517C336.686 127.434 337.589 126.711 338.673 126.711C339.756 126.711 340.659 127.795 340.479 129.059C340.298 130.143 339.576 130.865 338.492 130.684C337.409 130.684 336.686 129.781 336.686 128.517Z"
        fill="#F37140"
      />
      <path
        d="M354.023 226.402C354.023 227.666 353.301 228.388 352.217 228.388C350.953 228.388 350.231 227.666 350.231 226.402C350.231 225.137 351.134 224.234 352.217 224.415C353.301 224.415 354.023 225.137 354.023 226.402Z"
        fill="#F37140"
      />
      <path
        d="M340.659 226.401C340.659 227.665 339.756 228.388 338.673 228.388C337.589 228.388 336.867 227.665 336.867 226.401C336.867 225.137 337.77 224.234 338.854 224.234C339.756 224.234 340.659 225.137 340.659 226.401Z"
        fill="#F37140"
      />
      <path
        d="M316.279 78.1305C317.543 78.1305 318.446 79.0335 318.446 80.117C318.446 81.2006 317.362 82.1036 316.279 82.1036C315.195 82.1036 314.473 81.2006 314.292 80.117C314.292 78.8529 315.014 78.1305 316.279 78.1305Z"
        fill="#F37140"
      />
      <path
        d="M300.747 226.221C300.747 225.137 301.65 224.234 302.734 224.234C303.817 224.234 304.54 225.318 304.54 226.401C304.54 227.485 303.817 228.207 302.553 228.207C301.47 228.388 300.747 227.485 300.747 226.221Z"
        fill="#F37140"
      />
      <path
        d="M166.202 188.476C164.938 188.476 164.035 187.753 164.035 186.67C164.035 185.586 164.938 184.683 166.021 184.683C167.105 184.683 168.008 185.406 168.008 186.489C168.008 187.753 167.285 188.476 166.202 188.476Z"
        fill="#F37140"
      />
      <path
        d="M406.036 220.08C404.772 220.08 404.049 219.358 404.049 218.094C404.049 217.01 404.772 216.288 405.855 216.288C407.119 216.288 408.022 217.01 408.022 218.094C408.022 219.177 407.3 220.08 406.036 220.08Z"
        fill="#F37140"
      />
      <path
        d="M237.899 188.476C236.635 188.476 235.913 187.753 235.913 186.489C235.913 185.406 236.635 184.683 237.719 184.503C238.983 184.503 239.886 185.225 239.886 186.309C239.886 187.573 239.163 188.476 237.899 188.476Z"
        fill="#F37140"
      />
      <path
        d="M102.993 210.147C102.993 211.231 101.909 212.134 100.825 212.134C99.7418 212.134 98.8389 211.05 99.0195 209.967C99.0195 208.883 99.7418 208.161 101.006 208.161C102.09 208.161 102.993 209.064 102.993 210.147Z"
        fill="#F37140"
      />
      <path
        d="M374.612 240.669C375.876 240.669 376.599 241.391 376.599 242.655C376.599 243.739 375.696 244.642 374.612 244.642C373.528 244.642 372.445 243.558 372.445 242.655C372.445 241.572 373.348 240.669 374.612 240.669Z"
        fill="#F37140"
      />
      <path
        d="M374.612 78.1305C375.876 78.1305 376.598 78.8529 376.598 80.117C376.598 81.2006 375.695 82.1036 374.792 82.1036C373.709 82.1036 372.625 81.2006 372.625 80.117C372.444 79.0335 373.347 78.1305 374.612 78.1305Z"
        fill="#F37140"
      />
      <path
        d="M381.294 188.476C380.03 188.476 379.127 187.573 379.307 186.489C379.307 185.406 380.21 184.683 381.294 184.683C382.558 184.683 383.281 185.406 383.281 186.67C383.461 187.753 382.558 188.656 381.294 188.476Z"
        fill="#F37140"
      />
      <path
        d="M166.021 217.191C167.285 217.191 168.008 217.913 168.008 219.177C168.008 220.261 167.285 220.983 166.021 220.983C164.757 220.983 163.854 220.261 163.854 219.177C163.854 218.094 164.938 217.191 166.021 217.191Z"
        fill="#F37140"
      />
      <path
        d="M453.352 188.476C452.088 188.476 451.366 187.753 451.366 186.489C451.366 185.406 452.088 184.683 453.172 184.503C454.436 184.503 455.339 185.225 455.339 186.309C455.339 187.573 454.436 188.476 453.352 188.476Z"
        fill="#F37140"
      />
      <path
        d="M304.72 128.878C304.72 130.142 303.998 130.865 302.734 130.865C301.65 130.865 300.928 130.142 300.928 128.878C300.928 127.795 301.831 126.711 302.915 126.711C303.817 126.711 304.72 127.614 304.72 128.878Z"
        fill="#F37140"
      />
      <path
        d="M85.4745 193.533C85.4745 192.449 86.3775 191.727 87.461 191.727C88.7252 191.727 89.4476 192.81 89.267 194.075C89.0864 195.158 88.364 195.88 87.2804 195.7C86.0163 195.7 85.2939 194.797 85.4745 193.533Z"
        fill="#F37140"
      />
      <path
        d="M218.034 81.0202C218.034 79.756 218.756 79.0336 219.84 78.853C221.104 78.853 221.826 79.5754 221.826 80.8396C221.826 81.9232 220.923 82.8261 219.84 82.8261C218.756 83.0067 218.034 82.1037 218.034 81.0202Z"
        fill="#F37140"
      />
      <path
        d="M125.206 193.894C125.206 195.158 124.303 195.881 123.22 195.881C122.136 195.881 121.414 195.158 121.414 193.894C121.414 192.63 122.317 191.727 123.4 191.727C124.303 191.727 125.206 192.63 125.206 193.894Z"
        fill="#F37140"
      />
      <path
        d="M138.57 193.713C138.57 194.978 137.848 195.881 136.584 195.881C135.5 195.881 134.778 195.158 134.778 193.894C134.778 192.63 135.5 191.727 136.764 191.727C137.848 191.727 138.57 192.63 138.57 193.713Z"
        fill="#F37140"
      />
      <path
        d="M374.431 143.146C375.696 143.146 376.418 143.868 376.418 145.132C376.418 146.216 375.515 147.119 374.612 147.119C373.528 147.119 372.445 146.216 372.445 145.132C372.445 144.049 373.348 143.146 374.431 143.146Z"
        fill="#F37140"
      />
      <path
        d="M174.509 193.894C174.509 195.158 173.606 195.881 172.523 195.881C171.439 195.881 170.717 194.978 170.717 193.713C170.717 192.63 171.62 191.907 172.703 191.727C173.787 191.727 174.509 192.63 174.509 193.894Z"
        fill="#F37140"
      />
      <path
        d="M170.717 63.8634C170.717 62.7799 171.62 61.6963 172.703 61.8769C173.787 61.8769 174.509 62.7799 174.509 63.8634C174.509 65.1276 173.787 65.85 172.703 66.0306C171.439 65.85 170.717 65.1276 170.717 63.8634Z"
        fill="#F37140"
      />
      <path
        d="M316.279 143.146C317.543 143.146 318.446 144.049 318.446 145.132C318.446 146.216 317.362 147.119 316.279 147.119C315.195 147.119 314.473 146.216 314.292 145.132C314.292 143.868 315.014 143.146 316.279 143.146Z"
        fill="#F37140"
      />
      <path
        d="M389.962 96.1902C389.962 97.4543 389.24 98.1767 387.976 98.1767C386.892 98.1767 386.17 97.4543 385.989 96.3708C385.989 95.1066 386.712 94.2036 387.795 94.2036C389.059 94.2036 389.962 95.1066 389.962 96.1902Z"
        fill="#F37140"
      />
      <path
        d="M138.569 63.8632C138.569 65.1274 137.847 65.8497 136.763 65.8497C135.499 65.8497 134.777 65.1274 134.777 63.6826C134.777 62.599 135.68 61.696 136.583 61.696C137.847 61.696 138.75 62.599 138.569 63.8632Z"
        fill="#F37140"
      />
      <path
        d="M266.795 143.146C268.059 143.146 268.962 143.868 268.962 144.952C268.962 145.855 267.878 147.119 266.975 147.119C265.892 147.119 264.989 146.216 264.989 145.132C264.808 143.868 265.531 143.146 266.795 143.146Z"
        fill="#F37140"
      />
      <path
        d="M354.023 96.1903C354.023 97.4545 353.301 98.3575 352.037 98.1769C350.953 98.1769 350.231 97.4545 350.231 96.1903C350.231 94.9262 351.134 94.0232 352.217 94.0232C353.301 94.2038 354.023 95.1068 354.023 96.1903Z"
        fill="#F37140"
      />
      <path
        d="M304.72 193.894C304.72 195.158 303.818 195.88 302.734 195.88C301.65 195.88 300.928 194.977 300.928 193.894C300.928 192.81 301.831 191.727 302.915 191.907C303.817 191.727 304.72 192.63 304.72 193.894Z"
        fill="#F37140"
      />
      <path
        d="M336.686 96.371C336.686 95.1068 337.589 94.2039 338.673 94.3845C339.756 94.3845 340.659 95.2874 340.479 96.5516C340.479 97.6352 339.756 98.3576 338.673 98.5382C337.589 98.3576 336.686 97.6352 336.686 96.371Z"
        fill="#F37140"
      />
      <path
        d="M336.686 193.713C336.686 192.449 337.589 191.727 338.673 191.727C339.756 191.727 340.479 192.81 340.479 193.894C340.479 194.978 339.756 195.7 338.492 195.7C337.409 195.88 336.686 194.978 336.686 193.713Z"
        fill="#F37140"
      />
      <path
        d="M354.023 193.713C354.023 194.978 353.301 195.881 352.037 195.881C350.953 195.881 350.231 195.158 350.231 193.894C350.231 192.63 350.953 191.727 352.217 191.727C353.12 191.727 354.023 192.63 354.023 193.713Z"
        fill="#F37140"
      />
      <path
        d="M166.021 123.461C164.938 123.461 163.854 122.558 163.854 121.474C163.854 120.39 164.938 119.487 166.021 119.487C167.105 119.487 167.827 120.21 168.008 121.293C168.008 122.738 167.285 123.461 166.021 123.461Z"
        fill="#F37140"
      />
      <path
        d="M389.962 193.894C389.962 195.158 389.059 195.881 387.976 195.881C386.892 195.881 386.17 195.158 386.17 193.894C386.17 192.63 387.073 191.727 388.156 191.727C389.24 191.727 389.962 192.63 389.962 193.894Z"
        fill="#F37140"
      />
      <path
        d="M300.747 96.1901C300.747 95.1065 301.65 94.023 302.734 94.2036C303.817 94.2036 304.72 95.1065 304.54 96.3707C304.54 97.4543 303.817 98.1766 302.734 98.1766C301.47 98.3572 300.747 97.4543 300.747 96.1901Z"
        fill="#F37140"
      />
      <path
        d="M237.899 123.46C236.635 123.46 235.913 122.738 235.913 121.474C235.913 120.39 236.635 119.668 237.899 119.668C239.163 119.668 240.066 120.39 240.066 121.655C239.886 122.738 238.983 123.46 237.899 123.46Z"
        fill="#F37140"
      />
      <path
        d="M174.509 128.698C174.509 129.962 173.787 130.684 172.523 130.684C171.259 130.684 170.536 129.962 170.536 128.698C170.536 127.614 171.259 126.711 172.342 126.711C173.606 126.711 174.509 127.614 174.509 128.698Z"
        fill="#F37140"
      />
      <path
        d="M430.236 137.005C430.236 138.089 429.333 138.992 428.249 138.992C427.166 138.992 426.443 137.908 426.443 136.825C426.443 135.741 427.346 135.019 428.43 135.019C429.513 135.019 430.416 135.741 430.236 137.005Z"
        fill="#F37140"
      />
      <path
        d="M174.509 96.3709C174.509 97.635 173.606 98.3574 172.523 98.3574C171.439 98.3574 170.717 97.4545 170.717 96.1903C170.717 95.1067 171.62 94.3843 172.703 94.3843C173.787 94.2037 174.69 95.2873 174.509 96.3709Z"
        fill="#F37140"
      />
      <path
        d="M159.159 143.146C160.423 143.146 161.145 144.049 160.965 145.313C160.965 146.396 160.062 147.119 158.978 147.119C157.894 147.119 156.811 146.035 156.992 144.952C156.992 143.868 158.075 143.146 159.159 143.146Z"
        fill="#F37140"
      />
      <path
        d="M185.887 243.558C185.887 244.822 184.984 245.725 183.9 245.725C182.817 245.725 181.914 244.822 181.914 243.558C181.914 242.475 182.636 241.752 183.72 241.572C185.165 241.572 185.887 242.294 185.887 243.558Z"
        fill="#F37140"
      />
      <path
        d="M174.509 226.221C174.509 227.485 173.787 228.207 172.523 228.207C171.259 228.207 170.536 227.485 170.536 226.221C170.536 225.137 171.259 224.234 172.342 224.234C173.606 224.234 174.509 225.137 174.509 226.221Z"
        fill="#F37140"
      />
      <path
        d="M284.855 90.0497C283.59 90.0497 282.688 89.1468 282.688 88.0632C282.688 86.9796 283.59 86.0767 284.855 86.2573C285.938 86.2573 286.661 86.9796 286.841 88.0632C286.841 89.3274 286.119 90.0497 284.855 90.0497Z"
        fill="#F37140"
      />
      <path
        d="M237.899 220.983C236.635 220.983 235.913 220.261 235.913 218.997C235.913 217.913 236.635 217.191 237.719 217.01C238.983 217.01 239.886 217.733 239.886 218.816C239.886 220.08 239.163 220.983 237.899 220.983Z"
        fill="#F37140"
      />
      <path
        d="M138.569 96.1899C138.569 97.454 137.847 98.1764 136.763 98.1764C135.499 98.1764 134.777 97.454 134.777 96.1899C134.777 94.9257 135.68 94.0227 136.763 94.2033C137.847 94.3839 138.569 95.1063 138.569 96.1899Z"
        fill="#F37140"
      />
      <path
        d="M121.232 96.1898C121.232 95.1062 122.135 94.2032 123.219 94.2032C124.302 94.2032 125.025 95.2868 125.025 96.3704C125.025 97.454 124.302 98.1763 123.038 98.1763C121.955 98.3569 121.232 97.454 121.232 96.1898Z"
        fill="#F37140"
      />
      <path
        d="M218.034 243.378C218.034 242.113 218.936 241.391 220.201 241.572C221.284 241.572 222.007 242.475 222.007 243.739C221.826 244.822 220.923 245.545 220.02 245.545C218.756 245.545 217.853 244.642 218.034 243.378Z"
        fill="#F37140"
      />
      <path
        d="M101.006 143.146C102.09 143.326 102.993 144.049 102.812 145.132C102.812 146.216 101.729 147.119 100.645 147.119C99.5614 147.119 98.6584 146.035 98.839 144.952C99.0196 143.688 99.742 142.965 101.006 143.146Z"
        fill="#F37140"
      />
      <path
        d="M138.569 128.877C138.569 130.142 137.847 130.864 136.763 130.864C135.499 130.864 134.777 130.142 134.777 128.697C134.777 127.613 135.68 126.71 136.583 126.71C137.847 126.71 138.75 127.613 138.569 128.877Z"
        fill="#F37140"
      />
      <path
        d="M334.339 86.2573C335.603 86.2573 336.325 87.1603 336.145 88.4245C335.964 89.508 335.422 90.2304 334.158 90.2304C332.894 90.2304 331.991 89.3274 332.171 88.2439C332.171 86.9797 333.074 86.2573 334.339 86.2573Z"
        fill="#F37140"
      />
      <path
        d="M89.2669 96.3704C89.2669 97.6346 88.5445 98.3569 87.2803 98.3569C86.1967 98.3569 85.4744 97.6346 85.4744 96.3704C85.4744 95.2868 86.3773 94.2032 87.4609 94.2032C88.3639 94.2032 89.2669 95.1062 89.2669 96.3704Z"
        fill="#F37140"
      />
      <path
        d="M121.232 63.6826C121.232 62.599 122.135 61.696 123.399 61.696C124.483 61.8766 125.205 62.7796 125.205 64.0438C125.025 65.1274 124.302 65.8497 123.219 65.8497C121.955 65.6692 121.232 64.9468 121.232 63.6826Z"
        fill="#F37140"
      />
      <path
        d="M453.172 90.9529C451.908 90.9529 451.186 90.0499 451.186 88.9664C451.186 87.8828 451.908 87.1604 453.172 87.1604C454.436 87.1604 455.339 88.0634 455.159 89.1469C455.339 90.2305 454.436 91.1335 453.172 90.9529Z"
        fill="#F37140"
      />
      <path
        d="M381.294 90.9528C380.21 90.7722 379.488 90.0499 379.488 88.9663C379.488 87.8827 380.571 86.9797 381.655 87.1603C382.919 87.1603 383.641 88.0633 383.461 89.3275C383.28 90.411 382.377 91.1334 381.294 90.9528Z"
        fill="#F37140"
      />
      <path
        d="M134.778 226.402C134.778 225.137 135.681 224.234 136.764 224.415C137.848 224.415 138.57 225.318 138.57 226.402C138.57 227.666 137.848 228.388 136.764 228.388C135.5 228.388 134.778 227.666 134.778 226.402Z"
        fill="#F37140"
      />
      <path
        d="M125.206 226.401C125.206 227.665 124.303 228.388 123.22 228.388C122.136 228.388 121.414 227.665 121.414 226.401C121.414 225.137 122.317 224.234 123.4 224.234C124.303 224.234 125.206 225.137 125.206 226.401Z"
        fill="#F37140"
      />
      <path
        d="M125.205 128.697C125.205 129.961 124.483 130.683 123.399 130.864C122.316 130.864 121.593 130.142 121.413 129.058C121.232 127.794 122.135 126.891 123.219 126.891C124.122 126.71 125.025 127.613 125.205 128.697Z"
        fill="#F37140"
      />
      <path
        d="M237.899 87.1604C239.163 87.1604 239.886 88.0634 239.886 89.1469C239.886 90.2305 238.983 91.1335 237.719 90.9529C236.635 90.9529 235.913 90.2305 235.913 89.1469C235.913 87.8828 236.635 87.1604 237.899 87.1604Z"
        fill="#F37140"
      />
      <path
        d="M214.963 201.84C214.963 203.104 214.241 204.007 212.977 204.007C211.893 204.007 210.99 203.104 210.99 202.021C210.99 200.937 211.712 200.034 212.796 200.034C214.06 199.853 214.963 200.576 214.963 201.84Z"
        fill="#F37140"
      />
      <path
        d="M166.021 87.1604C167.285 87.1604 168.008 88.0634 168.008 89.1469C168.008 90.2305 167.285 90.9529 166.021 90.9529C164.757 90.9529 163.854 90.0499 163.854 88.9664C164.035 88.0634 164.938 87.1604 166.021 87.1604Z"
        fill="#F37140"
      />
      <path
        d="M85.2937 128.697C85.2937 127.613 86.1967 126.71 87.2802 126.71C88.3638 126.71 89.0862 127.794 89.0862 128.877C89.0862 129.961 88.3638 130.683 87.0997 130.683C86.0161 130.864 85.2937 129.961 85.2937 128.697Z"
        fill="#F37140"
      />
      <path
        d="M264.266 202.02C264.266 203.285 263.363 204.007 262.28 204.007C261.196 204.007 260.293 202.923 260.474 201.84C260.474 200.756 261.196 200.034 262.46 200.034C263.544 200.034 264.266 200.756 264.266 202.02Z"
        fill="#F37140"
      />
      <path
        d="M89.2671 226.221C89.2671 227.485 88.5447 228.207 87.4612 228.388C86.3776 228.388 85.6552 227.666 85.4746 226.582C85.4746 225.318 86.197 224.415 87.2806 224.415C88.1835 224.234 89.2671 225.137 89.2671 226.221Z"
        fill="#F37140"
      />
      <path
        d="M85.2937 63.6826C85.2937 62.599 86.1967 61.696 87.2802 61.696C88.3638 61.696 89.0862 62.7796 89.0862 63.8632C89.0862 64.9468 88.3638 65.6692 87.0997 65.6692C86.0161 65.8497 85.2937 64.9468 85.2937 63.6826Z"
        fill="#F37140"
      />
      <path
        d="M381.475 119.668C382.739 119.668 383.461 120.571 383.461 121.655C383.461 122.738 382.558 123.46 381.294 123.46C380.21 123.46 379.307 122.557 379.488 121.474C379.307 120.571 380.391 119.668 381.475 119.668Z"
        fill="#F37140"
      />
      <path
        d="M453.172 220.983C451.908 220.983 451.186 220.261 451.186 218.997C451.186 217.913 451.908 217.191 453.172 217.191C454.436 217.191 455.339 217.913 455.339 219.177C455.339 220.261 454.436 220.983 453.172 220.983Z"
        fill="#F37140"
      />
      <path
        d="M356.552 86.2573C357.636 86.2573 358.719 87.1603 358.538 88.2439C358.538 89.3274 357.635 90.2304 356.371 90.0498C355.288 90.0498 354.565 89.3274 354.385 88.2439C354.565 86.9797 355.288 86.2573 356.552 86.2573Z"
        fill="#F37140"
      />
      <path
        d="M185.887 81.0201C185.887 82.2843 184.984 83.1873 183.9 83.1873C182.817 83.1873 181.914 82.2843 182.094 81.0201C182.094 79.9365 182.817 79.2142 183.9 79.0336C185.165 79.0336 185.887 79.7559 185.887 81.0201Z"
        fill="#F37140"
      />
      <path
        d="M406.036 90.0498C404.772 90.0498 404.049 89.1469 404.049 88.0633C404.049 86.9797 404.772 86.2573 406.036 86.2573C407.3 86.2573 408.203 87.1603 408.203 88.2439C408.022 89.3274 407.119 90.0498 406.036 90.0498Z"
        fill="#F37140"
      />
      <path
        d="M381.474 220.983C380.21 220.983 379.307 220.08 379.307 218.997C379.307 217.913 380.21 217.191 381.113 217.01C382.377 217.01 383.28 217.552 383.28 218.816C383.461 220.261 382.738 220.983 381.474 220.983Z"
        fill="#F37140"
      />
      <path
        d="M426.443 201.84C426.443 200.576 427.346 199.853 428.43 199.853C429.513 199.853 430.236 200.576 430.236 201.84C430.236 203.104 429.333 204.007 428.249 204.007C427.346 204.007 426.443 203.104 426.443 201.84Z"
        fill="#F37140"
      />
      <path
        d="M453.172 123.46C451.908 123.46 451.186 122.738 451.186 121.474C451.186 120.39 451.908 119.668 453.172 119.668C454.436 119.668 455.339 120.39 455.339 121.655C455.339 122.738 454.436 123.46 453.172 123.46Z"
        fill="#F37140"
      />
      <path
        d="M441.794 90.05C440.53 90.05 439.988 89.3276 439.988 88.0635C439.988 86.7993 440.711 86.0769 442.155 86.0769C443.239 86.0769 444.142 86.9799 444.142 88.0635C443.961 89.3276 443.058 90.2306 441.794 90.05Z"
        fill="#F37140"
      />
      <path
        d="M273.838 220.983C272.574 220.983 271.851 220.442 271.851 219.177C271.671 217.913 272.574 217.01 273.657 217.01C274.922 217.01 275.825 217.913 275.825 218.997C275.825 220.08 274.922 220.983 273.838 220.983Z"
        fill="#F37140"
      />
      <path
        d="M423.915 78.1305C425.179 78.1305 425.902 78.8529 425.902 80.117C425.902 81.2006 425.179 82.1036 424.096 82.1036C423.012 82.1036 421.929 81.2006 421.929 80.117C421.748 79.0335 422.831 78.1305 423.915 78.1305Z"
        fill="#F37140"
      />
      <path
        d="M273.838 90.953C272.574 90.953 271.852 90.4112 271.852 89.147C271.852 87.8828 272.574 86.9799 273.838 86.9799C275.103 86.9799 276.006 87.8828 276.006 88.9664C275.825 90.2306 274.922 90.953 273.838 90.953Z"
        fill="#F37140"
      />
      <path
        d="M473.218 194.797C473.218 196.061 472.496 196.603 471.412 196.603C470.329 196.603 469.245 195.7 469.245 194.616C469.245 193.713 470.329 192.449 471.412 192.449C472.315 192.63 473.218 193.713 473.218 194.797Z"
        fill="#F37140"
      />
      <path
        d="M437.279 194.797C437.279 196.061 436.557 196.783 435.473 196.783C434.209 196.783 433.487 196.061 433.487 194.797C433.487 193.713 434.39 192.81 435.293 192.81C436.376 192.63 437.279 193.533 437.279 194.797Z"
        fill="#F37140"
      />
      <path
        d="M255.778 196.783C254.695 196.783 253.611 195.88 253.611 194.797C253.611 193.894 254.875 192.81 255.778 192.81C256.862 192.81 257.765 193.894 257.584 194.977C257.765 196.061 257.042 196.783 255.778 196.783Z"
        fill="#F37140"
      />
      <path
        d="M218.034 194.616C218.034 193.532 218.937 192.629 219.84 192.629C220.923 192.629 221.826 193.532 221.826 194.797C221.826 196.061 220.923 196.783 219.659 196.783C218.575 196.603 218.034 195.88 218.034 194.616Z"
        fill="#F37140"
      />
      <path
        d="M273.658 123.461C272.394 123.461 271.852 122.738 271.852 121.474C271.852 120.21 272.574 119.487 273.838 119.487C274.922 119.487 275.825 120.39 275.825 121.474C275.825 122.738 274.922 123.641 273.658 123.461Z"
        fill="#F37140"
      />
      <path
        d="M210.448 145.132C210.448 146.216 209.545 147.119 208.462 147.119C207.559 147.119 206.475 146.035 206.475 145.132C206.475 144.049 207.378 143.146 208.462 143.146C209.906 143.146 210.448 143.868 210.448 145.132Z"
        fill="#F37140"
      />
      <path
        d="M425.902 242.655C425.902 243.739 424.999 244.642 423.915 244.642C423.012 244.642 421.928 243.558 421.928 242.655C421.928 241.572 422.831 240.669 423.915 240.669C425.179 240.669 425.902 241.391 425.902 242.655Z"
        fill="#F37140"
      />
      <path
        d="M423.915 143.146C425.179 143.146 425.902 143.868 425.902 145.132C425.902 146.216 424.999 147.119 424.096 147.119C423.012 147.119 421.929 146.216 421.929 145.132C421.748 144.049 422.831 143.146 423.915 143.146Z"
        fill="#F37140"
      />
      <path
        d="M221.826 97.2738C221.826 98.538 221.104 99.2604 220.02 99.2604C218.756 99.2604 218.034 98.538 218.034 97.2738C218.034 96.1903 218.756 95.2873 219.84 95.2873C220.923 95.1067 221.826 96.0097 221.826 97.2738Z"
        fill="#F37140"
      />
      <path
        d="M226.521 86.2573C227.786 86.2573 228.689 86.9797 228.689 88.0633C228.689 89.1469 227.786 90.0498 226.521 90.0498C225.257 90.0498 224.716 89.3274 224.535 88.2439C224.535 86.9797 225.077 86.2573 226.521 86.2573Z"
        fill="#F37140"
      />
      <path
        d="M441.975 220.081C440.711 220.081 439.808 219.358 439.808 218.094C439.808 216.83 440.53 216.288 441.614 216.108C442.878 216.108 443.781 217.011 443.781 218.094C443.961 219.358 443.239 220.081 441.975 220.081Z"
        fill="#F37140"
      />
      <path
        d="M274.019 184.683C275.102 184.864 275.825 185.767 275.825 186.67C275.825 187.753 274.741 188.476 273.477 188.476C272.394 188.295 271.671 187.573 271.671 186.489C271.852 185.225 272.755 184.503 274.019 184.683Z"
        fill="#F37140"
      />
      <path
        d="M257.765 97.4545C257.765 98.5381 257.043 99.2604 255.778 99.2604C254.695 99.2604 253.611 98.1769 253.792 97.2739C253.792 96.3709 255.056 95.2874 255.959 95.2874C257.043 95.2874 257.765 96.1903 257.765 97.4545Z"
        fill="#F37140"
      />
      <path
        d="M441.975 187.573C440.711 187.573 439.988 186.851 439.988 185.586C439.988 184.322 440.711 183.78 441.794 183.78C443.058 183.78 443.961 184.683 443.961 185.767C443.961 186.851 443.058 187.573 441.975 187.573Z"
        fill="#F37140"
      />
      <path
        d="M441.975 118.765C443.058 118.765 443.961 119.668 443.961 120.571C443.961 121.654 443.058 122.557 441.794 122.557C440.53 122.557 439.808 121.835 439.808 120.571C439.988 119.307 440.711 118.765 441.975 118.765Z"
        fill="#F37140"
      />
      <path
        d="M208.462 78.1305C209.726 78.1305 210.448 78.8529 210.448 80.117C210.448 81.2006 209.726 82.1036 208.642 82.1036C207.559 82.1036 206.475 81.2006 206.475 80.2976C206.295 79.0335 207.378 78.1305 208.462 78.1305Z"
        fill="#F37140"
      />
      <path
        d="M226.521 187.573C225.257 187.573 224.535 186.851 224.535 185.586C224.535 184.322 225.077 183.78 226.341 183.78C227.605 183.78 228.508 184.503 228.508 185.586C228.689 186.67 227.786 187.573 226.521 187.573Z"
        fill="#F37140"
      />
      <path
        d="M208.462 208.161C209.726 208.161 210.448 208.883 210.448 210.147C210.448 211.231 209.545 212.134 208.642 212.134C207.559 212.134 206.475 211.05 206.475 210.147C206.475 209.064 207.378 208.161 208.462 208.161Z"
        fill="#F37140"
      />
      <path
        d="M218.034 227.304C218.034 226.04 218.937 225.137 220.02 225.137C221.104 225.137 221.826 226.04 222.007 227.124C222.007 228.388 221.284 229.291 220.02 229.291C218.756 229.291 218.034 228.568 218.034 227.304Z"
        fill="#F37140"
      />
      <path
        d="M255.598 229.291C254.514 229.291 253.611 228.207 253.611 227.124C253.611 226.221 254.875 225.318 255.598 225.137C256.681 225.137 257.584 226.221 257.584 227.485C257.584 228.749 256.862 229.291 255.598 229.291Z"
        fill="#F37140"
      />
      <path
        d="M437.279 97.0933C437.279 98.3575 436.557 99.0798 435.473 99.0798C434.209 99.0798 433.487 98.3575 433.487 97.0933C433.487 96.0097 434.209 95.1067 435.293 94.9261C436.196 95.1067 437.279 96.0097 437.279 97.0933Z"
        fill="#F37140"
      />
      <path
        d="M473.218 97.2739C473.218 98.538 472.496 99.0798 471.412 99.0798C470.329 99.0798 469.245 98.1769 469.245 97.0933C469.245 96.1903 470.329 94.9261 471.412 94.9261C472.315 95.1067 473.218 96.1903 473.218 97.2739Z"
        fill="#F37140"
      />
      <path
        d="M423.915 175.653C425.179 175.653 425.902 176.376 425.902 177.64C425.902 178.723 424.999 179.626 424.096 179.626C423.012 179.626 421.929 178.723 421.929 177.64C421.748 176.556 422.831 175.653 423.915 175.653Z"
        fill="#F37140"
      />
      <path
        d="M437.279 227.304C437.279 228.568 436.557 229.291 435.473 229.291C434.209 229.291 433.487 228.568 433.487 227.304C433.487 226.221 434.39 225.318 435.293 225.318C436.376 225.137 437.279 226.04 437.279 227.304Z"
        fill="#F37140"
      />
      <path
        d="M226.521 216.288C227.605 216.288 228.689 217.191 228.508 218.274C228.327 219.358 227.424 220.08 226.341 220.08C225.257 220.08 224.535 219.358 224.535 218.094C224.535 217.01 225.257 216.288 226.521 216.288Z"
        fill="#F37140"
      />
      <path
        d="M473.218 227.304C473.218 228.568 472.496 229.11 471.412 229.11C470.329 229.11 469.245 228.207 469.245 227.124C469.245 226.221 470.329 224.956 471.412 224.956C472.315 225.137 473.218 226.221 473.218 227.304Z"
        fill="#F37140"
      />
      <path
        d="M208.462 240.669C209.726 240.669 210.448 241.21 210.448 242.655C210.448 243.739 209.726 244.642 208.642 244.642C207.559 244.822 206.475 243.739 206.475 242.836C206.295 241.572 207.378 240.669 208.462 240.669Z"
        fill="#F37140"
      />
      <path
        d="M210.448 177.64C210.448 178.723 209.365 179.626 208.281 179.446C207.378 179.265 206.295 178.362 206.295 177.459C206.295 176.376 207.198 175.473 208.462 175.473C209.907 175.653 210.629 176.376 210.448 177.64Z"
        fill="#F37140"
      />
      <path
        d="M224.535 152.898C224.716 151.815 225.619 151.092 226.883 151.273C227.966 151.453 228.689 152.356 228.508 153.44C228.327 154.523 227.425 155.246 226.16 155.065C225.077 155.065 224.354 154.162 224.535 152.898Z"
        fill="#F37140"
      />
      <path
        d="M423.915 208.161C425.179 208.161 425.902 208.883 425.902 210.147C425.902 211.231 424.999 212.134 424.096 212.134C423.012 212.134 421.928 211.05 421.928 210.147C421.928 209.064 422.831 208.161 423.915 208.161Z"
        fill="#F37140"
      />
      <path
        d="M473.218 129.962C473.218 131.046 472.496 131.768 471.231 131.768C470.148 131.768 469.064 130.685 469.245 129.782C469.245 128.879 470.509 127.795 471.412 127.795C472.315 127.795 473.218 128.698 473.218 129.962Z"
        fill="#F37140"
      />
      <path
        d="M441.975 151.272C443.058 151.272 443.961 152.175 443.961 153.078C443.961 154.162 443.058 155.065 441.794 155.065C440.53 155.065 439.808 154.162 439.988 152.898C439.988 151.814 440.711 151.272 441.975 151.272Z"
        fill="#F37140"
      />
      <path
        d="M273.838 155.968C272.574 155.968 271.852 155.426 271.852 154.162C271.852 152.898 272.574 151.995 273.838 151.995C275.103 151.995 276.006 152.898 276.006 153.981C275.825 155.246 274.922 155.968 273.838 155.968Z"
        fill="#F37140"
      />
      <path
        d="M218.034 64.5858C218.034 63.5022 218.936 62.5992 219.839 62.5992C220.923 62.5992 221.826 63.5022 221.826 64.7664C221.826 66.0306 220.923 66.7529 219.659 66.5724C218.575 66.5724 217.853 65.85 218.034 64.5858Z"
        fill="#F37140"
      />
      <path
        d="M210.448 112.444C210.448 113.528 209.545 114.611 208.462 114.611C207.378 114.611 206.295 113.528 206.295 112.625C206.295 111.541 207.198 110.819 208.281 110.638C209.726 110.638 210.448 111.18 210.448 112.444Z"
        fill="#F37140"
      />
      <path
        d="M255.778 66.7529C254.695 66.7529 253.611 65.6694 253.792 64.5858C253.792 63.6828 255.056 62.5992 256.14 62.5992C257.223 62.7798 257.946 63.6828 257.946 64.947C257.584 66.0306 256.862 66.7529 255.778 66.7529Z"
        fill="#F37140"
      />
      <path
        d="M425.902 112.625C425.902 113.708 424.818 114.611 423.735 114.431C422.832 114.25 421.748 113.347 421.929 112.444C421.929 111.361 422.832 110.458 424.096 110.458C425.36 110.638 426.082 111.36 425.902 112.625Z"
        fill="#F37140"
      />
      <path
        d="M221.826 129.601C221.826 130.865 221.104 131.588 220.02 131.588C218.756 131.588 218.034 130.865 218.034 129.601C218.034 128.517 218.756 127.614 219.84 127.434C220.923 127.614 221.826 128.517 221.826 129.601Z"
        fill="#F37140"
      />
      <path
        d="M471.231 164.276C470.148 164.276 469.064 163.192 469.245 162.289C469.245 161.386 470.509 160.302 471.412 160.302C472.496 160.483 473.218 161.386 473.218 162.65C473.037 163.553 472.315 164.276 471.231 164.276Z"
        fill="#F37140"
      />
      <path
        d="M437.279 162.108C437.279 163.372 436.557 164.095 435.293 164.095C434.029 164.095 433.487 163.372 433.487 162.108C433.487 161.025 434.209 160.122 435.293 159.941C436.196 160.122 437.279 161.025 437.279 162.108Z"
        fill="#F37140"
      />
      <path
        d="M257.765 129.962C257.765 131.046 257.043 131.768 255.778 131.768C254.695 131.768 253.611 130.685 253.792 129.782C253.792 128.879 255.056 127.795 255.959 127.795C257.043 127.795 257.765 128.698 257.765 129.962Z"
        fill="#F37140"
      />
      <path
        d="M226.521 118.765C227.605 118.765 228.689 119.668 228.508 120.751C228.508 121.835 227.424 122.738 226.341 122.557C225.257 122.557 224.535 121.835 224.535 120.571C224.535 119.487 225.257 118.765 226.521 118.765Z"
        fill="#F37140"
      />
      <path
        d="M257.765 162.47C257.765 163.553 257.043 164.276 255.778 164.276C254.695 164.276 253.611 163.192 253.792 162.289C253.792 161.386 255.056 160.302 255.959 160.302C257.043 160.302 257.765 161.205 257.765 162.47Z"
        fill="#F37140"
      />
      <path
        d="M221.826 162.108C221.826 163.372 221.104 164.095 220.02 164.095C218.756 164.095 218.034 163.372 218.034 162.108C218.034 161.025 218.756 160.122 219.84 159.941C220.923 160.122 221.826 161.025 221.826 162.108Z"
        fill="#F37140"
      />
      <path
        d="M471.231 66.7529C470.148 66.7529 469.064 65.6693 469.245 64.7663C469.245 63.8634 470.509 62.7798 471.412 62.7798C472.496 62.9604 473.218 63.8633 473.218 65.1275C473.037 66.0305 472.315 66.7529 471.231 66.7529Z"
        fill="#F37140"
      />
      <path
        d="M437.279 64.7662C437.279 66.0303 436.557 66.7527 435.473 66.7527C434.209 66.7527 433.487 66.0303 433.487 64.7662C433.487 63.6826 434.209 62.7796 435.293 62.7796C436.376 62.599 437.279 63.502 437.279 64.7662Z"
        fill="#F37140"
      />
      <path
        d="M437.279 129.782C437.279 130.865 436.557 131.588 435.293 131.588C434.029 131.588 433.306 130.865 433.306 129.42C433.306 128.337 434.209 127.434 435.293 127.434C436.376 127.614 437.279 128.698 437.279 129.782Z"
        fill="#F37140"
      />
      <path
        d="M185.887 97.0933C185.887 98.3575 185.345 99.0798 184.081 99.0798C182.817 99.0798 182.094 98.3575 182.094 97.0933C182.094 95.8291 182.997 94.9261 184.081 94.9261C184.984 95.1067 185.887 96.0097 185.887 97.0933Z"
        fill="#F37140"
      />
      <path
        d="M129.902 155.968C128.818 155.787 128.096 155.065 128.276 153.801C128.457 152.537 129.179 151.995 130.443 151.995C131.527 152.175 132.249 152.898 132.069 154.162C131.888 155.607 131.166 156.149 129.902 155.968Z"
        fill="#F37140"
      />
      <path
        d="M386.17 210.148C386.17 208.883 386.712 208.161 387.976 208.161C389.24 207.981 390.143 208.703 390.143 209.967C390.143 211.231 389.421 212.134 388.156 212.134C387.073 212.134 386.17 211.231 386.17 210.148Z"
        fill="#F37140"
      />
      <path
        d="M182.094 64.7662C182.094 63.502 182.997 62.599 184.081 62.7796C185.165 62.7796 185.887 63.6826 185.887 64.7662C185.887 66.0303 185.165 66.7527 183.9 66.7527C182.636 66.5721 182.094 65.8497 182.094 64.7662Z"
        fill="#F37140"
      />
      <path
        d="M170.717 242.475C170.717 241.21 171.439 240.669 172.703 240.669C173.967 240.669 174.69 241.391 174.69 242.655C174.69 243.739 173.787 244.642 172.703 244.642C171.62 244.642 170.717 243.739 170.717 242.475Z"
        fill="#F37140"
      />
      <path
        d="M401.34 64.5859C401.34 65.85 400.798 66.5724 399.534 66.5724C398.27 66.5724 397.548 66.0306 397.548 64.5859C397.548 63.3217 398.451 62.4187 399.534 62.4187C400.437 62.5993 401.34 63.5023 401.34 64.5859Z"
        fill="#F37140"
      />
      <path
        d="M185.887 162.108C185.887 163.372 185.345 164.095 184.081 164.095C182.817 164.095 182.094 163.553 181.914 162.108C181.914 160.844 182.817 159.941 183.9 159.941C184.984 160.122 185.887 161.025 185.887 162.108Z"
        fill="#F37140"
      />
      <path
        d="M401.34 162.109C401.34 163.373 400.798 164.095 399.715 164.095C398.451 164.276 397.728 163.553 397.548 162.289C397.548 161.025 398.451 160.122 399.534 160.122C400.437 160.122 401.34 161.025 401.34 162.109Z"
        fill="#F37140"
      />
      <path
        d="M389.962 112.625C389.962 113.889 389.059 114.792 387.976 114.792C386.892 114.792 386.17 113.889 386.17 112.805C386.17 111.541 386.892 110.819 388.156 110.819C389.24 110.638 389.962 111.361 389.962 112.625Z"
        fill="#F37140"
      />
      <path
        d="M170.717 112.625C170.717 111.361 171.258 110.638 172.523 110.638C173.787 110.638 174.69 111.18 174.69 112.444C174.69 113.708 173.787 114.611 172.703 114.611C171.62 114.611 170.717 113.708 170.717 112.625Z"
        fill="#F37140"
      />
      <path
        d="M397.548 129.781C397.548 128.517 398.451 127.614 399.534 127.614C400.618 127.614 401.34 128.517 401.34 129.601C401.34 130.865 400.618 131.587 399.354 131.587C398.089 131.587 397.548 131.045 397.548 129.781Z"
        fill="#F37140"
      />
      <path
        d="M172.523 175.653C173.787 175.653 174.509 176.376 174.509 177.64C174.509 178.723 173.606 179.626 172.703 179.626C171.62 179.626 170.717 178.723 170.717 177.459C170.717 176.376 171.439 175.653 172.523 175.653Z"
        fill="#F37140"
      />
      <path
        d="M401.34 227.304C401.34 228.568 400.618 229.11 399.534 229.11C398.27 229.11 397.548 228.388 397.548 227.124C397.548 226.04 398.451 225.137 399.354 225.137C400.437 225.137 401.34 226.221 401.34 227.304Z"
        fill="#F37140"
      />
      <path
        d="M174.509 210.147C174.509 211.231 173.787 212.134 172.703 212.134C171.62 212.134 170.717 211.231 170.717 210.147C170.717 208.883 171.439 208.161 172.703 208.161C173.967 208.161 174.509 208.883 174.509 210.147Z"
        fill="#F37140"
      />
      <path
        d="M389.962 177.64C389.962 178.723 389.24 179.626 388.156 179.626C387.073 179.626 386.17 178.723 386.17 177.64C386.17 176.376 386.892 175.834 387.976 175.653C389.24 175.653 389.962 176.376 389.962 177.64Z"
        fill="#F37140"
      />
      <path
        d="M401.34 97.093C401.34 98.3572 400.798 99.0795 399.715 99.0795C398.451 99.2601 397.728 98.5378 397.548 97.2736C397.548 96.0094 398.451 95.1064 399.534 95.1064C400.437 95.1064 401.34 96.0094 401.34 97.093Z"
        fill="#F37140"
      />
      <path
        d="M185.887 227.124C185.887 228.388 185.345 229.11 184.262 229.11C182.997 229.291 182.094 228.568 182.094 227.304C182.094 226.04 182.997 225.137 184.081 225.137C184.984 225.137 185.887 226.04 185.887 227.124Z"
        fill="#F37140"
      />
      <path
        d="M174.509 80.117C174.509 81.3812 173.606 82.2842 172.523 82.1036C171.439 82.1036 170.717 81.2006 170.717 80.117C170.717 78.8529 171.258 78.1305 172.523 78.1305C173.787 78.1305 174.509 78.8529 174.509 80.117Z"
        fill="#F37140"
      />
      <path
        d="M389.962 242.655C389.962 243.739 389.059 244.642 388.156 244.642C387.073 244.642 386.17 243.739 386.17 242.475C386.17 241.21 386.892 240.669 387.976 240.669C389.24 240.669 389.962 241.391 389.962 242.655Z"
        fill="#F37140"
      />
      <path
        d="M386.17 144.952C386.17 143.688 386.892 143.146 388.156 143.146C389.421 143.146 390.143 143.868 390.143 145.132C390.143 146.216 389.24 147.119 388.156 147.119C386.892 147.119 386.17 146.216 386.17 144.952Z"
        fill="#F37140"
      />
      <path
        d="M185.887 129.601C185.887 130.865 185.345 131.587 184.262 131.587C182.997 131.768 182.094 131.045 182.094 129.781C182.094 128.517 182.997 127.614 184.081 127.614C184.984 127.614 185.887 128.517 185.887 129.601Z"
        fill="#F37140"
      />
      <path
        d="M130.082 184.683C131.346 184.683 132.069 185.406 132.069 186.67C132.069 187.934 131.346 188.476 130.263 188.476C128.999 188.476 128.096 187.753 128.276 186.489C128.096 185.406 128.818 184.683 130.082 184.683Z"
        fill="#F37140"
      />
      <path
        d="M130.082 217.191C131.346 217.191 132.069 217.913 132.069 219.177C132.069 220.442 131.346 220.983 130.082 220.983C128.818 220.983 128.096 220.261 128.096 218.997C128.096 217.913 128.999 217.191 130.082 217.191Z"
        fill="#F37140"
      />
      <path
        d="M389.962 80.117C389.962 81.3812 389.059 82.2842 387.976 82.1036C386.892 82.1036 386.17 81.2006 386.17 80.117C386.17 78.8529 386.892 78.1305 387.976 78.1305C389.24 78.1305 389.962 78.8529 389.962 80.117Z"
        fill="#F37140"
      />
      <path
        d="M185.887 194.797C185.887 196.061 185.345 196.783 184.081 196.783C182.817 196.783 182.094 196.061 182.094 194.797C182.094 193.533 182.997 192.63 184.081 192.81C185.165 192.81 185.887 193.533 185.887 194.797Z"
        fill="#F37140"
      />
      <path
        d="M397.548 194.797C397.548 193.533 398.451 192.63 399.534 192.81C400.618 192.81 401.34 193.713 401.34 194.797C401.34 196.061 400.618 196.783 399.354 196.783C398.089 196.603 397.548 195.88 397.548 194.797Z"
        fill="#F37140"
      />
      <path
        d="M132.068 88.966C132.068 90.2302 131.345 90.9525 130.081 90.9525C128.998 90.9525 128.095 90.2301 128.095 89.1466C128.095 88.063 128.817 87.16 129.901 87.16C131.165 87.16 132.068 87.7018 132.068 88.966Z"
        fill="#F37140"
      />
      <path
        d="M132.068 121.654C132.068 122.918 131.345 123.64 130.081 123.64C128.998 123.64 128.275 122.918 128.095 121.654C128.095 120.57 128.817 119.848 129.901 119.667C131.345 119.667 132.068 120.39 132.068 121.654Z"
        fill="#F37140"
      />
      <path
        d="M174.509 144.952C174.509 146.216 173.606 147.119 172.523 147.119C171.439 147.119 170.717 146.216 170.536 145.132C170.536 143.868 171.078 143.146 172.161 143.146C173.787 142.965 174.509 143.688 174.509 144.952Z"
        fill="#F37140"
      />
      <path
        d="M392.672 86.2573C393.755 86.2573 394.658 87.1603 394.658 88.0633C394.658 89.1469 393.755 90.0498 392.491 90.0498C391.227 90.0498 390.505 89.1468 390.685 87.8827C390.685 86.7991 391.408 86.2573 392.672 86.2573Z"
        fill="#F37140"
      />
      <path
        d="M430.236 88.2441C430.236 89.5082 429.333 90.2306 428.249 90.2306C427.166 90.2306 426.443 89.5082 426.443 88.2441C426.443 86.9799 427.166 86.2575 428.43 86.4381C429.694 86.2575 430.416 86.9799 430.236 88.2441Z"
        fill="#F37140"
      />
      <path
        d="M464.188 86.2573C465.453 86.2573 466.175 86.7991 466.175 88.0633C466.356 89.3274 465.633 90.2304 464.369 90.2304C463.105 90.2304 462.202 89.3274 462.202 88.2439C462.202 87.1603 463.105 86.2573 464.188 86.2573Z"
        fill="#F37140"
      />
      <path
        d="M345.716 220.983C344.452 220.983 343.91 220.442 343.73 219.177C343.549 217.913 344.271 217.01 345.536 217.01C346.8 217.01 347.522 217.552 347.522 218.816C347.522 220.261 346.98 220.983 345.716 220.983Z"
        fill="#F37140"
      />
      <path
        d="M87.2805 240.669C88.5447 240.669 89.0864 241.21 89.267 242.475C89.267 243.739 88.5447 244.642 87.2805 244.642C86.1969 244.642 85.2939 243.739 85.2939 242.475C85.4745 241.391 86.1969 240.669 87.2805 240.669Z"
        fill="#F37140"
      />
      <path
        d="M121.233 145.132C121.233 143.868 121.775 143.146 123.039 143.146C124.303 143.146 125.206 143.868 125.206 144.952C125.206 146.216 124.303 147.119 123.219 147.119C122.136 147.119 121.414 146.216 121.233 145.132Z"
        fill="#F37140"
      />
      <path
        d="M304.72 145.132C304.72 146.396 303.818 147.299 302.734 147.119C301.65 147.119 300.928 146.216 300.928 145.132C300.928 143.868 301.65 143.146 302.915 143.146C303.998 143.146 304.72 143.868 304.72 145.132Z"
        fill="#F37140"
      />
      <path
        d="M347.522 88.9664C347.522 90.2305 346.8 90.9529 345.535 90.9529C344.271 90.9529 343.729 90.4111 343.729 89.1469C343.729 87.8828 344.271 87.1604 345.355 87.1604C346.619 87.1604 347.341 87.7022 347.522 88.9664Z"
        fill="#F37140"
      />
      <path
        d="M87.2806 143.146C88.5447 143.146 89.0865 143.688 89.2671 144.952C89.2671 146.216 88.5447 147.119 87.4612 147.119C86.3776 147.119 85.4746 146.216 85.4746 144.952C85.4746 143.868 86.197 143.146 87.2806 143.146Z"
        fill="#F37140"
      />
      <path
        d="M392.491 155.065C391.227 155.065 390.685 154.343 390.685 153.259C390.685 151.995 391.407 151.272 392.672 151.272C393.755 151.272 394.658 152.175 394.658 153.078C394.658 154.343 393.575 155.065 392.491 155.065Z"
        fill="#F37140"
      />
      <path
        d="M248.735 86.2573C249.999 86.2573 250.722 86.7991 250.722 88.0633C250.902 89.3274 250.18 90.2304 248.916 90.2304C247.652 90.2304 246.749 89.3274 246.749 88.2439C246.749 87.1603 247.651 86.2573 248.735 86.2573Z"
        fill="#F37140"
      />
      <path
        d="M336.686 145.132C336.686 143.868 337.409 143.326 338.492 143.146C339.756 143.146 340.479 143.868 340.479 145.132C340.479 146.216 339.576 147.119 338.673 147.119C337.589 147.119 336.686 146.216 336.686 145.132Z"
        fill="#F37140"
      />
      <path
        d="M347.522 186.489C347.522 187.753 346.8 188.476 345.535 188.476C344.271 188.476 343.729 187.934 343.729 186.67C343.729 185.406 344.271 184.683 345.355 184.683C346.619 184.683 347.341 185.225 347.522 186.489Z"
        fill="#F37140"
      />
      <path
        d="M426.443 153.078C426.443 151.814 427.166 151.272 428.43 151.272C429.694 151.272 430.416 151.995 430.416 153.259C430.416 154.343 429.694 155.065 428.43 155.065C427.166 155.065 426.443 154.343 426.443 153.078Z"
        fill="#F37140"
      />
      <path
        d="M464.369 151.272C465.633 151.272 466.175 151.995 466.175 153.078C466.175 154.343 465.453 155.065 464.188 155.065C463.105 155.065 462.202 154.162 462.202 153.259C462.202 152.175 463.105 151.272 464.369 151.272Z"
        fill="#F37140"
      />
      <path
        d="M338.673 78.1305C339.756 78.1305 340.479 78.8529 340.659 79.9364C340.659 81.2006 339.756 82.1036 338.673 82.1036C337.589 82.1036 336.686 81.2006 336.867 79.9364C336.867 78.6723 337.408 78.1305 338.673 78.1305Z"
        fill="#F37140"
      />
      <path
        d="M347.522 121.655C347.522 122.919 346.8 123.641 345.535 123.641C344.271 123.641 343.729 122.919 343.729 121.835C343.729 120.571 344.271 119.849 345.535 119.849C346.619 119.668 347.522 120.391 347.522 121.655Z"
        fill="#F37140"
      />
      <path
        d="M464.188 220.08C463.105 220.08 462.202 219.177 462.202 218.274C462.202 217.191 463.105 216.288 464.369 216.288C465.633 216.288 466.356 217.01 466.356 218.274C466.175 219.539 465.453 220.08 464.188 220.08Z"
        fill="#F37140"
      />
      <path
        d="M87.6418 208.161C88.906 208.342 89.4478 209.245 89.2672 210.509C89.0866 211.592 88.1836 212.315 87.1 212.134C86.0165 211.953 85.2941 210.87 85.4747 209.786C85.6553 208.522 86.3777 207.98 87.6418 208.161Z"
        fill="#F37140"
      />
      <path
        d="M464.369 183.78C465.633 183.78 466.175 184.503 466.355 185.586C466.355 186.851 465.633 187.573 464.369 187.573C463.285 187.573 462.382 186.67 462.382 185.767C462.202 184.683 463.105 183.78 464.369 183.78Z"
        fill="#F37140"
      />
      <path
        d="M430.236 185.586C430.236 186.85 429.513 187.573 428.249 187.573C427.166 187.573 426.443 186.85 426.443 185.586C426.443 184.322 427.166 183.6 428.43 183.6C429.694 183.78 430.236 184.503 430.236 185.586Z"
        fill="#F37140"
      />
      <path
        d="M392.491 183.78C393.575 183.78 394.478 184.683 394.478 185.586C394.478 186.67 393.575 187.573 392.31 187.573C391.046 187.573 390.324 186.67 390.505 185.406C390.685 184.322 391.408 183.78 392.491 183.78Z"
        fill="#F37140"
      />
      <path
        d="M302.734 78.1305C303.998 78.1305 304.72 78.8529 304.54 80.2976C304.54 81.3812 303.637 82.2842 302.553 82.1036C301.47 82.1036 300.567 81.02 300.747 79.9364C300.928 78.6723 301.65 78.1305 302.734 78.1305Z"
        fill="#F37140"
      />
      <path
        d="M87.2803 78.1302C88.5445 78.1302 89.0863 78.672 89.2669 79.9362C89.2669 81.2004 88.5445 82.1033 87.4609 82.1033C86.3773 82.1033 85.4744 81.2004 85.4744 79.9362C85.4744 78.8526 86.1967 78.1302 87.2803 78.1302Z"
        fill="#F37140"
      />
      <path
        d="M121.233 242.655C121.233 241.391 121.955 240.849 123.039 240.669C124.303 240.669 125.025 241.391 125.025 242.655C125.025 243.739 124.303 244.642 123.219 244.642C122.136 244.642 121.233 243.739 121.233 242.655Z"
        fill="#F37140"
      />
      <path
        d="M250.722 185.767C250.722 187.031 249.999 187.573 248.916 187.754C247.652 187.754 246.749 186.851 246.749 185.767C246.749 184.684 247.832 183.781 248.916 183.961C250.18 183.781 250.902 184.503 250.722 185.767Z"
        fill="#F37140"
      />
      <path
        d="M428.43 216.288C429.694 216.288 430.236 217.01 430.236 218.094C430.236 219.358 429.514 220.08 428.249 220.08C426.985 220.08 426.263 219.177 426.443 217.913C426.624 216.83 427.166 216.288 428.43 216.288Z"
        fill="#F37140"
      />
      <path
        d="M213.157 183.78C214.421 183.78 214.963 184.503 214.963 185.586C214.963 186.851 214.241 187.573 212.977 187.573C211.713 187.573 210.99 186.67 211.171 185.406C211.171 184.322 211.893 183.78 213.157 183.78Z"
        fill="#F37140"
      />
      <path
        d="M177.038 183.78C178.302 183.78 179.205 184.683 179.205 185.767C179.205 186.851 178.302 187.573 177.218 187.573C175.954 187.573 175.232 186.851 175.232 185.586C175.232 184.503 175.774 183.78 177.038 183.78Z"
        fill="#F37140"
      />
      <path
        d="M121.233 210.147C121.233 208.883 121.775 208.161 123.039 208.161C124.303 208.161 125.206 208.883 125.206 209.967C125.206 211.231 124.303 212.134 123.219 212.134C122.136 212.134 121.414 211.231 121.233 210.147Z"
        fill="#F37140"
      />
      <path
        d="M392.672 216.288C393.755 216.288 394.658 217.191 394.658 218.094C394.658 219.177 393.755 220.08 392.491 220.08C391.227 220.08 390.505 219.177 390.685 217.913C390.685 216.83 391.408 216.288 392.672 216.288Z"
        fill="#F37140"
      />
      <path
        d="M336.686 242.655C336.686 241.391 337.409 240.849 338.492 240.669C339.756 240.669 340.479 241.391 340.479 242.655C340.479 243.739 339.576 244.642 338.673 244.642C337.589 244.642 336.686 243.739 336.686 242.655Z"
        fill="#F37140"
      />
      <path
        d="M177.399 216.288C178.483 216.469 179.205 217.372 179.205 218.275C179.024 219.358 178.121 220.08 176.857 220.08C175.593 219.9 175.051 218.997 175.232 217.733C175.412 216.83 176.135 216.107 177.399 216.288Z"
        fill="#F37140"
      />
      <path
        d="M121.232 80.1168C121.232 78.8526 121.774 78.1302 123.038 78.1302C124.302 78.1302 125.205 78.8526 125.205 79.9362C125.205 81.2004 124.302 82.1033 123.219 82.1033C122.135 82.1033 121.413 81.2004 121.232 80.1168Z"
        fill="#F37140"
      />
      <path
        d="M347.522 153.982C347.522 155.246 346.8 155.968 345.535 155.968C344.271 155.968 343.729 155.426 343.729 154.162C343.729 152.898 344.271 152.176 345.355 152.176C346.619 152.176 347.341 152.718 347.522 153.982Z"
        fill="#F37140"
      />
      <path
        d="M212.977 86.2573C214.241 86.2573 214.782 86.7991 214.963 88.0633C214.963 89.3274 214.241 90.0498 212.977 90.0498C211.712 90.0498 210.99 89.3274 210.99 88.0633C211.171 86.7991 211.712 86.2573 212.977 86.2573Z"
        fill="#F37140"
      />
      <path
        d="M250.722 153.44C250.722 154.523 249.819 155.246 248.735 155.246C247.471 155.246 246.568 154.343 246.749 153.078C246.929 151.995 247.832 151.272 249.096 151.272C250.361 151.453 250.902 152.175 250.722 153.44Z"
        fill="#F37140"
      />
      <path
        d="M336.686 177.64C336.686 176.376 337.228 175.653 338.312 175.653C339.576 175.473 340.479 176.195 340.479 177.459C340.479 178.724 339.576 179.627 338.492 179.627C337.589 179.627 336.686 178.724 336.686 177.64Z"
        fill="#F37140"
      />
      <path
        d="M304.72 177.64C304.72 178.724 303.817 179.627 302.915 179.627C301.831 179.627 300.928 178.724 300.928 177.459C300.928 176.195 301.831 175.473 303.095 175.653C303.998 175.653 304.72 176.376 304.72 177.64Z"
        fill="#F37140"
      />
      <path
        d="M464.369 118.765C465.633 118.765 466.175 119.487 466.175 120.751C466.175 122.016 465.453 122.738 464.188 122.738C463.105 122.738 462.202 121.835 462.202 120.751C462.202 119.668 463.105 118.765 464.369 118.765Z"
        fill="#F37140"
      />
      <path
        d="M304.72 242.475C304.72 243.739 303.817 244.642 302.734 244.642C301.65 244.642 300.928 243.739 300.747 242.655C300.747 241.391 301.47 240.669 302.734 240.669C303.998 240.669 304.54 241.391 304.72 242.475Z"
        fill="#F37140"
      />
      <path
        d="M177.218 86.2573C178.302 86.2573 179.205 86.9797 179.205 88.0633C179.205 89.1469 178.302 90.0498 177.038 90.0498C175.774 90.0498 175.051 89.3274 175.051 88.0633C175.232 86.7991 175.954 86.2573 177.218 86.2573Z"
        fill="#F37140"
      />
      <path
        d="M248.735 122.557C247.471 122.557 246.568 121.654 246.749 120.571C246.749 119.487 247.652 118.765 248.735 118.765C249.999 118.765 250.722 119.487 250.722 120.751C250.722 122.016 249.999 122.557 248.735 122.557Z"
        fill="#F37140"
      />
      <path
        d="M121.233 177.64C121.233 176.376 121.775 175.653 122.858 175.653C124.122 175.473 125.025 176.195 125.025 177.459C125.025 178.724 124.122 179.627 123.039 179.627C122.136 179.627 121.414 178.904 121.233 177.64Z"
        fill="#F37140"
      />
      <path
        d="M89.2671 177.64C89.2671 178.723 88.3641 179.626 87.4612 179.626C86.3776 179.626 85.4746 178.723 85.4746 177.459C85.4746 176.376 86.197 175.653 87.4612 175.653C88.5447 175.653 89.2671 176.376 89.2671 177.64Z"
        fill="#F37140"
      />
      <path
        d="M89.2669 112.624C89.2669 113.888 88.3639 114.791 87.2803 114.611C86.1967 114.611 85.4744 113.708 85.4744 112.624C85.4744 111.36 86.1967 110.638 87.4609 110.638C88.5445 110.638 89.2669 111.36 89.2669 112.624Z"
        fill="#F37140"
      />
      <path
        d="M121.232 112.624C121.232 111.36 121.774 110.638 122.858 110.638C124.122 110.457 125.025 111.179 125.025 112.444C125.025 113.708 124.122 114.611 123.038 114.611C122.135 114.611 121.413 113.888 121.232 112.624Z"
        fill="#F37140"
      />
      <path
        d="M430.236 120.752C430.236 122.016 429.333 122.738 428.069 122.557C426.985 122.557 426.263 121.835 426.263 120.571C426.263 119.307 426.985 118.584 428.43 118.765C429.694 118.946 430.416 119.487 430.236 120.752Z"
        fill="#F37140"
      />
      <path
        d="M214.963 153.078C214.963 154.343 214.241 155.065 212.977 155.065C211.893 155.065 211.171 154.343 211.171 153.259C211.171 151.995 211.893 151.272 213.157 151.272C214.241 151.272 214.963 151.995 214.963 153.078Z"
        fill="#F37140"
      />
      <path
        d="M302.734 208.161C303.998 208.161 304.54 208.703 304.72 209.967C304.72 211.231 303.998 212.134 302.914 212.134C301.831 212.134 300.928 211.231 300.928 209.967C300.747 208.883 301.47 208.161 302.734 208.161Z"
        fill="#F37140"
      />
      <path
        d="M304.72 112.444C304.72 113.708 303.998 114.611 302.734 114.611C301.65 114.611 300.928 113.708 300.747 112.625C300.747 111.361 301.47 110.638 302.734 110.638C303.998 110.638 304.54 111.18 304.72 112.444Z"
        fill="#F37140"
      />
      <path
        d="M336.686 112.625C336.686 111.361 337.228 110.638 338.492 110.638C339.756 110.638 340.659 111.361 340.659 112.444C340.659 113.708 339.756 114.611 338.673 114.611C337.589 114.611 336.686 113.708 336.686 112.625Z"
        fill="#F37140"
      />
      <path
        d="M212.977 118.765C214.241 118.765 214.783 119.487 214.783 120.571C214.783 121.835 214.06 122.557 212.796 122.557C211.532 122.557 210.81 121.654 210.99 120.39C211.171 119.307 211.893 118.765 212.977 118.765Z"
        fill="#F37140"
      />
      <path
        d="M212.977 216.288C214.241 216.288 214.782 216.83 214.963 218.094C214.963 219.358 214.241 220.08 212.977 220.08C211.712 220.08 210.99 219.358 210.99 218.094C211.171 216.83 211.712 216.288 212.977 216.288Z"
        fill="#F37140"
      />
      <path
        d="M248.916 216.288C250.18 216.288 250.722 217.01 250.722 218.094C250.722 219.358 249.999 220.08 248.735 220.08C247.651 220.08 246.749 219.177 246.749 218.094C246.749 217.191 247.832 216.288 248.916 216.288Z"
        fill="#F37140"
      />
      <path
        d="M392.672 118.765C393.755 118.765 394.658 119.668 394.658 120.571C394.658 121.654 393.755 122.557 392.491 122.557C391.227 122.557 390.505 121.654 390.685 120.39C390.685 119.307 391.408 118.765 392.672 118.765Z"
        fill="#F37140"
      />
      <path
        d="M336.686 210.148C336.686 208.883 337.228 208.161 338.312 208.161C339.576 207.981 340.479 208.703 340.479 209.967C340.479 211.231 339.576 212.134 338.492 212.134C337.589 212.134 336.686 211.231 336.686 210.148Z"
        fill="#F37140"
      />
      <path
        d="M177.218 151.272C178.302 151.272 179.205 152.175 179.205 153.078C179.205 154.162 178.302 155.065 177.038 155.065C175.774 155.065 175.051 154.162 175.232 152.898C175.232 151.814 175.954 151.272 177.218 151.272Z"
        fill="#F37140"
      />
      <path
        d="M177.038 118.765C178.302 118.765 179.205 119.668 179.024 120.751C179.024 121.835 178.121 122.557 177.038 122.557C175.774 122.557 175.051 121.835 175.051 120.571C175.232 119.487 175.954 118.765 177.038 118.765Z"
        fill="#F37140"
      />
      <path
        d="M260.474 218.094C260.474 216.83 261.377 216.108 262.641 216.288C263.905 216.469 264.447 217.011 264.447 218.275C264.447 219.539 263.544 220.261 262.28 220.081C261.016 220.081 260.474 219.358 260.474 218.094Z"
        fill="#F37140"
      />
      <path
        d="M354.023 112.625C354.023 113.708 353.301 114.611 352.217 114.611C351.134 114.611 350.231 113.708 350.231 112.444C350.231 111.18 350.953 110.638 352.037 110.638C353.301 110.638 354.023 111.361 354.023 112.625Z"
        fill="#F37140"
      />
      <path
        d="M138.569 112.624C138.569 113.888 137.666 114.791 136.583 114.611C135.68 114.611 134.777 113.708 134.777 112.624C134.777 111.36 135.499 110.638 136.763 110.638C138.028 110.638 138.569 111.36 138.569 112.624Z"
        fill="#F37140"
      />
      <path
        d="M134.778 177.64C134.778 176.376 135.32 175.653 136.584 175.653C137.848 175.653 138.57 176.195 138.57 177.64C138.57 178.904 137.667 179.807 136.584 179.807C135.681 179.626 134.778 178.723 134.778 177.64Z"
        fill="#F37140"
      />
      <path
        d="M264.266 120.752C264.266 122.016 263.544 122.738 262.28 122.738C261.196 122.738 260.474 122.016 260.474 120.752C260.474 119.487 261.196 118.765 262.46 118.946C263.725 118.765 264.266 119.487 264.266 120.752Z"
        fill="#F37140"
      />
      <path
        d="M354.024 177.82C354.024 178.904 353.121 179.807 352.037 179.807C350.954 179.807 350.051 178.723 350.231 177.64C350.231 176.376 350.953 175.834 352.218 175.834C353.482 175.653 354.204 176.376 354.024 177.82Z"
        fill="#F37140"
      />
      <path
        d="M138.57 210.147C138.57 211.412 137.667 212.315 136.584 212.134C135.681 212.134 134.778 211.231 134.778 210.147C134.778 208.883 135.5 208.161 136.764 208.161C138.029 208.161 138.57 208.883 138.57 210.147Z"
        fill="#F37140"
      />
      <path
        d="M354.023 210.147C354.023 211.231 353.301 212.134 352.217 212.134C351.134 212.134 350.231 211.231 350.231 209.967C350.231 208.703 350.953 208.161 352.037 208.161C353.301 208.161 354.023 208.883 354.023 210.147Z"
        fill="#F37140"
      />
      <path
        d="M138.569 80.2976C138.389 81.3812 137.486 82.1035 136.583 82.1035C135.499 81.9229 134.777 81.02 134.777 79.7558C134.957 78.6722 135.68 77.9498 136.763 78.1304C138.028 78.1304 138.75 79.0334 138.569 80.2976Z"
        fill="#F37140"
      />
      <path
        d="M354.023 242.655C354.023 243.739 353.12 244.642 352.217 244.642C351.134 244.642 350.231 243.558 350.231 242.475C350.231 241.21 350.953 240.669 352.217 240.669C353.482 240.669 354.023 241.391 354.023 242.655Z"
        fill="#F37140"
      />
      <path
        d="M264.266 153.259C264.266 154.523 263.544 155.246 262.28 155.246C261.196 155.246 260.474 154.523 260.474 153.259C260.474 151.995 261.196 151.273 262.46 151.453C263.725 151.273 264.266 151.995 264.266 153.259Z"
        fill="#F37140"
      />
      <path
        d="M134.778 242.655C134.778 241.391 135.32 240.669 136.584 240.669C137.848 240.669 138.57 241.21 138.57 242.475C138.57 243.739 137.667 244.642 136.584 244.642C135.681 244.642 134.778 243.739 134.778 242.655Z"
        fill="#F37140"
      />
      <path
        d="M354.023 145.313C353.843 146.396 352.94 147.119 352.037 147.119C350.953 146.938 350.231 146.035 350.231 144.771C350.412 143.687 351.134 142.965 352.217 143.146C353.482 143.146 354.204 144.049 354.023 145.313Z"
        fill="#F37140"
      />
      <path
        d="M354.023 80.117C354.023 81.3812 353.12 82.2842 352.037 82.1036C351.134 82.1036 350.231 81.2006 350.231 80.117C350.231 78.8529 350.953 78.1305 352.217 78.1305C353.482 78.1305 354.023 78.8529 354.023 80.117Z"
        fill="#F37140"
      />
      <path
        d="M264.266 88.244C264.266 89.5081 263.363 90.2305 262.099 90.0499C261.016 90.0499 260.293 89.3276 260.293 88.0634C260.293 86.7992 261.016 86.0768 262.46 86.2574C263.725 86.438 264.447 86.9798 264.266 88.244Z"
        fill="#F37140"
      />
      <path
        d="M138.57 145.132C138.57 146.216 137.848 147.119 136.764 147.119C135.681 147.119 134.778 146.216 134.778 145.132C134.778 143.868 135.5 143.146 136.764 143.146C138.029 143.146 138.57 143.868 138.57 145.132Z"
        fill="#F37140"
      />
      <path
        d="M264.266 185.586C264.266 186.85 263.544 187.573 262.28 187.573C261.196 187.573 260.474 186.85 260.474 185.586C260.474 184.322 261.196 183.6 262.46 183.6C263.725 183.78 264.266 184.503 264.266 185.586Z"
        fill="#F37140"
      />
      <path
        d="M296.413 153.078C296.413 151.814 296.955 151.272 298.219 151.272C299.483 151.272 300.205 151.995 300.205 153.259C300.205 154.343 299.483 155.065 298.399 155.246C297.135 155.246 296.413 154.523 296.413 153.078Z"
        fill="#F37140"
      />
      <path
        d="M96.1299 154.162C96.1299 155.426 95.5881 155.968 94.324 156.148C93.0598 156.148 92.3374 155.426 92.3374 154.343C92.3374 153.259 93.0598 152.537 94.1434 152.356C95.4075 152.175 96.1299 152.898 96.1299 154.162Z"
        fill="#F37140"
      />
      <path
        d="M311.583 153.982C311.583 155.246 311.041 155.968 309.958 155.968C308.693 155.968 307.791 155.426 307.791 154.162C307.791 152.898 308.513 152.176 309.777 152.176C310.861 152.176 311.583 152.898 311.583 153.982Z"
        fill="#F37140"
      />
      <path
        d="M296.413 120.571C296.413 119.307 296.955 118.765 298.219 118.765C299.483 118.765 300.205 119.487 300.205 120.751C300.205 121.835 299.483 122.557 298.399 122.557C297.135 122.738 296.413 121.835 296.413 120.571Z"
        fill="#F37140"
      />
      <path
        d="M80.9595 120.57C80.9595 119.306 81.5013 118.764 82.7654 118.764C84.0296 118.764 84.752 119.487 84.752 120.751C84.752 121.834 84.0296 122.557 82.946 122.557C81.6819 122.737 80.9595 121.834 80.9595 120.57Z"
        fill="#F37140"
      />
      <path
        d="M84.752 153.259C84.752 154.523 84.0296 155.246 82.7654 155.246C81.6819 155.246 80.9595 154.523 80.9595 153.259C80.9595 151.995 81.5013 151.453 82.7654 151.453C84.0296 151.273 84.752 151.995 84.752 153.259Z"
        fill="#F37140"
      />
      <path
        d="M300.205 218.275C300.205 219.539 299.483 220.261 298.219 220.261C297.135 220.261 296.413 219.539 296.413 218.275C296.413 217.01 296.955 216.469 298.219 216.469C299.483 216.288 300.205 217.01 300.205 218.275Z"
        fill="#F37140"
      />
      <path
        d="M84.7519 88.2436C84.7519 89.5078 83.8489 90.2302 82.5848 90.0496C81.5012 90.0496 80.7788 89.3272 80.7788 88.063C80.7788 86.7989 81.5012 86.2571 82.5848 86.2571C84.2101 86.2571 84.7519 86.9794 84.7519 88.2436Z"
        fill="#F37140"
      />
      <path
        d="M80.9595 185.586C80.9595 184.322 81.5013 183.78 82.7654 183.78C84.0296 183.78 84.752 184.503 84.752 185.767C84.752 186.851 84.0296 187.573 82.946 187.753C81.6819 187.753 80.9595 187.031 80.9595 185.586Z"
        fill="#F37140"
      />
      <path
        d="M298.399 183.78C299.664 183.78 300.386 184.503 300.205 185.767C300.205 186.851 299.483 187.573 298.219 187.573C296.955 187.573 296.232 186.851 296.413 185.406C296.413 184.322 297.135 183.78 298.399 183.78Z"
        fill="#F37140"
      />
      <path
        d="M311.583 121.655C311.583 122.919 311.041 123.641 309.777 123.641C308.513 123.641 307.79 122.919 307.61 121.835C307.61 120.752 308.332 120.029 309.416 119.849C310.68 119.668 311.583 120.391 311.583 121.655Z"
        fill="#F37140"
      />
      <path
        d="M311.583 186.489C311.583 187.753 311.041 188.476 309.777 188.476C308.513 188.476 307.61 187.934 307.61 186.67C307.61 185.406 308.332 184.683 309.596 184.683C310.861 184.683 311.583 185.406 311.583 186.489Z"
        fill="#F37140"
      />
      <path
        d="M82.9464 216.288C84.2106 216.288 84.9329 217.01 84.7523 218.274C84.7523 219.358 84.03 220.08 82.7658 220.08C81.5016 220.08 80.7792 219.358 80.9598 218.094C80.9598 216.83 81.6822 216.288 82.9464 216.288Z"
        fill="#F37140"
      />
      <path
        d="M311.583 89.1469C311.583 90.4111 310.861 90.9529 309.777 90.9529C308.513 90.9529 307.791 90.2305 307.791 88.9664C307.791 87.8828 308.513 87.1604 309.596 87.1604C310.861 87.1604 311.583 87.8828 311.583 89.1469Z"
        fill="#F37140"
      />
      <path
        d="M300.205 88.0633C300.205 89.3274 299.483 90.0498 298.219 90.0498C297.135 90.0498 296.413 89.3274 296.413 88.2439C296.413 86.9797 296.955 86.4379 298.219 86.2573C299.483 86.2573 300.205 86.9797 300.205 88.0633Z"
        fill="#F37140"
      />
      <path
        d="M311.583 219.177C311.583 220.442 310.861 220.983 309.777 220.983C308.513 220.983 307.791 220.261 307.791 218.997C307.791 217.913 308.513 217.191 309.777 217.191C310.861 217.191 311.583 217.913 311.583 219.177Z"
        fill="#F37140"
      />
      <path
        d="M94.3235 123.46C93.0593 123.46 92.3369 122.737 92.3369 121.473C92.3369 120.39 93.0593 119.667 94.3235 119.667C95.5876 119.667 96.31 120.39 96.31 121.654C96.1294 122.918 95.407 123.46 94.3235 123.46Z"
        fill="#F37140"
      />
      <path
        d="M94.1433 220.983C92.8791 220.983 92.1567 220.261 92.1567 218.997C92.1567 217.913 92.8791 217.191 94.1433 217.191C95.4075 217.191 96.1298 217.913 96.1298 219.177C96.1298 220.442 95.4075 221.164 94.1433 220.983Z"
        fill="#F37140"
      />
      <path
        d="M96.1299 186.67C96.1299 187.934 95.4075 188.476 94.324 188.476C93.0598 188.476 92.3374 187.753 92.3374 186.489C92.3374 185.225 93.0598 184.503 94.324 184.683C95.5881 184.683 96.1299 185.406 96.1299 186.67Z"
        fill="#F37140"
      />
      <path
        d="M94.1428 90.9525C92.8786 90.9525 92.1562 90.2302 92.1562 88.966C92.1562 87.8824 92.8786 87.16 94.1428 87.16C95.407 87.16 96.1293 87.8824 95.9488 89.1466C96.1293 90.4107 95.407 91.1331 94.1428 90.9525Z"
        fill="#F37140"
      />
      <path
        d="M57.6626 237.237C57.6626 235.792 57.6626 234.709 57.6626 233.445C59.4686 233.445 60.3715 233.986 60.3715 235.251C60.3715 236.515 59.4686 237.237 57.6626 237.237Z"
        fill="#F37140"
      />
      <path
        d="M57.6626 172.222C57.6626 170.958 57.6626 169.874 57.6626 168.61C58.7462 168.249 59.4686 168.429 59.8297 169.332C60.1909 169.874 60.1909 170.777 60.0103 171.319C59.6492 172.222 58.9268 172.583 57.6626 172.222Z"
        fill="#F37140"
      />
      <path
        d="M57.6626 107.206C57.6626 105.761 57.6626 104.678 57.6626 103.414C59.288 103.414 60.3715 103.955 60.3715 105.22C60.5521 106.484 59.4686 107.206 57.6626 107.206Z"
        fill="#F37140"
      />
      <path
        d="M57.6626 139.713C57.6626 138.449 57.6626 137.185 57.6626 135.921C59.4686 135.921 60.3715 136.643 60.3715 137.727C60.3715 138.991 59.4686 139.713 57.6626 139.713Z"
        fill="#F37140"
      />
      <path
        d="M57.6626 204.73C57.6626 203.465 57.6626 202.201 57.6626 200.937C59.4686 200.937 60.3715 201.66 60.3715 202.743C60.3715 204.007 59.4686 204.73 57.6626 204.73Z"
        fill="#F37140"
      />
      <path
        d="M57.6626 87.3409C59.6491 87.3409 60.3715 88.0633 60.3715 89.3275C60.1909 90.5917 59.4686 90.9529 57.6626 90.7723C57.6626 89.6887 57.6626 88.6051 57.6626 87.3409Z"
        fill="#F37140"
      />
      <path
        d="M57.6626 155.968C57.6626 154.704 57.6626 153.62 57.6626 152.537C58.5656 152.176 59.4686 152.176 59.8297 153.079C60.0103 153.62 60.0103 154.523 59.8297 155.065C59.6491 155.968 58.7462 156.149 57.6626 155.968Z"
        fill="#F37140"
      />
      <path
        d="M57.8433 184.864C59.6492 184.864 60.5522 185.405 60.5522 186.67C60.5522 187.753 59.6492 188.295 57.8433 188.295C57.8433 187.211 57.8433 186.128 57.8433 184.864Z"
        fill="#F37140"
      />
      <path
        d="M57.6626 119.848C59.4686 119.848 60.3715 120.57 60.3715 121.654C60.3715 122.738 59.4686 123.279 57.6626 123.279C57.6626 122.196 57.6626 121.112 57.6626 119.848Z"
        fill="#F37140"
      />
      <path
        d="M57.6626 220.803C57.6626 219.719 57.6626 218.635 57.6626 217.371C58.5656 217.01 59.4686 217.01 59.8297 217.913C60.0103 218.455 60.0103 219.358 59.8297 219.9C59.6491 220.983 58.9268 221.164 57.6626 220.803Z"
        fill="#F37140"
      />
      <path
        d="M470.509 245.364C469.064 243.739 468.884 243.197 469.787 242.294C470.509 241.391 471.412 241.391 473.038 242.294C472.135 243.377 471.232 244.28 470.509 245.364Z"
        fill="#F37140"
      />
      <path
        d="M64.1641 65.4886C65.067 64.5856 65.97 63.8633 66.873 63.1409C66.873 65.308 66.3312 65.8498 64.1641 65.4886Z"
        fill="#F37140"
      />
      <path
        d="M460.937 81.2005L460.215 80.4781C460.395 80.4781 460.395 80.6587 460.576 80.8393C460.757 81.0199 460.757 81.0199 460.937 81.2005Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M460.034 80.478C459.673 80.1168 459.131 79.7556 458.589 79.575C457.867 79.2139 456.964 79.0333 456.061 79.0333H425.54C422.109 79.0333 419.4 81.7422 419.4 85.1735C419.4 86.0765 419.581 86.7989 419.942 87.7018C420.123 88.063 420.303 88.4242 420.484 88.7854L417.414 85.7153C416.691 84.8123 416.33 83.5481 416.33 82.1034C416.33 78.6721 419.039 75.9631 422.47 75.9631H452.991C454.436 75.9631 455.88 76.5049 456.964 77.4079L457.145 77.5885L457.686 78.1303L457.867 78.3109L460.034 80.478Z"
        :fill="myColors.engineering.radioBox.indicatorDepth"
        :stroke="myColors.engineering.radioBox.indicatorOutline"
      />
      <path
        d="M489.653 49.054C483.151 43.0943 474.483 39.3018 465.091 38.9406C464.55 38.9406 464.008 38.9406 463.466 38.9406H77.5284C70.6657 38.9406 64.3448 40.7466 58.7463 43.8167C54.0507 46.3451 49.7164 49.957 46.4656 54.2914C44.479 56.8198 42.8536 59.5287 41.5895 62.4183C39.7835 66.3914 38.6999 70.9064 38.3387 75.6019C38.3387 76.5049 38.1581 77.4079 38.1581 78.4915V239.043C38.1581 239.585 38.1581 240.307 38.1581 240.849C38.5193 250.601 42.673 259.451 48.994 266.133L10.5266 227.304C4.3863 220.441 0.59375 211.231 0.59375 201.298V40.7465C0.59375 39.8436 0.59375 38.76 0.774348 37.857C1.13554 33.1614 2.21913 28.8271 4.02511 24.6733C5.28929 21.7838 6.91467 19.0748 8.90125 16.5464C12.152 12.2121 16.3058 8.78073 21.1819 6.07176C26.7804 3.0016 33.1014 1.37622 39.9641 1.37622H425.902C436.738 1.37622 446.49 5.71056 453.714 12.9345L489.653 49.054Z"
        :fill="myColors.engineering.radioBox.depth"
        :stroke="myColors.engineering.radioBox.depthOutline"
        stroke-miterlimit="10"
      />
      <path
        d="M41.5888 62.2374L4.0249 24.8541"
        :stroke="myColors.engineering.radioBox.depthLines"
        stroke-miterlimit="10"
      />
      <path
        d="M38.3383 75.421L0.774414 37.8572"
        :stroke="myColors.engineering.radioBox.depthOutline"
      />
      <path
        d="M46.465 54.1106L8.90112 16.7273"
        :stroke="myColors.engineering.radioBox.depthOutline"
      />
      <path
        d="M58.7457 43.8162L21.1819 6.25232"
        :stroke="myColors.engineering.radioBox.depthOutline"
      />
      <path
        d="M83.1264 38.9401L45.5625 1.37622"
        :stroke="myColors.engineering.radioBox.depthOutline"
      />
      <path
        d="M462.382 85.1737C462.382 88.605 459.673 91.3139 456.241 91.3139H425.721C424.095 91.3139 422.47 90.5916 421.387 89.508C421.206 89.3274 421.025 88.9662 420.664 88.7856C420.484 88.4244 420.303 88.0632 420.122 87.702C419.761 86.9797 419.581 86.0767 419.581 85.1737C419.581 81.7424 422.289 79.0334 425.721 79.0334H456.241C457.144 79.0334 457.867 79.214 458.77 79.5752C459.312 79.7558 459.853 80.117 460.215 80.4782L460.937 81.2006C461.84 82.2842 462.382 83.7289 462.382 85.1737Z"
        fill="white"
        stroke="#FA713F"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</template>

<script>
import { store } from "@/store/store.js";
import { codingColors } from "@/core/colors.js";
export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style scoped>
</style>