<template>
  <div class="stripes">
    <svg
      width="450"
      height="120"
      viewBox="0 0 450 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="452"
        height="120"
      >
        <rect
          x="0.480469"
          y="0.335388"
          width="450.901"
          height="119.665"
          :fill="myColors.foregroundAccentActive"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M52.7259 -44.422H81.4296L21.4296 156.311H-7.27417L52.7259 -44.422Z"
          :fill="myColors.foregroundAccentActive"
        />
        <path
          d="M102.19 -44.422H130.894L70.8942 156.311H42.1904L102.19 -44.422Z"
          :fill="myColors.foregroundAccentActive"
        />
        <path
          d="M151.655 -44.422H180.359L120.359 156.311H91.6553L151.655 -44.422Z"
          :fill="myColors.foregroundAccentActive"
        />
        <path
          d="M201.12 -44.422H229.824L169.824 156.311H141.12L201.12 -44.422Z"
          :fill="myColors.foregroundAccentActive"
        />
        <path
          d="M250.585 -44.422H279.289L219.288 156.311H190.585L250.585 -44.422Z"
          :fill="myColors.foregroundAccentActive"
        />
        <path
          d="M300.049 -44.422H328.753L268.753 156.311H240.049L300.049 -44.422Z"
          :fill="myColors.foregroundAccentActive"
        />
        <path
          d="M349.514 -44.422H378.218L318.218 156.311H289.514L349.514 -44.422Z"
          :fill="myColors.foregroundAccentActive"
        />
        <path
          d="M398.979 -44.422H427.683L367.683 156.311H338.979L398.979 -44.422Z"
          :fill="myColors.foregroundAccentActive"
        />
        <path
          d="M448.444 -44.422H477.148L417.148 156.311H388.444L448.444 -44.422Z"
          :fill="myColors.foregroundAccentActive"
        />
        <path
          d="M497.909 -44.422H526.612L466.612 156.311H437.909L497.909 -44.422Z"
          :fill="myColors.foregroundAccentActive"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";
export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.isDark, this.$store);
    },
  },
};
</script>

<style>
</style>