var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#/ux"}},[_c('h1',{class:_vm.shouldRotate ? 'rotate-text' : 'horizontal-text',style:([
      {
        color: _vm.myColors.main,
        top: _vm.topPosition,
      },
      _vm.headerStyle,
      _vm.position,
    ])},[_vm._v(" "+_vm._s(_vm.headerName)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }