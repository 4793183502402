<template>
  <div class="high-level">
    <div class="header-info">
      <div class="logo-container">
        <!--        <Logo />-->
        <img :src="logo" class="logo" loading="lazy" />
      </div>
      <div class="info-text">
        <h1 :style="{ color: myColors.foreground }" class="my-name">
          {{ myName }}
        </h1>
        <div
          :style="{ 'background-color': myColors.main }"
          class="divider"
        ></div>
        <h1 :style="{ color: myColors.foreground }" class="title">
          <span>{{ spitTitle().first }}</span>
          <Times />
          <span>{{ spitTitle().second }}</span>
        </h1>
        <p :style="{ color: myColors.foreground }" class="summary">
          {{ summary }}
        </p>
      </div>
    </div>
    <div class="header-decoration">
      <div class="plus-container">
        <Plus :strokeColor="myColors.onBackground" />
      </div>
      <div class="stripe-container">
        <!--        <Stripes />-->
        <img :src="stripes" class="stripes" loading="lazy" />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";
import Times from "@/components/svg/Times.vue";
import Logo from "@/components/svg/Logo.vue";
import Stripes from "@/components/svg/Stripes.vue";
import stripes from "@/assets/images/stripes.webp";
import Plus from "@/components/svg/Plus.vue";
import Vue from "vue";
import { data } from "@/core/data/index";
import logo from "@/assets/icon/logo.webp";

export default Vue.extend({
  store: store,
  components: {
    Times,
    Logo,
    Stripes,
    Plus,
  },
  name: "HighLevelInfo",
  data() {
    return {
      title: "",
    };
  },
  computed: {
    summary: function () {
      return data.written.highLevel.intro;
    },
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.isDark, this.$store);
    },
    myName: function () {
      return data.written.highLevel.name;
    },
    logo: () => logo,
    stripes: () => stripes,
  },
  methods: {
    spitTitle() {
      const first = data.written.highLevel.title.split(",")[0];
      const second = data.written.highLevel.title.split(",")[1];
      return {
        first: first,
        second: second,
      };
    },
  },
});
</script>

<style scoped>
.high-level {
  display: grid;
  inline-size: 100%;
  block-size: fit-content;
  box-sizing: border-box;
  padding-block-start: 5%;
}

@media screen and (max-width: 830px) {
  .high-level {
  }
}

@media screen and (max-width: 500px) {
  .high-level {
    top: 0;
    left: 0;
    margin: 0;
    padding-inline: 2rem;
  }
}

h1 {
  text-transform: uppercase;
  font-size: 1.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  width: 100%;
}

h1 > span {
  text-align: left;
  align-self: flex-start;
}

.my-name {
  inline-size: 23rem;
  font-size: 2.5rem;
  letter-spacing: -0.5px;
  margin: 0;
}

@media screen and (max-width: 830px) {
  .my-name {
    font-size: xx-large;
    inline-size: fit-content;
  }
}

@media screen and (max-width: 500px) {
  .my-name {
    font-size: x-large;
  }
}

.stripes {
  block-size: 7.5rem;
}

@media screen and (max-width: 830px) {
  .stripes {
    block-size: 100%;
  }
}

@media screen and (max-width: 600px) {
  .stripes {
    display: none;
  }
}

@media screen and (max-width: 1030px) {
  .stripes {
    block-size: 100%;
  }
}

@media screen and (max-width: 575px) {
  .high-level {
  }
}

.logo {
  inline-size: 100%;
  block-size: 100%;
}

.summary {
  font-size: 16px;
  font-weight: 400;
  width: calc(100% - 4rem);
}

@media screen and (max-width: 830px) {
  .summary {
    inline-size: 100%;
  }
}

p {
  text-align: left;
  margin-top: 16px;
  width: 360px;
  line-height: 28px;
  padding: 0;
}

.header-info {
  display: grid;
  grid-template-columns: 10% 1fr;
  gap: 2rem;
  grid-template-rows: 1fr;
  block-size: 100%;
  inline-size: 100%;
  box-sizing: border-box;
  padding-inline-start: 2rem;
}

@media screen and (max-width: 500px) {
  .header-info {
    /*grid-template-columns: 3rem 1fr;*/
    padding-inline-start: 0;
    gap: 1rem;
  }
}

.header-decoration {
  inline-size: 50%;
  align-self: flex-end;
  display: flex;
  flex-flow: column;
}

@media screen and (max-width: 500px) {
  .header-decoration {
    display: none;
  }
}

.logo-container {
  height: fit-content;
  max-inline-size: 7rem;
}

.info-text {
  inline-size: 100%;
  height: 100%;
  display: grid;
  gap: 1.25rem;
  max-inline-size: 23rem;
  min-inline-size: 18rem;
}

:is(.info-text) p {
  margin: 0;
}

@media screen and (max-width: 575px) {
  .info-text {
    inline-size: 80%;
    min-inline-size: initial;
  }
}

.divider {
  block-size: 2px;
  inline-size: 100%;
}

.title {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 1rem;
  inline-size: fit-content;
  block-size: fit-content;
}

@media screen and (max-width: 830px) {
  .title {
    font-size: medium;
  }
}

.plus-container {
  height: 1.375rem;
  width: 10rem;
  z-index: 2;
  position: absolute;
  left: 80%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.stripe-container {
  block-size: 7.437rem;
  inline-size: 100%;
  position: relative;
  top: 0.7rem;
  left: 100%;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1030px) {
  .stripe-container {
    block-size: 4rem;
  }
}

@media screen and (max-width: 830px) {
  .stripe-container {
    display: none;
  }
}
</style>
