<template>
  <svg
    fill="none"
    height="189"
    viewBox="0 0 55 189"
    width="55"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.035 160.832L17.4366 161.012L0.280029 143.675H5.87847L23.035 160.832Z"
      fill="white"
    />
    <path
      :fill="myColors.engineering.topOne.depth"
      :stroke="myColors.engineering.topOne.depthOutline"
      d="M23.0352 18.3403V160.832L5.87842 143.675V1.00293L23.0352 18.3403Z"
    />
    <path
      :fill="myColors.engineering.topOne.depth"
      :stroke="myColors.engineering.topOne.depthOutline"
      d="M17.4366 161.012V187.56L0.280029 170.223V143.675L17.4366 161.012Z"
    />
    <path
      :stroke="myColors.engineering.topOne.mainOutline"
      d="M44.5262 160.832H50.1247V187.561H17.4365V160.832H23.035V18.3403H44.5262L53.5561 45.2494L44.5262 54.6405V160.832Z"
      fill="#FFFDF7"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
    <path
      :stroke="myColors.engineering.topOne.topdepthOutline"
      d="M44.5259 18.3401H23.035L5.87842 1.00293H27.3693L44.5259 18.3401Z"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
    <path
      :stroke="myColors.engineering.topOne.mainOutline"
      d="M50.1248 160.832H44.5264V155.234L50.1248 160.832Z"
      fill="#FBFAFA"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
import { store } from "@/store/store.js";
import { codingColors } from "@/core/colors.js";

export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style scoped></style>
