/* eslint-disable no-undef */
import Vue from "vue";
import Vuex from "vuex";
import {
  processData
} from "@/core/process_data.js";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    isDesktop: false,
    shouldShowHeader: false,
    isDarkMode: false,
    primaryDark: "#DD7F5C",
    primaryLight: "#FA713F",
    backgroundLight: "#FBFAFA",
    backgroundDark: "#424242",
    onBackgroundLight: "#5E5E5E",
    onBackgroundDark: "#FFF",
    foregroundDark: "#EBEBEB",
    foregroundLight: "#000",
    foregroundAccent: "#FFFDF6",
    foregroundAccentDark: "#535350",
    foregroundAccentActive: "#FFF3CC",
    darkAccentFaint: "rgba(255, 246, 217, 0.09)",
    darkAccentFainter: "rgba(255, 246, 217, 0.09)",
    foregroundFaint: "rgba(250, 113, 63, 0.05)",
    accentFaint: "#FFF3CC",
    zoomImage: "",
    zoomShouldClose: true
  },
  mutations: {
    toggleScheme() {
      this.state.isDarkMode = !this.state.isDarkMode;
    },

    setForDesktop() {
      this.state.isDesktop = true;
    },
    setForMobile() {
      this.state.isDesktop = false;
    },
    setHeaderDisplay(state, shouldShow) {
      this.state.shouldShowHeader = shouldShow;
    },
    setZoomImage(state, source) {
      this.state.zoomImage = source
      this.state.zoomShouldClose = false
    },
    setZoomClose(state) {
      this.state.zoomShouldClose = true
    }

  },
  getters: {
    isDark: (state) => state.isDarkMode,
    isDesktop: (state) => state.isDesktop,
    darkPrimary: (state) => state.primaryDark,
    lightPrimary: (state) => state.primaryLight,
    onBackgroundLight: (state) => state.onBackgroundLight,
    onBackgroundDark: (state) => state.onBackgroundDark,
    foregroundLight: (state) => state.foregroundLight,
    foregroundDark: (state) => state.foregroundDark,
    backgroundDark: (state) => state.backgroundDark,
    backgroundLight: (state) => state.backgroundLight,
    foregroundAccent: (state) => state.foregroundAccent,
    foregroundAccentDark: (state) => state.foregroundAccentDark,
    foregroundAccentActive: (state) => state.foregroundAccentActive,
    foregroundFaint: (state) => state.foregroundFaint,
    darkAccentFaint: (state) => state.darkAccentFaint,
    darkAccentFainter: (state) => state.darkAccentFainter,
    accentFaint: (state) => state.accentFaint,
    shouldShowHeader: (state) => state.shouldShowHeader,
    zoomImage: (state) => state.zoomImage,
    zoomShouldClose: (state) => state.zoomShouldClose,

  },
});