import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/pages/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/ux/",
    name: "UX Home",
    component:
      window.innerWidth > 700
        ? () =>
            import(
              /* webpackChunkName: "about" */ "@/pages/ux/desktop/UXHome.vue"
            )
        : () =>
            import(
              /* webpackChunkName: "about" */ "@/pages/ux/mobile/UXHome.vue"
            ),
  },
  {
    path: "/ux/sentinel_digital",
    name: "Sentinel Digital",
    component:
      window.innerWidth > 700
        ? () => import("@/pages/ux/desktop/SentinelDigital.vue")
        : () => import("@/pages/ux/mobile/SentinelDigital.vue"),
  },
  {
    path: "/ux/luminoso_design_system",
    component:
      window.innerWidth > 700
        ? () => import("@/pages/ux/desktop/LunimosoDesignSystem.vue")
        : () => import("@/pages/ux/mobile/LunimosoDesignSystem.vue"),
  },
  {
    path: "/ux/csq",
    name: "Christian Science Quarterly",
    component:
      window.innerWidth > 700
        ? () => import("@/pages/ux/desktop/BibleLesson.vue")
        : () => import("@/pages/ux/mobile/BibleLesson.vue"),
  },
  {
    path: "/ux/sentinel_design_system",
    name: "Sentinel Design System",
    component:
      window.innerWidth > 700
        ? () => import("@/pages/ux/desktop/SDDesignSystem.vue")
        : () => import("@/pages/ux/mobile/SDDesignSystem.vue"),
  },
  {
    path: "/ux/tag_editor",
    name: "Luminoso Tag Editor",
    component: () => import("@/pages/ux/desktop/LuminosoTagEditor.vue"),
  },
  {
    path: "/software_engineering",
    name: "Software Engineering",
    component:
      window.innerWidth > 700
        ? () => import("@/pages/ux/desktop/Coding.vue")
        : () => import("@/pages/ux/mobile/Coding.vue"),
  },
  {
    path: "/illustrations",
    name: "Illustration",
    component:
      window.innerWidth > 700
        ? () => import("@/pages/illustration/desktop/Illustration.vue")
        : () => import("@/pages/illustration/mobile/Illustration.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
