<template>
  <svg
    class="slide-handle"
    fill="none"
    height="158"
    viewBox="0 0 159 158"
    width="159"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :stroke="myColors.dSlideHandle.outline"
      d="M158.02 87.9709C158.02 107.03 150.329 124.327 137.831 136.82C125.334 149.312 108.029 157 88.9627 157C70.2163 157 53.2325 149.473 40.7349 137.3L39.6134 136.179C36.7293 133.136 34.0055 129.933 31.7624 126.409C24.392 115.358 20.0659 102.065 20.0659 87.8107C20.0659 68.7516 27.7567 51.4543 40.2543 38.9618C52.7518 26.4693 70.0561 18.7816 89.1229 18.7816C103.383 18.7816 116.682 23.1059 127.577 30.4733C130.301 32.235 132.704 34.157 135.107 36.3992L140.555 41.8447C151.45 54.177 158.02 70.3532 158.02 87.9709Z"
      fill="#FFFDF7"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
    <path
      :fill="myColors.dSlideHandle.depth"
      :stroke="myColors.dSlideHandle.depthOutline"
      d="M134.947 36.5593C132.544 34.4772 129.98 32.3951 127.417 30.6334C116.361 23.266 103.223 18.9417 88.9626 18.9417C69.8958 18.9417 52.5916 26.6293 40.094 39.1219C27.5964 51.6144 19.9056 68.9117 19.9056 87.9708C19.9056 102.225 24.2317 115.518 31.6021 126.569C33.8452 130.093 36.569 133.296 39.4531 136.339L22.309 119.202L19.9056 116.8C8.04898 104.467 0.838867 87.6505 0.838867 69.232C0.838867 50.1729 8.52965 32.8756 21.0272 20.3831C33.5248 7.89057 50.8291 0.202881 69.8958 0.202881C87.6808 0.202881 103.864 6.92961 116.201 17.9807L121.168 22.9457L134.947 36.5593Z"
    />
    <path
      d="M38.9725 40.4034L20.0659 21.6646"
      stroke="#FBFAFA"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
    <path
      d="M31.7625 49.5324L12.856 30.6335"
      stroke="#FBFAFA"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
    <path
      d="M22.95 67.7907L4.04346 48.8918"
      stroke="#FBFAFA"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
    <path
      d="M48.7464 31.9148L30 13.0159"
      stroke="#FBFAFA"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
    <path
      d="M26.4749 58.6616L7.72852 39.7627"
      stroke="#FBFAFA"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
import { store } from "@/store/store";
import { codingColors } from "@/core/colors.js";

export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style scoped></style>
