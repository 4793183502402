<template>
  <svg
    fill="none"
    height="317"
    viewBox="0 0 324 317"
    width="324"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="myColors.illustration.bottomRightNode.bar"
      d="M36.629 134.524L284.985 189.028L286.448 182.361L38.0919 127.858L36.629 134.524Z"
    />
    <path
      :fill="myColors.illustration.bottomRightNode.boxDepth"
      :stroke="myColors.illustration.bottomRightNode.boxOutline"
      d="M176.719 173.724L132.105 161.769L129.024 173.268L173.638 185.222L176.719 173.724Z"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
    <path
      d="M186.29 138.159L141.676 126.205L132.146 161.773L176.76 173.727L186.29 138.159Z"
      fill="#FFFDF7"
      stroke="#FA713F"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
    <path
      :fill="myColors.illustration.bottomRightNode.circle"
      d="M306.995 204.504C315.877 199.376 318.92 188.018 313.792 179.136C308.664 170.254 297.307 167.211 288.425 172.339C279.542 177.467 276.499 188.825 281.627 197.707C286.755 206.589 298.113 209.632 306.995 204.504Z"
    />
    <path
      :fill="myColors.illustration.bottomRightNode.circle"
      d="M34.6703 144.549C43.5524 139.421 46.5957 128.063 41.4676 119.181C36.3395 110.299 24.982 107.256 16.0999 112.384C7.21776 117.512 4.17453 128.869 9.30262 137.751C14.4307 146.634 25.7882 149.677 34.6703 144.549Z"
    />
  </svg>
</template>

<script>
import { store } from "@/store/store";
import { codingColors } from "@/core/colors.js";

export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style></style>
