<template>
  <div :style="strokePosition" class="plus">
    <svg
      fill="none"
      height="21"
      viewBox="0 0 22 21"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path :stroke="plusColor" d="M10.8262 21V0" />
      <path :stroke="plusColor" d="M21.3262 10.5L0.326172 10.5" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    strokeColor: {
      type: String,
      default: "black",
    },
    strokePosition: {
      type: Object,
    },
  },
  computed: {
    plusColor: function () {
      return this.strokeColor;
    },
  },
};
</script>

<style scoped></style>
