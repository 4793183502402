<template>
  <div class="mainBody">
    <HighLevelInfo />
    <DesktopUXDesign />
    <ResponsiveEngineering />
    <Illustration />

    <button
      class="to-top"
      @click="scrollToTop"
      v-on:mouseleave="renderNormal"
      v-on:mouseover="renderHover"
    >
      <UpArrow :userHasHovered="hasHovered" />
      <p :style="{ color: myColors.onBackground }">back to top</p>
    </button>
  </div>
</template>

<script>
import HighLevelInfo from "@/components/info/desktop/HighLevelInfo.vue";
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";
import DesktopUXDesign from "@/components/appBody/section/desktop/UXDesign.vue";
import Engineering from "@/components/appBody/section/desktop/Engineering.vue";
import Illustration from "@/components/appBody/section/desktop/Illustration.vue";
import UpArrow from "@/components/svg/UpArrow.vue";
import ResponsiveEngineering from "@/components/appBody/section/desktop/ResponsiveEngineering.vue";

export default {
  name: "appBody",
  store: store,
  components: {
    ResponsiveEngineering,
    HighLevelInfo,
    DesktopUXDesign,
    Illustration,
    Engineering,
    UpArrow,
  },
  mounted: function () {
    if (window.innerWidth >= 768) {
      this.$store.commit("setForDesktop");
    } else {
      this.$store.commit("setForMobile");
    }
  },
  data() {
    return {
      hasHovered: false,
    };
  },
  computed: {
    UXDesignTitle: function () {
      return "UX Design";
    },
    myColors: function () {
      return colors(this.isDark, this.$store);
    },
    UXSkillDescription: function () {
      return "I employ established and effective user experience practices\
       to provide value both to the end user business.";
    },
    uxTitle: function () {
      return this.$store.getters.uxTitle;
    },
    uxBody: function () {
      return this.$store.getters.uxBody;
    },
    codingTitle: function () {
      return this.$store.getters.codingTitle;
    },
    codingBody: function () {
      return this.$store.getters.codingBody;
    },
    illustrationTitle: function () {
      return this.$store.getters.illustrationTitle;
    },
    illusrationBody: function () {
      return this.$store.getters.illustrationBody;
    },
    isDesktop: function () {
      return this.$store.getters.isDesktop;
    },
  },
  methods: {
    renderHover: function () {
      this.hasHovered = true;
      console.log(`has hovered: ${this.hasHovered}`);
    },
    renderNormal: function () {
      this.hasHovered = false;
      console.log(`has hovered: ${this.hasHovered}`);
    },
    scrollToTop: function () {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
.mainBody {
  display: grid;
  width: 100%;
  height: fit-content;
  place-items: center;
  margin-top: 3rem;
}

@media screen and (max-width: 500px) {
  .mainBody {
    gap: 3rem;
  }
}

.mainBody > * {
  margin-bottom: 6.8rem;
}

@media screen and (max-width: 500px) {
  .mainBody > * {
    margin: 0;
  }
}

.to-top {
  position: fixed;
  width: 4.6rem;
  height: 3rem;
  z-index: 20;
  right: 2rem;
  bottom: 2rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  background: none;
  width: 7rem;
  border: none;
  cursor: pointer;
}

.to-top:hover {
  font-weight: bold;
}

.to-top > p {
  font-size: 0.75rem;
  text-transform: uppercase;
}
</style>
