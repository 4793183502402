export interface ITextStyle {
    weight: number,
    size: number,
    leading: number,
    charSpacing: number,
    transform: string,
    alignment: string,
}

export class TextStyle implements ITextStyle {
    constructor(
        public weight: number,
        public size: number,
        public leading: number,
        public charSpacing: number,
        public transform: string,
        public alignment: string,
    ) {
    }
    get formatted(): FormattedStyle {
        return {
            fontWeight: `${this.weight}`,
            fontSize: `${this.size}rem`,
            lineHeight: `${this.leading}rem`,
            letterSpacing: `${this.charSpacing}rem`,
            textTransform: `${this.transform}`,
            textAlign: `${this.alignment}`
        }
    }
}

export interface MobileHeaders {
    horizontalHeader: TextStyle
}

export interface DesktopHeaders {
    verticalHeader?: TextStyle,
    verticalSmallHeader?: TextStyle
}

export interface StyleGroup {
    headers?: {
        mobileHeader?: MobileHeaders,
        desktopHeader?: DesktopHeaders,
    },
    bodies?: ITextStyle[]
}

export interface FormattedStyle {
    fontWeight: string,
    fontSize: string,
    lineHeight: string,
    letterSpacing: string,
    textTransform: string,
    textAlign: string,
}