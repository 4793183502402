<template>
  <div class="mainBody">
    <MobileHighLevelInfo />
    <Section>
      <MobileSectionBody :skillTitle="uxTitle" :skillDescription="uxBody" />
      <DesignIllustration />
      <CTA path="/ux" />
    </Section>
    <Section>
      <MobileSectionBody :skillTitle="codingTitle" :skillDescription="codingBody" />
      <CodingIllustration />
      <CTA />
    </Section>
    <Section>
      <MobileSectionBody :skillTitle="illustrationTitle" :skillDescription="illusrationBody" />
      <IllustrationIllustration />
      <CTA />
    </Section>
  </div>
</template>

<script>
import MobileHighLevelInfo from "@/components/info/MobileHighLevelInfo.vue";
import DesignIllustration from "@/components/svg/DesignIllustration.vue";
import CodingIllustration from "@/components/svg/CodingIllustration.vue";
import IllustrationIllustration from "@/components/svg/IllustrationIllustration.vue";
import MobileSectionBody from "@/components/appBody/section/mobile/SectionBody.vue";
import Section from "@/components/appBody/section/Section.vue";
import CTA from "@/components/controls/button/CTA.vue";
import { store } from "@/store/store.js";
import { written } from "@/core/data/written_data.ts";
export default {
  name: "appBody",
  store: store,
  components: {
    MobileHighLevelInfo,
    DesignIllustration,
    CodingIllustration,
    IllustrationIllustration,
    MobileSectionBody,
    Section,
    CTA,
  },
  mounted: function () {
    if (window.innerWidth >= 768) {
      this.$store.commit("setForDesktop");
    } else {
      this.$store.commit("setForMobile");
    }
  },
  computed: {
    UXDesignTitle: function () {
      return "UX Design";
    },
    UXSkillDescription: function () {
      return "I employ established and effective user experience practices\
       to provide value both to the end user business.";
    },
    uxTitle: function () {
      return written.skills.ux.name;
    },
    uxBody: function () {
      return written.skills.ux.description;
    },
    codingTitle: function () {
      return written.skills.engineering.name;
    },
    codingBody: function () {
      return written.skills.engineering.description;
    },
    illustrationTitle: function () {
      return written.skills.illustration.name;
    },
    illusrationBody: function () {
      return written.skills.illustration.description;
    },
    isDesktop: function () {
      return this.$store.getters.isDesktop;
    },
  },
};
</script>

<style scoped>
button {
  width: calc(100% - 4rem) !important;
}
.mainBody {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 4rem;
}
</style>
