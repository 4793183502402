<template>
  <div class="icon-only-button">
    <button v-on:click="handleClick" :style="{ 'background-color': buttonBg }">
      <slot></slot>
    </button>
  </div>
</template>

<script>
import { store } from "../../../store/store.js";
import { colors } from "@/core/colors.js";
export default {
  name: "IconOnlyButton",
  store: store,
  props: {
    bg: {
      type: String,
    },
  },
  methods: {
    handleClick: function () {
      this.$emit("onSomething");
    },
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    mycolors: function () {
      return colors(this.isDark, this.$store);
    },
    shouldHide: function () {
      return this.$store.getters.shouldShowHeader;
    },
    buttonBg: function () {
      return this.bg == undefined ? this.mycolors.background : this.bg;
    },
  },
};
</script>

<style>
.icon-only-button button {
  display: flex;
  flex-flow: row nowrap;
  border: 0;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
