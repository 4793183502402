import { Projects, UXProjects } from "@/core/data/types";

export const uxProjects: UXProjects = {
  tagEditor: {
    name: "Luminoso Tag Editor",
    roles: ["Product strategist", "UX researcher", "UI Designer"],
    overview:
      "This feature allows users to uniquely define a set of concepts within a given dataset. It builds upon boolean concepts and optimizes the workflow around it.",
    path: "/ux/tag_editor",
    thumbnail: "images/design_system/analytics_ui/lumi_ds_thumb.webp",
    details: {
      description: {
        title: "The mandate",
        description: `I was asked to explore <i>tagging</i> 
         as a potential feature for <i>Daylight</i> by management.
          This was in an effort to be more competitive with
           other players in the text-analytics field such as
            <i>Tableau</i> and <i>Qualtrics.<i> I was told data analysts
             were using other tools to tag their data before
              uploading it to <i>Daylight</i> for analysis. 
              Therefore by adding a tagging feature, 
              we would be vertically integrating the product. Users
               would no longer need to use other products, and
                they would spend more time in <i>Daylight.</i> This would
                 in turn reduce churn and make us more competitive.`,
      },
      sections: [
        {
          title: "What is <i>tagging?</i>",
          intro: `The process of attaching a keyword
           to a particular data entry. For example in
            a dataset of beer reviews, you could tag
             all entries related to experience of drinking
              beer in the fall with the keyword “fall”. 
              Maybe you’re doing some market research and 
              you’re interested in creating new fall beer flavors.  
              With the tag, you would be able to reference all 
              data related to “fall” after completing your analysis.`,
        },
        {
          title: "The disconnect",
          intro: `Platforms like <i>Tableau</i> and Qualtrics use 
          document-level analysis whereas <i>Daylight</i> uses concept-level analysis. 
          <br><br> 
          With <i>document-level analysis,</i> the algorithm will parse 
          each data entry and assign them a sentiment of <i>positive, negative,</i> 
          or <i>neutral.</i> In this system, it is easy to tag an entry because 
          there is a one-to-one relationship between an entry and a tag. 
          <br><br> 
          In <i>concept-level analysis,</i> the algorithm parses multiple 
          entries at a time and picks out keywords or <i>concepts</i> that 
          encapsulates certain sentiments. This is a much more efficient 
          way to analyze data because it looks at the whole picture not 
          individual responses. it’s also more robust and comprehensive.
          <br><br> 
          It’s impossible to do tagging in <i>Daylight</i> due to its 
          <i>concept-level</i> analysis. This basic facts made it 
          challenging to do what management was asking.
            `,
        },
        {
          title: "User research",
          intro: `Despite the technical difficulties of this 
          feature, I decided to interview some of our users 
          to find gauge their appetites for this feature and 
          to also understand their workflows better. With the 
          help of our CSMs we scheduled 2 interviews per user 
          persona. We had 5 personas in total which means I 
          conducted 10 interviews in total. Our personas were 
          Manager, Scientist, Marketer and API developer. 
          The Manager and the Marketer were our most lucrative 
          personas so I would always pay special attention 
          to their needs. The API developer hardly ever 
          used the UI so they were less consequential for 
          this feature. The Scientist was our most aspirational 
          persona because we’ve barely tapped into their 
          industry and there’s a lot of potential there.
          <br><br>
          Over a period of 2 weeks, I met with all 10 users 
          over zoom, asked them questions and transcribed 
          their answers into Google Docs. Some questions I 
          asked them include: 
          `,
          subsections: [
            {
              title: "Manager",
              list: [
                "Purchasing decisions",
                "Light user",
                "Superior industry knowledge",
              ],
            },
            {
              title: "Marketer",
              list: [
                "Evangelist of good products",
                "Heavy user",
                "Mid-low data analysis knowledge",
              ],
            },
            {
              title: "Scientist",
              list: [
                "Somewhat influential on acquisition",
                "Heavy and sparse user",
                "Very technical level of data analysis",
              ],
            },
            {
              title: "API developer",
              list: [
                "No influence on purchases",
                "Heavy user",
                "Very technical level of data analysis",
              ],
            },
          ],
        },
        {
          intro: `Over a period of 2 weeks, I met with 
          all 10 users over zoom, asked them questions 
          and transcribed their answers into Google Docs. 
          Some questions I asked them include: `,
          list: [
            `Describe your daily workflow regarding 
            text-analysis to me.`,
            `Aside from Daylight, what other tools do 
            you use for your work and why?`,
            `Do you do any kind of tagging either now 
            or in the past. If not why?`,
            `Are there aspects of concepts in Daylight 
            you wish could be improved?`,
          ],
        },
        {
          title: "Interview results",
          intro: `Most users obtain a fresh batch of data at 
          regular intervals and then upload it into an 
          existing project for continued analysis. 
          The <i>Marketer</i> persona was unique because in addition 
          to adding data to an existing project, they were 
          also starting new projects on a very frequent basis 
          due to the nature of their industry whereby they 
          were always seeking out different avenues to sell products.
          <br/>
          <br/>
          Most personas used very common tools like 
          <i>Microsoft Excel,</i> <i>Microsoft Powerpoint</i> 
          and <i>Apple Keynote</i> in the course of their 
          analysis work. The Marketer persona would export 
          data from Daylight into CRM tools like <i>Salesforce,</i> 
          <i>HubSpot</i> and <i>Zendesk.</i>
          Surprisingly, only the <i>API developer</i> persona 
          was doing any form of tagging. They built tools 
          in-house for cleaning their data and tagging 
          entries before uploading via API code. In addition, 
          <em>no other persona’s except for API developer 
          expressed any interest in tagging.</em> 
          This was contrary to what 
          I had been told by management.
          <br />
          <br />
          There were also a lot of frustrations around the 
          process of selecting <i>concepts</i> in <i>Daylight</i> 
          and creating lists of <i>concepts.</i> Creating <i>boolean 
          concepts</i> was the most egregious of the 
          frustrations. <i>Boolean concepts</i> are concepts 
          either through an <code>AND</code> operation 
          (adding one concept to another) or <code>NOT</code> 
          operation  (subtracting one concept from others). 
          The interface and interaction for creating boolean 
          concepts was unintuitive and laborious.
          `,
        },
        {
          title: "Strategy",
          intro: `
          After conducting user research, it was clear 
          that there was a disconnect between the feature 
          I was asked to implement and the needs and 
          expectations of most of our users. The <i>Manager</i> and 
          <i>Marketer</i> personas, our most lucrative personas 
          seemed uninterested but they were growing 
          frustrated around other issues which seemed more 
          pressing from a business standpoint.
          <br />
          <br />
          Creating boolean concepts was the closest one 
          could get to tagging in <i>Daylight</i> because 
          doing so customizes generated concepts to be 
          referenced later. However, the process of 
          creating booleans was bad. Fixing this problem 
          would therefore satisfy management’s need to 
          have tagging as a product offering and also 
          make it easy for users to create <i>boolean concepts.</i>
          `,
        },
        {
          title: "Previous interaction",
          intro: `
          The biggest problem with the process of 
          <i>boolean concepts</i> was how daunting of a task
           it was. It involved moving between the 
           <i>side panel</i> to the left of the screen and 
           the visualization to right of the screen. 
           Here’s a breakdown of the steps:
          `,
          list: [
            `Switch to <i>search tab</i> in the side panel if it’s 
              not already open.
              `,
            `Select a concept of interest in the 
              visualization to the left of the screen.
              `,
            "Open the boolean editor above the visualization.",
            `Type in your boolean composition. As you update 
            the <i>boolean concept,</i> the <i>side panel</i> 
            switches to the <i>Active Concept</i> tab in 
            order to see your <i>boolean concept</i> in the 
            context of your active concepts. 
              `,
            `Switch back to the <i>Search tab</i> to see 
              what other <i>suggested concepts</i> you 
              could be adding to your boolean concept. 
              `,
            "Repeat steps 3 - 5 to add to you boolean concepts.",
          ],
        },
        {
          title: "Prototype solution",
          intro: `I prototyped a few solutions in Figma and 
          tested them with users. The solution that received 
          the most positive feedback from users put the 
          list in the Search tab into the boolean editor to 
          create a new editor called the Tag editor. 
          This solution simplifies the process greatly: `,
          list: [
            "Select a <i>concept</i> in the visualization.",
            "Open the boolean editor above the visualization.",
            "Type in your boolean composition.",
            `Observe the updating list of <i>suggested 
            concepts</i> in the same editor to see if there are 
            any additional concepts of interest. Check 
            any additional concept in the list and click 
            Include or Exclude to add or subtract those 
            concepts from the <i>boolean concept.</i>`,
          ],
          subsections: [
            {
              intro: `This particular flow involves far fewer 
              steps and also keeps the user engaged in the 
              concept building workflow. With every addition 
              or subtraction from the boolean concept, the 
              suggested list updates and the user can 
              determine if there are any additional <i>concepts</i> 
              to be added or subtracted.`,
            },
          ],
        },
        {
          title: "Outcomes",
          intro: `With the <i>Tag editor,</i> I was able to satisfy 
          the business need from management to have a 
          tagging feature in the product. More importantly, 
          I was able to vastly improve the experience 
          around creating boolean concepts. This in turn met 
          the needs of our most reliable persona type 
          <i>Manager</i> and  <i>Marketer.</i>
          <br />
          <br />
          As result, the creation of boolean concepts in 
          <i>Daylight</i> went up significantly. Some 
          users who didn’t even understand <i>boolean concepts</i> 
          previously got it for the first time. By utilizing 
          <i>boolean concepts,</i> users are able to 
          encapsulate business requirements into their 
          <i>concepts</i> which is very important.
          <br />
          <br />
          Finally, sales people at Luminoso were able to 
          add <i>tagging</i> to the sales pitch to their leads. 
          Our well-rounded feature-set made us more competitive 
          in the text-analytics field.
          `,
        },
      ],
    },
  },
  luminosoDesignSystem: {
    path: "/ux/luminoso_design_system",
    name: "design system for luminoso daylight",
    overview:
      "The Daylight product had a viable user interface when I first joined the team. Users were able to do the things they needed to do. But there were a few inconsistencies here and there. In some cases, users found it unintuitive to perform certain tasks. It became clear to me that we needed to unify Daylight's components and interactions. This would place us on a better path for making the interface more intuitive.",
    thumbnail: "images/design_system/analytics_ui/lumi_ds_thumb.webp",
    details: {
      description: {
        title: "Initial observations",
        description: `<p>The <i>Daylight</i> product had a viable user interface when I first joined the team. Users were able to do the things they needed to do. But there were a few inconsistencies here and there. In some cases, users were confused by the interface which in turn affected their ability to be productive in <i>Daylight.</i> <br><br> It became clear to me that we needed to unify the app’s components and interactions.
        This would place us on a better path for making the interface more intuitive.<p>`,
      },
      sections: [
        {
          title: "Cataloging the <i>Daylight</i> interface",
          intro:
            "As per best practices for building design systems, you start by taking inventory. I made notes of every detail of the web interface, inspected the website and talked to developers.",
          subsections: [
            {
              title: "Atoms",
              intro:
                "After a few weeks I started to build the design system in Figma. I started with typography, color and iconography. These make up the general styling for the web app.",
              details: [
                {
                  title: "Colors",
                  description:
                    "12-scale color gradation for each swatch. The wide range allows themed components e.g. a blue button can have a background color of <code>Blue 0</code> and text color of <code>Blue 6</code>. Having a larger difference in luminance between the foreground color and the background color creates a contrast ratio that allows for legibility in especially for reading. This in turn makes the interface accessible.",
                },
                {
                  title: "Spacing",
                  description:
                    "The spacing scale is in multiples of 4s. This makes it easy to proportionally increase or decrease margins and paddings.",
                },
                {
                  title: "Iconography",
                  description:
                    "The previous iconography felt a bit vague in some instances whereby it was sometimes hard to make out the meaning of the icons. Another issue was that the icons were distracting because they were too colorful and cartoon-like which felt inappropriate for a data-driven analysis web app. I  therefore designed a minimal, yet expressive icon set. This icon set conveys meaning and gets out of the way.",
                },
                {
                  title: "Bars and dots",
                  description:
                    "These form the building blocks for all charts and graphs used within Daylight. They utilize colors from the design system to convey meaning.",
                },
              ],
            },
          ],
        },
        {
          title: "Molecules",
          intro:
            "Next came the input components and controls like buttons, drop downs, and checkboxes",
          subsections: [
            {
              title: "Buttons",
              intro:
                "Themed button communicate behavoir intuitively. Blues perform an action, greens submits a form and red deletes something or cancels an action.",
            },
            {
              title: "Checkboxes and Toggles",
              intro:
                "I wanted the design of these to be as close as possible to native browser controls. The purpose was to make these so recognizable to users such that there wouldn't be an confusion.",
            },
            {
              title: "Banners",
              intro:
                "I standardized response banners and color-coded them. Blue for information, green for successes, yellow for warning s and red for errors.",
            },
            {
              title: "Tooltips",
              intro:
                "<i>Daylight</i> uses tooltips to convey small bits of information. The <i>text-only</i> tooltip is for giving the user helpful hints and tips. The <i>list</i> and <i>text list</i> in the <i>graphic + text</i> tooltips provide contextual information within visualizations.",
            },
          ],
        },
        {
          title: "Organisms",
          intro:
            "At this point, I had the building blocks <i>(atoms)</i> to compose functional interfaces. I defined <i>lists,</i> <i>tables,</i> <i>tiles</i> and <i>overlays.</i> ",
          subsections: [
            {
              title: "Visualizations",
              intro:
                "In <i>Daylight,</i> all except one type of visualization is composed of either <i>bars</i> or <i>dots.</i> The visualizations are <i>bar charts,</i> <i>plot graphs</i> and word <i>clouds.</i>",
            },
            {
              title: "Tiles",
              intro:
                "Tiles are critical for any functional application especially data analysis apps. <i>Daylight</i> uses a lot of dashboards and panels for which tiles are the foundation. The tiles have rounded corners and a drop shadow. This helps to visually group the components within the tile.",
            },
            {
              title: "",
              intro:
                "A component that is present in most of the pages is the side panel. It is on the left side of the page and contains critical controls for manipulating data. The side panel is made up of a collection of tiles.",
            },
            {
              title: "Templates",
              intro:
                "With all the components accounted for, it was time to consider how these would be arranged on a page. Layouts are the blueprints for how we structure the pages based on the use case. There are four main layouts for <i>Daylight.</i>",
            },
            {
              title: "Pages",
              intro:
                "Once all the pieces are in place, it’s easy to build pages based on the desired use case. Below are a couple of example pages based on this design system.",
            },
            {
              title: "Side panel",
              intro:
                "A component that is present in most of the pages is the side panel. It is on the left side of the page and contains critical controls for manipulating data. The side panel is made up of a collection of tiles.",
            },
          ],
        },
      ],
    },
  },
  SDdesignSystem: {
    path: "/ux/sentinel_design_system",
    name: "design system for sentinel digital",
    overview:
      "Design guide, product principles,\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      accessibility standards and voice and tone of\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      the Sentinel Digital product.",

    thumbnail: "images/SD_DS_thumb.webp",
    details: {
      description: {
        title: "Sentinel Design System",
        subtitle: "Process",
        secondSubtitle: "Stage 1: Atomic Design",
      },
      steps: [
        {
          name: `<em>Stage 1:</em> Atomic Design`,
          description:
            "To design a system that is\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                both versatile and cohesive, I had to start\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                with the small building blocks e.g.\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                typography, color, spacing etc. From there,\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                I worked on how these building blocks can be\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                configured to form larger elements and how\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                those elements can be grouped to create\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                larger interfaces and so on. This\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                technique is a well-established design\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                process popularized by Brad Frost in his\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                seminal 2013 article\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <a style='color: #F08D4F; font-weight: bold;' class='p-link'  href='https://bradfrost.com/blog/post/atomic-web-design/'> Atomic Design</a>.",
        },
        {
          name: "<em>Stage 2:</em> Layouts",
          description:
            "With the building blocks in place,\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            I turned my attention towards constructing usable\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            layouts that would enable users to complete their\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            intended tasks, be it filling out forms, sharing\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            content or changing preferences.",
        },
        {
          name: "<em>Stage 3:</em> Interactions",
          description:
            "To complete the  interface's look\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            and feel, I had to account for how the elements\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            react to the users interaction and ensure that\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            it felt smooth and natural. This means\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            everything from button states to animation\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            styles had to be accounted for.",
        },
        {
          name: "<em>Stage 4:</em> Invite collaboration",
          description:
            "Over the course of a few weeks,\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            I held several working sessions with fellow\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            designers, product owners, mangers and\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            developers. The purpose of these meetings\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            was to introduce others to this project,\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            some of whom weren't even familiar with the\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            concept of design systems so I ended up\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            educating some of my coworkers.\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            The other purpose was to invite others to\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            review the work in order to suggest revisions\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            and make contributions where necessary.",
        },
        {
          name: "<em>Stage 5:</em> Use",
          description:
            "Upon getting the design system\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            to a good state, it was time to start using\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            it. I used it as a blueprint for building out\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            the Sentinel designs. While using it, I made\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            a notes of tweaks needed for the system in\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            order to allow for a flexible, robust\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            system. Whenever I had time between hard\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            deadlines, I would make tweaks to the system\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            and run them by fellow designers. ",
        },
      ],
      verification: {
        subtitle: "Continued maintenance",
        description:
          "As Sentinel Digital grows and\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          features are added and removed, it is\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          necessary to continually update the design\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          system to reflect the latest and future\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          state of the web app. To this effect, my\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          team members and I frequently review the\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          system to discuss and update it.",
      },
    },
  },

  sentinel: {
    path: "/ux/sentinel_digital",
    name: "Sentinel Digital",
    overview:
      "A subscription based magazine web application which is\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      companion to a print magazine.",
    thumbnail: "images/SD_thumb.webp",
    details: {
      description: {
        title: "Description",
        description:
          "Sentinel Digital is a web application that\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          serves as a counterpart for the Christian Science Sentinel\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          print magazine. It offers a convenient and comfortable\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          digital reading experience.",
      },
      role: ["UX designer", "UX researcher", "Product strategist"],
      targetAudience: {
        title: "Target audience",
        description:
          "This web application is tailored for subscribers\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          who prefer digital platforms over print and for those who would\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          like the convenience of a digital experience in situations where\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          they don't have the print magazines with them.",
      },
      businessGoals: [
        "Provide convenient access to sentinel product",
        "Supplement print subscription to offer more value for the customer",
        "Future-proof the sentinel by establishing a build-release pipeline",
      ],
      uxProcess: {
        processes: [
          {
            title: "Personas",
            description:
              "We spent a couple of months collecting and\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              analyzing subscriber survey data  in order to group our\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              subscribers into 'buckets' for the purpose of extrapolating\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              personas to guide us in tailoring this product. We initially\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              identified 12 separate personas but  narrowed them down to 3.\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              These 3 represented broadest customer needs and the most diversity.",
          },
          {
            title: "Target audience",
            description:
              "This web application is tailored for subscribers\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              who prefer digital platforms over print and for those who\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              would like the convenience of a digital experience in\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              situations where they don't have the print magazines with them.",
          },
          {
            title: "Customer needs",
            description:
              "After identifying our top 3 \
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          persona's, we set out to conduct interviews with customers\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          who fell within these 3 persona types in order to understand customer\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          needs. The team used information from those interviews to construct a \
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          story map to outline what the product *Must* do, *Should* do, *Could* \
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          do and *Won't* do.",
            listDetails: [
              {
                description:
                  "The most actionable information from the exercise can be\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          distilled down to the following list in no particular order;",
                list: [
                  "Clean, distraction-free",
                  "Right-sized content",
                  "Optimized for sharing on social media",
                  "Mobile-first",
                ],
              },
            ],
          },
          {
            title: "Clean UI",
            listDetails: [
              {
                title: "Reading experience",
                description:
                  "The design of the reading experience is as clutter-free as\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          possible with very legible typography optimum for reading.",
              },
              {
                title: "Spacious and simple",
                description:
                  "There's an ample amount of whitespace built into the interface which\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  helps to group information into functional units. Additionally, visual elements are\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  also crafted to avoid overwhelming the user.",
              },
              {
                title: "Unified color scheme",
                description:
                  "The web app was designed with a simple effective color scheme\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          that ties everything together. At the same time, the colors are robust enough\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          to handle any use case required by the app.",
              },
            ],
          },
          {
            title: "Right-sized content",
            description:
              "This platform offers a carefully selected sub-set of content from\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              the print magazine. What's available is essential and will not overwhelm the user\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              who doesn't have a lot of time to endlessly browse through content to find what they want to read.",
          },
          {
            title: "Mobile-first",
            description:
              "Taking a mobile-first approach to the design means that every page has\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              to work well on mobile and only after perfecting the mobile view do we figure out how\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              that would translate to larger screens. Most of our users don't always have the luxury\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              of being in front of their computers so we have to give them a satisfying experience\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              whilst on their smart phones",
          },
        ],
      },
      verification: {
        title: "Solution verification",
        description:
          "To ensure that the web app met the needs of our users, user tests had to be conducted\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          paying attention to all the key features outlined above. Aside from a few changes cosmetic changes\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          needed to make the interface more accessible, by and large, the final solution checked out with our\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          users which was what we were hoping for. The web app is in early beta and continues to be user tested\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          and refined with the intention of launching a public beta early 2021.",
      },
    },
  },
};

export const projects: Projects = {
  SDdesignSystem: {
    path: "/ux/sentinel_design_system",
    name: "design system for sentinel digital",
    overview:
      "Design guide, product principles,\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  accessibility standards and voice and tone of\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  the Sentinel Digital product.",
    thumbnail: "../../assets/images/SD_DS_thumb.webp",
    details: {
      description: {
        title: "Sentinel Design System",
        subtitle: "Process",
        secondSubtitle: "Stage 1: Atomic Design",
      },
      steps: [
        {
          name: "Atomic Design",
          description:
            "To design a system that is\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            both versatile and cohesive, I had to start\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            with the small building blocks e.g.\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            typography, color, spacing etc. From there,\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            I worked on how these building blocks can be\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            configured to form larger elements and how\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            those elements can be grouped to create\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            larger interfaces and so on. This\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            technique is a well-established design\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            process popularized by Brad Frost in his\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            seminal 2013 article\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <a style='color: #F08D4F; font-weight: bold;' class='p-link'  href='https://bradfrost.com/blog/post/atomic-web-design/'> Atomic Design</a>.",
        },
        {
          name: "Layouts",
          description:
            "With the building blocks in place,\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        I turned my attention towards constructing usable\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        layouts that would enable users to complete their\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        intended tasks, be it filling out forms, sharing\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        content or changing preferences.",
        },
        {
          name: "Interactions",
          description:
            "To complete the  interface's look\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        and feel, I had to account for how the elements\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        react to the users interaction and ensure that\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        it felt smooth and natural. This means\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        everything from button states to animation\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        styles had to be accounted for.",
        },
        {
          name: "Invite collaboration",
          description:
            "Over the course of a few weeks,\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        I held several working sessions with fellow\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        designers, product owners, mangers and\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        developers. The purpose of these meetings\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        was to introduce others to this project,\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        some of whom weren't even familiar with the\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        concept of design systems so I ended up\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        educating some of my coworkers.\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        The other purpose was to invite others to\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        review the work in order to suggest revisions\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        and make contributions where necessary.",
        },
        {
          name: "Use",
          description:
            "Upon getting the design system\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        to a good state, it was time to start using\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        it. I used it as a blueprint for building out\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        the Sentinel designs. While using it, I made\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        a notes of tweaks needed for the system in\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        order to allow for a flexible, robust\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        system. Whenever I had time between hard\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        deadlines, I would make tweaks to the system\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        and run them by fellow designers. ",
        },
      ],
      verification: {
        subtitle: "Continued maintenance",
        description:
          "As Sentinel Digital grows and\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      features are added and removed, it is\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      necessary to continually update the design\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      system to reflect the latest and future\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      state of the web app. To this effect, my\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      team members and I frequently review the\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      system to discuss and update it.",
      },
    },
  },
  luminosoDesignSystem: {
    path: "/ux/luminoso_design_system",
    name: "design system for luminoso daylight",
    overview:
      "The Daylight product had a viable user interface when I first joined the team. Users were able to do the things they needed to do. But there were a few inconsistencies here and there. In some cases, users found it unintuitive to perform certain tasks. It became clear to me that we needed to unify Daylight's components and interactions. This would place us on a better path for making the interface more intuitive.",
    thumbnail:
      "../../../assets/images/design_system/analytics_ui/lumi_ds_thumb.webp",
    details: {
      description: {},
    },
  },
  sentinel: {
    path: "/ux/sentinel_digital",
    name: "Sentinel Digital",
    overview:
      "A subscription based magazine web application which is\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  companion to a print magazine.",
    thumbnail: "../../../assets/images/SD_thumb.webp",
    details: {
      description: {
        title: "Description",
        description:
          "Sentinel Digital is a web application that\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      serves as a counterpart for the Christian Science Sentinel\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      print magazine. It offers a convenient and comfortable\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      digital reading experience.",
      },
      role: ["UX designer", "UX researcher", "Product strategist"],
      targetAudience: {
        title: "Target audience",
        description:
          "This web application is tailored for subscribers\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      who prefer digital platforms over print and for those who would\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      like the convenience of a digital experience in situations where\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      they don't have the print magazines with them.",
      },
      businessGoals: [
        "Provide convenient access to sentinel product",
        "Supplement print subscription to offer more value for the customer",
        "Future-proof the sentinel by establishing a build-release pipeline",
      ],
      uxProcess: {
        processes: [
          {
            title: "Personas",
            description:
              "We spent a couple of months collecting and\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          analyzing subscriber survey data  in order to group our\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          subscribers into 'buckets' for the purpose of extrapolating\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          personas to guide us in tailoring this product. We initially\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          identified 12 separate personas but  narrowed them down to 3.\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          These 3 represented broadest customer needs and the most diversity.",
          },
          {
            title: "Target audience",
            description:
              "This web application is tailored for subscribers\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          who prefer digital platforms over print and for those who\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          would like the convenience of a digital experience in\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          situations where they don't have the print magazines with them.",
          },
          {
            title: "Customer needs",
            description:
              "After identifying our top 3 \
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      persona's, we set out to conduct interviews with customers\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      who fell within these 3 persona types in order to understand customer\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      needs. The team used information from those interviews to construct a \
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      story map to outline what the product *Must* do, *Should* do, *Could* \
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      do and *Won't* do.",
            listDetails: [
              {
                description:
                  "The most actionable information from the exercise can be\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      distilled down to the following list in no particular order;",
                list: [
                  "Clean, distraction-free",
                  "Right-sized content",
                  "Optimized for sharing on social media",
                  "Mobile-first",
                ],
              },
            ],
          },
          {
            title: "Clean UI",
            listDetails: [
              {
                title: "Reading experience",
                description:
                  "The design of the reading experience is as clutter-free as\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      possible with very legible typography optimum for reading.",
              },
              {
                title: "Spacious and simple",
                description:
                  "There's an ample amount of whitespace built into the interface which\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              helps to group information into functional units. Additionally, visual elements are\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              also crafted to avoid overwhelming the user.",
              },
              {
                title: "Unified color scheme",
                description:
                  "The web app was designed with a simple effective color scheme\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      that ties everything together. At the same time, the colors are robust enough\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      to handle any use case required by the app.",
              },
            ],
          },
          {
            title: "Right-sized content",
            description:
              "This platform offers a carefully selected sub-set of content from\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          the print magazine. What's available is essential and will not overwhelm the user\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          who doesn't have a lot of time to endlessly browse through content to find what they want to read.",
          },
          {
            title: "Mobile-first",
            description:
              "Taking a mobile-first approach to the design means that every page has\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          to work well on mobile and only after perfecting the mobile view do we figure out how\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          that would translate to larger screens. Most of our users don't always have the luxury\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          of being in front of their computers so we have to give them a satisfying experience\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          whilst on their smart phones",
          },
        ],
      },
      verification: {
        title: "Solution verification",
        description:
          "To ensure that the web app met the needs of our users, user tests had to be conducted\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      paying attention to all the key features outlined above. Aside from a few changes cosmetic changes\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      needed to make the interface more accessible, by and large, the final solution checked out with our\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      users which was what we were hoping for. The web app is in early beta and continues to be user tested\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      and refined with the intention of launching a public beta early 2021.",
      },
    },
  },
  coding: {
    overview:
      "Even though I do more UX design\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  than programming, I have spent a\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  substantial amount of time learning how to\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  code and exploring a variety of programming\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  languages for a diversity of purposes. The\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  knowledge learned and skills acquired from\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  my exploration of software has been come in\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  very handy for freelance web development\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  projects as was well as UX design projects\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  especially for making developer handoffs\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  more seamless.",
    intro: {
      title: "Languages and frameworks",
      description:
        "Below is a breakdown of the languages\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                and frameworks  and my level of\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                proficiency in them.",
    },
    breakdown: [
      {
        name: "javascript",
        languages: [
          {
            name: "Vanilla",
            experience: 98,
          },
          {
            name: "Vue",
            experience: 92,
          },
          {
            name: "Angular",
            experience: 85,
          },
          {
            name: "React",
            experience: 48,
          },
        ],
      },
      {
        name: "other web languages",
        languages: [
          {
            name: "HTML",
            experience: 100,
          },
          {
            name: "CSS",
            experience: 100,
          },
          {
            name: "SCSS",
            experience: 80,
          },
          {
            name: "Elm",
            experience: 40,
          },
        ],
      },
      {
        name: "Mobile",
        languages: [
          {
            name: "Flutter",
            experience: 75,
          },
          {
            name: "Swift",
            experience: 45,
          },
        ],
      },
      {
        name: "Systems",
        languages: [
          {
            name: "Dart",
            experience: 92,
          },
          {
            name: "Python",
            experience: 75,
          },
          {
            name: "Rust",
            experience: 35,
          },
        ],
      },
    ],
  },
  illustrations: [
    {
      title: "System Icons",
      date: "February 2020",
      description:
        "A collection\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              of custom system icons for a web\
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              app’s user interface.",
    },
    {
      title: "Family album",
      date: "July  2019",
    },
    {
      title: "Teen Zine Cover",
      date: "July 2019",
    },
    {
      title: "Parot perch",
      date: "June  2019",
    },
    {
      title: "Triumph",
      date: "May  2019",
    },
    {
      title: "Halloween",
      date: "March  2019",
    },
    {
      title: "Reading book",
      date: "February  2019",
    },
  ],
};
