<template>
  <div
    :style="{'border-color': myColors.main, 
    'grid-template-rows': layoutConfiguration, 
    height: boxHeight, 
    left: horizontalPosition,
    width: textBoxWidth }"
    class="text-section-container"
  >
    <div class="top-container">
      <div class="text-container">
        <p :style="{color: myColors.onBackground}">{{bodyText}}</p>
      </div>
    </div>
    <div class="bottom-container" :style="{height: bottomBoxHeight}">
      <div :style="{'background-color': myColors.main}" class="bottom-rect"></div>
      <div :style="{'background-color': myColors.main}" class="top-rect"></div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";
import { data } from "@/core/data/index";
import Vue from "vue";

export default Vue.extend({
  name: "TextSection",
  store: store,
  props: {
    bodyText: {
      type: String,
      required: true,
    },
    layoutConfiguration: {
      type: String,
      required: true,
    },
    boxHeight: {
      type: String,
      default: "",
    },
    bottomBoxHeight: {
      type: String,
      default: "",
    },
    horizontalPosition: {
      type: String,
      default: "",
    },
    textBoxWidth: {
      type: String,
      default: "18.4rem",
    },
  },
  computed: {
    uxBody: function () {
      return data.written.skills.ux.description;
    },
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.isDark, this.$store);
    },
  },
});
</script>

<style scoped>
.text-section-container {
  /* width: 18.4rem; */
  /* height: 10.4rem; */
  /* display: flex;
  flex-flow: column;*/
  position: relative;
  display: grid;
  /* grid-template-rows: auto 2rem; */
  /* left: 7.85rem; */
  border-left: 0.2rem solid;
}
.top-container {
  width: 100%;
  /* height: 8.375rem; */
}
.bottom-container {
  width: 4.375rem;
  /* height: 2rem; */
  display: grid;
  grid-template-rows: 1fr 2fr;
}
p {
  text-align: left;
  line-height: 1.75rem;
  position: relative;
  left: 0.5rem;
}
.bottom-rect {
  grid-column: 3 / 7;
  /* height: 50%; */
  /* top: 0.5rem; */
  /* position: relative; */
}
.top-rect {
  grid-column: 1 / 4;
}
</style>