import { Skills } from "./types";

export const skills: Skills = {
  ux: {
    name: "ux design",
    description:
      "I employ established and effective user experience practices to provide value both to the end user and the business.",
  },
  engineering: {
    name: "software engineering",
    description:
      "A great user experience demands close coordination between design and development. My unique ability to both code and design helps to close the gap between conceptualization and implementation thereby ensuring product specifications are met.",
  },
  illustration: {
    name: "illustration",
    description:
      "As a formally trained artist, I’m able to bring my meaning and delight to projects by adding illustrations to tell stories and communicate ideas.",
  },
};
