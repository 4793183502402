<template>
  <div class="high-level">
    <h1 :style="{ color: myColors.foreground }">
      <span>{{ spitTitle().first }}</span>
      <Times />
      <span>{{ spitTitle().second }}</span>
    </h1>
    <p :style="{ color: myColors.foreground }" class="summary">{{ summary }}</p>
  </div>
</template>

<script>
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";
import Times from "@/components/svg/Times.vue";
import { data } from "@/core/data/index";
import Vue from "vue";

export default Vue.extend({
  store: store,
  components: {
    Times,
  },
  name: "HighLevelInfo",

  computed: {
    summary: function () {
      return data.written.highLevel.intro;
    },
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.isDark, this.$store);
    },
  },
  methods: {
    spitTitle() {
      var first = data.written.highLevel.title.split(",")[0];
      var second = data.written.highLevel.title.split(",")[1];
      return {
        first: first,
        second: second,
      };
    },
  },
});
</script>

<style scoped>
h1 {
  text-transform: uppercase;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  width: 100%;
  margin: 0 auto;
  margin-left: 2rem;
}
h1 > span {
  text-align: left;
  align-self: flex-start;
}
.high-level {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: calc(100% - 4rem);
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.summary {
  font-size: 16px;
  font-weight: 400;
  width: calc(100% - 4rem);
}

p {
  text-align: left;
  margin-top: 16px;
  width: 360px;
  line-height: 28px;
  padding: 0;
}
</style>
