var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",style:(_vm.hover ? _vm.hoverStyle : _vm.unHoverStyle),on:{"click":_vm.goToIllustrations,"mouseleave":function($event){_vm.hover = false},"mouseover":function($event){_vm.hover = true}}},[_c('div',{staticClass:"inner"},[_c('div',{class:['for-mobile', _vm.isDark ? 'cover-dark' : 'cover-light']}),_c('div',{staticClass:"info positioned"},[_c('h3',{class:[
          _vm.isDark ? 'dark-header' : 'light-header',
          'home-vert-header',
          _vm.isDark ? 'border-dark' : 'border-light',
        ]},[_vm._v(" "+_vm._s(_vm.illustrationTitle)+" ")]),_c('p',{class:[
          _vm.isDark ? 'body-dark' : 'body-light',
          _vm.isDark ? 'border-dark' : 'border-light',
          _vm.isDark ? 'after-dark' : 'after-light',
        ]},[_vm._v(" "+_vm._s(_vm.illustrationBody)+" ")]),_c('div',{staticClass:"cta"},[_c('CTA',{attrs:{"label":"learn more","path":"/ux"}})],1)]),_c('div',{staticClass:"scroll positioned"},[_c('Scroll')],1),_c('div',{staticClass:"pencil positioned"},[_c('Pencil')],1),_c('div',{staticClass:"top-left-handle positioned"},[_c('TopLeftHandle')],1),_c('div',{staticClass:"top-right-handle positioned"},[_c('RightTopHandle')],1),_c('div',{staticClass:"bottom-left-handle positioned"},[_c('BottomRightHandle')],1),_c('div',{staticClass:"bottom-right-handle positioned"},[_c('TopRightHandle')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }