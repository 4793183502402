var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",style:(_vm.hover ? _vm.hoverStyle : _vm.unHoverStyle),on:{"click":_vm.gotoCoding,"mouseleave":function($event){_vm.hover = false},"mouseover":function($event){_vm.hover = true}}},[_c('div',{staticClass:"top-left-plus positioned"},[_c('PlusResponsive',{attrs:{"stroke-color":_vm.isDark ? 'hsl(16, 65.5%, 61.4%)' : 'hsl(16, 94.9%, 61.4%)'}})],1),_c('div',{staticClass:"top-right-plus positioned"},[_c('PlusResponsive',{attrs:{"stroke-color":_vm.isDark ? 'hsl(47, 100%, 98.2%)' : 'hsla(235, 12%, 20%, 1)'}})],1),_c('div',{staticClass:"bottom-plus positioned"},[_c('PlusResponsive',{attrs:{"stroke-color":_vm.isDark ? 'hsl(47, 100%, 98.2%)' : 'hsla(235, 12%, 20%, 1)'}})],1),_c('div',{staticClass:"inner"},[_c('div',{class:['bottom-stripes', 'positioned']},[_c('div',{class:_vm.isDark ? 'dark-decoration' : 'light-decoration'}),_c('div',{class:_vm.isDark ? 'dark-decoration' : 'light-decoration'})]),_c('div',{class:[
        'box',
        'positioned',
        _vm.isDark ? 'dark-decoration' : 'light-decoration',
      ]}),_c('div',{staticClass:"info-box positioned"},[_c('h3',{class:['home-vert-header', _vm.isDark ? 'dark-header' : 'light-header']},[_vm._v(" "+_vm._s(_vm.engineeringTitle)+" ")]),_c('p',{class:[
          'after-decoration',
          _vm.isDark ? 'body-dark' : 'body-light',
          _vm.isDark ? 'border-dark' : 'border-light',
          _vm.isDark ? 'after-dark' : 'after-light',
        ]},[_vm._v(" "+_vm._s(_vm.engineeringBody)+" ")]),_c('div',{staticClass:"cta"},[_c('CTA',{attrs:{"label":"learn more","path":"/ux"}})],1)]),_c('div',{staticClass:"radio-box positioned"},[_c('RadioBox')],1),_c('div',{staticClass:"top-one positioned"},[_c('TopOne')],1),_c('div',{staticClass:"top-zero positioned"},[_c('TopZero')],1),_c('div',{staticClass:"top-right-zero positioned"},[_c('TopRightZero')],1),_c('div',{staticClass:"bottom-one positioned"},[_c('BottomOne')],1),_c('div',{staticClass:"bottom-left-zero positioned"},[_c('BottomLeftZero')],1),_c('div',{staticClass:"bottom-right-zero positioned"},[_c('BottomRightZero')],1),_c('div',{staticClass:"l-shape"},[_c('LShaped')],1),_c('div',{staticClass:"stripes positioned"},[_c('div',{class:_vm.isDark ? 'dark-decoration' : 'light-decoration'}),_c('div',{class:_vm.isDark ? 'dark-decoration' : 'light-decoration'})]),_c('div',{class:[
        'mobile-cover',
        'positioned',
        _vm.isDark ? 'dark-cover' : 'light-cover',
      ]})])])
}
var staticRenderFns = []

export { render, staticRenderFns }