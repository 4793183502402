<template>
  <header
      id="app-bar"
      v-scroll="handleScroll"
      :style="{ 'background-color': myColors.background, /*flexFlow: buttonPosition,*/ boxShadow: barBoxShadow  }"
  >
    <IconOnlyButton @onSomething="toggleDark">
      <Sun v-if="!isDark"/>
      <Crescent v-else/>
    </IconOnlyButton>
    <button @click="goToHome">
      <AppBarHeader v-if="shouldHide" :textColor="myColors.onBackground"/>
    </button>
  </header>
</template>

<script>
import AppBarHeader from "./AppBarHeader.vue";
import IconOnlyButton from "@/components/controls/button/IconOnlyButton.vue";
import Sun from "@/components/svg/Sun.vue";
import Crescent from "@/components/svg/Crescent.vue";
import {store} from "@/store/store";
import {colors} from "@/core/colors.js";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (event) {
      if (binding.value(event, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});

Vue.use(VueRouter);

export default {
  name: "AppBar",
  store: store,
  props: {
    name: String,
  },
  data() {
    return {
      scrollIsBelowIntro: true,
    };
  },
  components: {AppBarHeader, IconOnlyButton, Sun, Crescent},
  methods: {
    toggleDark: function () {
      this.$store.commit("toggleScheme");
      return "";
    },
    handleScroll: function () {
      if (this.$route.name != "Home") {
        this.$store.commit("setHeaderDisplay", true);
      } else {
        if (window.scrollY >= 158) {
          this.$store.commit("setHeaderDisplay", true);
        } else {
          this.$store.commit("setHeaderDisplay", false);
        }
      }
    },
    goToHome: function () {
      this.$router.push("/");
    },
  },
  mounted() {
    console.log(this.$route.name);
    if (this.$route.name != "Home") {
      this.$store.commit("setHeaderDisplay", true);
    } else {
      this.$store.commit("setHeaderDisplay", false);
    }
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.isDark, this.$store);
    },
    appBarButton: function () {
      return this.$store.isDesktop
          ? "transparent"
          : `${colors(this.isDark, this.$store)}`;
    },
    isDesktop: function () {
      return this.$store.getters.isDesktop;
    },
    shouldHide: function () {
      return this.$store.getters.shouldShowHeader;
    },
    buttonPosition: function () {
      if (this.$store.getters.isDesktop) {
        return "row-reverse nowrap";
      } else {
        return "";
      }
    },
    barBoxShadow: function () {
      return this.$store.getters.isDesktop && !this.shouldHide
          ? "none"
          : "0px 4px 4px rgba(0, 0, 0, 0.1)";
    },
  },
};
</script>

<style scoped>
button {
  margin: 0 auto;
  border: none;
  background: none;
  cursor: pointer;
}

#app-bar {
  display: flex;
  flex-flow: row-reverse nowrap;
  background-color: white;
  width: 100vw;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 25;
  cursor: pointer;
}
</style>
