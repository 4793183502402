<template>
  <div
    :style="{ 
      'border-color': myColors.foreground,
      }"
    class="skill-section"
    ref="uxSection"
  >
    <div class="section-header" :style="isUXSection ? uxHeaderStyle : noStyle ">
      <h1 :style="{ color: myColors.main ,  'border-color': myColors.main, }">{{ skillTitle }}</h1>
    </div>
    <UXDivider v-if="number==1" />
    <Pointer v-if="number == 1" :parentPosition="parentPosition" />
    <p :style="isUXSection ? bodyStyle : noStyle">{{ skillDescription }}</p>
  </div>
</template>

<script>
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";
import Pointer from "@/components/svg/Pointer";
import UXDivider from "@/components/svg/UXDivider.vue";
export default {
  store: store,
  data() {
    return {
      uxHeaderStyle: {
        display: "flex",
        flexFlow: "row nowrap",
        overflow: "hidden",
        width: "6rem",
        height: "11.5rem",
        justifyContent: "flex-end",
        alignItems: "baseline",
      },
      bodyStyle: {
        color: `${colors(this.isDark, this.$store).foreground}`,
        marginTop: !this.$store.getters.isDesktop ? "-0.7rem" : "1rem",
        marginLeft: "-3.75rem",
      },
      noStyle: {},
      parentPosition: {},
    };
  },
  props: {
    skillTitle: String,
    skillDescription: String,
    number: Number,
  },
  name: "MobileSectionBody",
  components: {
    UXDivider,
    Pointer,
  },
  mounted() {
    let position = this.$refs.uxSection;
    this.parentPosition = {
      x: position.offsetTop,
      y: position.offsetLeft,
    };
  },
  computed: {
    myColors: function () {
      return colors(this.isDark, this.$store);
    },
    isDark: function () {
      return this.$store.getters.isDark;
    },
    borderVisibility: function () {
      return this.$store.getters.isDesktop ? "none" : "2px solid";
    },
    textOrientation: function () {
      return this.$store.getters.isDesktop ? "sideways-lr" : "horizontal-tb";
    },
    arrangement: function () {
      return this.$store.getters.isDesktop ? "row nowrap" : "column";
    },
    containerWidth: function () {
      return this.$store.getters.isDesktop ? "20rem" : "calc(100% - 4rem)";
    },
    sectionAlignment: function () {
      return this.$store.getters.isDesktop ? "start" : "";
    },
    bodyMarginTop: function () {
      return this.$store.getters.isDesktop ? "0" : "1rem";
    },
    textHeight: function () {
      return this.$store.getters.isDesktop ? "20rem" : "100%";
    },
    headerIndent: function () {
      return this.$store.getters.isDesktop && this.number == 1 ? "2.8rem" : "0";
    },
    isUXSection: function () {
      return this.$store.getters.isDesktop && this.number == 1 ? true : false;
    },
  },
};
</script>

<style scoped>
.ux_header {
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  width: 6rem;
  height: 11.5rem;
  justify-content: flex-end;
  align-items: baseline;
}
.skill-section {
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  text-align: left;
  border-top: 2px solid;
  padding-top: 2rem;
  width: calc(100% - 4rem);
  height: 100%;
}
.skill-section h1 {
  text-transform: uppercase;
  letter-spacing: 21.5%;
  font-weight: 600;
}
.skill-section p {
  margin-top: 16px;
  line-height: 28px;
}
</style>
