<template>
  <svg width="91" height="209" viewBox="0 0 91 209" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M81.8909 30.7147C81.5298 30.3535 81.1686 29.8117 80.8074 29.4505C80.085 28.5475 79.182 27.8251 78.4596 27.1028C75.7506 24.5744 72.6805 22.5878 69.4297 21.143C64.7341 18.9759 59.6774 17.7117 54.0789 17.7117C47.9385 17.7117 41.9788 19.3371 36.9221 22.046C32.9489 24.2132 29.337 27.2834 26.2668 30.7147C24.6414 32.5207 23.3772 34.6879 22.2936 36.6744C19.5847 41.7312 18.1399 47.5103 18.1399 53.6506V172.484C18.1399 178.083 19.4041 183.32 21.5713 187.835C23.016 191.086 25.0026 193.975 27.3504 196.504L27.531 196.684C27.7116 196.865 27.8922 197.045 28.0728 197.226L28.434 197.587C29.1564 198.31 30.0594 199.213 30.9624 199.935C37.2833 205.172 45.2296 208.423 54.0789 208.423C62.9282 208.423 71.0551 205.172 77.1954 199.935C84.9611 193.253 90.0179 183.501 90.0179 172.484V53.6506C90.1984 44.982 87.1283 37.0356 81.8909 30.7147ZM68.7073 172.484C68.7073 180.43 62.2058 186.932 54.2595 186.932H54.0789C46.1326 186.932 39.631 180.43 39.631 172.484V53.6506C39.631 46.6073 44.6878 40.6476 51.5505 39.3834C52.4535 39.2028 53.3565 39.2028 54.2595 39.2028C62.2058 39.2028 68.7073 45.7043 68.7073 53.6506V172.484Z"
      fill="#FFFDF7"
      :stroke="myColors.engineering.topLeftZero.depthOutline"
      stroke-miterlimit="10"
    />
    <path
      d="M78.4596 27.1027C75.7506 24.5743 72.6804 22.5878 69.4297 21.143C64.7341 18.9758 59.6774 17.7116 54.0789 17.7116C47.9385 17.7116 41.9788 19.337 36.9221 22.046C32.9489 24.2131 29.337 27.2833 26.2668 30.7146C24.6414 32.5206 23.3772 34.6878 22.2936 36.6744C19.5847 41.7311 18.1399 47.5103 18.1399 53.6506V172.484C18.1399 178.083 19.4041 183.32 21.5712 187.835C23.016 191.086 25.0026 193.975 27.3504 196.503L13.2637 182.417L13.0832 182.236C12.9026 182.056 12.722 181.875 12.5414 181.694L10.1936 179.347C4.41446 173.026 0.80249 164.538 0.80249 155.147V36.4938C0.80249 27.8251 3.87265 19.8788 9.10999 13.5579C15.6115 5.61154 25.725 0.55481 36.7415 0.55481C46.4938 0.55481 55.3431 4.34736 61.8446 10.6683L78.4596 27.1027Z"
      :stroke="myColors.engineering.topLeftZero.depthOutline"
      :fill="myColors.engineering.topLeftZero.depth"
      stroke-miterlimit="10"
    />
    <path
      d="M68.7066 53.6501V172.484C68.7066 180.43 62.2051 186.931 54.2588 186.931H54.0782C46.1319 186.931 39.6304 180.43 39.6304 172.484V169.594C46.3125 168.33 51.5498 162.37 51.5498 155.327V39.3829C52.4528 39.2023 53.3558 39.2023 54.2588 39.2023C62.2051 39.2023 68.7066 45.7038 68.7066 53.6501Z"
      :stroke="myColors.engineering.topLeftZero.depthOutline"
      :fill="myColors.engineering.topLeftZero.depth"
      stroke-miterlimit="10"
    />
    <path
      d="M13.2656 182.419L12.7217 181.875"
      :stroke="myColors.engineering.topLeftZero.depthOutline"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
import { store } from "@/store/store.js";
import { codingColors } from "@/core/colors.js";
export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style scoped>
svg {
}
</style>