<template>
  <svg
    class="ux-divider"
    width="72"
    height="163"
    viewBox="0 0 72 163"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.39137 162.883L2.39136 0.860413" :stroke="myColors.main" stroke-width="4" />
    <rect x="22.2109" y="140" width="49.7287" height="13.9135" :fill="myColors.main" />
    <rect x="1.02527" y="152.332" width="29.7451" height="10.5502" :fill="myColors.main" />
    <path d="M2.39136 162.882L2.39136 126.031" :stroke="myColors.main" stroke-width="4" />
    <rect x="22.2109" y="140" width="49.7287" height="13.9135" :fill="myColors.main" />
    <rect x="1.02527" y="152.332" width="29.7451" height="10.5502" :fill="myColors.main" />
  </svg>
</template>

<script>
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";
export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    right: function () {
      return this.$store.getters.isDesktop ? "1.45rem" : "0";
    },
    myColors: function () {
      return colors(this.isDark, this.$store);
    },
  },
};
</script>

<style scoped>
.ux-divider {
  width: 100%;
  align-self: end;
  position: relative;
  top: -10rem;
}
</style>