<template>
  <svg
    width="1rem"
    height="1rem"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 1L1 13M1 1L13 13"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { store } from "../../store/store";
export default {
  name: "Times",
  store: store,
  computed: {
    isDark: function() {
      return this.$store.getters.isDark;
    },
    color: function() {
      return this.isDark
        ? this.$store.getters.foregroundDark
        : this.$store.getters.foregroundLight;
    }
  }
};
</script>

<style>
</style>