<template>
  <div class="l-shaped">
    <svg
      fill="none"
      height="22"
      viewBox="0 0 20 22"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path :stroke="myColors.dLShape" d="M0.913086 20.9784H18.9131V0.978394" />
    </svg>
  </div>
</template>

<script>
import { store } from "@/store/store";
import { codingColors } from "@/core/colors.js";

export default {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return codingColors(this.isDark, this.$store);
    },
  },
};
</script>

<style scoped>
.l-shaped {
  display: grid;
  grid-column: 14 / span 1;
  grid-row: 17 / span 1;
}
</style>
