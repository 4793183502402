<template>
  <div
    class="container"
    style="
       {
        'background-color':mycolors.background ;
      }
    "
  >
    <AppBar />
    <AppBody />
  </div>
</template>

<script>
import AppBar from "@/components/app_bar/AppBar.vue";
import { store } from "@/store/store";
import { colors } from "@/core/colors.js";
import AppBody from "@/components/appBody/AppBody.vue";

export default {
  store: store,
  name: "App",

  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.isDark, this.$store);
    },
    backgroundColor: function () {
      if (this.isDark) {
        return this.$store.getters.backgroundDark;
      } else {
        return this.$store.getters.backgroundLight;
      }
    },
  },
  components: {
    AppBar,
    AppBody,
  },
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

textarea,
select,
input,
button {
  outline: none;
}

.container {
  display: grid;
  inline-size: 100vw;
  overflow-x: hidden;
}

.cta {
  height: 44px;
  background-color: #fffdf7;
  border: 1px solid;
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 3rem;
  transition: all 190 ease-out;
}

.cta:hover {
  cursor: pointer;
  background-color: #fff3cc;
}

.skill-illustration {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.section-break {
  height: 6.875rem;
  display: block;
}
</style>
