<template>
  <a href="#/ux"
    ><h1
      :class="shouldRotate ? 'rotate-text' : 'horizontal-text'"
      :style="[
        {
          color: myColors.main,
          top: topPosition,
        },
        headerStyle,
        position,
      ]"
    >
      {{ headerName }}
    </h1></a
  >
</template>

<script>
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";
import { textStyles } from "@/core/styles/text_styles.ts";
export default {
  name: "Header",
  store: store,
  props: {
    sectionName: String,
    shouldRotate: {
      type: Boolean,
      default: true,
    },
    topPosition: {
      type: String,
      default: "",
    },
    headerType: {
      type: String,
      default: "large",
    },
    isHomepage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      headerName: this.sectionName,
    };
  },
  computed: {
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
    position: function () {
      return this.isHomepage ? { left: "" } : { left: "10rem" };
    },
    headerStyle: function () {
      if (this.headerType == "large") {
        return {
          "font-size": "2.5rem",
          "font-weight": "600",
          // width: "14rem",
          // "letter-spacing": "0.5rem",
        };
      } else if (this.headerType == "small") {
        return {
          "font-size": "2.5rem",
          "font-weight": "600",
          width: "14rem",
          "letter-spacing": "0.2rem",
        };
      } else if (this.headerType == "xSmall") {
        return textStyles.headers.desktopHeader.verticalSmallHeader;
      }
      return null;
    },
  },
  myColors: function () {
    return colors(this.$store.getters.isDark, this.$store);
  },
};
</script>

<style scoped>
h1 {
  position: relative;
  float: right;
  font-weight: 500;
  text-transform: uppercase;
}
.rotate-text {
  text-orientation: sideways-right;
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
}
.horizontal-text {
  text-orientation: initial;
  writing-mode: lr;
  transform: rotate(0deg);
}
h1:hover {
  text-decoration: underline;
}
</style>
